import { forwardRef, Ref } from 'react';

import { stringifyDaysOld } from 'helpers';
import { InsightMetric } from 'types/Insights';
import Kit from 'types/Kit';

import { Typography } from '@repo/ui';
import MaturationIndexGraph from './MaturationIndexGraph';
import LogoSvg from 'assets/images/logos/logo-full.svg?react';

import styles from './MaturationIndexShareImage.module.scss';

const MaturationIndexShareImage = forwardRef(function MaturationIndexShareImage(
  {
    kit,
    subMetric,
    name,
  }: { kit: Kit; subMetric: InsightMetric; name: string },
  ref,
) {
  return (
    <div
      className={styles.cardContainer}
      style={{ width: 362, height: 362 }}
      ref={ref as Ref<HTMLDivElement> | null}
    >
      {' '}
      <div className='dark-grey mb-2'>
        <Typography variant='heading-m'>{`${name}'s gut looks like that of a`}</Typography>
      </div>
      <div className=''>
        <Typography
          variant={'heading-2xl'}
          className={subMetric.evaluation_color}
          serif
        >
          {stringifyDaysOld(subMetric.user_value, true)}
        </Typography>
      </div>
      <div className={styles.chartContainer}>
        <MaturationIndexGraph
          kit={kit}
          subMetric={subMetric}
          showXAxisLabel={false}
          disableAnimations={true}
          onlyCurrentKit={true}
        />
      </div>
      <div className={styles.logoContainer}>
        <LogoSvg />
      </div>
    </div>
  );
});

export default MaturationIndexShareImage;
