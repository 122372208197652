import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';

export async function getAllBookmarks(kitId: string, pageType: string) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/feedback/${kitId}/${pageType}`,
    {},
  );
  return response;
}

const useKitFeedback = (kitId: string | null, pageType: string) => {
  const kitFeedbackQuery = useQuery({
    queryKey: [apiName, 'feedback', kitId, pageType],
    queryFn: () => !!kitId && !!pageType && getAllBookmarks(kitId, pageType),
  });
  return kitFeedbackQuery;
};

export default useKitFeedback;
