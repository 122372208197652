import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { DiveDeeperSummaryCard } from '../DiveDeeperSummaryCard/DiveDeeperSummaryCard';

export const SeedingSummaryCard = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();

  if (!currentKitId) return <></>;
  return (
    <DiveDeeperSummaryCard
      key={`Seeding_SummaryCard`}
      title='Seeding at birth'
      description={`See what microbes you and your baby share (valid in the first 6 months).`}
      link={`/results/${currentKitId}/seeding`}
      linkText='Learn more'
    />
  );
};
