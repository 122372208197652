import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

export const getVaginalTypeResponse = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/vaginalType/${kitId}`,
    {},
  );
  return response?.result;
};
export const getVaginalTypeQuery = (kitId?: string | null) => ({
  queryKey: [apiName, 'vaginalType', kitId],
  queryFn: () => kitId && getVaginalTypeResponse(kitId),
});
const useVaginalType = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const vaginalTypeQuery = useSuspenseQuery(getVaginalTypeQuery(currentKitId));

  return vaginalTypeQuery;
};

export default useVaginalType;
