import { useEffect, useMemo, useRef } from 'react';

import useFeature from 'services/features/useFeature';
import { useUrlQuery } from 'hooks/useUrlQuery';

import { Header } from 'components';
import { useConsultLink } from '../Consults';

interface CalendlyEmbedProps {
  url: string;
  prefill?: Record<string, unknown>;
  pageSettings?: Record<string, unknown>;
  utm?: Record<string, unknown>;
}

const CalendlyEmbed: React.FC<CalendlyEmbedProps> = ({
  url,
  prefill,
  pageSettings,
  utm,
}) => {
  const embedContainerRef = useRef<HTMLDivElement>(null);
  const scriptElementRef = useRef<HTMLScriptElement | null>(null);
  const isWidgetInitialized = useRef<boolean>(false);

  useEffect(() => {
    const scriptId = 'calendly-script';

    const initWidget = () => {
      if (
        window.Calendly &&
        embedContainerRef.current &&
        !isWidgetInitialized.current
      ) {
        window.Calendly.initInlineWidget({
          url:
            url.indexOf('?') > 0
              ? `${url}&hide_event_type_details=1&hide_gdpr_banner=1`
              : `${url}?hide_event_type_details=1&hide_gdpr_banner=1`,
          parentElement: embedContainerRef.current,
          prefill: prefill,
          pageSettings: pageSettings,
          utm: utm,
        });
        isWidgetInitialized.current = true; // Mark as initialized to prevent duplication
      }
    };

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      script.id = scriptId;
      script.onload = initWidget;
      document.body.appendChild(script);
      scriptElementRef.current = script;
    } else if (!isWidgetInitialized.current) {
      // If script exists but widget is not initialized, try initializing.
      initWidget();
    }

    return () => {
      // Cleanup script to prevent re-initialization issues when component is remounted
      if (scriptElementRef.current) {
        document.body.removeChild(scriptElementRef.current);
        scriptElementRef.current = null;
      }
      isWidgetInitialized.current = false;
    };
  }, [url, prefill, pageSettings, utm]);

  return (
    <div ref={embedContainerRef} style={{ minWidth: 320, height: '80vh' }} />
  );
};

const ConsultsBooking = () => {
  const { isFeatureEnabled: showV2, variables } = useFeature('consults_v2');
  const cancellation = useUrlQuery().get('cancellation');
  const rescheduling = useUrlQuery().get('rescheduling');
  const consultLink = useConsultLink();

  const calendlyLink = useMemo(() => {
    if (cancellation) {
      return `https://calendly.com/cancellations/${cancellation}`;
    }
    if (rescheduling) {
      return `https://calendly.com/reschedulings/${rescheduling}`;
    }
    return showV2 &&
      variables?.use_calendly_test_link &&
      typeof variables?.calendly_test_link === 'string'
      ? variables.calendly_test_link +
          consultLink.substring(consultLink.indexOf('?'))
      : consultLink;
  }, [cancellation, rescheduling, consultLink]);

  return (
    <>
      <Header title={`Coaching`} />
      <CalendlyEmbed url={calendlyLink} />
    </>
  );
};

export default ConsultsBooking;
