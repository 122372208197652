import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { useAppDispatch } from 'store/store';
import { getImpersonateId, getUsername, impersonate } from 'store/user';
import { apiBasePath, apiName } from 'assets';
import { ImpersonateTableData } from 'views/PractitionerPortal/ImpersonateTable';

export const impersonateUser = createAsyncThunk(
  'user/impersonateUser',
  async (impersonateId: string, { dispatch }) => {
    dispatch(impersonate(impersonateId));
  },
);
const getImpersonatableKits = async () => {
  // make multiple requests to get every page and then stitch together
  const { kits: firstPage, totalPages } = await API.get(
    apiName,
    `${apiBasePath}/mainaccounts/impersonate/v2`,
    {},
  );
  if (totalPages === 1) {
    return firstPage;
  } else {
    const kits = firstPage;
    for (let i = 1; i < totalPages; i++) {
      const { kits: nextPage } = await API.get(
        apiName,
        `${apiBasePath}/mainaccounts/impersonate/v2?page=${i}`,
      );
      kits.push(...nextPage);
    }
    return kits;
  }
};

const useImpersonate = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const impersonateId = useSelector(getImpersonateId);
  const username = useSelector(getUsername);
  const { data: impersonatableKits, ...query } = useSuspenseQuery<
    ImpersonateTableData[]
  >({
    queryKey: ['impersonatableKits', username],
    queryFn: async () => await getImpersonatableKits(),
    // Add other options here if needed
  });
  const impersonate = useCallback(
    async (impersonateId: string, kitId?: string) => {
      await dispatch(impersonateUser(impersonateId)).unwrap();
      if (kitId) {
        navigate(`/results/${kitId}`);
      } else {
        navigate('/results');
      }
    },
    [dispatch, navigate],
  );
  const stopImpersonating = useCallback(async () => {
    await queryClient.removeQueries({
      predicate: query =>
        impersonateId ? query.queryKey.includes(impersonateId) : false,
    });
    await dispatch(impersonateUser('')).unwrap();
    navigate('/portal');
  }, [dispatch, impersonateId, navigate, queryClient]);
  return {
    impersonate,
    impersonateId,
    impersonatableKits: impersonatableKits ?? [],
    stopImpersonating,
    ...query,
  };
};
export default useImpersonate;
