import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getKits } from 'store/shared';
import { getEmail, getIsAuthenticated, getRole, getUsername } from 'store/user';
import { sortKitsByResultsViewableOrLatest } from 'helpers';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const useIdentify = (analyticsBrowser: AnalyticsBrowser | null) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const email = useSelector(getEmail);
  const username = useSelector(getUsername);
  const role = useSelector(getRole);
  const kits = useSelector(getKits);
  const sortedKits = sortKitsByResultsViewableOrLatest(kits);
  const latest_results_ready = sortedKits[0]?.date_results_ready;
  const [isIdentified, setIsIdentified] = React.useState(false);
  const [isIdentifiedWithKits, setIsIdentifiedWithKits] = React.useState(false);
  useEffect(() => {
    if (!analyticsBrowser) return;
    if (isAuthenticated && latest_results_ready && !isIdentifiedWithKits) {
      analyticsBrowser.identify(username, {
        email,
        role,
        latest_results_ready,
      });
      setIsIdentifiedWithKits(true);
      setIsIdentified(true);
    } else if (isAuthenticated && !isIdentified) {
      analyticsBrowser.identify(username, { email, role });
      setIsIdentified(true);
    } else if (!isAuthenticated && isIdentified) {
      analyticsBrowser.reset();
      setIsIdentified(false);
      setIsIdentifiedWithKits(false);
    }
  }, [
    analyticsBrowser,
    email,
    isAuthenticated,
    isIdentified,
    isIdentifiedWithKits,
    latest_results_ready,
    role,
    username,
  ]);
};
