import { GutVaginalHealth } from './Insights';
import Guide from './Guide';

export type ActionPlanData = {
  id: number;
  action_plan_recommendations_id: string;
  /* OPTIONAL */
  criteria?: string;
  display_order?: number;
  kit_id?: string;
  section?: string;
  metric_name?: string;
  outcome_marker?: string;
  outcome_tag?: string;
};

export type ActionPlanItem = {
  id: string | number;
  v2: number | null;
  published?: boolean;
  category?: string;
  created_at?: string;
  cover_url?: { url: string; color: string };
  display_tags?: string;
  updated_at?: string;
  display_title: string;
  detail: string;
  display_order?: number;
  sampling_group?: string; //"adult",
  subtitle?: string;
  tags?: string;
  instruction_1?: string;
  instruction_1_icon?: string;
  instruction_2?: string;
  instruction_2_icon?: string;
  instruction_3?: string;
  instruction_3_icon?: string;
  about?: string;
  evidence_rating?: string;
  faq?: string;

  desired_outcomes: ActionPlanDesiredOutcome[];
  guides?: Guide[];
  recommendations?: ActionPlanRecommendation[];
  action_plan_personalization?: ActionPlanPersonalization[];
};

export type ActionPlanDesiredOutcome = {
  id: number;
  action_plan_recommendations_id?: string;
  created_at?: string;
  criteria?: string;
  criteria_level?: string;
  meaning_keyword?: string;
  outcome_display_order: number | null;
  section?: string;
  section_keywords?: string;
  tags?: string;
  title: string;
  updated_at?: string;

  gut_vaginal_health?: GutVaginalHealth;
  outcomes_kit?: { kit_id: string };
};

export enum RecommendationItemType {
  PROBIOTIC = 'probiotic',
  FOOD = 'food',
  TIP = 'tip',
  SUPPLEMENT = 'supplement',
  PRODUCT = 'product',
  RESOURCE = 'resource',
  RECIPE = 'recipe',
}

export type ActionPlanRecommendationItem = {
  id: number;
  type: RecommendationItemType;
  published: boolean;
  title?: string;
  description?: string;
  photo_url?: string;
  key_ingredients?: string;
  age_low: number;
  age_high: number;
  info?: string;
  time?: string;
  link?: string;
  discount_code?: string;
  allergens_cows_milk: boolean;
  allergens_gluten: boolean;
  allergens_egg: boolean;
  allergens_soy: boolean;
  allergens_peanuts: boolean;
  allergens_treenuts: boolean;
  allergens_shellfish: boolean;
  vegan: boolean;
  vegetarian: boolean;
  display_order?: number;

  recommendations?: ActionPlanRecommendation[];
};

export type ActionPlanRecommendation = {
  id?: number;
  display_order?: number;
  display_title?: string;
  description?: string | null;
  name?: string;

  items?: ActionPlanRecommendationItem[];
  action_plan_recommendations?: ActionPlanItem[];
};

export type BookmarkedItem = {
  recommendation_item_id: number;
  is_bookmarked: boolean;
  kit_id?: string;
  kit_ids?: string[];
  action_plan_recommendation_id?: number;
  recommendation_item?: ActionPlanRecommendationItem;
  id?: number;
};

export type ActionPlanPersonalization = {
  id?: number;
  action_plan_recommendation_id?: number;
  created_at?: string;
  probiotic_usage?: boolean;
  probiotic_type?: string;
  fermented_servings?: string;
  personalized_text?: string;
  variable_name?: string;
  updated_at?: string;

  action_plan_personalization_kits?: ActionPlanPersonalizationKits[];
};
export type ActionPlanPersonalizationKits = {
  kit_id?: string;
  action_plan_personalization_id?: number;
  created_at?: string;
  variable?: string;
  updated_at?: string;
};
