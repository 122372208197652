import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import c from 'classnames';

import styles from './THRadioButton.module.scss';

type RadioButtonProps = {
  label: string;
  disabled?: boolean;
  value?: string;
  defaultChecked?: boolean;
  classes?: string;
} & UseFormRegisterReturn;
const THRadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ name, value, label, classes, ...rest }, ref) => {
    return (
      <label
        htmlFor={`${name}-${value}`}
        className={c(styles.container, classes)}
      >
        {label}
        <input
          id={`${name}-${value}`}
          name={name}
          value={value}
          type='radio'
          {...rest} // { onChange, onBlur }
          ref={ref}
        />
        <span className={styles.checkmark}></span>
      </label>
    );
  },
);

THRadioButton.displayName = 'THRadioButton';
export default THRadioButton;
