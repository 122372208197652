import { useEffect, useRef } from 'react';

import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import styles from './LineChart.module.scss';

Chart.register(annotationPlugin);
if (registerables !== undefined) {
  Chart.register(...registerables);
}

Chart.defaults.font.size = 8;

function setData(chart, data, labels) {
  if (!data) return;
  // TODO: make sure labels are valid
  chart.data.labels = [...labels];
  chart.data.datasets = [...data];
  chart.update();
}

export const LineChart = ({ id, config, data, labels }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const line = new Chart(ctx, config);
    setData(line, data, labels);
    return () => line.destroy();
  }, [data, config, labels]);

  return (
    <>
      <div className={styles.wrapper}>
        <canvas id={id} height='240' ref={canvasRef} />
      </div>
    </>
  );
};
