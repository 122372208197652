import { QuestionAnswerPairType } from 'types/Surveys';

export type ImportantQuestionsGroup = {
  displayTitle: string;
  questions: QuestionAnswerPairType[];
};
export type ImportantQuestionGroupMap = {
  displayTitle: string;
  questions: string[];
};
const importantQuestionsGroupedStructure: ImportantQuestionGroupMap[] = [
  {
    // formId: 'Qqup3Txd',
    displayTitle: 'Antibiotics',
    questions: ['nMwulgwgCbYI', '1DboMNKFdj4k', 'hvnMBJZ6RkTV', 'SPntqbrS1qOR'],
  },
  {
    // formId: 'Qqup3Txd',
    displayTitle: 'Antibiotics Resample',
    questions: ['rlIoojuMxycZ', 'lqyhU3cGIBaU'],
  },
  {
    // formId: 'Qqup3Txd',
    displayTitle: 'Probiotics',
    questions: [
      'Et9FDovuonSK',
      'ZNxr2jxefbpP',
      'Mh8GDVttovjB',
      'ue3wuklo3GzF',
      'EKCSROPdB4fG',
      '8jqNjYWFWeDW',
      '8Viq5E3YU8ye',
      'GPSZbk47h19P',
      'tg3bJRNf4bdc',
      'Xl8AWDcJIQ3v',
      'AgSBxZ3G3VcM',
    ],
  },
  {
    // formId: 'Qqup3Txd',
    displayTitle: 'Probiotics Resample',
    questions: ['ZxKlGAJan0LS', 'YpaXDsn3xZtZ', 'X6vVrJkdGYNQ'],
  },
  {
    // formId: 'gcKqsqD8',
    displayTitle: 'Diet',
    questions: ['908fKUlj0Adt', 'PeCwIsRyad6v', 'CvXE4XEMN7vW'],
  },
  {
    // formId: 'gcKqsqD8',
    displayTitle: 'Diet Resample',
    questions: ['onK7QlGUoNF1', 'itkURYNPuSqf'],
  },
  {
    // formId: 'X9ri1D2J',
    displayTitle: 'NICU',
    questions: ['FUThGkqGg3Wc', 'Qj3sAitK2A3I'],
  },
  {
    // formId: 'X9ri1D2J',
    displayTitle: 'Eczema',
    questions: [
      'tn4CzEabUxYD',
      'kYM1ii5ljITa',
      'u8zSnvf761uh',
      'j9hPCDOwWAs0',
      'rsnuigGOIaKs',
      'zVHD3Nz1R6Cr',
      'C6jYXdnYlu9q',
    ],
  },
  {
    // formId: 'X9ri1D2J',
    displayTitle: 'Food reactions',
    questions: [
      'LhdfM87uYidg',
      'jPDe4OrjVmUD',
      'ji3cbldwyu4d',
      '68geoyijLhlB',
      'jvAjmYIwy6kP',
      'EaJJyH2eeD0K',
    ],
  },
  {
    // formId: 'X9ri1D2J',
    displayTitle: 'Vaginal symptoms',
    questions: [
      'PpWt74rdcOwD',
      'Stws5dC5V8Y6',
      'TcniRfEVKwUS',
      'jR6dqdkQKmrB',
      'J6XOBvPMNnmd',
      'eovL20lNu3bv',
      'w8h5ICq0x9ru',
    ],
  },
  {
    // formId: 'X9ri1D2J',
    displayTitle: 'Vaginal conditions',
    questions: [
      'IMA4DddlX26z',
      '0F9NSxBWHAmk',
      'SrHLp1YBa1uS',
      's61niQRyFz3J',
      'I9cyCcDP7Be3',
      'J9gCPjmwVRkh',
    ],
  },
  {
    // formId: 'X9ri1D2J',
    displayTitle: 'GI symptoms',
    questions: [
      'gFhW0Ym9lPqx',
      'Yc3XBoacvOqU',
      'cRuLXaTpViRg',
      'T1mqFOEmi15M',
      'bdvJWiKGzC93',
      'b4SFr8JLRIUL',
      'tjabagQL6Pjj',
      '2YNwp5hfCdYm',
    ],
  },
  {
    // formId: 'X9ri1D2J',
    displayTitle: 'Metabolic health',
    questions: [
      'm9gpoTHSztYr',
      'XL2DUFj9NadK',
      'WX9L5syG9WDn',
      'Yrou5rVz57mb',
      'he98EOgG9iWZ',
    ],
  },
  {
    // formId: 'HyCkVdiY',
    displayTitle: 'Daily Life',
    questions: [
      'igSDGpBncSca',
      '7veysb6q77Mg',
      'shtGT8rN6EwV',
      'M15SqmXyUkLg',
      '4AvCR4KOZiS1',
    ],
  },
  {
    // formId: 'HyCkVdiY',
    displayTitle: 'Daily Life Resample',
    questions: [
      'FFTPU85vVJPU',
      '43yYlHL0cxfC',
      'TNtwufciIMJf',
      'MIZ8qypPCo2h',
      'kW62KWVUXxgh',
      '7hcMItZSrOrt',
      'nSL1zNG7AUdE',
    ],
  },
  {
    // formId: 'Jz2sYg0x',
    displayTitle: 'TTC',
    questions: ['vKCshAZWz4U9', 'gexrMQobvZiC'],
  },
  {
    displayTitle: 'Pregnancy',
    questions: [
      // formId: 'skpTe0TS',
      'mIImNU6oOmC9',
      'KdJVSSbABGCy',
      'PqJdhJGWz152',
      // formId: 'IYH0dy52',
      'UJSWLGluUqjx',
      'ySIFsOPimvTQ',
      'OTGIRNCMYYhy',
      'sPRB8ZpogEFO',
      'wp7shnhTukZL',
      'kgsg5ZT64u1j',
      'Rdhbx6dXizJS',
      'PUAdjkSS38eN',
    ],
  },
  {
    // formId: 'IYH0dy52',
    displayTitle: 'Pregnancy Resample',
    questions: [
      '6Zd3zouYWsJ9',
      '1FpLE6AX5cCk',
      'Fdr5RHcyAz0t',
      '6p76EkZ5JVwz',
      'qCC4bA6RGSJm',
    ],
  },
];

export const getImportantQuestionsGroupStructure = () => {
  return importantQuestionsGroupedStructure;
};
