import { useSelector } from 'react-redux';

import useProfileQuestions from 'services/general/profileQuestions';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { getHomePageTinyAccountId } from 'store/home';

import { HomeNextStep } from '../HomeNextSteps';
import { CheckIcon } from 'views/Information/NextStep';

export const HomeNextStepProfile = () => {
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const { latestKit } = useTinyAccountForHome(tinyAccountId);
  const { needsProfile, isLoading } = useProfileQuestions(
    tinyAccountId,
    latestKit?.id,
  );
  const profileIsCompleted =
    !needsProfile ||
    !!latestKit?.date_survey_completed ||
    !!latestKit?.is_resample;

  if (isLoading)
    return (
      <HomeNextStep
        title='Create Profile'
        isActive={false}
        isCompleted={true}
        Status={<CheckIcon isCompleted={true} />}
      />
    );
  return (
    <HomeNextStep
      title='Create Profile'
      isActive={!profileIsCompleted}
      isCompleted={profileIsCompleted}
      Status={<CheckIcon isCompleted={profileIsCompleted} />}
      clickable={{
        text: 'Get started',
        url: `/profile?account=${tinyAccountId}`,
      }}
    />
  );
};
