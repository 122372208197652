import { UpdateStepProps } from '../VersionUpdate';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  DesignButton,
  Typography,
} from 'components';
import UpdatingSvg from 'assets/images/updating.svg?react';

export const Updating = ({ setCurrentStep, navigateBack }: UpdateStepProps) => {
  return (
    <div className='flex flex-column mt-4 align-items-center text-center'>
      <Typography variant='heading-2xl' className='mt-4 mb-4'>
        Your results are now updating.
      </Typography>

      <UpdatingSvg className='mx-auto' />

      <Typography variant='body-s' className='mt-4 mb-4'>
        You will receive an email when this process is complete.
      </Typography>

      <DesignButton
        label='Return to Results'
        iconNameRight='chevronForward'
        variant={ButtonVariants.TEXT}
        color={ButtonColors.PURPLE}
        size={ButtonSizes.S}
        onClick={() => navigateBack()}
        className='pt-4'
      />
    </div>
  );
};
