import React, { useState } from 'react';

interface ImageFallbackProps {
  src?: string;
  fallback: string;
  alt?: string;
}

export const ImageFallback: React.FC<ImageFallbackProps> = ({
  src,
  fallback,
  alt,
}) => {
  const [imageSrc, setImageSrc] = useState<string>(src ? src : fallback);

  const handleImageError = () => {
    setImageSrc(fallback);
  };

  return (
    <img
      src={imageSrc}
      onError={handleImageError}
      alt={alt ? alt : 'Fallback Image'}
    />
  );
};
