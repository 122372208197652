import { useMemo } from 'react';

import useSurveyResponses from 'services/surveys/surveyResponses';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { DiveDeeperSummaryCard } from '../DiveDeeperSummaryCard/DiveDeeperSummaryCard';
import { getAllQuestionsAnswers } from 'views/Results/Surveys/SurveysSummary/SurveysSummary';

export const WytuSummaryCard = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const { importantQuestions } = useSurveyResponses(currentKitId);
  const onlyImportantQuestionsAnswers = useMemo(
    () => getAllQuestionsAnswers(importantQuestions),
    [importantQuestions],
  );

  if (!onlyImportantQuestionsAnswers) return <></>;
  return (
    <DiveDeeperSummaryCard
      key={`WYTU_SummaryCard`}
      title={`What you've told us`}
      description={`Here is what was going on when you took this sample, in case you need to
      take a look back.`}
      link={`/results/${currentKitId}/surveys`}
      linkText='See responses'
    />
  );
};
