import { useState } from 'react';

import {
  ButtonColors,
  ButtonSizes,
  ContainerSizes,
  DesignButton,
  NewFamilyMemberModal,
  PageContainer,
  Typography,
} from 'components';
import { AccountOnboardingModal } from './AccountOnboardingModal/AccountOnboardingModal';

import styles from './GetStarted.module.scss';

export const GetStarted = () => {
  const [showCreateTinyAccountModal, setShowCreateTinyAccountModal] =
    useState(false);

  return (
    <>
      <AccountOnboardingModal enabled={true} />
      <NewFamilyMemberModal
        toggle={() => setShowCreateTinyAccountModal(prev => !prev)}
        showModal={showCreateTinyAccountModal}
        withProfile={true}
      />
      <PageContainer size={ContainerSizes.SM} className={styles.pageContainer}>
        <div className={styles.greenCard}>
          <div className={styles.content}>
            <Typography variant='heading-2xl'>Get started</Typography>
            <Typography variant='heading-m' className='grey-text'>
              Set up the profile for your first sample
            </Typography>
            <DesignButton
              label={`Let’s go`}
              size={ButtonSizes.M}
              color={ButtonColors.GREEN}
              onClick={async () => setShowCreateTinyAccountModal(true)}
            />
          </div>
        </div>
      </PageContainer>
    </>
  );
};
