import c from 'classnames';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  DesignButton,
} from 'components';
import { Typography } from '@repo/ui';
import styles from './ModalBody.module.scss';

type ModalBodyProps = {
  image: string;
  title: string;
  description: string;
  backgroundColor?: string;
  onFinish: () => void;
};

const ModalBody = ({
  image,
  title,
  description,
  backgroundColor,
  onFinish,
}: ModalBodyProps) => {
  return (
    <div
      className={styles.modalBody}
      style={{ backgroundColor: backgroundColor ?? '#ABB8FF' }}
    >
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img src={image} alt={`Illustration`} />
        </div>
        <Typography variant={'heading-2xl'} className={styles.padding}>
          {title}
        </Typography>
        <div className={c(styles.description, styles.padding)}>
          <Typography variant={'body-s'} serif>
            {description}
          </Typography>
        </div>
      </div>
      <div className={styles.padding}>
        <DesignButton
          label='Next'
          variant={ButtonVariants.SOLID}
          size={ButtonSizes.M}
          color={ButtonColors.PURPLE}
          onClick={async () => onFinish()}
          width={'full'}
          className='mb-3 mt-3'
        />
      </div>
    </div>
  );
};
export default ModalBody;
