import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import Kit from 'types/Kit';
import { useSelector } from 'react-redux';
import { getMainAccountId } from 'store/account';

export const patchKit = async (kit: Partial<Kit>) => {
  const response = await API.patch(apiName, `${apiBasePath}/kits/${kit.id}`, {
    ...kit,
  });
  return response;
};

const useUpdateKitDates = ({ onlyOptimistic = false, silent = false }) => {
  const queryClient = useQueryClient();
  const mainAccountId = useSelector(getMainAccountId);

  return useMutation({
    mutationFn: (kit: Partial<Kit>) => {
      return patchKit(kit);
    },
    onMutate: async variables => {
      const queryKey = ['kits', mainAccountId];
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });
      // Snapshot the previous value
      const previousKits = queryClient.getQueryData(queryKey);
      // Optimistically update the cache with the new value
      queryClient.setQueryData(queryKey, (oldKits: Kit[]) => {
        return oldKits.map(kit =>
          kit.id === variables.id ? { ...kit, ...variables } : kit,
        );
      });
      // Return the previous value in case of error
      return { previousKits };
    },
    onSuccess(data, variables, context) {
      if (!silent) {
        toast.success('Kit updated successfully');
      }
    },
    onError: (err, variables, context) => {
      const queryKey = ['kits', mainAccountId];
      // Roll back the cache to the previous value
      queryClient.setQueryData(queryKey, context?.previousKits);
      if (!silent) {
        toast.error('There was an error. Please try again.');
      }
    },
    onSettled: (data, error, variables, context) => {
      const queryKey = ['kits', mainAccountId];
      if (!onlyOptimistic) {
        // Invalidate the query to refetch the updated data from the backend
        queryClient.invalidateQueries({ queryKey });
      }
    },
  });
};
export default useUpdateKitDates;
