export const scatterPlotBabyGutData = {
  typeBif: [
    { x: 0.7075, y: -0.1393 },
    { x: 0.4567, y: -0.2499 },
    { x: 0.572, y: -0.1622 },
    { x: 0.6591, y: -0.0541 },
    { x: 0.7171, y: -0.0281 },
    { x: 0.6985, y: -0.0596 },
    { x: 0.7406, y: -0.0799 },
    { x: 0.7577, y: -0.1679 },
    { x: 0.7575, y: -0.145 },
    { x: 0.5575, y: 0.0647 },
    { x: 0.2247, y: -0.5285 },
    { x: 0.6384, y: -0.1773 },
    { x: 0.4917, y: -0.3295 },
    { x: 0.4189, y: -0.4505 },
    { x: 0.7762, y: -0.1839 },
    { x: 0.6413, y: -0.1666 },
    { x: 0.7145, y: -0.111 },
    { x: 0.7064, y: -0.2782 },
    { x: 0.504, y: -0.2135 },
    { x: 0.7539, y: -0.2139 },
    { x: 0.7431, y: -0.2317 },
    { x: 0.701, y: -0.0862 },
    { x: 0.6639, y: -0.2311 },
    { x: 0.609, y: -0.2546 },
    { x: 0.3729, y: -0.3428 },
    { x: 0.6755, y: -0.1237 },
    { x: 0.682, y: -0.1799 },
    { x: 0.2347, y: -0.5036 },
    { x: 0.6644, y: -0.1706 },
    { x: 0.1916, y: 0.1165 },
    { x: 0.7189, y: -0.0835 },
    { x: 0.383, y: -0.483 },
    { x: 0.687, y: -0.0057 },
    { x: 0.7291, y: -0.1058 },
    { x: 0.1996, y: -0.5034 },
    { x: 0.6001, y: -0.3073 },
    { x: 0.7182, y: -0.0618 },
    { x: 0.78, y: -0.208 },
    { x: 0.6241, y: -0.1813 },
    { x: 0.7747, y: -0.2156 },
    { x: 0.7837, y: -0.2115 },
    { x: 0.7189, y: -0.0776 },
    { x: 0.6684, y: 0.035 },
    { x: 0.7201, y: -0.1077 },
    { x: 0.7314, y: -0.2601 },
    { x: 0.7044, y: -0.2139 },
    { x: 0.6458, y: -0.2035 },
    { x: 0.5612, y: -0.3677 },
    { x: 0.7376, y: -0.111 },
    { x: 0.4299, y: 0.3254 },
    { x: 0.7189, y: -0.2194 },
    { x: 0.4516, y: 0.3212 },
    { x: 0.7247, y: -0.1741 },
    { x: 0.75, y: -0.2394 },
    { x: 0.7788, y: -0.1917 },
    { x: 0.734, y: -0.0715 },
    { x: 0.7352, y: -0.2633 },
    { x: 0.5197, y: -0.3387 },
    { x: 0.7747, y: -0.1848 },
    { x: 0.7833, y: -0.2089 },
    { x: 0.7731, y: -0.1827 },
    { x: 0.6233, y: -0.1941 },
    { x: 0.4158, y: -0.4055 },
    { x: 0.3291, y: -0.3267 },
    { x: 0.7748, y: -0.1805 },
    { x: 0.4738, y: -0.4245 },
    { x: 0.1554, y: -0.5548 },
    { x: 0.7643, y: -0.1576 },
    { x: 0.7723, y: -0.1935 },
    { x: 0.7302, y: -0.1976 },
    { x: 0.5987, y: 0.0488 },
    { x: 0.2173, y: -0.5196 },
    { x: 0.6566, y: -0.0437 },
    { x: 0.5907, y: 0.0841 },
    { x: 0.544, y: -0.2576 },
    { x: 0.7362, y: -0.1115 },
    { x: 0.7029, y: -0.2743 },
    { x: 0.438, y: -0.4371 },
    { x: 0.7204, y: -0.2223 },
    { x: 0.6314, y: -0.2659 },
    { x: 0.2469, y: -0.4019 },
    { x: 0.4711, y: -0.4026 },
  ],
  typeEnt: [
    { x: -0.6091, y: -0.3488 },
    { x: -0.5977, y: -0.1866 },
    { x: -0.5615, y: -0.2556 },
    { x: 0.0077, y: -0.5347 },
    { x: 0.0783, y: -0.3062 },
    { x: -0.5145, y: -0.342 },
    { x: -0.5976, y: -0.2601 },
    { x: -0.5323, y: -0.1203 },
    { x: -0.4805, y: 0.0928 },
    { x: -0.4332, y: 0.0116 },
    { x: -0.6036, y: -0.4468 },
    { x: -0.5916, y: -0.4357 },
    { x: -0.4914, y: 0.1437 },
    { x: -0.6099, y: -0.4394 },
    { x: -0.6079, y: -0.3927 },
    { x: -0.6052, y: -0.4323 },
    { x: 0.1025, y: -0.4109 },
    { x: -0.6089, y: -0.3583 },
    { x: -0.5413, y: -0.0926 },
    { x: -0.5677, y: -0.2461 },
    { x: -0.6097, y: -0.4325 },
    { x: -0.5959, y: -0.2499 },
    { x: -0.5474, y: -0.4477 },
    { x: -0.0171, y: -0.4693 },
    { x: -0.175, y: -0.4528 },
    { x: 0.0051, y: -0.4288 },
    { x: -0.6039, y: -0.4499 },
    { x: -0.5362, y: -0.0821 },
    { x: -0.4324, y: -0.5341 },
    { x: -0.6065, y: -0.4247 },
    { x: -0.5747, y: -0.4362 },
    { x: -0.6121, y: -0.382 },
    { x: -0.081, y: -0.4296 },
    { x: -0.5963, y: -0.2648 },
    { x: -0.6098, y: -0.4286 },
    { x: -0.6135, y: -0.4014 },
    { x: -0.6078, y: -0.3875 },
    { x: -0.6046, y: -0.4462 },
    { x: -0.4801, y: -0.2573 },
    { x: -0.1995, y: 0.0178 },
    { x: -0.603, y: -0.4708 },
    { x: -0.5889, y: -0.3288 },
    { x: -0.6, y: -0.4654 },
    { x: -0.5947, y: -0.2255 },
    { x: -0.4591, y: -0.2467 },
    { x: -0.5686, y: -0.4189 },
    { x: -0.0735, y: -0.26 },
    { x: -0.1715, y: -0.3299 },
    { x: -0.6077, y: -0.4322 },
    { x: -0.6047, y: -0.4659 },
    { x: -0.1832, y: -0.3307 },
    { x: -0.5608, y: -0.1649 },
    { x: -0.2337, y: -0.2137 },
    { x: -0.5997, y: -0.159 },
    { x: -0.6054, y: -0.4544 },
    { x: -0.4868, y: -0.0821 },
    { x: -0.5109, y: -0.0658 },
    { x: -0.5531, y: -0.1615 },
    { x: -0.605, y: -0.3383 },
    { x: -0.4471, y: 0.2403 },
    { x: -0.6057, y: -0.3834 },
    { x: -0.25, y: -0.304 },
    { x: -0.5932, y: -0.2616 },
    { x: -0.5854, y: -0.2947 },
    { x: -0.6027, y: -0.4738 },
    { x: -0.6058, y: -0.4556 },
    { x: -0.4852, y: -0.0187 },
    { x: -0.5956, y: -0.3503 },
    { x: -0.4592, y: -0.3477 },
    { x: -0.5297, y: -0.4505 },
    { x: -0.5549, y: -0.2828 },
    { x: -0.5897, y: -0.4517 },
    { x: -0.6131, y: -0.3653 },
    { x: -0.6051, y: -0.3697 },
    { x: -0.4595, y: -0.5104 },
    { x: 0.0131, y: -0.5711 },
    { x: -0.5647, y: -0.1372 },
    { x: -0.6074, y: -0.4406 },
    { x: -0.4289, y: -0.1193 },
    { x: -0.6, y: -0.3915 },
    { x: -0.5099, y: -0.3119 },
    { x: -0.6077, y: -0.4493 },
    { x: -0.0875, y: -0.5546 },
    { x: -0.2035, y: -0.0473 },
    { x: -0.5615, y: -0.0912 },
    { x: -0.6026, y: -0.3313 },
    { x: -0.6138, y: -0.2884 },
    { x: -0.074, y: -0.4566 },
    { x: -0.6103, y: -0.421 },
    { x: -0.5662, y: -0.4244 },
  ],
  typeStrep: [
    { x: -0.4814, y: 0.1332 },
    { x: -0.1348, y: 0.6735 },
    { x: -0.0754, y: 0.5745 },
    { x: -0.0871, y: 0.5734 },
    { x: -0.0901, y: 0.6293 },
    { x: -0.0752, y: 0.6254 },
    { x: -0.0684, y: 0.5447 },
    { x: -0.1126, y: 0.6244 },
    { x: -0.0828, y: 0.7222 },
    { x: -0.084, y: 0.5281 },
    { x: -0.311, y: 0.3947 },
    { x: -0.316, y: 0.4499 },
    { x: -0.0925, y: 0.6338 },
    { x: -0.0958, y: 0.7381 },
    { x: -0.1467, y: 0.6264 },
    { x: -0.0973, y: 0.6456 },
    { x: -0.0805, y: 0.6622 },
    { x: -0.1104, y: 0.6755 },
    { x: -0.1002, y: 0.4898 },
    { x: -0.2067, y: 0.4472 },
  ],
  typeEntcoc: [
    { x: 0.2165, y: 0.4891 },
    { x: -0.1009, y: 0.4246 },
    { x: -0.2692, y: 0.2412 },
    { x: -0.0965, y: 0.5865 },
    { x: -0.4837, y: 0.0314 },
    { x: -0.1276, y: 0.6141 },
    { x: -0.3136, y: 0.2615 },
    { x: -0.237, y: 0.091 },
    { x: -0.0378, y: 0.4756 },
    { x: -0.1303, y: 0.6511 },
    { x: 0.2979, y: 0.2095 },
    { x: 0.4327, y: 0.1294 },
    { x: -0.1037, y: 0.5604 },
    { x: -0.4166, y: 0.0609 },
    { x: -0.1213, y: 0.555 },
    { x: -0.1, y: 0.4225 },
    { x: -0.0982, y: 0.4237 },
    { x: -0.1354, y: 0.5343 },
    { x: -0.2673, y: 0.5865 },
    { x: -0.1779, y: 0.6042 },
    { x: -0.1937, y: 0.5195 },
    { x: -0.1263, y: 0.5594 },
    { x: -0.1, y: 0.4372 },
    { x: -0.1091, y: 0.465 },
    { x: -0.316, y: 0.3534 },
  ],
  typeOther: [
    { x: -0.09, y: 0.6825 },
    { x: -0.0948, y: 0.7482 },
    { x: 0.1644, y: 0.3657 },
    { x: -0.1005, y: 0.7376 },
    { x: -0.1129, y: 0.6373 },
    { x: -0.1444, y: 0.599 },
    { x: -0.0704, y: 0.6868 },
    { x: -0.3435, y: 0.4239 },
    { x: -0.1378, y: 0.7577 },
    { x: -0.106, y: 0.6802 },
    { x: -0.0777, y: 0.6953 },
    { x: -0.1556, y: 0.6998 },
    { x: -0.0932, y: 0.6899 },
    { x: -0.4162, y: 0.2257 },
    { x: -0.091, y: 0.6715 },
    { x: -0.0462, y: 0.2172 },
    { x: -0.1171, y: 0.8097 },
    { x: -0.2013, y: 0.5754 },
    { x: -0.0747, y: 0.7025 },
    { x: -0.0823, y: 0.6659 },
    { x: -0.0876, y: 0.7374 },
    { x: -0.1204, y: 0.7505 },
    { x: -0.1677, y: 0.669 },
    { x: -0.0129, y: 0.684 },
    { x: -0.0793, y: 0.6918 },
    { x: 0.4263, y: 0.1789 },
    { x: -0.0774, y: 0.7299 },
    { x: -0.0744, y: 0.6713 },
    { x: 0.4258, y: 0.34 },
    { x: -0.1291, y: 0.8069 },
    { x: -0.0764, y: 0.6851 },
    { x: -0.1069, y: 0.6294 },
    { x: -0.1059, y: 0.7553 },
  ],
  typeBact: [
    { x: 0.2606, y: -0.0664 },
    { x: 0.4812, y: -0.0407 },
    { x: 0.3454, y: 0.1289 },
    { x: -0.0601, y: 0.1045 },
    { x: -0.0491, y: 0.3819 },
    { x: -0.0988, y: 0.2201 },
    { x: 0.0168, y: 0.0043 },
    { x: -0.1607, y: 0.2161 },
    { x: -0.1562, y: 0.0639 },
    { x: -0.0884, y: 0.0513 },
    { x: 0.1071, y: -0.1933 },
    { x: -0.3245, y: -0.2475 },
    { x: -0.1591, y: -0.0593 },
    { x: 0.3297, y: -0.0346 },
    { x: 0.2666, y: -0.2502 },
    { x: 0.0056, y: 0.3299 },
    { x: 0.3308, y: -0.1152 },
    { x: 0.1643, y: 0.028 },
    { x: -0.0359, y: 0.0271 },
    { x: -0.1353, y: 0.0626 },
    { x: -0.297, y: -0.1307 },
  ],
  typeStaph: [
    { x: -0.2061, y: 0.1147 },
    { x: -0.067, y: 0.606 },
    { x: 0.2248, y: 0.2507 },
    { x: 0.0139, y: 0.3193 },
    { x: 0.0135, y: 0.3234 },
  ],
};
