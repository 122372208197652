import { Suspense, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import c from 'classnames';

import useActionPlanItem from 'services/actionPlan/actionPlanItem';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import useInsightCategoryDetailById from 'services/insights/kitInsightCategoryDetailById';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { stringToUrl, userValueFormat } from 'helpers';
import { ActionPlanItem } from 'types/ActionPlan';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
  ImageFallback,
  TextLink,
} from 'components';
import { Icon, Spinfinity, Typography, TextColor } from '@repo/ui';
import { ActionDisplayTag } from 'views/ActionPlan/ActionDisplayTag/ActionDisplayTag';
import { BacteriaSlider } from 'views/Results';
import { getActionsForSubMetric } from 'views/Results/Insights/SubMetricDetail/SubMetricDetail';
import actionPlaceholder from 'assets/images/placeholders/action.svg';

import styles from './MarkdownCard.module.scss';

type MarkdownCardVariant = 'action' | 'insight';
type MarkdownCardProps = { variant: MarkdownCardVariant; id: string };
export const MarkdownCard = ({ variant, id }: MarkdownCardProps) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  return (
    <Suspense fallback={<MarkdownCardLoadingState />}>
      {variant === 'action' ? (
        <MarkdownCardAction id={id} kitId={currentKitId} />
      ) : null}

      {variant === 'insight' ? (
        <MarkdownCardInsight id={parseInt(id)} kitId={currentKitId} />
      ) : null}
    </Suspense>
  );
};
const MarkdownCardLoadingState = () => {
  return (
    <div className={styles.markdownCard}>
      <Spinfinity />
    </div>
  );
};
const MarkdownCardEmptyState = () => {
  return (
    <div className={styles.markdownCard}>
      <Typography variant={'heading-m'}>
        {`We're not able to display this action. Please reach out to
hello@tinyhealth.com`}
      </Typography>
    </div>
  );
};

const MarkdownCardAction = ({ id, kitId }: { id: string; kitId?: string }) => {
  const { pathname } = useLocation();
  const { data: action, isPending } = useActionPlanItem({
    kitId,
    actionId: id,
  });
  const tags: string[] = useMemo(() => {
    return action?.display_tags ? action.display_tags.split(',') : [];
  }, [action]);
  if (isPending) return <MarkdownCardLoadingState />;
  if (!kitId || !action) return <></>;
  return (
    <div className={c(styles.markdownCard, styles.markdownCardAction)}>
      <div className={styles.imageContainer}>
        <ImageFallback
          src={action.cover_url?.url}
          fallback={actionPlaceholder}
          alt={action.display_tags}
        />
      </div>
      <div className={styles.mainContent}>
        <Typography variant={'heading-s'}>{action.display_title}</Typography>
        <div className={styles.footer}>
          <div className={styles.tagsContainer}>
            {!!tags?.length && (
              <div className={styles.tagsContainer}>
                {tags.map(tag => (
                  <ActionDisplayTag name={tag} key={tag} />
                ))}
              </div>
            )}
          </div>
          <LinkButton
            label='Learn more'
            iconNameRight='chevronForward'
            href={{
              pathname: `/action-plan/${kitId}/action/${action.id}`,
            }}
            state={{ from: pathname }}
            variant={ButtonVariants.TEXT}
            size={ButtonSizes.S}
            color={ButtonColors.PURPLE}
          />
        </div>
      </div>
    </div>
  );
};

const MarkdownCardInsight = ({ id, kitId }: { id: number; kitId?: string }) => {
  const { pathname } = useLocation();
  const { data, isPending } = useInsightCategoryDetailById(id);
  const { data: allActions } = useActionPlanData({ kitId });
  const metric = data?.metric;

  const relatedActions: ActionPlanItem[] = useMemo(
    () =>
      !allActions?.length || !metric?.sample_health_id
        ? []
        : getActionsForSubMetric(allActions, metric?.sample_health_id),
    [allActions, metric],
  );

  if (isPending) return <MarkdownCardLoadingState />;
  if (!metric) return <></>;
  return (
    <div className={styles.markdownCard}>
      <div className={c(styles.flexRow, styles.greyText)}>
        <Typography variant={'label'}>
          {
            metric?.gut_vaginal_health?.key_insights_category
              ?.key_insights_sections?.display_title
          }
        </Typography>
        <Icon name='chevronForward' size='s' />
        <Typography variant={'label'}>{metric?.category_title}</Typography>
      </div>
      <div className={c(styles.evaluation, styles[metric.evaluation_color])}>
        <Typography variant={'heading-m'} className={styles.title}>
          {metric?.name}
        </Typography>
        <Typography variant={'heading-m'} className={styles.value}>
          {userValueFormat(
            metric.user_value,
            metric.data_type,
            metric.user_string,
          )}
        </Typography>
      </div>
      <div className={c(styles.footer, styles.alignCenter, styles.gap1)}>
        {!!metric.range_bar_display && metric.data_type !== 'string' && (
          <div className={styles.graphContainer}>
            <BacteriaSlider
              dataType={metric.data_type}
              relativeAbundance={metric.user_value}
              ranges={
                metric.gut_vaginal_health_kit_ranges
                  ? metric.gut_vaginal_health_kit_ranges
                  : metric.gut_vaginal_health_ranges
              }
              scaleBarMin={metric.scale_bar_min}
              scaleBarMax={metric.scale_bar_max}
              withDetails={false}
              withTooltip={false}
              withSlimBars
            />
          </div>
        )}
        <div className='flex align-items-center justify-content-between gap-1 w-100'>
          {relatedActions?.length ? (
            <TextLink
              label={`${relatedActions.length} action items`}
              to={`/results/${kitId}/insights/${stringToUrl(
                metric?.gut_vaginal_health?.key_insights_category
                  ?.key_insights_sections?.display_title,
              )}/${metric?.sample_health_id}`}
              state={{ scrollTo: 'actions' }}
              size='heading-s'
              color={TextColor.PURPLE}
            />
          ) : (
            <div></div>
          )}
          <TextLink
            label='Read more'
            iconNameRight='chevronForward'
            to={`/results/${kitId}/insights/${stringToUrl(
              metric?.gut_vaginal_health?.key_insights_category
                ?.key_insights_sections?.display_title,
            )}/${metric?.sample_health_id}`}
            size='heading-s'
            color={TextColor.PURPLE}
          />
        </div>
      </div>
    </div>
  );
};
