import { useDownloadAllPDFs } from 'services/pdfReport/useDownloadAllPDFs';
import { UpdateStepProps } from '../VersionUpdate';

import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  Typography,
} from 'components';

export const DownloadPDF = ({
  setCurrentStep,
  navigateBack,
}: UpdateStepProps) => {
  const { downloadAllPDFs } = useDownloadAllPDFs();
  return (
    <div className='flex flex-column'>
      <Typography variant='heading-m' className='mb-3'>
        What to expect:
      </Typography>
      <ul className='mb-4'>
        <li className='mb-2'>
          <Typography variant='body-s' className=''>
            <Typography variant='body-s' medium>
              Automatic Update:{' '}
            </Typography>
            All prior kits linked to your family’s account will be updated,
            including those for other family members.
          </Typography>
        </li>
        <li className='mb-2'>
          <Typography variant='body-s' className=''>
            <Typography variant='body-s' medium>
              Potential Changes:{' '}
            </Typography>
            Flagged metrics, actions, and the microbiome summary score may
            change after the update.
          </Typography>
        </li>
        <li className='mb-2'>
          <Typography variant='body-s' className=''>
            <Typography variant='body-s' medium>
              Processing Time:{' '}
            </Typography>
            Updates may take up to 1 week. You will receive an email
            notification once the update is complete.
          </Typography>
        </li>
      </ul>
      <Typography variant='heading-m' className='mb-3'>
        Want to keep your current results?
      </Typography>
      <Typography variant='body-s' className='mb-4'>
        If you prefer to keep your prior results as they are, you may opt out of
        the update on the next screen. If you proceed with the update, consider
        downloading a PDF of your current results for your records before
        activating the new kit.
      </Typography>
      <DesignButton
        label='Download all PDFs'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.LIGHT}
        className='mt-3'
        width={'full'}
        onClick={async () => downloadAllPDFs()}
      />
      <DesignButton
        label='Next'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.GREEN}
        className='mt-3'
        width={'full'}
        onClick={() => setCurrentStep(current => current + 1)}
      />
    </div>
  );
};
