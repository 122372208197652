import usePublishedGuides from 'services/general/guidesList';

import {
  Spinfinity,
  PageContainer,
  ContainerSizes,
  Typography,
} from 'components';
import { GuideCard } from './GuideCard/GuideCard';

import styles from './Guides.module.scss';

export const Guides = () => {
  const { data: guides, isError } = usePublishedGuides();

  return (
    <PageContainer size={ContainerSizes.MD} className={styles.pageContainer}>
      <Typography variant='heading-xl' className='mb-4'>
        Guides
      </Typography>
      <div className={styles.guidesWrapper}>
        {guides ? (
          guides.map(guide => <GuideCard key={guide.id} guide={guide} />)
        ) : isError ? (
          <p>{`We can't display the guides at the moment`}</p>
        ) : (
          <Spinfinity />
        )}
      </div>
    </PageContainer>
  );
};
