import c from 'classnames';

import { getColorFromGutVaginalEvaluationMeaning } from 'helpers';
import { getColors } from 'contexts';

import {
  Spinfinity,
  PieChart,
  pieChartConfig,
  EvidenceRating,
  AssociationsList,
  Markdown,
  Typography,
} from 'components';

import styles from './VaginalType.module.scss';

export const VaginalTypeCard = ({ kitData }) => {
  if (!kitData.length) return <Spinfinity />;

  return (
    <div className={styles.cstContainer}>
      <Typography
        variant='heading-xl'
        className={c(
          `${getColorFromGutVaginalEvaluationMeaning(
            kitData[0].evaluation_meaning,
          )}`,
          'flex',
        )}
      >
        {`CST: ${kitData[0].vt_display_name}`}
      </Typography>
      {kitData[0].vt_evidence_rating && (
        <>
          <EvidenceRating evidenceRating={kitData[0].vt_evidence_rating} />
        </>
      )}
      <ul className={styles.cstBullets}>
        {!!kitData[0].bullet1 && (
          <Markdown
            customOverride={{ p: ({ children }) => <li>{children}</li> }}
          >
            {kitData[0].bullet1}
          </Markdown>
        )}
        {!!kitData[0].bullet2 && (
          <Markdown
            customOverride={{ p: ({ children }) => <li>{children}</li> }}
          >
            {kitData[0].bullet2}
          </Markdown>
        )}
        {!!kitData[0].bullet3 && (
          <Markdown
            customOverride={{ p: ({ children }) => <li>{children}</li> }}
          >
            {kitData[0].bullet3}
          </Markdown>
        )}
      </ul>
      <Markdown>{kitData[0].vt_long_description}</Markdown>

      {!!kitData[0].vt_associations_list.length && (
        <div className='mb-3'>
          <AssociationsList associations={kitData[0].vt_associations_list} />
        </div>
      )}

      <PieChart
        id={'cstPieChart'}
        config={{
          ...pieChartConfig({
            animate: true,
          }),
        }}
        labels={['Protective', 'Disruptive', 'Variable', 'Unknown']}
        data={[
          {
            label: 'Vaginal Microbes',
            // Order must match labels
            data: [
              kitData[0].bacteriaRelAbun.protective
                .classified_relative_abundance,
              kitData[0].bacteriaRelAbun.disruptive
                .classified_relative_abundance,
              kitData[0].bacteriaRelAbun.variable.classified_relative_abundance,
              kitData[0].bacteriaRelAbun.unknown.classified_relative_abundance,
            ],
            // Order must match labels
            backgroundColor: [
              getColors().green[500],
              getColors().red[500],
              getColors().orange[500],
              getColors().grey[300],
            ],
            hoverOffset: 4,
          },
        ]}
      />
    </div>
  );
};
