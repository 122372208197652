import { useMemo } from 'react';
import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getColorFromGutVaginalEvaluationMeaning } from 'helpers';
import useGutType from 'services/gutType/useGutType';

import {
  AssociationsList,
  EvidenceRating,
  Markdown,
  Typography,
} from 'components';
import { GutHealthCompositionBar } from './GutHealthCompositionBar';

import styles from './GutHealth.module.scss';

export const GutHealth = () => {
  const { currentKitIsBabySample } = useCurrentKitAndTinyAccount();
  const { data: currentKitGutData } = useGutType();
  const source = useMemo(
    () =>
      !currentKitGutData
        ? ''
        : currentKitGutData?.gut_vaginal_type?.long_description?.replace(
            /\\n/gi,
            '\n \n',
          ),
    [currentKitGutData],
  );

  const associations = useMemo(() => {
    return currentKitGutData?.gut_vaginal_type?.associations_list ?? [];
  }, [currentKitGutData]);

  if (!currentKitGutData)
    return (
      <div>This kit doesn&apos;t have information about the gut type.</div>
    );
  return (
    <div>
      <Typography
        variant='heading-xl'
        className={c(
          styles[
            getColorFromGutVaginalEvaluationMeaning(
              currentKitGutData?.gut_vaginal_type?.evaluation_meaning,
            )
          ],
        )}
      >
        {`Type ${currentKitGutData?.gut_vaginal_type?.display_name}`}
      </Typography>

      {source && (
        <div className={styles.descriptionContainer}>
          <Markdown>{source}</Markdown>
        </div>
      )}

      {!!associations?.length && (
        <div className='mb-2'>
          <h5 className={styles.detailHeading}>Associations</h5>
          <AssociationsList associations={associations} />
        </div>
      )}
      {!!currentKitGutData?.gut_vaginal_type?.evidence_rating && (
        <>
          <EvidenceRating
            evidenceRating={currentKitGutData.gut_vaginal_type.evidence_rating}
          />
        </>
      )}

      {currentKitIsBabySample && (
        <>
          <div className={styles.gutActionPlanContainer}>
            <h5>Sample details</h5>
            <p>
              Baby gut type is based on seven key groups of microbes in your
              sample.
            </p>
          </div>
          <div className={styles.gutVerticalBarCharts}>
            <GutHealthCompositionBar
              name='BIF'
              height={Math.round(currentKitGutData.type_bif * 100)}
            />
            <GutHealthCompositionBar
              name='BAC'
              height={Math.round(currentKitGutData.type_bact * 100)}
            />
            <GutHealthCompositionBar
              name='ENT'
              height={Math.round(currentKitGutData.type_ent * 100)}
            />
            <GutHealthCompositionBar
              name='ENC'
              height={Math.round(currentKitGutData.type_entcoc * 100)}
            />
            <GutHealthCompositionBar
              name='SPH'
              height={Math.round(currentKitGutData.type_staph * 100)}
            />
            <GutHealthCompositionBar
              name='STR'
              height={Math.round(currentKitGutData.type_strep * 100)}
            />
            <GutHealthCompositionBar
              name='OTH'
              height={Math.round(currentKitGutData.type_other * 100)}
            />
          </div>
        </>
      )}
    </div>
  );
};
