import c from 'classnames';

import { Modal } from 'components';
import { SeedingBacteria } from './SeedingBacteria';
import { SeedingMicrobiomeBar } from './SeedingMicrobiomeBar';

import styles from './Seeding.module.scss';

const microbiomeOriginText = birthMotherFirstName => {
  if (birthMotherFirstName) {
    return `Microbes transferred from ${birthMotherFirstName}`;
  } else {
    return 'Microbes from the environment';
  }
};

const modalHeaderTitle = birthMotherFirstName => {
  if (birthMotherFirstName) {
    return `From ${birthMotherFirstName}`;
  } else {
    return 'From the environment';
  }
};

const modalHeaderText = ({ birthMotherFirstName, babyFirstName }) => {
  if (birthMotherFirstName) {
    return (
      <div className='mb-3'>
        <p className='mb-1 text-center'>
          These microbes are present in {babyFirstName}’s sample <br />
          and in {birthMotherFirstName}’s{' '}
          <span className='sharedTaxaSymbol'>G</span>ut and{' '}
          <span className='sharedTaxaSymbol'>V</span>aginal samples
        </p>
      </div>
    );
  } else {
    return 'Microbes are everywhere. They can come from surfaces in the hospital and home, people in contact with the baby and even pets.';
  }
};

export const SeedingModal = ({
  data,
  birthMotherFirstName,
  babyFirstName,
  bacteriaBreakdown,
}) => {
  return (
    <div>
      <Modal
        title={modalHeaderTitle(birthMotherFirstName)}
        label={
          <div className={c('flex', styles.seedingMicrobiomeBarContainer)}>
            <h5 className={`mb-3 ${styles.microbesText}`}>
              {' '}
              {microbiomeOriginText(birthMotherFirstName)}
            </h5>
            <SeedingMicrobiomeBar data={bacteriaBreakdown} />
          </div>
        }
        labelClassName={styles.linkLike}
      >
        <div>
          <h5>
            {modalHeaderText({
              birthMotherFirstName,
              babyFirstName,
            })}
          </h5>
          <SeedingBacteria
            data={data}
            transferredFromBirthMother={birthMotherFirstName}
          />
        </div>
      </Modal>
    </div>
  );
};
