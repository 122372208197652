import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

// Types
export type UiProps = {
  [key: string]: string | number | boolean | null | undefined;
};

export type MetricAccordion = {
  name: string;
  props: UiProps;
};

interface MetricAccordionState {
  openedMetricsAccordion: MetricAccordion[];
}

// State
export const initialState: MetricAccordionState = {
  openedMetricsAccordion: [],
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setMetricAccordionState(state, action: PayloadAction<MetricAccordion>) {
      const { name, props } = action.payload;
      const metricAccordionToUpdateIdx = state.openedMetricsAccordion.findIndex(
        metric => metric.name === name,
      );
      if (metricAccordionToUpdateIdx > -1) {
        const existingMetricAccordionState =
          state.openedMetricsAccordion[metricAccordionToUpdateIdx];
        if (existingMetricAccordionState !== undefined) {
          existingMetricAccordionState.props.isOpen = props.isOpen;
          return;
        }
      }
      state.openedMetricsAccordion.push(action.payload);
    },
  },
});

// Selectors
export const getUiState = (state: RootState) => state.ui;
export const getOpenedMetricsAccordions = createSelector(
  getUiState,
  state => state.openedMetricsAccordion,
);
export const getIsMetricAccordionOpened = (kitId: string, metric: string) =>
  createSelector(getOpenedMetricsAccordions, openedMetricsAccordion => {
    const metricAccordionOnState = openedMetricsAccordion.find(
      openedMetricAccordion =>
        openedMetricAccordion.name === `${kitId}_${metric}`,
    );
    return !!metricAccordionOnState &&
      typeof metricAccordionOnState.props.isOpen === 'boolean'
      ? metricAccordionOnState.props.isOpen
      : true;
  });

export const { setMetricAccordionState } = uiSlice.actions;
export default uiSlice.reducer;
