import { useEffect, useRef } from 'react';

import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import styles from './PieChart.module.scss';

Chart.register(annotationPlugin);
if (registerables !== undefined) {
  Chart.register(...registerables);
}

Chart.defaults.font.size = 8;

function addData(chart, data, labels) {
  chart.data.labels = labels;
  chart.data.datasets = data;
  chart.update();
}

export const PieChart = ({
  id,
  config,
  data,
  labels,
  chartIndex,
  highlightIndex = 0,
  hideLegend = false,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const pieChart = new Chart(ctx, config);
    addData(pieChart, data, labels);
    return () => pieChart.destroy();
  }, [config, data, labels, hideLegend, highlightIndex]);

  return (
    <div className={styles.wrapper}>
      <canvas
        height='350'
        width='350'
        id={id}
        ref={canvasRef}
        data-chart-id={chartIndex}
      />
    </div>
  );
};
