import { useMemo } from 'react';
import useSubMetricEvaluations from 'services/insights/useSubMetricEvaluations';
import { InsightMetric } from 'types/Insights';

export const useEvaluationGroups = () => {
  const { data: metrics } = useSubMetricEvaluations();

  const greenSubMetrics = useMemo(
    () =>
      metrics.filter(
        (subMetric: InsightMetric) => subMetric.evaluation_color === 'green',
      ),
    [metrics],
  );
  const greySubMetrics = useMemo(
    () =>
      metrics.filter(
        (subMetric: InsightMetric) => subMetric.evaluation_color === 'grey',
      ),
    [metrics],
  );
  const orangeSubMetrics = useMemo(
    () =>
      metrics.filter(
        (subMetric: InsightMetric) => subMetric.evaluation_color === 'orange',
      ),
    [metrics],
  );
  const redSubMetrics = useMemo(
    () =>
      metrics.filter(
        (subMetric: InsightMetric) => subMetric.evaluation_color === 'red',
      ),
    [metrics],
  );

  return { greenSubMetrics, greySubMetrics, orangeSubMetrics, redSubMetrics };
};
