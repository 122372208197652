import ChartDataLabels from 'chartjs-plugin-datalabels';

import { getColors } from 'contexts';
import { scatterPlotBabyGutData } from './BabyGutData';

import graphData from './taxaGraphData.json';

export const allPlotData = graphData.map(data => {
  const x = Number.parseFloat(data.PC1_means);
  const y = Number.parseFloat(data.PC2_means);
  return { x, y };
});

const theData = graphData.reduce(
  (filtered, option) => {
    if (option.metadata_7.Delivery_mode === 'Vaginal') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotVaginalDeliveryData.push(coordinates);
    }

    if (option.metadata_7.Delivery_mode === 'Caesarean') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotCSectionDeliveryData.push(coordinates);
    }

    if (option.metadata_7.Feeding_method === 'NA') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotFeedMethodNoneData.push(coordinates);
    }

    if (option.metadata_7.Feeding_method === 'BF') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotFeedMethodBreastData.push(coordinates);
    }

    if (option.metadata_7.Feeding_method === 'Mixed') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotFeedMethodMixedData.push(coordinates);
    }

    if (option.top_archetypes === 'Bif') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeBifData.push(coordinates);
    }

    if (option.top_archetypes === 'Ent') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeEntData.push(coordinates);
    }

    if (option.top_archetypes === 'Strep') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeStrepData.push(coordinates);
    }

    if (option.top_archetypes === 'Bact') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeBactData.push(coordinates);
    }

    if (option.top_archetypes === 'Bact') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeBactData.push(coordinates);
    }

    if (option.top_archetypes === 'Entcoc') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeEntcocData.push(coordinates);
    }

    if (option.top_archetypes === 'Staph') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeStaphData.push(coordinates);
    }

    if (option.top_archetypes === 'Other') {
      const coordinates = {
        x: Number.parseFloat(option.PC1_means),
        y: Number.parseFloat(option.PC2_means),
      };
      filtered.scatterPlotTopArchetypeOtherData.push(coordinates);
    }

    return filtered;
  },
  {
    scatterPlotVaginalDeliveryData: [],
    scatterPlotCSectionDeliveryData: [],
    scatterPlotFeedMethodNoneData: [],
    scatterPlotFeedMethodBreastData: [],
    scatterPlotFeedMethodMixedData: [],
    scatterPlotTopArchetypeBifData: [],
    scatterPlotTopArchetypeEntData: [],
    scatterPlotTopArchetypeBactData: [],
    scatterPlotTopArchetypeStrepData: [],
    scatterPlotTopArchetypeEntcocData: [],
    scatterPlotTopArchetypeStaphData: [],
    scatterPlotTopArchetypeOtherData: [],
  },
);

const {
  scatterPlotVaginalDeliveryData,
  scatterPlotCSectionDeliveryData,
  scatterPlotFeedMethodNoneData,
  scatterPlotFeedMethodBreastData,
  scatterPlotFeedMethodMixedData,
} = theData;

export const scatterChartDeliveryConfig = () => ({
  type: 'scatter',
  data: {
    label: 'scatter dataset',
    datasets: [
      {
        label: 'Vaginal Delivery',
        data: scatterPlotVaginalDeliveryData,
        backgroundColor: 'lightpink',
      },
      {
        label: 'C-Section Delivery',
        data: scatterPlotCSectionDeliveryData,
        backgroundColor: 'olivedrab',
      },
    ],
  },
  options: {
    plugins: {
      tooltip: {
        usePointStyle: true,
        callbacks: {
          labelPointStyle: function () {
            return {
              pointStyle: 'triangle',
              rotation: 0,
            };
          },
        },
      },
      autocolors: false,
      annotation: {
        annotations: {
          box1: {
            label: {
              content: 'Line annotation at x=0.5',
              enabled: true,
            },
            // Indicates the type of annotation
            type: 'box',
            xMin: -0.05,
            xMax: 0.05,
            yMin: -0.1,
            yMax: 0,
            backgroundColor: 'rgba(255, 99, 132, 0.25)',
          },
          annotation1: {
            drawTime: 'afterDraw',
            type: 'line',
            scaleID: 'x',
            borderWidth: 0,
            borderColor: 'transparent',
            value: 0.05,
            label: {
              content: () => {
                return 'highlight text here';
              },
              enabled: true,
            },
          },
        },
      },
    },
    scales: {
      x: {
        min: -0.15,
        max: 0.15,
      },
      y: {
        min: -0.15,
        max: 0.15,
      },
    },
  },
});

export const scatterChartFeedingMethodConfig = () => ({
  type: 'scatter',
  data: {
    label: 'scatter dataset',
    datasets: [
      {
        label: 'Breast Fed',
        data: scatterPlotFeedMethodBreastData,
        backgroundColor: 'mediumseagreen',
      },
      {
        label: 'Mixed Fed',
        data: scatterPlotFeedMethodMixedData,
        backgroundColor: 'thistle',
      },
      {
        label: 'NA',
        data: scatterPlotFeedMethodNoneData,
        backgroundColor: 'coral',
      },
    ],
  },
  options: {
    interaction: {
      intersect: false,
      mode: 'index',
    },

    plugins: {
      // autocolors: false,
      // annotation: {
      //   annotations: {
      //     line1: {
      //       type: 'line',
      //       yMin: 60,
      //       yMax: 60,
      //       xMin: 0.1,
      //       xMax: 1,
      //       borderColor: 'rgb(255, 99, 132)',
      //       borderWidth: 2,
      //       label: 'custom label'
      //     }
      //   }
      // },
      // TODO: tooltips data
      // tooltips: {
      //     displayColors: false,
      //     titleFontSize: 16,
      //     bodyFontSize: 14,
      //     xPadding: 10,
      //     yPadding: 10,
      //     callbacks: {
      //         label: (tooltipItem, data) => {
      //             return `$ ${tooltipItem.value} yeet`
      //         }
      //     }
      // }
    },
    scales: {
      x: {
        min: -0.15,
        max: 0.15,
      },
      y: {
        min: -0.15,
        max: 0.15,
      },
    },
  },
});

const babyGutTypeLabels = [
  'Type 1',
  'Type 2',
  'Type 3',
  'Type 4',
  'Type 5',
  'Type 6',
  'Type 7',
];

export const scatterChartTopArchetypeConfig = () => ({
  type: 'scatter',
  plugins: [ChartDataLabels],
  data: {
    label: 'scatter dataset',
    datasets: [
      {
        label: 'Type 1',
        data: scatterPlotBabyGutData.typeBif,
        backgroundColor: getColors().type1,
      },
      {
        label: 'Type 2',
        data: scatterPlotBabyGutData.typeBact,
        backgroundColor: getColors().type2,
      },
      {
        label: 'Type 3',
        data: scatterPlotBabyGutData.typeEnt,
        backgroundColor: getColors().type3,
      },
      {
        label: 'Type 4',
        data: scatterPlotBabyGutData.typeStrep,
        backgroundColor: getColors().type4,
      },
      {
        label: 'Type 5',
        data: scatterPlotBabyGutData.typeEntcoc,
        backgroundColor: getColors().type5,
      },
      {
        label: 'Type 6',
        data: scatterPlotBabyGutData.typeStaph,
        backgroundColor: getColors().type6,
      },
      {
        label: 'Type 7',
        data: scatterPlotBabyGutData.typeOther,
        backgroundColor: getColors().type7,
      },
    ],
  },
  options: {
    // colors set color of the labels
    color: getColors().fg,
    interaction: {
      intersect: false,
      mode: 'index',
    },

    plugins: {
      legend: {
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 14,
          },
          usePointStyle: true,
          filter: function (context) {
            // only displays types 1 - 7
            const displayLabel = babyGutTypeLabels.includes(context.text);
            if (displayLabel) return context.text;
            return null;
          },
        },
      },
      tooltip: {
        enabled: false,
        displayColors: false,
        titleFontSize: 16,
        bodyFontSize: 14,
      },
      datalabels: {
        color: getColors().bg,
        align: 'start',
        offset: 15,
        formatter: function (value, context) {
          return context?.dataset?.label &&
            !babyGutTypeLabels.includes(context.dataset.label)
            ? context.dataset.label
            : '';
        },
        borderColor: function (context) {
          if (
            context?.dataset?.label &&
            !babyGutTypeLabels.includes(context.dataset.label)
          ) {
            return context.dataset.backgroundColor;
          }
        },
        backgroundColor: function (context) {
          if (
            context?.dataset?.label &&
            !babyGutTypeLabels.includes(context.dataset.label)
          ) {
            return context.dataset.backgroundColor;
          }
        },
        borderRadius: '5',
        borderWidth: function (context) {
          if (
            context?.dataset?.label &&
            !babyGutTypeLabels.includes(context.dataset.label)
          ) {
            return 2;
          } else {
            return 0;
          }
        },
        padding: { left: 8, right: 8 },
        labels: {
          title: {
            font: {
              size: 16,
              weight: 600,
            },
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: getColors().unknown,
        },
        ticks: {
          color: getColors().fg,
        },
        min: -0.8,
        max: 0.85,
      },
      y: {
        grid: {
          color: getColors().unknown,
        },
        ticks: {
          color: getColors().fg,
        },
        min: -0.6,
        max: 0.85,
      },
    },
  },
});
