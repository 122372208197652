import { useEffect, useRef } from 'react';

import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);
if (registerables !== undefined) {
  Chart.register(...registerables);
}

Chart.defaults.font.size = 8;

function addData(chart, data, labels) {
  chart.data.labels = labels;
  chart.data.datasets = data;
  chart.update();
}

const highlightChart = chart => {
  chart.setActiveElements([
    // Gut composition renders individual Bar Charts, every barchart is index 0
    { datasetIndex: 0, index: 0 },
  ]);
  chart.update();
};

export const BarChart = ({
  id,
  config,
  data,
  labels,
  chartIndex = 0,
  highlightIndex = 0,
  hideLegend = false,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    const barChart = new Chart(ctx, config);
    addData(barChart, data, labels);

    if (parseInt(barChart.canvas.dataset.chartId) === highlightIndex) {
      highlightChart(barChart);
    }

    return () => barChart.destroy();
  }, [config, data, labels, hideLegend, highlightIndex]);

  return (
    <>
      <div>
        <canvas
          height='400'
          width='300'
          id={id}
          ref={canvasRef}
          data-chart-id={chartIndex}
        />
      </div>
    </>
  );
};
