import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Partytown } from '@builder.io/partytown/react';
import * as Sentry from '@sentry/react';
import { registerSW } from 'virtual:pwa-register';

import App from './App';
import { ENVIRONMENT, SENTRY_DSN } from './assets/env_constants';
import { toast } from 'react-toastify';
import { DesignButton, Typography } from 'components';

if (ENVIRONMENT === 'production') {
  Sentry.init({
    environment: ENVIRONMENT,
    dsn: SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
  },
]);
const render = () => {
  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Failed to find the root element');

  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Partytown forward={['dataLayer.push']} />
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
};

const updateSW = registerSW({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRegistered(r: any) {
    r &&
      setInterval(
        () => {
          r.update();
        },
        1000 * 60 * 60,
      ); // 1 hour
  },
  onNeedRefresh() {
    toast.dismiss();
    toast(
      <div>
        <Typography variant='body-s'>
          This page has been updated. Reload to see the latest version.
        </Typography>
        <div className='flex gap-3'>
          <DesignButton
            label='Reload'
            onClick={async () => {
              await updateSW();
              await toast.dismiss();
            }}
          />
          <DesignButton
            label='Dismiss'
            onClick={async () => await toast.dismiss()}
          />
        </div>
      </div>,
      {
        autoClose: false,
      },
    );
  },
});
render();
