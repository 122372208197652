import { useEffect, useState } from 'react';
import { userValueFormat } from 'helpers';
import c from 'classnames';
import styles from './Insights.module.scss';

const getGroupedRanges = (sortedRanges, scaleBarMin, scaleBarMax) => {
  if (!sortedRanges?.length) return [];
  let newRanges = [];
  let prevRange = { ...sortedRanges[0] };
  if (sortedRanges?.length > 1) {
    for (let index = 1; index < sortedRanges.length; index++) {
      let range = { ...sortedRanges[index] };
      if (range.evaluation_color === prevRange.evaluation_color) {
        //unify both ranges with the values of the wider one
        if (
          prevRange.range_upper - prevRange.range_lower >
          range.range_upper - range.range_lower
        ) {
          prevRange.range_upper = range.range_upper;
        } else {
          prevRange = { ...range, range_lower: prevRange.range_lower };
        }
      } else {
        //first push the old one
        newRanges.push(prevRange);
        prevRange = { ...range };
      }
    }
  }
  //push the remaining one
  newRanges.push(prevRange);

  // now apply the min and max to the ranges
  if (!scaleBarMin && !scaleBarMax) return newRanges;
  let limitedRanges = [];
  newRanges.forEach(range => {
    let newMin = null;
    let newMax = null;
    if (scaleBarMin && range.range_lower < scaleBarMin) {
      if (range.range_upper > scaleBarMin) {
        newMin = scaleBarMin;
      } else {
        return; //the whole range is out of the min-max range
      }
    }
    if (scaleBarMax && range.range_upper > scaleBarMax) {
      if (range.range_lower < scaleBarMax) {
        newMax = scaleBarMax;
      } else {
        return; //the whole range is out of the min-max range
      }
    }
    limitedRanges.push({
      ...range,
      range_lower: newMin ? newMin : range.range_lower,
      range_upper: newMax ? newMax : range.range_upper,
    });
  });
  return limitedRanges;
};

const getBreakpointsData = (rangesData, currentIndex, dataType) => {
  if (rangesData?.length === 1) {
    return currentIndex === 0
      ? {
          displayBreakpoint: userValueFormat(
            rangesData[currentIndex].range_upper,
            dataType,
          ),
          emoji: true,
          alignment: 'flexBetween',
        }
      : {};
  }
  if (rangesData?.length === 2) {
    const endBlockWider = rangesData[0].percentage < rangesData[1].percentage;
    if (endBlockWider) {
      /*
            Icon         || Breakpoint                  Icon
      */
      return currentIndex === 0
        ? {
            displayBreakpoint: false,
            emoji: true,
            alignment: 'flexStart',
          }
        : {
            displayBreakpoint: userValueFormat(
              rangesData[1].range_lower,
              dataType,
            ),
            emoji: true,
            alignment: 'flexBetween-reverse',
          };
    } else {
      //first block is wider
      /*
            Icon 	Breakpoint  ||                   Icon
      */
      return currentIndex === 0
        ? {
            displayBreakpoint: userValueFormat(
              rangesData[0].range_upper,
              dataType,
            ),
            emoji: true,
            alignment: 'flexBetween',
          }
        : {
            displayBreakpoint: false,
            emoji: true,
            alignment: 'flexEnd',
          };
    }
  }
  if (rangesData?.length === 3) {
    //if the first or last are too small we use the middle one
    const firstTooSmall =
      rangesData[0].percentage < rangesData[1].percentage / 2 &&
      rangesData[0].percentage < rangesData[2].percentage / 2;
    if (firstTooSmall) {
      /*
            Icon  || Breakpoint          Icon  || Breakpoint          Icon
      */
      return currentIndex === 0
        ? {
            displayBreakpoint: false,
            emoji: true,
            alignment: 'flexStart',
          }
        : {
            displayBreakpoint: userValueFormat(
              rangesData[currentIndex].range_lower,
              dataType,
            ),
            emoji: true,
            alignment: 'flexBetween-reverse',
          };
    }
    const lastTooSmall =
      rangesData[2].percentage < rangesData[0].percentage / 2 &&
      rangesData[2].percentage < rangesData[1].percentage / 2;
    if (lastTooSmall) {
      /*
            Icon       Breakpoint || Icon         Breakpoint || Icon
      */
      return currentIndex === 2
        ? {
            displayBreakpoint: false,
            emoji: true,
            alignment: 'flexEnd',
          }
        : {
            displayBreakpoint: userValueFormat(
              rangesData[currentIndex].range_upper,
              dataType,
            ),
            emoji: true,
            alignment: 'flexBetween',
          };
    }
    //default display: first and last, center aligned
    /*
        Icon        Breakpoint ||          Icon          || Breakpoint        Icon
    */
    if (currentIndex === 0) {
      return {
        displayBreakpoint: userValueFormat(
          rangesData[currentIndex].range_upper,
          dataType,
        ),
        emoji: true,
        alignment: 'flexBetween',
      };
    }
    if (currentIndex === 1) {
      return {
        displayBreakpoint: false,
        emoji: true,
        alignment: 'flexCenter',
      };
    }
    if (currentIndex === 2) {
      return {
        displayBreakpoint: userValueFormat(
          rangesData[currentIndex].range_lower,
          dataType,
        ),
        emoji: true,
        alignment: 'flexBetween-reverse',
      };
    }
  }
  //more than 3 blocks
  if (rangesData?.length > 3) {
    return currentIndex === 0
      ? {
          displayBreakpoint: false,
          emoji: true,
          alignment: 'flexStart',
        }
      : {
          displayBreakpoint: userValueFormat(
            rangesData[currentIndex].range_lower,
            dataType,
          ),
          emoji: true,
          alignment: 'flexBetween-reverse',
        };
  }
  return {};
};

const getSamplePositioning = (range, value) => {
  if (value < range.range_lower) return 1; //at the lower limit
  if (value > range.range_upper) return 99; //at the upper limit
  const rangeWidth = range.range_upper - range.range_lower;
  return rangeWidth ? ((value - range.range_lower) * 100) / rangeWidth : null;
};
const getSampleTooltipPositioning = (ranges, value) => {
  if (!ranges?.length) return null;
  if (value < ranges[0].range_lower) return 1; //at the lower limit
  if (value > ranges[ranges.length - 1].range_upper) return 99; //at the upper limit
  const rangeWidth =
    ranges[ranges.length - 1].range_upper - ranges[0].range_lower;
  return rangeWidth
    ? ((value - ranges[0].range_lower) * 100) / rangeWidth
    : null;
};

const getRangeEmoji = color => {
  if (color === 'red') return '🙁';
  if (color === 'green') return '🤩';
  if (color === 'grey') return '🙂';
  if (color === 'gray') return '🙂';
  return '🤔';
};

export const BacteriaSlider = ({
  relativeAbundance,
  dataType,
  ranges,
  scaleBarMin,
  scaleBarMax,
  withDetails = true,
  withTooltip = true,
  withSlimBars = false,
}) => {
  const [sampleRangeIndex, setSampleRangeIndex] = useState(0);
  const [rangesData, setRangesData] = useState([]);

  useEffect(() => {
    if (ranges?.length) {
      //get only a group for each "color" (group with the boundary conditions)
      let tempRangesData = getGroupedRanges(
        JSON.parse(JSON.stringify(ranges)), //new array of objects, important to avoid the objects to being pass by reference
        scaleBarMin,
        scaleBarMax,
      );
      if (tempRangesData?.length) {
        const min = tempRangesData[0].range_lower;
        const max = tempRangesData[tempRangesData.length - 1].range_upper;
        const dataWidth = max - min;
        //prepare data for graph
        tempRangesData.forEach((range, idx) => {
          //percentage 0 to 100
          tempRangesData[idx].percentage = dataWidth
            ? ((range.range_upper - range.range_lower) * 100) / dataWidth
            : 0;
          //find the range in which this kit sample is located
          if (
            range.range_lower <= relativeAbundance &&
            relativeAbundance < range.range_upper
          ) {
            setSampleRangeIndex(idx);
          }
        });
        tempRangesData.forEach((range, idx) => {
          //decide where to display the breakpoints
          tempRangesData[idx] = {
            ...tempRangesData[idx],
            ...getBreakpointsData(tempRangesData, idx, dataType),
          };
        });
        setRangesData(tempRangesData);
        //validate sample range index in case of outside min max boundaries
        if (relativeAbundance < tempRangesData[0].range_lower) {
          setSampleRangeIndex(0);
        }
        if (
          relativeAbundance >
          tempRangesData[tempRangesData.length - 1].range_upper
        ) {
          setSampleRangeIndex(tempRangesData.length - 1);
        }
      }
    }
  }, [ranges, relativeAbundance, dataType, scaleBarMax, scaleBarMin]);

  return !rangesData?.length ? null : (
    <div
      className={c(
        styles.barContainer,
        withDetails && styles.withDetails,
        withTooltip && styles.withTooltip,
      )}
    >
      {rangesData.map((range, displayIdx) => (
        <div
          className={c(styles.barBlock, withSlimBars && styles.slimBars)}
          style={{ flexBasis: range.percentage + '%' }}
          key={range.range_lower + '_' + range.range_upper}
        >
          <div className={c(styles.bar, range.evaluation_color)}></div>
          {withDetails && (
            <div className={c(styles.details, styles[range.alignment])}>
              {!!range.emoji && (
                <span className={c(styles.emoji)}>
                  {getRangeEmoji(range.evaluation_color)}
                </span>
              )}
              {!!range.displayBreakpoint && (
                <span className={c(styles.breakpoint)}>
                  {range.displayBreakpoint}
                </span>
              )}
            </div>
          )}
          {sampleRangeIndex === displayIdx && (
            <>
              <div
                className={c(
                  styles.relativeAbundance,
                  withTooltip && styles.withTooltip,
                  withSlimBars && styles.slimBars,
                )}
                style={{
                  left: `calc(${getSamplePositioning(
                    range,
                    relativeAbundance,
                  )}% - 4px)`,
                }}
              ></div>
            </>
          )}
        </div>
      ))}
      {withTooltip && (
        <div
          className={styles.customTooltip}
          style={{
            left: `calc(${getSampleTooltipPositioning(
              rangesData,
              relativeAbundance,
            )}% - ${
              getSampleTooltipPositioning(rangesData, relativeAbundance) > 80
                ? 70
                : 8
            }px)`,
          }}
        >
          This&nbsp;sample
        </div>
      )}
    </div>
  );
};
