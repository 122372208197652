import { NavLink } from 'react-router-dom';
import c from 'classnames';

import { Icon } from '@repo/ui';
import { BetaTag } from 'components/BetaTag';

import styles from './PageHeader.module.scss';

export const PageHeader = ({
  linkBack,
  title,
  beta = false,
}: {
  title: string;
  linkBack?: string;
  beta?: boolean | number;
}) => {
  return (
    <div className={styles.container}>
      {!!linkBack && (
        <NavLink to={linkBack} className={c('green', styles.back)}>
          <Icon name='chevronBack' size='s' />
          <span className={styles.backLabel}>Back</span>
        </NavLink>
      )}
      {title && (
        <div className='flex align-items-center gap-1'>
          <h2 className={styles.headerTitle}>{title}</h2>
          {!!beta && <BetaTag />}
        </div>
      )}

      <span className={styles.rightPlaceholder}>&nbsp;</span>
    </div>
  );
};
