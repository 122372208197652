import { Bacterium } from 'components';

export const SeedingBacteriaList = ({ data, transferredFromBirthMother }) => {
  let bacterialist = data.map((bacterium, i) => {
    return (
      <Bacterium
        key={bacterium.id || i}
        bacterium={bacterium}
        transferredFromBirthMother={transferredFromBirthMother}
      />
    );
  });
  return bacterialist;
};
