import { useLocation } from 'react-router-dom';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
} from 'components';
import { Icon, Typography } from '@repo/ui';
import styles from './NextStep.module.scss';

export const CheckIcon = ({
  isCompleted,
  isBlocked,
}: {
  isCompleted: boolean;
  isBlocked?: boolean;
}) => {
  if (isBlocked)
    return (
      <div className={styles.blockedIcon}>
        <Icon name='alertCircleFilled' size='m' />
      </div>
    );
  return isCompleted ? (
    <div className={styles.completedIcon}>
      <Icon name='check' size='m' />
    </div>
  ) : (
    <div className='purple-icon flex align-items-center'>
      <Icon name='circle' size='m' />
    </div>
  );
};

type NextStepProps = {
  title: string;
  isActive: boolean;
  isCompleted: boolean;
  linkCompleted?: string;
  children: React.ReactNode;
};
export const NextStep = ({
  isActive,
  isCompleted,
  linkCompleted,
  title,
  children,
}: NextStepProps) => {
  const { pathname } = useLocation();
  return (
    <div className={styles.nextStep}>
      <div className='flex align-items-center justify-content-between gap-2'>
        <div className='flex align-items-center gap-2'>
          <Typography variant='body-s'>{title}</Typography>
          {isCompleted && !!linkCompleted && (
            <LinkButton
              label='View'
              href={linkCompleted}
              state={{ from: pathname }}
              variant={ButtonVariants.TEXT}
              size={ButtonSizes.M}
              color={ButtonColors.PURPLE}
            />
          )}
        </div>
        <CheckIcon isCompleted={isCompleted} />
      </div>
      {isActive && <div className={styles.content}>{children}</div>}
    </div>
  );
};
