import useAllFeatures from 'services/features/useAllFeatures';

const useFeature = (featureName: string) => {
  const { data: features, isFetched } = useAllFeatures();
  const feature = features?.find(f => f.name === featureName);
  const isFeatureEnabled = !!feature?.enabled;
  const featureVariables = feature?.variables ?? {};

  return { isFeatureEnabled, variables: featureVariables, isFetched };
};

export default useFeature;
