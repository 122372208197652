import { Fragment, useMemo, useState } from 'react';
import c from 'classnames';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import { ActionPlanItem } from 'types/ActionPlan';
import Kit from 'types/Kit';

import ActionPlanGroup, {
  OutcomeWithItems,
} from '../ActionPlanGroupV2/ActionPlanGroupV2';
import { ResampleSummaryCard } from '../../Results/Summary/SummaryCards';

import { Filters } from 'components/Filters/Filters';
import { Icon, IconName, Typography } from '@repo/ui';

import styles from './ActionPlanAccordionList.module.scss';

export type SectionGroupings = {
  tag:
    | 'support'
    | 'improve'
    | 'informationBased'
    | 'general'
    | 'whenToResample';
  title: string;
  icon?: IconName;
  color?: string;
  sections: (string | undefined)[];
  description?: string;
  placeholder?: string;
  category: string | null;
  defaultOpen?: boolean;
};
export const listOfSectionGroups: SectionGroupings[] = [
  {
    tag: 'support',
    title: 'Needs support',
    icon: 'alertTriangleFilled',
    color: 'red',
    sections: ['Gut Vaginal Health', 'Baby Gut Type', 'CST'],
    description:
      'Your top recommendations for the most impactful changes you could make based on your metrics that need support.',
    placeholder: 'Congrats! Nothing needs support',
    category: null,
  },
  {
    tag: 'improve',
    title: 'Needs improvement',
    icon: 'alertCircleFilled',
    color: 'orange',
    sections: ['Gut Vaginal Health', 'Baby Gut Type', 'CST'],
    description:
      'These recommendations address your metrics that need improvement, and are a second priority after those above.',

    category: null,
  },
  {
    tag: 'informationBased',
    title: 'Resources based on your surveys',
    icon: 'clipboard',
    color: 'green',
    sections: ['Condition'],
    description: 'These recommendations are based on your survey responses.',

    category: null,
  },
  {
    tag: 'general',
    title: 'Additional resources',
    icon: 'lightOn',
    color: 'green',
    sections: ['General'],
    description:
      'These recommendations help you build and maintain a healthy microbiome.',

    category: null,
  },
  {
    tag: 'whenToResample',
    title: 'When to resample',
    icon: 'microscope',
    color: 'grey',
    sections: ['General'],

    category: 'WhenToResample',
  },
];

export const getSectionGrouping = (
  category?: string,
  evaluations?: (string | null)[],
  section?: string,
): string => {
  if (evaluations && evaluations.includes('red')) {
    return 'support';
  } else if (evaluations && evaluations.includes('orange')) {
    return 'improve';
  } else if (section === 'Condition') {
    return 'informationBased';
  } else if (category === 'WhenToResample') {
    return 'whenToResample';
  } else {
    return 'general';
  }
};
export type SectionedOutcomes = {
  [section: string]: {
    [outcome: string]: OutcomeWithItems;
  };
};
export const groupByOutcomeAndFilter = (
  actionPlan: (ActionPlanItem & { hide?: boolean })[],
  filters?: string[],
): SectionedOutcomes => {
  const result: SectionedOutcomes = {};
  // each action plan item has multiple desired outcomes. We want to group them by the most important one
  actionPlan.forEach(item => {
    // first priority is outcomes that have an evaluation color for that kit's metrics, red > orange
    const chosenOutcome = item.desired_outcomes
      .filter(outcome => outcome.gut_vaginal_health?.gut_vaginal_health_kits)
      .sort((a, b) => {
        const colorPriority: { [color: string]: number } = {
          red: 1,
          orange: 2,
        };
        const colorA =
          a.gut_vaginal_health?.gut_vaginal_health_kits?.[0]
            ?.evaluation_color ?? 'other';
        const colorB =
          b.gut_vaginal_health?.gut_vaginal_health_kits?.[0]
            ?.evaluation_color ?? 'other';
        const colorComparison =
          (colorPriority[colorA] ?? 3) - (colorPriority[colorB] ?? 3);
        return (
          colorComparison ||
          (a.outcome_display_order ?? 0) - (b.outcome_display_order ?? 0)
        );
      })[0];
    // if no evaluation color then prioritize based on outcome_display_order
    const outcome =
      chosenOutcome ??
      item.desired_outcomes.sort(
        (a, b) =>
          (a.outcome_display_order ?? 0) - (b.outcome_display_order ?? 0),
      )[0];
    const sectionKey = getSectionGrouping(
      item.category,
      [
        outcome.gut_vaginal_health?.gut_vaginal_health_kits?.[0]
          ?.evaluation_color ?? null,
      ],
      outcome.section,
    );

    if (!result[sectionKey]) {
      result[sectionKey] = {};
    }

    if (!result[sectionKey][outcome.title]) {
      let color =
        outcome.gut_vaginal_health?.gut_vaginal_health_kits?.[0]
          ?.evaluation_color ?? '';

      if (sectionKey === 'informationBased' || sectionKey === 'general') {
        color = 'green';
      } else if (sectionKey === 'whenToResample') {
        color = 'grey';
      }

      result[sectionKey][outcome.title] = {
        name: outcome.title,
        color: color,
        order: outcome.outcome_display_order ?? Infinity,
        metricId: outcome.gut_vaginal_health?.id ?? null,
        actions: [],
        hide: false,
        section: outcome.section,
      };
    }
    if (
      !filters ||
      !item.category ||
      filters.length === 0 ||
      filters.includes(item.category)
    ) {
      item.hide = false;
    } else {
      item.hide = true;
    }
    result[sectionKey][outcome.title].actions.push(item);
  });
  for (const sectionKey in result) {
    for (const outcomeKey in result[sectionKey]) {
      const outcome = result[sectionKey][outcomeKey];
      outcome.actions.sort(
        (a, b) => (a.display_order ?? 0) - (b.display_order ?? 0),
      );
      if (outcome.actions.some(action => !action.hide)) {
        outcome.hide = false;
      } else {
        outcome.hide = true;
      }
    }
  }
  return result;
};
const ActionFilter = ({
  iconName,
  display,
}: {
  iconName: IconName;
  display: string;
}) => {
  return (
    <div className='flex gap-1'>
      <Icon name={iconName} size='s' />
      <Typography variant='label'>{display}</Typography>
    </div>
  );
};
const actionFilterConfig = [
  {
    value: 'DietarySuggestions',
    display: <ActionFilter iconName='carrot' display='Diet' />,
  },
  {
    value: 'LifestyleSuggestions',
    display: <ActionFilter iconName='heartHealth' display='Lifestyle' />,
  },
  {
    value: 'SupplementSuggestions',
    display: <ActionFilter iconName='pill' display='Supplement' />,
  },
];
export const ActionPlanAccordionList = ({
  kit,
  isActionPlanPage,
}: {
  kit: Kit;
  isActionPlanPage?: boolean;
}) => {
  const { data } = useActionPlanData({ kitId: kit.id });

  const [actionFilters, setActionFilters] = useState<string[]>([]);

  const actionsGroupedByOutcomeAndSection = useMemo(
    () => groupByOutcomeAndFilter(data ?? [], actionFilters) ?? {},
    [data, actionFilters],
  );
  const openedClosedSections = useMemo(() => {
    let hasOpenedDefault = false;
    listOfSectionGroups.forEach(sectionGroup => {
      if (
        !hasOpenedDefault &&
        actionsGroupedByOutcomeAndSection[sectionGroup.tag]
      ) {
        sectionGroup.defaultOpen = true;
        hasOpenedDefault = true;
      } else {
        sectionGroup.defaultOpen = false;
      }
    });
    return listOfSectionGroups;
  }, [actionsGroupedByOutcomeAndSection]);

  const handleSetActionFilters = async (filters: string[]) => {
    await setActionFilters(filters);
  };

  return (
    <div
      className={c(
        'flex flex-column gap-2',
        isActionPlanPage ? styles.scrollable : '',
      )}
    >
      <Filters
        name='categories'
        filters={actionFilters}
        filterConfig={actionFilterConfig}
        setFiltered={handleSetActionFilters}
        className={styles.actionFilters}
      />
      {openedClosedSections.map((sectionCard, i) => {
        return (
          <Fragment key={sectionCard.tag}>
            <ActionPlanGroup
              title={sectionCard.title}
              icon={sectionCard.icon ?? 'alertTriangle'}
              color={sectionCard.color}
              description={sectionCard.description}
              placeholder={sectionCard.placeholder}
              actionsGroupedByOutcome={
                actionsGroupedByOutcomeAndSection[sectionCard.tag]
              }
              defaultOpen={sectionCard.defaultOpen}
              clearFilters={() => setActionFilters([])}
            />
          </Fragment>
        );
      })}
      {isActionPlanPage && (
        <div className='mt-4'>
          <ResampleSummaryCard samplingType={kit?.sampling_type} />
        </div>
      )}
    </div>
  );
};
