import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { colorForTinyAccount, getItemLinkLabelForType } from 'helpers';
import {
  ActionPlanRecommendationItem,
  BookmarkedItem,
  RecommendationItemType,
} from 'types/ActionPlan';
import {
  getTinyAccountsOrderedByLatestKitResults,
  getTinyAccountsWithTheseKits,
} from 'store/account';

import { TextLink } from 'components';
import { Icon, IconName } from '@repo/ui';
import { Bookmark } from '../Bookmark/Bookmark';

import styles from './BookmarksGroup.module.scss';

type BookmarksGroupType = {
  name: string;
  icon: IconName;
  content: BookmarkedItem[];
};

type GroupOfBookmarksProps = {
  group: BookmarksGroupType;
};

export const BookmarksGroup = ({ group }: GroupOfBookmarksProps) => {
  const groupedByItem = [] as BookmarkedItem[];
  group.content.forEach(item => {
    const elementIndex = groupedByItem.findIndex(
      element => element.recommendation_item_id === item.recommendation_item_id,
    );
    if (!item.kit_id) return;
    if (elementIndex < 0) {
      groupedByItem.push({ ...item, kit_ids: [item.kit_id] });
    } else {
      groupedByItem[elementIndex]?.kit_ids?.push(item.kit_id);
    }
  });

  if (!groupedByItem?.length) return null;
  return (
    <>
      <div className={styles.rowContainer}>
        <div className={styles.header}>
          <Icon name={group.icon} size='s' />
          <span>{group.name}</span>
        </div>
        <div className={styles.cardsContainer}>
          {groupedByItem.map(item =>
            !item.recommendation_item ? null : (
              <div className={styles.card} key={item.recommendation_item_id}>
                <div className={styles.cardHeader}>
                  <div className={styles.imageTitle}>
                    {item.recommendation_item?.photo_url && (
                      <div className={styles.cardImage}>
                        <img
                          src={item.recommendation_item.photo_url}
                          alt={item.recommendation_item.title}
                        />
                      </div>
                    )}
                    <div className={styles.text}>
                      <div className={styles.cardTitle}>
                        {item.recommendation_item.type ===
                        RecommendationItemType.TIP
                          ? item.recommendation_item.description
                          : item.recommendation_item.title}
                      </div>
                      {!!item.recommendation_item.discount_code && (
                        <div className={styles.itemExtraDescription}>
                          <Icon name='sale' size='xs' />
                          <span>{item.recommendation_item.discount_code}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <Bookmark
                    recommendation_item_id={item.recommendation_item_id}
                    is_bookmarked={true}
                    kit_ids={item.kit_ids}
                    action_plan_recommendation_id={
                      item.action_plan_recommendation_id
                    }
                  />
                </div>

                <div className={styles.cardFooter}>
                  <div className={styles.info}>
                    {item.kit_ids ? (
                      <BookmarkingAccounts kitIds={item.kit_ids} />
                    ) : (
                      <div>&nbsp;</div> //for space-between to work
                    )}
                    <NavLink
                      to={`/bookmarks/item/${item.recommendation_item_id}`}
                      className={styles.more}
                    >
                      <span>See details</span>
                      <Icon name='help' size='s' />
                    </NavLink>
                  </div>
                  {!!item.recommendation_item.link && (
                    <RecommendationItemLink item={item.recommendation_item} />
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </>
  );
};

export const BookmarkingAccounts = ({ kitIds }: { kitIds: string[] }) => {
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  const tinyAccountsForAvatars = useSelector(
    getTinyAccountsWithTheseKits(kitIds),
  );

  if (!tinyAccountsForAvatars) return <></>;
  return (
    <div className={styles.accountBubblesRow}>
      {tinyAccountsForAvatars.map((ta, index) =>
        ta.first_name ? (
          <div
            key={ta.id}
            className={styles.avatarBubble}
            style={{
              transform: `translate(${-4 * index}px)`,
              backgroundColor: colorForTinyAccount(orderedTinyAccounts, ta.id),
            }}
          >
            {ta.first_name.substr(0, 1)}
          </div>
        ) : null,
      )}
    </div>
  );
};

export const RecommendationItemLink = ({
  item,
}: {
  item: ActionPlanRecommendationItem;
}) => {
  const text = getItemLinkLabelForType(item.type);
  return !text ? null : item.link ? (
    <TextLink label={text} iconNameRight='trendUp02' to={item?.link} external />
  ) : null;
};
