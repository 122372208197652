import { useSelector } from 'react-redux';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { sortKitsByLatestSampleDate } from 'helpers';
import { getKits } from 'store/shared';

import { TextLink, Typography } from 'components';

import styles from './VersionUpdateAlert.module.scss';

export const VersionUpdateAlert = () => {
  const { currentKitId, currentKit } = useCurrentKitAndTinyAccount();
  //only show for the latest kit
  const kits = useSelector(getKits);
  const latestKits = sortKitsByLatestSampleDate([...kits]);
  const isUpdating = !currentKit?.sci_version;

  if (!currentKitId || !latestKits.length || latestKits[0].id !== currentKitId)
    return <></>;
  return (
    <div className={styles.updateAlert}>
      <Typography variant='body-s' className=''>
        <Typography variant='body-s' medium>
          Alert:{' '}
        </Typography>
        {isUpdating
          ? `Your kit results are currently updating. This could take up to 1 week. You will receive an email when they are updated.`
          : `Your results from prior kits may not be comparable to this latest kit because you’re on different system versions.`}
      </Typography>
      {!isUpdating && (
        <TextLink
          to={`/results/update`}
          state={{ from: `/results/${currentKitId}` }}
          label='Update'
          iconNameRight='chevronForward'
        />
      )}
    </div>
  );
};
