import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { AllMetrics } from 'views/Results/Insights/CategoryMetrics/AllMetrics';

import { CategoryMetrics } from './CategoryMetrics/CategoryMetrics';
import { SubMetricDetail } from './SubMetricDetail/SubMetricDetail';

export const InsightsRoutes = () => {
  const { kitId } = useParams<{
    kitId: string;
    section: string;
    category: string;
  }>();

  return (
    <Routes>
      <Route
        path={`:category/:subMetricId`}
        element={<SubMetricDetail key='insights-submetric-detail' />}
      />
      <Route
        path={`/all-insights`}
        element={<AllMetrics key='insights-all-metrics' />}
      />
      <Route
        path={`:category`}
        element={<CategoryMetrics key='insights-category-metrics' />}
      />
      <Route
        path={``}
        key='insights'
        element={<Navigate to={`/results/${kitId}`} replace />}
      />
    </Routes>
  );
};
