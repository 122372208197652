import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getMajorVersion } from 'helpers';
import { useSystemInfo } from './useSystemInfo';
import { getKits } from 'store/shared';

export const usePreviousKitMajorVersion = () => {
  const { latestSciVersion } = useSystemInfo();
  const kits = useSelector(getKits);

  const oldestMajorVersion = useMemo(() => {
    let majorVersion = 0;
    if (kits.length) {
      // Extract major versions, filtering out invalid or NaN values
      const validVersions = kits
        .map(kit => getMajorVersion(kit?.sci_version ?? '') ?? 0)
        .filter(version => !isNaN(version));
      // If we have valid versions, get the minimum
      if (validVersions.length > 0) {
        majorVersion = Math.min(...validVersions);
      }
    }
    return majorVersion;
  }, [kits]);

  return {
    oldestVersion: oldestMajorVersion,
    hasMultipleVersions:
      oldestMajorVersion != 0 &&
      oldestMajorVersion != getMajorVersion(latestSciVersion ?? ''),
  };
};
