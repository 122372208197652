export type ImportantQuestionItem = {
  typeform_id: string;
  question_ids: string[];
};
export type ImportantQuestionsBySurvey = {
  baby_gut_sample_survey?: ImportantQuestionItem;
  adult_gut_sample_survey?: ImportantQuestionItem;
  adult_vaginal_sample_survey?: ImportantQuestionItem;
  resample_baby_gut?: ImportantQuestionItem;
  resample_adult_gut?: ImportantQuestionItem;
  resample_vaginal?: ImportantQuestionItem;
  health_history_adult_child_survey?: ImportantQuestionItem;
  health_history_toddler_survey?: ImportantQuestionItem;
  birth_pregnancy_complete_survey?: ImportantQuestionItem;
  baby_solids_survey?: ImportantQuestionItem;
  trying_to_conceive_survey?: ImportantQuestionItem;
};

const importantQuestionsBySurveyType: ImportantQuestionsBySurvey = {
  baby_gut_sample_survey: {
    typeform_id: 'uMHPlrH1',
    question_ids: [
      '5eQRJ3h747Pq',
      'AswJml9cZjzL',
      'QRGjVoyFOqW7',
      '0J3MwTFLnZs4',
      'rxaZlLKuOzn4',
      'aV3lOViinIq8',
      'sQM3E5nK5xHH',
      'KNlj879OT0iJ',
      'zPEkJTKVAVzB',
      'rzWrVvZ9iX5U',
      '5XAiOFP5gPuF',
      'Kz9x3CzLRMYz',
      'MLdvniNXwG8G',
      'emgVj14R1yww',
      'LVSuNT8O60eV',
      'ORweEkJDRYu0',
    ],
  },
  adult_gut_sample_survey: {
    typeform_id: 'hTntowos',
    question_ids: [
      'qK9HZwsn28k0',
      'fica5i2O6w3n',
      '8efo8NMzFJL3',
      'GCYdI0xU44Hp',
      'pkPckKQv8fcb',
      '3UCXXnkifzaB',
      'XWXSrzeSvWpx',
      'aWguik79VeZD',
      'QRUY1jyYLDUS',
      '2SYA8HhbrPSf',
      'qnGdldciKveh',
      'YGTllLtKysUk',
      'mCdE07LfLLrg',
    ],
  },
  adult_vaginal_sample_survey: {
    typeform_id: 'BmxKJHaE',
    question_ids: [],
  },
  resample_baby_gut: {
    typeform_id: 'SWcNGzSS',
    question_ids: [],
  },
  resample_adult_gut: {
    typeform_id: 'I2C9BUug',
    question_ids: [],
  },
  resample_vaginal: {
    typeform_id: 'ZT0BCNdn',
    question_ids: [],
  },
  health_history_adult_child_survey: {
    typeform_id: 'ydGEdJNf',
    question_ids: [
      'NNzCNw3FwFqj',
      '8efo8NMzFJL3',
      'GCYdI0xU44Hp',
      'xWlGTknE88mE',
      'OKBrJNeXdJ2j',
      'zWSWFmq3Ltps',
      'mCdE07LfLLrg',
      't7RpKi8iRT2V',
    ],
  },
  health_history_toddler_survey: {
    typeform_id: 'pmekKLQs',
    question_ids: [
      'GONlWxGahowu',
      'UI2Cz4tcQPRC',
      '3WWRWueut4gV',
      'QRGjVoyFOqW7',
      'hlX0eYcOvIgH',
      'XAUwq2mwCgs3',
      'dpGiRrKYTNbD',
      'hsaKqXSvxPsR',
      'bXx2FmVcqIKP',
    ],
  },
  birth_pregnancy_complete_survey: {
    typeform_id: 'hEcpsQXp',
    question_ids: [
      'qLIPjNf4zlZj',
      '1QWKwudvtl0C',
      '81mHc8wGa8LD',
      'cdBgXpOZGlYJ',
      'ejs5GBOKpDpb',
    ],
  },
  baby_solids_survey: {
    typeform_id: 'g1zx0SEd',
    question_ids: ['2611pJ6WsVLc', 'KtqIojQVj8Rg', 'GLBiX9EKsgwa'],
  },
  trying_to_conceive_survey: {
    typeform_id: 'xKAX9PWl',
    question_ids: ['G6ycg7BQtFFk', 'AKEO17KwpUgU'],
  },
};

export const getImportantSurveyQuestions = (): ImportantQuestionsBySurvey => {
  return importantQuestionsBySurveyType;
};
