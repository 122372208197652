import { Suspense } from 'react';

import useConditionsTaxa from 'services/conditions/conditionsTaxa';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { Condition } from 'types/Conditions';

import { Spinfinity, Markdown } from 'components';
import { AssociatedTaxaList } from './AssociatedTaxaList';
import { AssociatedRiskFactors } from './AssociatedRiskFactors';

import styles from './Conditions.module.scss';

export const ConditionRisk = ({
  condition,
  kitId,
}: {
  condition: Condition;
  kitId: string;
}) => {
  const { currentTinyAccount } = useCurrentKitAndTinyAccount();
  // fetch the condition taxa for the current kit
  const { data: taxaData } = useConditionsTaxa();

  const taxa = taxaData ?? [];
  const associations = [...new Set(taxa?.map(t => t.association))].sort(
    (a, b) => {
      return b < a ? 1 : -1;
    },
  );

  if (!kitId || !currentTinyAccount) return null;

  return (
    <Suspense fallback={<Spinfinity />}>
      {associations.length ? (
        <div>
          <h3 className={styles.conditionTitle}>Microbes in this sample</h3>
          <div className={styles.taxaList}>
            {associations.map(association => (
              <AssociatedTaxaList
                key={'list-' + association}
                taxa={taxa}
                condition={condition.condition}
                association={association}
              />
            ))}
          </div>
        </div>
      ) : null}
      {!!condition?.decreased_risk ||
      !!condition?.increased_risk ||
      !!condition?.other_factors ? (
        <div>
          <h3 className={styles.conditionTitle}>Other risk factors</h3>
          <div className={styles.secondaryText}>
            {condition?.other_factors && (
              <Markdown>{condition.other_factors}</Markdown>
            )}
          </div>
          {associations.length &&
          (!!condition?.decreased_risk || !!condition?.increased_risk) ? (
            <div className={styles.taxaList}>
              {associations.map(association => (
                <AssociatedRiskFactors
                  key={'risk-' + association}
                  condition={condition}
                  association={association}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </Suspense>
  );
};
