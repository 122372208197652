import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import c from 'classnames';

import { usePreviousKitMajorVersion } from 'services/system-info/usePreviousKitMajorVersion';

import { ContainerSizes, PageContainer } from 'components';
import { Icon } from '@repo/ui';
import { DownloadPDF } from './steps/DownloadPDF';
import { OptInOrOut } from './steps/OptInOrOut';
import { Updating } from './steps/Updating';

import styles from './VersionUpdate.module.scss';

export type UpdateStepProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  navigateBack: () => void;
};
type UpdateStep = {
  id: string;
  Component: React.FC<UpdateStepProps>;
};
const versionUpdateSteps: UpdateStep[] = [
  {
    id: 'downloadPDF',
    Component: DownloadPDF,
  },
  {
    id: 'optInOrOut',
    Component: OptInOrOut,
  },
  {
    id: 'updating',
    Component: Updating,
  },
];

export const VersionUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { hasMultipleVersions } = usePreviousKitMajorVersion();

  const [currentStep, setCurrentStep] = useState(0);

  const navigateBack = () => {
    if (state?.from) {
      navigate(state.from);
    } else {
      navigate('/results');
    }
  };
  const onClickBack = () => {
    if (currentStep === 0) {
      navigateBack();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const StepComponent = versionUpdateSteps[currentStep].Component;

  if (!hasMultipleVersions && currentStep === 0) return <></>;
  return (
    <div>
      <div className={styles.navigation}>
        <div className={styles.navPlaceholder}>
          {currentStep < versionUpdateSteps.length - 1 && (
            <div className={styles.backButton} onClick={onClickBack}>
              <Icon name='chevronBack' />
              Back
            </div>
          )}
        </div>
        <div className={styles.stepsProgress}>
          {versionUpdateSteps.map((s, idx) => (
            <div
              className={c(
                styles.progressMarker,
                idx < currentStep && styles.filled,
                idx === currentStep && styles.partial,
              )}
              key={idx}
              data-testid='progressMarker'
            ></div>
          ))}
        </div>
        <div className={styles.navPlaceholder}></div>
      </div>
      <PageContainer size={ContainerSizes.SM} className={styles.pageContainer}>
        <StepComponent
          setCurrentStep={setCurrentStep}
          navigateBack={navigateBack}
        />
      </PageContainer>
    </div>
  );
};
