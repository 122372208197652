import { GutEvaluationMeaning } from 'types/GutType';

export const getColorFromGutVaginalEvaluationMeaning = (
  meaning: GutEvaluationMeaning,
) => {
  let color;
  switch (meaning) {
    case 'borderline':
      color = 'orange';
      break;
    case 'clear':
      color = 'green';
      break;
    case 'flag':
      color = 'red';
      break;
    default:
      color = 'purple';
      break;
  }
  return color;
};
