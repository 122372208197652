import { ActionPlanItem, ActionPlanPersonalization } from 'types/ActionPlan';

import { Modal } from 'components';
import { Icon, Typography } from '@repo/ui';
import styles from './WytuCardAP.module.scss';

const getTextToDisplay = (personalization: ActionPlanPersonalization) => {
  if (!personalization.action_plan_personalization_kits?.length)
    return personalization.personalized_text;
  const variables =
    personalization.action_plan_personalization_kits[0]?.variable?.split(',');
  let finalText = personalization.personalized_text;
  variables?.forEach(variable => {
    finalText = finalText?.replace('{}', variable);
  });
  return finalText;
};

export const WytuCardAP = ({ action }: { action: ActionPlanItem }) => {
  if (!action?.action_plan_personalization?.[0]) return <></>;
  return (
    <div className={styles.wytuCard}>
      <div className={styles.wytuHeader}>
        <Icon name='stars02Filled' size='s' />
        <span>You told us</span>
      </div>
      <div className={styles.wytuBody}>
        <div>{getTextToDisplay(action.action_plan_personalization[0])}</div>
      </div>
      {action.action_plan_personalization?.length > 1 && (
        <Modal
          title='You told us'
          label={<span className={styles.detailMore}>Read more</span>}
        >
          <div className={styles.wytuBody}>
            {action.action_plan_personalization.map(
              (personalization: ActionPlanPersonalization) => (
                <div key={personalization.id}>
                  {getTextToDisplay(personalization)}
                </div>
              ),
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};
