import { PieChart, pieChartConfigSeeding, Typography } from 'components';
import { getColors } from 'contexts';
import { SeedingModal } from './SeedingModal';
import styles from './Seeding.module.scss';

export const SeedingCard = ({
  seedingData,
  babyFirstName,
  birthMotherFirstName,
}) => {
  const sharedTaxaPercent = Math.round(
    seedingData.sharedTaxaRelAbundance * 100,
  );

  return (
    <div className={styles.seedingCarouselWrapper}>
      <div>
        <Typography variant='heading-xl'>
          <span className={styles.pieChartTitlePercent}>
            {sharedTaxaPercent}%&nbsp;
          </span>
          <small className={styles.pieChartTitleText}>
            {' '}
            from {birthMotherFirstName}
          </small>
        </Typography>
        <div className={styles.pieChartContainer}>
          <PieChart
            id={'seedingPieChart'}
            config={{ ...pieChartConfigSeeding() }}
            labels={[birthMotherFirstName, 'Environment']}
            data={[
              {
                label: 'Seeding Microbes',
                // Order must match labels
                data: [
                  // mom
                  sharedTaxaPercent,
                  // env
                  100 - sharedTaxaPercent,
                ],
                // Order must match labels
                backgroundColor: [getColors().seed, getColors().neutral],
                borderColor: [getColors().bg],
                borderWidth: 4,
                hoverOffset: 4,
              },
            ]}
          />
        </div>
      </div>

      <div className={styles.seedingCarouselContainer}>
        <div className={styles.environmentContainer}>
          <SeedingModal
            data={seedingData.environmentMicrobes}
            bacteriaBreakdown={seedingData.environmentBreakdown}
          />
        </div>
        <div className={styles.transferredContainer}>
          <SeedingModal
            birthMotherFirstName={birthMotherFirstName}
            babyFirstName={babyFirstName}
            data={seedingData.transferredMicrobes}
            bacteriaBreakdown={seedingData.transferBreakdown}
          />
        </div>
      </div>
    </div>
  );
};
