import { useMatch } from 'react-router-dom';
import styles from './HeaderIllustration.module.scss';
const HeaderIllustration = ({
  url,
  imgSrc,
  bgColor,
}: {
  url?: string;
  imgSrc?: string;
  bgColor?: string | null;
}) => {
  const { subMetricId } =
    useMatch<'subMetricId', string>(
      '/results/:kitId/insights/:category/:subMetricId',
    )?.params ?? {};
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.style.display = 'none';
  };
  if (subMetricId) return null;
  return (
    <div
      className={styles.illustrationWrapper}
      style={{ backgroundColor: bgColor ?? '' }}
    >
      {!!url && (
        <img src={url} alt='header illustration' onError={handleError} />
      )}
      {!!imgSrc && <img src={imgSrc} alt='header illustration' />}
    </div>
  );
};

export default HeaderIllustration;
