import { useEffect, useRef, useState } from 'react';
import {
  Area,
  ReferenceDot,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  ComposedChart,
} from 'recharts';

import { stringifyDaysOld } from 'helpers';
import useTaxaOverTime from 'services/insights/useTaxaOverTime';
import { InsightMetric } from 'types/Insights';
import Kit from 'types/Kit';

import { Spinfinity, Typography } from '@repo/ui';
import maturationIndexData from './maturation_index_reference_data.json';

const formatAge = (age?: number) => {
  if (!age && typeof age !== 'number') return '';
  switch (age) {
    case 0:
      return 'Birth';
    case 182:
      return '6mo';
    case 365:
      return '12mo';
    case 548:
      return '18mo';
    case 730:
      return '24mo';
    case 912:
      return '30mo';
    case 1095:
      return '36mo';
  }
  return age + ' days';
};
interface CustomDotProps {
  cx: number;
  cy: number;
  r?: number;
  fill?: string;
  borderColor?: string;
  onMouseEnter?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
  onMouseLeave?: () => void;
  onClick?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
}
const CustomDot: React.FC<CustomDotProps> = ({
  cx,
  cy,
  r = 8,
  fill = '#FF6347',
  borderColor = '#32CD32',
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => {
  if (isNaN(cx) || isNaN(cy)) return null;

  return (
    <svg
      x={cx - r - 3}
      y={cy - r - 3}
      width={r * 2 + 6}
      height={r * 2 + 6}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <circle
        cx='10'
        cy='10'
        r='5'
        fill={fill}
        stroke={borderColor}
        strokeWidth='3'
      />
    </svg>
  );
};

const CustomTick: React.FC<{
  x: number;
  y: number;
  payload: any;
  isFirst: boolean;
}> = ({ x, y, payload, isFirst }) => {
  return (
    <text
      x={x}
      y={y + 2}
      textAnchor={isFirst ? 'start' : 'middle'} // Align first tick to the start
      fill='#666'
      fontSize='11px'
    >
      {formatAge(payload.value)}
    </text>
  );
};

interface CustomTooltipProps {
  x: number;
  y: number;
  trueAge: number;
  gutAge: number;
  isVisible: boolean;
}
const CustomTooltip: React.FC<CustomTooltipProps> = ({
  x,
  y,
  trueAge,
  gutAge,
  isVisible,
}) => {
  if (!isVisible) return <></>;
  return (
    <div
      style={{
        position: 'absolute',
        top: y - 55,
        left: x - 50,
        backgroundColor: '#fff',
        border: '1px solid var(--warm-grey-400)',
        color: 'var(--warm-grey-900)',
        padding: '5px 10px',
        borderRadius: '4px',
        fontSize: '12px',
        pointerEvents: 'none',
        zIndex: 1000,
      }}
    >
      <div className='flex flex-column'>
        <div className='flex align-items-center gap-1'>
          <Typography variant='label'>Real age:</Typography>
          <Typography variant='label' weight='bold'>
            {stringifyDaysOld(trueAge, false, false)}
          </Typography>
        </div>
        <div className='flex align-items-center gap-1'>
          <Typography variant='label'>Gut age:</Typography>
          <Typography variant='label' weight='bold'>
            {stringifyDaysOld(gutAge, true, false)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const MaturationIndexGraph = ({
  kit,
  subMetric,
  minHeight,
  showXAxisLabel = true,
  showYAxisLabel = true,
  showYAxisTicks = true,
  disableAnimations = true,
  onlyCurrentKit = false,
  hideAllAxisLabels = false,
}: {
  kit?: Kit;
  subMetric?: InsightMetric;
  minHeight?: number;
  showXAxisLabel?: boolean;
  showYAxisLabel?: boolean;
  showYAxisTicks?: boolean;
  disableAnimations?: boolean;
  onlyCurrentKit?: boolean;
  hideAllAxisLabels?: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Tooltip state
  const [tooltip, setTooltip] = useState<{
    x: number;
    y: number;
    trueAge: number;
    gutAge: number;
    isVisible: boolean;
  }>({
    x: 0,
    y: 0,
    trueAge: 0,
    gutAge: 0,
    isVisible: false,
  });

  // Mouse enter handler for ReferenceDot
  const handleMouseEnter = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    taxa: any,
  ) => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    const xValue = e.pageX - containerRect!.left;
    const yValue = e.pageY - containerRect!.top;
    setTooltip({
      x: xValue,
      y: yValue,
      trueAge: taxa.age_at_sampling,
      gutAge: taxa.user_value ?? 0,
      isVisible: true,
    });
  };

  // Mouse leave handler to hide tooltip
  const handleMouseLeave = () => {
    setTooltip(prev => ({ ...prev, isVisible: false }));
  };

  const handleClick = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    taxa: any,
  ) => {
    e.stopPropagation(); // Prevent click from triggering outside click
    const containerRect = containerRef.current?.getBoundingClientRect();
    setTooltip({
      x: e.pageX - containerRect!.left,
      y: e.pageY - containerRect!.top - window.scrollY,
      trueAge: taxa.age_at_sampling,
      gutAge: taxa.user_value ?? 0,
      isVisible: true,
    });
  };
  const handleOutsideClick = () => {
    setTooltip(prev => ({ ...prev, isVisible: false }));
  };
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  const { data: taxaOverTimeData, isLoading } = useTaxaOverTime({
    kitId: kit?.id,
    metricId: subMetric?.sample_health_id,
  });

  if (!kit) return <></>;
  if (isLoading) return <Spinfinity />;
  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <ResponsiveContainer
        width={'100%'}
        minWidth={225}
        minHeight={minHeight ?? 250}
        maxHeight={!!minHeight && minHeight > 325 ? minHeight : 325}
      >
        <ComposedChart
          width={730}
          height={275}
          //the data from David with the average of the age range limits as requested
          data={maturationIndexData}
          margin={{
            top: 10,
            bottom: 10,
            left: 5,
            right: hideAllAxisLabels ? 5 : 0,
          }}
        >
          <Area
            dataKey='redArea'
            stroke={'none'}
            fill='var(--red-100)'
            fillOpacity={1}
            activeDot={false}
            isAnimationActive={!disableAnimations}
          />

          <XAxis
            dataKey='age_days'
            tickMargin={10}
            type='number'
            domain={['dataMin', 'dataMax']}
            scale='linear'
            ticks={[0, 182, 365, 548, 730, 912, 1095]}
            tick={props =>
              hideAllAxisLabels ? (
                <></>
              ) : (
                <CustomTick
                  x={props.x}
                  y={props.y}
                  payload={props.payload}
                  isFirst={props.index === 0}
                />
              )
            }
            label={
              showXAxisLabel && !hideAllAxisLabels
                ? {
                    value: 'real age',
                    offset: 15,
                    dy: 18,
                    style: {
                      textAnchor: 'middle',
                      fill: '#7E7E7E',
                      fontSize: '11px',
                    },
                  }
                : {}
            }
            hide={hideAllAxisLabels}
            allowDataOverflow
          />
          <YAxis
            orientation='right'
            ticks={[0, 182, 365, 548, 730, 912, 1095]}
            tickFormatter={tick =>
              showYAxisTicks && !hideAllAxisLabels
                ? formatAge(tick)
                : formatAge()
            }
            label={
              showYAxisLabel && !hideAllAxisLabels
                ? {
                    value: 'estimated microbiome age',
                    angle: -90,
                    dx: showYAxisTicks ? 15 : -5,
                    dy: 8,
                    position: 'outsideRight',
                    style: {
                      textAnchor: 'middle',
                      fill: '#7E7E7E',
                      fontSize: '11px',
                    },
                  }
                : {}
            }
            domain={[0, 1100]}
            hide={hideAllAxisLabels}
            allowDataOverflow
          />

          <Area
            dataKey='orangeArea'
            stroke={'none'}
            fill='var(--orange-100)'
            fillOpacity={1}
            activeDot={false}
            type='basis'
            isAnimationActive={!disableAnimations}
          />
          <Area
            dataKey='greyArea'
            stroke={'none'}
            fill='var(--grey-100)'
            fillOpacity={1}
            activeDot={false}
            type='basis'
            isAnimationActive={!disableAnimations}
          />
          <Area
            type='basis'
            dataKey='whiteArea'
            stroke={'none'}
            fill='var(--green-100)'
            fillOpacity={1}
            activeDot={false}
            isAnimationActive={!disableAnimations}
          />
          <Line
            type='basis'
            dataKey='greenLine'
            stroke='var(--green-200)'
            strokeWidth={4}
            dot={false}
            activeDot={false}
          />

          {taxaOverTimeData
            .filter((taxa, index) => {
              if (onlyCurrentKit) {
                return taxa.kit_id === kit.id;
              }
              return true;
            })
            .map((taxa, index) => {
              return (
                <ReferenceDot
                  key={taxa.age_at_sampling}
                  x={taxa.age_at_sampling}
                  y={taxa.user_value ?? 0}
                  r={6}
                  shape={props => (
                    <CustomDot
                      {...props}
                      fill={
                        taxa.kit_id === kit.id
                          ? 'var(--purple-200)'
                          : 'var(--white)'
                      }
                      borderColor={
                        taxa.kit_id === kit.id
                          ? 'var(--purple-200)'
                          : taxa.evaluation_color === 'grey'
                            ? 'var(--warm-grey-400)'
                            : `var(--${taxa.evaluation_color}-300)`
                      }
                      onMouseEnter={e => handleMouseEnter(e, taxa)} // Attach event to CustomDot
                      onMouseLeave={handleMouseLeave}
                      onClick={e => handleClick(e, taxa)}
                    />
                  )}
                />
              );
            })}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomTooltip
        x={tooltip.x}
        y={tooltip.y}
        trueAge={tooltip.trueAge}
        gutAge={tooltip.gutAge}
        isVisible={tooltip.isVisible}
      />
    </div>
  );
};

export default MaturationIndexGraph;
