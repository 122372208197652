import { Control, Controller, RegisterOptions } from 'react-hook-form';
import c from 'classnames';

import { Typography } from '@repo/ui';
import { ProfileFormData } from 'views/Profiles/Profile';

import styles from './THCheckbox.module.scss';

type CheckboxProps = {
  options: string[];
  name: string;
  control: Control<ProfileFormData>;
  rules?: RegisterOptions;
  defaultValues?: string[];
  disabled?: boolean;
  classes?: string;
  id?: string;
};

const THCheckboxGroup = ({
  options,
  name,
  control,
  rules,
  defaultValues,
  classes,
  id,
  ...rest
}: CheckboxProps) => {
  return (
    <>
      {options.map(option => (
        <div key={option}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValues}
            rules={rules}
            render={({ field }) => (
              <label
                htmlFor={`${id}-${option}`}
                className={c(styles.container, classes)}
              >
                <Typography variant='body-s'>{option}</Typography>
                <input
                  id={`${id}-${option}`}
                  type='checkbox'
                  value={option}
                  checked={(field.value as string[]).includes(option)}
                  onChange={e => {
                    const newValue = [...(field.value as string[])];
                    if (e.target.checked) {
                      newValue.push(option);
                    } else {
                      const index = newValue.indexOf(option);
                      if (index > -1) {
                        newValue.splice(index, 1);
                      }
                    }
                    field.onChange(newValue);
                  }}
                  {...rest}
                />
                <span className={styles.checkmark}></span>
              </label>
            )}
          />
        </div>
      ))}
    </>
  );
};

export default THCheckboxGroup;
