import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';

import { colorForTinyAccount } from 'helpers';
import TinyAccount from 'types/TinyAccount';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';

import { CardSlider } from 'components';

import styles from './TinyAccountsFilter.module.scss';

type TinyAccountsFilterProps = {
  tinyAccounts: TinyAccount[];
  onSelection?: (tinyAccount: TinyAccount | null) => void;
  allowSelectAll?: boolean;
};

export const TinyAccountsFilter = ({
  tinyAccounts,
  onSelection,
  allowSelectAll = true,
}: TinyAccountsFilterProps) => {
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);

  const handleSelection = useCallback(
    (tinyAccount: TinyAccount | null) => {
      //call the function passed down on props with the selected family member (tinyAccount)
      if (onSelection && typeof onSelection === 'function') {
        onSelection(tinyAccount);
      }
      setSelectedAccount(tinyAccount ? tinyAccount.id : null);
    },
    [onSelection],
  );

  const accountsToShow = useMemo(() => {
    const selectAll = (
      <div
        key='all'
        className={c(
          styles.avatarButton,
          styles.all,
          !selectedAccount && styles.selected,
        )}
        onClick={() => handleSelection(null)}
      >
        <div className={styles.avatarName}>All items</div>
      </div>
    );
    const accounts = tinyAccounts.map((ta: TinyAccount, index: number) => (
      <div
        key={ta.id}
        className={c(
          styles.avatarButton,
          selectedAccount === ta.id && styles.selected,
        )}
        onClick={() => handleSelection(ta)}
      >
        <div
          className={c(styles.avatarBubble)}
          style={{
            backgroundColor: colorForTinyAccount(orderedTinyAccounts, ta.id),
          }}
        >
          {ta.first_name.substr(0, 1)}
        </div>
        <div className={styles.avatarName}>{ta.first_name}</div>
      </div>
    ));
    return allowSelectAll ? [selectAll, ...accounts] : accounts;
  }, [
    selectedAccount,
    tinyAccounts,
    allowSelectAll,
    handleSelection,
    orderedTinyAccounts,
  ]);

  return !tinyAccounts?.length ? null : (
    <>
      <div className={styles.rowContainer}>
        <CardSlider cards={accountsToShow} />
      </div>
    </>
  );
};
