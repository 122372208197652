import { useSuspenseQueries } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import useMainAccount, {
  getMainAccountQuery,
} from 'services/account/useMainAccount';
import useTinyAccounts, {
  getTinyAccountsQuery,
} from 'services/account/useTinyAccounts';
import useAllBookmarks, {
  getAllBookmarksQuery,
} from 'services/actionPlan/useAllBookmarks';
import useConsults, { getConsultsQuery } from 'services/general/consultsList';
import useAllFeatures, {
  getAllFeaturesQuery,
} from 'services/features/useAllFeatures';
import useKits, { getKitsQuery } from 'services/kits/useKits';
import { getFirstName, getLastName, getMainAccountId } from 'store/account';
import { getEmail, getPhoneNumber } from 'store/user';

const useAccountsAndKits = () => {
  const mainAccountId = useSelector(getMainAccountId);
  const email = useSelector(getEmail);
  const phoneNumber = useSelector(getPhoneNumber);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  useSuspenseQueries({
    queries: [
      getKitsQuery(mainAccountId),
      getMainAccountQuery(
        mainAccountId,
        email,
        phoneNumber,
        firstName,
        lastName,
      ),
      getTinyAccountsQuery(mainAccountId),
      getAllFeaturesQuery(mainAccountId),
      getConsultsQuery(mainAccountId),
      // getAllBookmarksQuery(mainAccountId),
    ],
  });
  useKits();
  useMainAccount();
  useTinyAccounts();
  useAllFeatures();
  useConsults();
  // useAllBookmarks();
};
export default useAccountsAndKits;
