import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getIsAuthenticated } from '../store/user';

const CatchAllRoute = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  if (isAuthenticated) {
    return <Navigate to='/results' />;
  }
  return <Navigate to='/' />;
};
export default CatchAllRoute;
