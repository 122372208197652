import MarkdownToJSX from 'markdown-to-jsx';

import { MarkdownCard } from 'components';

const LinkTargetBlank = (props: React.PropsWithChildren) => {
  return (
    <a {...props} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  );
};

interface MarkdownProps {
  children: string;
  className?: string;
  customOverride?: object;
  forceBlock?: boolean;
}
export const Markdown = ({
  children,
  className,
  customOverride,
  forceBlock = true,
}: MarkdownProps) => {
  const customOptions = {
    forceBlock: forceBlock,
    forceWrapper: !!className,
    wrapperProps: {
      className: className,
    },
    overrides: {
      MarkdownCard: {
        component: MarkdownCard,
      },
      a: LinkTargetBlank,
      ...customOverride,
    },
  };
  if (!children) return <></>;
  return (
    <MarkdownToJSX options={customOptions} className={className}>
      {children.replace(/\\n\s*/gi, '\n\n')}
    </MarkdownToJSX>
  );
};
