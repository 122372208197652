import { Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import useConditions from 'services/conditions/useConditions';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import { ActionPlanDesiredOutcome, ActionPlanItem } from 'types/ActionPlan';
import { Condition } from 'types/Conditions';
import { FeedbackPageType } from 'types/Feedback';
import Guide from 'types/Guide';

import {
  EvidenceRating,
  PageHeader,
  Spinfinity,
  FAQ,
  CardSlider,
  TextLink,
  PageContainer,
  ContainerSizes,
  FeedbackWidget,
  Markdown,
} from 'components';
import { ActionPlanGroup } from 'views/ActionPlan/ActionPlanGroup/ActionPlanGroup';
import { GuideCard } from 'views/Guides/GuideCard/GuideCard';
import { ConditionCard } from './ConditionCard';
import { ConditionRisk } from './ConditionRisk';

import styles from './Conditions.module.scss';

const filterOutcomesBySectionAndId = (
  data: ActionPlanItem,
  section = '',
  filterId = '',
) => {
  const cardData = {
    ...data,
    display_title: data.display_title,
    detail: data.detail,
    desired_outcomes:
      section && filterId
        ? data.desired_outcomes?.filter(
            (outcome: ActionPlanDesiredOutcome) =>
              outcome.section === section && outcome.criteria === filterId,
          )
        : data.desired_outcomes,
    id: data.id,
  };
  return cardData;
};

export const ConditionDetailView = () => {
  const { currentKitId, currentKit } = useCurrentKitAndTinyAccount();
  const { condition } = useParams<{ condition: string }>();
  if (!condition) throw new Error('No condition provided');

  // get the conditions for this kit
  const { data: kitData } = useConditions({
    kitId: currentKitId,
  });

  const currentCondition: Condition = useMemo(
    () => kitData?.find((cond: Condition) => cond.condition === condition),
    [condition, kitData],
  );

  const { data: actionPlanFromDBResults } = useActionPlanData({
    kitId: currentKitId,
  });
  const actionPlanItemsFiltered = !actionPlanFromDBResults?.length
    ? []
    : actionPlanFromDBResults
        .map((item: ActionPlanItem) =>
          filterOutcomesBySectionAndId(
            item,
            'Biomarkers',
            currentCondition.conditions_evaluation_id + '',
          ),
        )
        .filter((item: ActionPlanItem) => !!item.desired_outcomes?.length);

  const guidesToShow = useMemo(() => {
    return currentCondition.guides.map((guide: Guide) => (
      <GuideCard key={guide.id} guide={guide} />
    ));
  }, [currentCondition.guides]);

  if (!currentKitId || !currentCondition) return <Spinfinity />;
  return (
    <PageContainer size={ContainerSizes.LG}>
      <PageHeader title={currentCondition?.display_title ?? condition} />
      <div className={styles.sectionsContainer}>
        <main className={styles.conditionsWrapper}>
          <div className={styles.conditionContainer}>
            <ConditionCard
              condition={currentCondition}
              kit={currentKit}
              withEvaluationText
            />
            {(!!currentCondition?.evidence_rating ||
              !!currentCondition?.about) && (
              <div>
                <h3 className={styles.conditionTitle}>{`About the ${
                  currentCondition?.display_title ?? condition
                } ${
                  currentCondition?.type === 'biomarker'
                    ? 'Biomarker'
                    : 'Associations'
                }`}</h3>
                {!!currentCondition?.evidence_rating && (
                  <EvidenceRating
                    evidenceRating={currentCondition.evidence_rating}
                  />
                )}
                {!!currentCondition?.about && (
                  <span className={styles.secondaryText}>
                    <Markdown>{currentCondition.about}</Markdown>
                  </span>
                )}
              </div>
            )}

            <Suspense fallback={<Spinfinity />}>
              <ConditionRisk
                condition={currentCondition}
                kitId={currentKitId}
              />
            </Suspense>

            <FeedbackWidget
              kitId={currentKitId}
              pageType={FeedbackPageType.CONDITION}
              pageId={currentCondition?.id}
            />

            {currentCondition?.faq && (
              <div>
                <h3 className={styles.conditionTitle}>
                  Frequently asked questions
                </h3>
                <FAQ faq={currentCondition.faq} />
              </div>
            )}
            <div className={styles.hideOnDesktop}>
              <ActionPlanGroup actionPlanItems={actionPlanItemsFiltered} />
              {!!guidesToShow?.length && (
                <div className={!actionPlanItemsFiltered?.length ? '' : 'mt-4'}>
                  <div className={styles.guidesRow}>
                    <h3 className={styles.conditionTitle}>Related Articles</h3>
                    <TextLink label='View all' to={`/guides`} />
                  </div>
                  <div className={styles.conditionsSliderContainer}>
                    <CardSlider cards={guidesToShow} />
                  </div>
                </div>
              )}
            </div>
            {!!currentCondition?.references && (
              <div>
                <h3 className={styles.conditionTitle}>References</h3>
                <div className={styles.references}>
                  <Markdown>{currentCondition?.references}</Markdown>
                </div>
              </div>
            )}
          </div>
        </main>
        <section>
          <div className={styles.hideOnMobile}>
            <ActionPlanGroup actionPlanItems={actionPlanItemsFiltered} />
            {!!guidesToShow?.length && (
              <div className={!actionPlanItemsFiltered?.length ? '' : 'mt-4'}>
                <div className={styles.guidesRow}>
                  <h3 className={styles.conditionTitle}>Related Articles</h3>
                  <TextLink label='View all' to={`/guides`} />
                </div>
                <div className={styles.guidesGridListContainer}>
                  {guidesToShow}
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </PageContainer>
  );
};
