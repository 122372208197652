import EmptyActionPlanImage from 'assets/images/empty-list.svg?react';
import { Typography } from '@repo/ui';
import styles from './ActionPlan.module.scss';
const EmptyActionPlan = () => {
  return (
    <div className={styles.emptyActionPlan}>
      <div className='text-center'>
        <EmptyActionPlanImage className={styles.emptyActionPlanImage} />
      </div>
      <div className='flex flex-column gap-2'>
        <Typography variant='heading-l' className='m-auto'>
          Nothing to see yet
        </Typography>
        <Typography variant='body-s' className='text-center'>
          You'll get your personalized Action Plan when your lab results are
          ready.
        </Typography>
      </div>
    </div>
  );
};

export default EmptyActionPlan;
