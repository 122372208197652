import c from 'classnames';

import { MyFamily } from '../MyFamily';

import TinyAccount from 'types/TinyAccount';
import { KitStepProps } from 'views/Kits/ActivateKit';

import styles from '../Kits.module.scss';
import { useCallback } from 'react';

const MemberStep = ({
  tinyAccounts,
  setActivationState,
  activationState,
  step,
  setCurrentActivationStep,
}: KitStepProps) => {
  const tinyAccountChosen = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        setActivationState({
          ...activationState,
          tinyAccount: { ...tinyAccount, id: tinyAccount.id },
        });
        setCurrentActivationStep(step + 1);
      }
    },
    [activationState, setActivationState, setCurrentActivationStep, step],
  );
  return (
    <div className={c(styles.stepContainer, styles.memberStepContainer)}>
      <h2 className={styles.stepHeader}>Who is this kit for?</h2>
      <MyFamily
        type='column'
        tinyAccounts={tinyAccounts}
        onSelection={tinyAccountChosen}
      />
    </div>
  );
};

export default MemberStep;
