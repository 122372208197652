export function userValueFormat(
  value: number,
  dataType: string,
  stringValue = '',
) {
  if (dataType === 'string') return stringValue;
  if (!value && dataType === 'percent') return '0%';
  let result: number = value;
  let addSymbol = false;
  let decimalPlaces = 0;
  if (dataType === 'percent') {
    result = value * 100;
    addSymbol = true;
    decimalPlaces = 3;
  } else if (result <= 1) {
    decimalPlaces = 2;
  } else if (result < 100) {
    //some of these would look a little wonky since some functional numbers have values above and below 100 but it is acceptable
    decimalPlaces = 1;
  }
  const formattedValue: string =
    result.toFixed(decimalPlaces) + (addSymbol ? '%' : '');
  return formattedValue;
}
