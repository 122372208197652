import { Suspense, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getKitById, getLatestKitForTinyAccountId } from 'store/kits';
import { getHomePageTinyAccountId } from 'store/home';
import TinyAccount from 'types/TinyAccount';

import { Logo, Brand, TinyAccountSelector } from 'components';
import { useSidebarItems } from 'components/Sidebar/useSidebarItems';
import SidebarItem from './SidebarItem';

import styles from './Sidebar.module.scss';

export const Sidebar = () => {
  const navigate = useNavigate();
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const homeScreenMatch = useMatch('/');
  const resultsMatch = useMatch<'kitId', string>('/results/:kitId');
  const actionPlanMatch = useMatch<'kitId', string>('/action-plan/:kitId');
  const homePageTinyAccountId = useSelector(getHomePageTinyAccountId);
  const mostRecentKitIdFromHomePage = useSelector(
    getLatestKitForTinyAccountId(homePageTinyAccountId ?? null),
  )?.id;
  const kitIdParam = homeScreenMatch
    ? (mostRecentKitIdFromHomePage ?? '')
    : resultsMatch?.params.kitId ||
      actionPlanMatch?.params.kitId ||
      currentKitId ||
      mostRecentKitIdFromHomePage ||
      '';

  const [mostRecentKitId, setMostRecentKitId] = useState(currentKitId);
  const kit = useSelector(getKitById(kitIdParam));
  const tinyAccountId = kit?.tinyaccount_id;
  const tinyAccountSelected = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        navigate(`/`, { state: { tinyAccountId: tinyAccount.id } });
      }
    },
    [navigate],
  );
  useEffect(() => {
    if (kitIdParam && kitIdParam !== mostRecentKitId) {
      setMostRecentKitId(kitIdParam);
    }
  }, [kitIdParam, mostRecentKitId]);
  const sidebarItems = useSidebarItems({ kitId: mostRecentKitId });

  return (
    <div className={styles.sideBarWrapper}>
      <div className={c(styles.sidebarContainer)}>
        <div className={styles.logoContainer}>
          <div className={styles.navLogo}>
            <Suspense fallback={<Logo />}>
              <Brand href='/' />
            </Suspense>
          </div>
        </div>

        <div className={styles.selectorContainer}>
          <TinyAccountSelector
            onSelection={tinyAccountSelected}
            currentTinyAccountId={tinyAccountId ?? homePageTinyAccountId}
            withProfile
          />
        </div>
        <div className={styles.itemsContainer}>
          <div>
            {sidebarItems
              .filter(item => !item.bottom)
              .map((item, index) => (
                <SidebarItem
                  key={index}
                  to={item.to}
                  icon={item.icon}
                  label={item.label}
                  external={item.external}
                  state={{ tinyAccountId }}
                />
              ))}
          </div>
          {/* bottom section of nav items*/}
          <div>
            {sidebarItems
              .filter(item => item.bottom)
              .map((item, index) => (
                <SidebarItem
                  key={index}
                  to={item.to}
                  icon={item.icon}
                  label={item.label}
                  external={item.external}
                  state={{ tinyAccountId }}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
