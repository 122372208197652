import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import {
  getEmail,
  getLastName,
  getFirstName,
  getImpersonateId,
  getPhoneNumber,
  getRequiresImpersonateId,
  getUsername,
} from 'store/user';
import { getMainAccountId, setMainAccount } from 'store/account';
import { useAppDispatch } from 'store/store';
import MainAccount from 'types/MainAccount';

const getOrCreateMainAccount = async ({
  email,
  phoneNumber,
  firstName,
  lastName,
}: {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}) => {
  const getMainAccountResponse = await API.get(
    apiName,
    `${apiBasePath}/mainaccounts/find`,
    {},
  );
  if (getMainAccountResponse?.result) {
    return getMainAccountResponse.result;
  } else {
    const createMainAccountResponse = await API.post(
      apiName,
      `${apiBasePath}/mainaccounts`,
      {
        body: {
          email: email,
          phone_number: phoneNumber,
          first_name: firstName,
          last_name: lastName,
        },
      },
    );
    return createMainAccountResponse?.result;
  }
};
export const getMainAccountQuery = (
  id?: string | null,
  email?: string,
  phoneNumber?: string,
  firstName?: string,
  lastName?: string,
) => ({
  queryKey: ['mainaccount', id],
  queryFn: async () =>
    id &&
    (await getOrCreateMainAccount({
      email,
      phoneNumber,
      firstName,
      lastName,
    })),
  enabled: !!id,
});
const useMainAccount = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const username = useSelector(getUsername);
  const impersonateId = useSelector(getImpersonateId);
  const email = useSelector(getEmail);
  const phoneNumber = useSelector(getPhoneNumber);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const requiresImpersonateId = useSelector(getRequiresImpersonateId);
  const mainAccountId = useSelector(getMainAccountId);
  const id = impersonateId ? impersonateId : username;

  useEffect(() => {
    if (!mainAccountId) {
      queryClient.invalidateQueries({ queryKey: ['mainaccount', id] });
    }
  }, [mainAccountId, queryClient, id]);

  const MainAccountQuery = useQuery<MainAccount>(
    getMainAccountQuery(id, email, phoneNumber, firstName, lastName),
  );

  useEffect(() => {
    if (MainAccountQuery?.data?.id) {
      dispatch(setMainAccount(MainAccountQuery.data));
    }
  }, [MainAccountQuery?.data, dispatch]);

  return MainAccountQuery;
};

export default useMainAccount;
