import Kit from '../types/Kit';

export function getShouldDisplaySeeding(kit: Kit) {
  if (!kit?.age_at_sampling) return false;
  const isABabyUnder7months = kit.age_at_sampling < 211;
  return (
    (isABabyUnder7months && kit.sampling_type === 'stool') ||
    kit.ttc ||
    kit.pregnant
  );
}
