import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { useEffect } from 'react';

import { apiBasePath, apiName } from 'assets';
import {
  getMainAccountId,
  setTinyAccounts,
  getTinyAccounts as getTinyAccountsSelector,
} from 'store/account';
import { useAppDispatch } from 'store/store';
import TinyAccount from 'types/TinyAccount';

const getTinyAccounts = async (): Promise<TinyAccount[]> => {
  const response = await API.get(apiName, `${apiBasePath}/tinyaccounts`, {});
  return response?.result ?? [];
};
export const getTinyAccountsQuery = (mainAccountId?: string | null) => ({
  queryKey: ['tinyAccounts', mainAccountId],
  queryFn: async () => (mainAccountId ? await getTinyAccounts() : []),
});
const useTinyAccounts = () => {
  const dispatch = useAppDispatch();
  const mainAccountId = useSelector(getMainAccountId);
  const tinyAccounts = useSelector(getTinyAccountsSelector);
  const tinyAccountsQuery = useQuery<TinyAccount[]>(
    getTinyAccountsQuery(mainAccountId),
  );

  useEffect(() => {
    if (tinyAccountsQuery.isSuccess) {
      dispatch(setTinyAccounts(tinyAccountsQuery.data));
    }
  }, [tinyAccountsQuery.isSuccess, tinyAccountsQuery.data, dispatch]);

  return { ...tinyAccountsQuery, tinyAccounts };
};

export default useTinyAccounts;
