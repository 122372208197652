import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { parseDate } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getKitsForMicrobiomeBarList } from 'store/kits';

import { Modal, CompareSampleCharts, Spinfinity } from 'components';
import { MicrobiomeBar } from './MicrobiomeBar';

import styles from './MicrobiomeBarList.module.scss';

export const MicrobiomeBarList = () => {
  const { currentKitId, currentKitType, currentTinyAccount } =
    useCurrentKitAndTinyAccount();

  const kitsForMicrobiomeBarList = useSelector(
    getKitsForMicrobiomeBarList(currentKitId),
  );
  return (
    <>
      {kitsForMicrobiomeBarList.map((kit, i) => {
        return (
          <div className='pb-3' key={kit.id}>
            <Suspense key={kit.id} fallback={<Spinfinity />}>
              {<MicrobiomeBar kit={kit} index={i} displayMode={'full'} />}
            </Suspense>
            <label className='flex justify-between small'>
              <span className='active'>
                {currentTinyAccount?.first_name}, {kit.sample_label}{' '}
                <span className={styles.dateLong}>
                  <span className='phablet-up'>sampled </span>
                  on {format(parseDate(kit.sample_date), 'MMMM do yyyy')}
                </span>
                <span className={styles.dateShort}>
                  <span className='phablet-up'>sampled </span>
                  on {format(parseDate(kit.sample_date), 'MM/dd/yy')}
                </span>
              </span>

              {currentKitType === 'stool' && (
                <Modal
                  title='Compare samples'
                  label='Compare sample'
                  labelClassName={styles.linkLike}
                >
                  <p>
                    {kit.week_of_pregnancy
                      ? 'Your levels compared to a representative sample of pregnant participants.'
                      : 'Below are a few representative samples so you can see how your samples compare.'}
                  </p>
                  <CompareSampleCharts kit={kit} />
                </Modal>
              )}
            </label>
          </div>
        );
      })}
    </>
  );
};
