import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';
import { useSelector } from 'react-redux';
import { getMainAccountId } from 'store/account';

export async function getAllBookmarks() {
  const response = await API.get(
    apiName,
    `${apiBasePath}/actionPlan/bookmarks`,
    {},
  );
  return response?.result ?? [];
}
export const getAllBookmarksQuery = (mainAccountId: string | null) => ({
  queryKey: [apiName, 'bookmarks', mainAccountId],
  queryFn: () => !!mainAccountId && getAllBookmarks(),
  enabled: !!mainAccountId,
});
const useAllBookmarks = () => {
  const mainAccountId = useSelector(getMainAccountId);
  const actionPlanItemQuery = useSuspenseQuery(
    getAllBookmarksQuery(mainAccountId),
  );
  return actionPlanItemQuery;
};

export default useAllBookmarks;
