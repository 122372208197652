import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { ProfileQuestionAnswer } from 'types/ProfileQuestions';

const postQuestionAnswers = async (
  answers: ProfileQuestionAnswer[],
  tinyAccountId: string,
  kitId: string,
) => {
  if (!tinyAccountId || !kitId) throw new Error('No info provided');
  if (!answers?.length) throw new Error('No answer provided');
  return API.post(
    apiName,
    `${apiBasePath}/tinyaccounts/${tinyAccountId}/profile-answers?is_sample=1&kit_id=${kitId}`,
    {
      body: answers,
    },
  );
};

const useUpdateSampleInfoAnswer = () => {
  const queryClient = useQueryClient();
  const sampleInfoAnswerMutation = useMutation({
    mutationFn: ({
      answers,
      tinyAccountId,
      kitId,
    }: {
      answers: ProfileQuestionAnswer[];
      tinyAccountId: string;
      kitId: string;
    }) => {
      return postQuestionAnswers(answers, tinyAccountId, kitId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('sampleInfoQuestions'),
      });
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('profileSurveys'),
      });
    },
  });
  return sampleInfoAnswerMutation;
};

export default useUpdateSampleInfoAnswer;
