import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

import {
  Spinfinity,
  OrderKitButton,
  PageContainer,
  ContainerSizes,
  Typography,
} from 'components';
import { MicrobiomeBarList } from './MicrobiomeBarList';
import { InsightsBacteria } from 'views/Results/Insights';
import { VaginalHealthBacteria } from '../VaginalHealth';

import styles from './Microbiome.module.scss';
import { highRelativeAbundanceLimit } from 'assets/constants';

export const MicrobiomeRoutes = () => {
  return (
    <Routes>
      <Route path={``} key='microbiome' element={<Microbiome />} />
    </Routes>
  );
};
export const Microbiome = () => {
  const { currentKitId, currentKitType } = useCurrentKitAndTinyAccount();
  if (!currentKitId) {
    return (
      <PageContainer size={ContainerSizes.MD}>
        <p>
          You have not taken any gut samples yet or your results are not yet
          ready.
        </p>
        <OrderKitButton>Order a Tiny&nbsp;Gut&nbsp;Kit</OrderKitButton>
      </PageContainer>
    );
  }

  return (
    <PageContainer size={ContainerSizes.MD}>
      <Suspense fallback={<Spinfinity />}>
        <section id='breakdown'>
          <h2 data-test='microbiome-breakdown-title'>
            {`${
              currentKitType === 'vaginal' ? 'Vaginal' : 'Gut'
            } microbiome breakdown`}
          </h2>
          <div className={c(styles.secondaryText, 'mb-4')}>
            This shows what was detected in the sample, but is not meant to
            provide an evaluation if your levels are optimal. For evaluations
            based on your age range, see the Key Insights section.
          </div>

          <MicrobiomeBarList />

          <div>
            <h3 className='mt-3'>Microbe Species</h3>

            {currentKitType === 'vaginal' ? (
              <VaginalHealthBacteria />
            ) : (
              <InsightsBacteria />
            )}
            <div className='mt-2'>
              <Typography variant='label' serif>
                Our high confidence detection limit is{' '}
                {highRelativeAbundanceLimit * 100}% abundance. Very low
                abundance organisms can be important members of the microbiome
                but also very difficult to accurately detect. Detections below{' '}
                {highRelativeAbundanceLimit * 100}% abundance have an increased
                risk of being false positives. However, in our testing, we have
                observed that most false positive identifications are closely
                related to a detected species.
              </Typography>
            </div>
          </div>
        </section>
      </Suspense>
    </PageContainer>
  );
};
