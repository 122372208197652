import { Suspense } from 'react';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

import { Spinfinity } from 'components';
import { MicrobiomeBar } from 'views/Results/Microbiome/MicrobiomeBar';
import { DiveDeeperSummaryCard } from '../DiveDeeperSummaryCard/DiveDeeperSummaryCard';

export const MicrobiomeSummaryCard = () => {
  const { currentKit, currentKitId } = useCurrentKitAndTinyAccount();
  const isVaginal = currentKit?.sampling_type === 'vaginal';

  if (!currentKit) return null;
  return (
    <DiveDeeperSummaryCard
      title='Microbiome breakdown'
      description={`See all the relative abundance of all microbes in your ${
        isVaginal ? 'vagina' : 'gut'
      }.`}
      link={`/results/${currentKitId}/microbiome`}
      linkText='Learn more'
    >
      <Suspense fallback={<Spinfinity />}>
        <MicrobiomeBar kit={currentKit} displayMode='card' />
      </Suspense>
    </DiveDeeperSummaryCard>
  );
};
