import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from 'store/user';

const ProtectedRoute = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const location = useLocation();
  if (isAuthenticated) return <Outlet />;
  return (
    <Navigate
      to='/login'
      replace
      state={{ from: location.pathname, ...location.state }}
    />
  );
};
export default ProtectedRoute;
