import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';

function getSeedingData(kitId: string) {
  return API.get(apiName, `${apiBasePath}/reports/seeding/${kitId}`, {});
}

const useSeeding = ({ kitId }: { kitId?: string | null }) => {
  const seedingQuery = useQuery({
    queryKey: [apiName, 'seeding', kitId],
    queryFn: () => kitId && getSeedingData(kitId),
    enabled: !!kitId,
  });
  return seedingQuery;
};

export default useSeeding;
