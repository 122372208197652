import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { getMainAccountId } from 'store/account';
import PractitionerUser from 'types/PractitionerUser';

export async function getActivePractitioners() {
  const response = await API.get(apiName, `${apiBasePath}/practitioners`, {});
  return response?.result;
}

const useActivePractitioners = () => {
  const mainAccountId = useSelector(getMainAccountId);
  const practitionersDataQuery = useQuery<PractitionerUser[]>({
    queryKey: [apiName, `activePractitioners`, mainAccountId],
    queryFn: () => getActivePractitioners(),
    ...{},
  });

  return {
    ...practitionersDataQuery,
    data: useMemo(
      () =>
        practitionersDataQuery.data?.map(practitioner => {
          const full_name =
            practitioner?.user?.first_name +
            ' ' +
            practitioner?.user?.last_name;
          return { ...practitioner, full_name: full_name.trim() };
        }),
      [practitionersDataQuery.data],
    ),
  };
};

export default useActivePractitioners;
