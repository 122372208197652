import { useSuspenseQueries } from '@tanstack/react-query';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getEvaluationsQuery } from 'services/insights/useSubMetricEvaluations';
import { getConditionsQuery } from 'services/conditions/useConditions';
import { getPdfUrlQuery } from 'services/pdfReport/useRequestPDFReport';
import { getGutTypeQuery } from 'services/gutType/useGutType';
import { getTaxaBarChartDataQuery } from 'services/microbiome/useTaxaBarChartData';
import { getPersonalizedNoteQuery } from 'services/general/personalizedNote';

const useKitResults = () => {
  const { currentKitType, currentKitId, currentKit } =
    useCurrentKitAndTinyAccount();

  useSuspenseQueries({
    queries: [
      getEvaluationsQuery(currentKitId),
      getConditionsQuery(currentKitId),
      getPdfUrlQuery(currentKitId, currentKit?.pdf_url),
      getGutTypeQuery(currentKitId),
      getTaxaBarChartDataQuery(currentKitId, currentKitType),
      getPersonalizedNoteQuery(currentKitId),
    ],
  });
};

export default useKitResults;
