import { API } from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiBasePath, apiName } from 'assets';

type SubscriptionCancellationResponse = {
  result: object;
};

const cancelSubscription = async ({
  subscriptionId,
  cancellation_reason,
  cancellation_reason_comments,
}: {
  subscriptionId: string;
  cancellation_reason: string;
  cancellation_reason_comments: string;
}) => {
  const response: SubscriptionCancellationResponse = await API.put(
    apiName,
    `${apiBasePath}/subscriptions/${subscriptionId}/cancel`,
    {
      cancellation_reason,
      cancellation_reason_comments,
    },
  );
  return response?.result;
};

const useSubscriptionCancellation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes('subscription') ||
          query.queryKey.includes('subscriptionsList'),
      });
    },
  });
  return mutation;
};
export default useSubscriptionCancellation;
