import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Auth } from 'api';

import {
  getImpersonateId,
  getUsername,
  setIsAutoLoggedOut,
  signOut,
} from 'store/user';
import { RootState, useAppDispatch } from 'store/store';

import { toastConfig } from 'config';
import { AUTOLOGOUT_REASONS } from 'assets';
import { useLocation, useNavigate } from 'react-router-dom';
export const signOutUser = createAsyncThunk<
  unknown,
  SignOutOptions | undefined,
  { state: RootState }
>(
  'user/signOutUser',
  async (params: SignOutOptions | undefined, { dispatch, getState }) => {
    const state = getState();
    await Auth.signOut();

    const isAutoLoggedOut = state.user.isAutoLoggedOut;
    if (params?.autoLogoutReason && !isAutoLoggedOut) {
      let toastMessage =
        'Your session has expired. To keep your Tiny Health data safe and secure, we’ve signed you out. Please sign in again.';
      switch (params.autoLogoutReason) {
        case AUTOLOGOUT_REASONS.EXPIRED:
          toastMessage =
            'Your session has expired. To keep your Tiny Health data safe and secure, we’ve signed you out. Please sign in again.';
          break;
        default:
      }
      toast(toastMessage, toastConfig);
      await dispatch(setIsAutoLoggedOut());
    }
    // prevent redundant sign out dispatches in the case of multiple 401s
    if (state.user.isAuthenticated) {
      await dispatch(signOut());
    }
  },
);
type SignOutOptions = {
  autoLogoutReason?: string;
};

/*
  Returns a callback that removes user specific queries from the cache and signs the user out
  This avoids redux getting out of sync with react-query when switching users before queries have become stale.
  Queries must be removed, not invalidated, so that the subscribers don't immediately re-render/refetch
*/
const useSignOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const username = useSelector(getUsername);
  const impersonateId = useSelector(getImpersonateId);

  const signOut = useCallback(
    async (options?: SignOutOptions) => {
      await queryClient.removeQueries({
        predicate: query => true,
      });
      await dispatch(signOutUser(options));
      navigate('/', { state: { ...location?.state } });
    },
    [dispatch, impersonateId, navigate, queryClient, username],
  );

  return signOut;
};

export default useSignOut;
