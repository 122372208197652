import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets/constants';

export async function getAllKitInsights(tinyAccountId: string) {
  return await API.get(
    apiName,
    `${apiBasePath}/results/allKitsInsight/${tinyAccountId}`,
    {},
  );
}

const useAllKitsInsight = (tinyAccountId?: string | null) => {
  const actionPlanItemQuery = useSuspenseQuery({
    queryKey: [apiName, 'allKitsInsight', tinyAccountId],
    queryFn: () => tinyAccountId && getAllKitInsights(tinyAccountId),
  });
  return actionPlanItemQuery;
};

export default useAllKitsInsight;
