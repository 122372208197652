import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import c from 'classnames';

import { EMAIL_VALIDATION_PATTERN, LINKS, PLACEHOLDER } from 'assets';
import { states } from 'constants/states';
import { copyToClipboard } from 'helpers';
import useImpersonate from 'services/auth/useImpersonate';
import usePractitionerKitsForPatient from 'services/practitioner/usePractitionerKitsForPatient';
import useGetKitOrderForPatient from 'services/practitioner/useGetKitOrderForPatient';
import { getPractitionerCode } from 'store/user';

import {
  ButtonColors,
  ButtonSizes,
  Card,
  ContainerSizes,
  DesignButton,
  LinkButton,
  Modal,
  PageContainer,
  TextLink,
  THField,
} from 'components';
import { Icon, Typography } from '@repo/ui';
import Select from 'components/Form/Select/Select';
import PreviousRequestsTable from './PreviousRequestsTable/PreviousRequestsTable';

import styles from './OrderKits.module.scss';

export const ProValidationFailModal = ({ show }: { show?: boolean }) => {
  if (!show) return null;
  return (
    <Modal controlledIsOpen={show} showHeader={false}>
      <div className={styles.modalContent}>
        <div className='flex align-items-center gap-2 red'>
          <Icon name='alertTriangle' size='m' />
          <Typography variant='heading-l'>
            Missing / Ineligible License or NPI
          </Typography>
        </div>
        <Typography variant='body-m'>
          PRO Gut Health Tests can be purchased directly from Tiny Health by
          those with eligible licenses and NPIs. See{' '}
          <TextLink
            label='eligibility and FAQs'
            to={LINKS.orderTestPractitionerEligibility}
            external
            size='body-m'
          />
          . Others may purchase via Rupa Health using their Physician Services.
        </Typography>
        <Typography variant='body-m'>
          If you have questions, reach out to{' '}
          <TextLink
            label='practitioners@tinyhealth.com'
            to='mailto:practitioners@tinyhealth.com'
            external
            size='body-m'
          />
        </Typography>
        <LinkButton
          label='Upload License Information'
          href='/settings'
          external
          color={ButtonColors.PURPLE}
          size={ButtonSizes.M}
        />
      </div>
    </Modal>
  );
};

type SampleDetailsFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  state: string;
  product: number;
};

const copyCode = (code: string) => {
  if (!code) return;
  copyToClipboard(code);
  toast.success(`Code ${code} copied to clipboard`);
};

type ExpandedProps = {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};
const PatientPay = ({ expanded, setExpanded }: ExpandedProps) => {
  if (expanded)
    return <PatientPayForm expanded={expanded} setExpanded={setExpanded} />;
  return (
    <div className={styles.cardContent}>
      <Typography variant='heading-xl'>Patient Pay</Typography>
      <Typography variant='body-s' className={styles.coldGrey}>
        Enter patient info and Tiny Health will send them a link to complete the
        payment. Once paid, they will be mailed a kit and sampling instructions.{' '}
        <TextLink
          label='See FAQs'
          to={LINKS.orderTestPatientPayHelp}
          external
          size='body-s'
        />
      </Typography>

      <div>
        <DesignButton
          label='Enter patient info'
          color={ButtonColors.PURPLE}
          size={ButtonSizes.M}
          onClick={async () => setExpanded(true)}
        />
      </div>
    </div>
  );
};
const PatientPayForm = ({ expanded, setExpanded }: ExpandedProps) => {
  const [showValidationErrorModal, setShowValidationErrorModal] = useState<
    boolean | undefined
  >(undefined);
  const { data: practitionerKits } = usePractitionerKitsForPatient();
  const getKitOrder = useGetKitOrderForPatient(setShowValidationErrorModal);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SampleDetailsFormValues>({
    mode: 'onTouched',
  });
  const firstNameFormField = register('firstName', {
    required: { value: true, message: 'This is required' },
  });
  const lastNameFormField = register('lastName', {
    required: { value: true, message: 'This is required' },
  });
  const emailFormField = register('email', {
    required: { value: true, message: 'This is required' },
    pattern: EMAIL_VALIDATION_PATTERN,
  });

  const kitOptions = useMemo(() => {
    const temp: { value: string | number; label: string }[] = [];
    practitionerKits?.map(kit =>
      temp.push({
        label: kit.display_title,
        value: kit.product_id,
      }),
    );
    return temp;
  }, [practitionerKits]);

  const submitKitDetails = async (formData: any) => {
    const { email, firstName, lastName, state, product } = formData;
    const selectedKit = practitionerKits?.find(
      kit => kit.product_id === product,
    );
    if (!selectedKit) {
      toast.error('There was an error selecting the kit');
      return;
    }
    const order = await getKitOrder.mutateAsync({
      customer_email: email,
      customer_first_name: firstName,
      customer_last_name: lastName,
      customer_state: state,
      product_id: product,
      variant_id: selectedKit.variant_id,
    });
  };

  return (
    <>
      <ProValidationFailModal show={showValidationErrorModal} />

      <div className={styles.cardPayContent}>
        <motion.div
          className={styles.closeBtn}
          initial={{ opacity: 0 }}
          animate={{ opacity: expanded ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          style={{ display: expanded ? 'block' : 'none' }}
          onClick={() => setExpanded(false)}
        >
          <Icon name='closeSm' size='m' />
        </motion.div>
        <PageContainer>
          <Typography variant='heading-xl'>
            Patient Pay: Send your patient a Tiny Health link
          </Typography>
          <form
            data-testid='sample-form'
            onSubmit={handleSubmit(submitKitDetails)}
          >
            <div className={styles.formGrid}>
              <div className={styles.firstName}>
                <THField
                  type='text'
                  label='Patient first name'
                  placeholder={PLACEHOLDER.firstName}
                  errors={errors}
                  {...firstNameFormField}
                />
              </div>
              <div className={styles.lastName}>
                <THField
                  type='text'
                  label='Patient last name'
                  placeholder={PLACEHOLDER.lastName}
                  errors={errors}
                  {...lastNameFormField}
                />
              </div>
              <div className={styles.email}>
                <THField
                  type='text'
                  label='Patient email'
                  placeholder={PLACEHOLDER.email}
                  errors={errors}
                  {...emailFormField}
                />
              </div>
              <div className={styles.state}>
                <div className='dark-grey-text mb-1'>
                  <Typography variant='body-s'>
                    Patient state of residence
                  </Typography>
                </div>
                <Controller
                  name='state'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      options={states.map(state => ({
                        label: state,
                        value: state,
                      }))}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <div className={styles.errorWrapper}>
                  <ErrorMessage
                    errors={errors}
                    name='state'
                    render={({ message }) => (
                      <div
                        className={styles.error}
                        id={`error-state`}
                        key={message}
                        role='alert'
                      >
                        <Icon name='alertCircle' size='xs' /> {message}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className={styles.kitType}>
                <div className='dark-grey-text mb-1'>
                  <Typography variant='body-s'>Type of test kit</Typography>
                </div>
                <Controller
                  name='product'
                  control={control}
                  rules={{ required: 'This is required' }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      name={name}
                      options={kitOptions}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <div className={styles.errorWrapper}>
                  <ErrorMessage
                    errors={errors}
                    name='product'
                    render={({ message }) => (
                      <div
                        className={styles.error}
                        id={`error-product`}
                        key={message}
                        role='alert'
                      >
                        <Icon name='alertCircle' size='xs' /> {message}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div>
              <DesignButton
                label='Send to patient'
                type='submit'
                color={ButtonColors.PURPLE}
                size={ButtonSizes.M}
              />
            </div>
          </form>
        </PageContainer>
      </div>
    </>
  );
};

const PractitionerPay = ({
  expanded,
  animate = false,
}: {
  expanded: boolean;
  animate?: boolean;
}) => {
  const practitionerCode = useSelector(getPractitionerCode);
  const showContent = !animate || (animate && !expanded);
  return (
    <div className={styles.cardContent}>
      <Typography variant='heading-xl'>Practitioner Pay</Typography>
      {showContent && (
        <>
          <Typography variant='body-s' className={styles.coldGrey}>
            If you would like to pay for kits upfront you can purchase them
            directly. You can have them shipped to you or the patient.{' '}
            <TextLink
              label='See FAQs'
              to={LINKS.orderTestPractitionerPayHelp}
              external
              size='body-s'
            />
          </Typography>

          {!!practitionerCode && (
            <div
              onClick={() => copyCode(practitionerCode)}
              className='flex align-items-center gap-2 red clickable'
            >
              <Typography variant='body-s'>
                Use your code at checkout [{practitionerCode}]
              </Typography>
              <div className='purple-icon'>
                <Icon name='copy' size='s' />
              </div>
            </div>
          )}
          <LinkButton
            label='Buy a Kit'
            href={'/order-kits-store'}
            color={ButtonColors.PURPLE}
            size={ButtonSizes.M}
          />
        </>
      )}
    </div>
  );
};

const OrderKits = () => {
  const { impersonateId, stopImpersonating } = useImpersonate();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (impersonateId) {
      stopImpersonating();
    }
  }, []);

  return (
    <div className={styles.mainContent}>
      <Typography variant='heading-xl' className={styles.pageTitle}>
        Two Ways to Order Kits
      </Typography>
      <PageContainer size={ContainerSizes.XL}>
        <div className={c(styles.orderSection, styles.hideOnMobileSm)}>
          <motion.div
            initial={{ opacity: 1, width: '50%' }}
            animate={{
              opacity: expanded ? 0 : 1,
              width: expanded ? '0%' : '50%',
            }}
            transition={{ duration: 0.5 }}
            style={{
              visibility: expanded ? 'hidden' : 'visible',
            }}
          >
            <Card variant='wrapper' className='h-100'>
              <PractitionerPay expanded={expanded} animate />
            </Card>
          </motion.div>

          <motion.div
            className={styles.orText}
            initial={{ opacity: 1 }}
            animate={{ opacity: expanded ? 0 : 1 }}
            transition={{ duration: 0.5 }}
            style={{
              visibility: expanded ? 'hidden' : 'visible',
              display: expanded ? 'none' : 'flex',
            }}
          >
            <Typography variant='heading-xl'>OR</Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 1, width: '50%' }}
            animate={{ width: expanded ? '100%' : '50%' }}
            transition={{ duration: 0.5 }}
          >
            <Card variant='wrapper' className='h-100'>
              <PatientPay expanded={expanded} setExpanded={setExpanded} />
            </Card>
          </motion.div>
        </div>
        <div className={c(styles.orderSection, styles.showOnMobileSm)}>
          <Card variant='wrapper' className='h-100'>
            <PractitionerPay expanded={expanded} />
          </Card>
          <div className={styles.orText}>
            <Typography variant='heading-xl'>OR</Typography>
          </div>
          <Card variant='wrapper' className='h-100'>
            <PatientPay expanded={expanded} setExpanded={setExpanded} />
          </Card>
        </div>
      </PageContainer>
      <Typography variant='heading-xl' className={styles.pageTitle}>
        Patient pay requests
      </Typography>
      <PreviousRequestsTable />
    </div>
  );
};

export default OrderKits;
