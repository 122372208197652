import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import Kit from 'types/Kit';

export const usePage = (analyticsBrowser: AnalyticsBrowser | null) => {
  const location = useLocation();
  const { currentKit, currentKitId } = useCurrentKitAndTinyAccount();
  const pageEvent = useCallback(() => {
    if (!analyticsBrowser) return;
    if (location.pathname) {
      const enrichedProperties: Partial<Kit> & {
        name: string;
        path: string;
        kit_id?: string;
        page?: string;
      } = {
        name: location.pathname,
        path: location.pathname,
      };
      if (currentKitId && currentKit) {
        enrichedProperties.kit_id = currentKitId;
        enrichedProperties.page = location.pathname.replace(
          `/${currentKitId}`,
          '',
        );
        enrichedProperties.date_results_ready = currentKit.date_results_ready;
        enrichedProperties.sampling_type = currentKit.sampling_type;
        enrichedProperties.age_at_sampling = currentKit.age_at_sampling;
        enrichedProperties.is_resample = currentKit.is_resample;
      }
      analyticsBrowser.page(enrichedProperties);
    }
  }, [analyticsBrowser, currentKit, currentKitId, location.pathname]);
  return pageEvent;
};
