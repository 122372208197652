import c from 'classnames';
import { Icon } from '@repo/ui';
import { Typography } from '@repo/ui';
import styles from './BackButton.module.scss';

export type BackButtonVariants = 'button' | 'link';
export const BackButton = ({
  variant = 'button',
  onClick,
}: {
  variant?: BackButtonVariants;
  onClick?: () => void;
}) => {
  return (
    <div className={c(styles.backButton, styles[variant])} onClick={onClick}>
      <Icon name='chevronBack' size='s' className='my-auto' />
      {variant === 'link' && (
        <Typography variant='body-s' className='my-auto'>
          Back
        </Typography>
      )}
    </div>
  );
};
