import { useLocation } from 'react-router-dom';
import useConditions from 'services/conditions/useConditions';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { Condition, ConditionEvaluationMeaning } from 'types/Conditions';

import EvaluationSummaryCard, {
  EvaluationPill,
} from 'views/Results/Summary/SummaryCards/EvaluationSummaryCard/EvaluationSummaryCard';
import { DiveDeeperSummaryCard } from 'views/Results/Summary/SummaryCards/DiveDeeperSummaryCard/DiveDeeperSummaryCard';

export const ConditionsSummaryCard = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();

  const { pathname } = useLocation();
  const { data: conditions } = useConditions({
    kitId: currentKitId,
  });
  const conditionsInSample = conditions?.filter((condition: Condition) => {
    return condition.kit_id !== null;
  });
  const conditionsClearCount = conditionsInSample?.filter(
    (condition: Condition) =>
      condition.evaluation_meaning === ConditionEvaluationMeaning.CLEAR,
  ).length;
  const conditionsNeutralCount = conditionsInSample?.filter(
    (condition: Condition) =>
      condition.evaluation_meaning === ConditionEvaluationMeaning.NEUTRAL,
  ).length;
  const conditionsBorderlineCount = conditionsInSample?.filter(
    (condition: Condition) =>
      condition.evaluation_meaning === ConditionEvaluationMeaning.BORDERLINE,
  ).length;
  const conditionsFlagCount = conditionsInSample?.filter(
    (condition: Condition) =>
      condition.evaluation_meaning === ConditionEvaluationMeaning.FLAG,
  ).length;

  return (
    <DiveDeeperSummaryCard
      title='Emerging research: Conditions'
      description='See how your microbiome signature associates with risk of certain conditions later in life.'
      link={`${pathname}/conditions`}
      linkText='Learn more'
    >
      <div className='flex my-auto'>
        <EvaluationPill
          good={conditionsClearCount}
          okay={conditionsNeutralCount}
          improve={conditionsBorderlineCount}
          support={conditionsFlagCount}
        />
      </div>
    </DiveDeeperSummaryCard>
  );
};
