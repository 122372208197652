import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { Feedback, FeedbackPageType } from 'types/Feedback';

const upsertFeedback = async (
  kitId: string,
  pageType: FeedbackPageType,
  pageId: number,
  answer: boolean,
  feedback?: string,
  rating?: number,
) => {
  if (!kitId) throw new Error('No Kit Id provided');
  if (!pageType) throw new Error('No Page-Type provided');
  return API.put(apiName, `${apiBasePath}/feedback/${kitId}`, {
    body: {
      kit_id: kitId,
      page_type: pageType,
      page_id: pageId,
      answer,
      feedback,
      rating,
    },
  });
};

const useUpdateFeedback = () => {
  const queryClient = useQueryClient();
  const feedbackMutation = useMutation({
    mutationFn: (feedback: Feedback) => {
      return upsertFeedback(
        feedback.kit_id,
        feedback.page_type,
        feedback.page_id,
        feedback.answer,
        feedback.feedback,
        feedback.rating,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('feedback'),
      });
    },
  });
  return feedbackMutation;
};

export default useUpdateFeedback;
