export const getMomGutSamples = () => {
  return {
    labels: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    data: [
      {
        label: 'bifidobacterium',
        data: [
          2.352, 2.951, 0.014, 0.043, 1.382, 0.142, 0.023, 0.369, 0.155, 0.592,
          0.838, 0.084, 0.073, 1.123, 0.007, 0.117, 0.24, 0.056, 2.162, 0.619,
          4.604, 0.409, 2.79, 0.46, 0.707, 0.402, 0.191, 5.184, 1.94, 3.58,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--xgood'), // bifido color
      },
      {
        label: 'beneficial',
        data: [
          21.177, 23.978, 28.364, 28.865, 30.348, 33.597, 37.889, 38.421,
          40.887, 40.553, 40.876, 42.477, 42.943, 41.933, 43.51, 49.049, 49.611,
          54.713, 57.237, 59.827, 55.965, 60.341, 59.777, 64.176, 64.309,
          66.465, 67.932, 64.591, 70.112, 68.724,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--good'), // beneficial color
      },
      {
        label: 'unfriendly',
        data: [
          0.005, 4.671, 0.141, 0.133, 0.041, 35.203, 0.043, 1.336, 0, 0.129,
          1.675, 6.082, 0.105, 0.106, 3.568, 0.359, 0, 0.11, 0.129, 0.083,
          0.371, 0.092, 0.069, 1.671, 1.634, 0.529, 0.024, 1.619, 0.127, 1.135,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--bad'), // unfriendly color
      },
      {
        label: 'variable',
        data: [
          72.85, 59.285, 42.907, 55.681, 63.631, 12.237, 52.689, 24.515, 38.212,
          33.775, 48.5, 25.595, 29.884, 27.892, 41.383, 41.266, 31.844, 22.236,
          11.604, 27.093, 18.514, 23.501, 19.233, 17.909, 19.668, 15.524,
          17.839, 9.747, 13.971, 11.369,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--ok'), // variable color
      },
      {
        label: 'unknown',
        data: [
          3.616, 9.115, 28.574, 15.278, 4.598, 18.821, 9.356, 35.359, 20.746,
          24.951, 8.111, 25.762, 26.995, 28.946, 11.532, 9.209, 18.305, 22.885,
          28.868, 12.378, 20.546, 15.657, 18.131, 15.784, 13.682, 17.08, 14.014,
          18.859, 13.85, 15.192,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--unknown'), // unknown color
      },
    ],
  };
};

export const getNewbornSamples = () => {
  return {
    labels: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    data: [
      {
        label: 'bifidobacterium',
        data: [
          0, 0, 0.012, 0, 0, 0.067, 0, 0, 0, 0, 0, 0.017, 6.197, 8.106, 8.8, 0,
          18.974, 8.532, 29.437, 36.345, 28.567, 38.335, 0.009, 0.013, 55.483,
          63.244, 78.171, 89.199, 96.397, 99.223,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--xgood'), // bifido color
      },
      {
        label: 'beneficial',
        data: [
          0, 0, 0, 0.042, 0.059, 0.031, 0.103, 0.484, 1.911, 2.2, 3.702, 3.885,
          0.206, 0.412, 0, 17.027, 6.565, 19.785, 3.524, 0.012, 11.15, 5.218,
          54.251, 59.353, 7.321, 0.577, 11.715, 4.94, 0.25, 0,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--good'), // beneficial color
      },
      {
        label: 'unfriendly',
        data: [
          97.669, 89.73, 86.133, 95.066, 91.115, 46.67, 81.501, 9.867, 78.385,
          48.72, 70.172, 56.351, 22.972, 66.599, 87.306, 37.503, 7.296, 0.2,
          44.073, 58.563, 31.473, 10.375, 21.081, 17.55, 18.026, 26.974, 4.36,
          2.107, 1.457, 0.162,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--bad'), // unfriendly color
      },
      {
        label: 'variable',
        data: [
          1.759, 9.209, 12.919, 0.748, 4.158, 52.353, 0.97, 71.285, 17.481,
          47.82, 25.373, 38.668, 35.943, 19.957, 2.696, 44.095, 63.579, 66.996,
          21.365, 3.219, 27.855, 43.865, 21.485, 20.606, 18.259, 6.348, 4.489,
          1.704, 1.618, 0.336,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--ok'), // variable color
      },
      {
        label: 'unknown',
        data: [
          0.572, 1.061, 0.936, 4.144, 4.668, 0.879, 17.426, 18.364, 2.223, 1.26,
          0.753, 1.079, 34.682, 4.926, 1.198, 1.375, 3.586, 4.487, 1.601, 1.861,
          0.955, 2.207, 3.174, 2.478, 0.911, 2.857, 1.265, 2.05, 0.278, 0.279,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--unknown'), // unknown color
      },
    ],
  };
};

export const getFourthTrimesterSamples = () => {
  return {
    labels: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    data: [
      {
        label: 'bifidobacterium',
        data: [
          0, 0, 0.034, 0, 0.017, 42.581, 0.01, 34.737, 31.025, 23.902, 10.636,
          49.938, 10.873, 41.45, 58.991, 62.564, 35.957, 62.477, 61.656, 75.286,
          80.513, 77.14, 79.685, 80.286, 79.825, 71.917, 80.897, 92.39, 97.241,
          98.166,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--xgood'), // bifido color
      },
      {
        label: 'beneficial',
        data: [
          0.084, 0.298, 4.451, 15.049, 40.684, 1.673, 46.182, 11.485, 18.475,
          28.584, 47.546, 8.435, 48.998, 18.429, 1.546, 2.654, 31.598, 5.188,
          9.937, 0.086, 0.267, 3.82, 2.686, 3.68, 5.31, 13.231, 5.99, 0.475,
          0.031, 0.708,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--good'), // beneficial color
      },
      {
        label: 'unfriendly',
        data: [
          93.835, 67.744, 31.134, 81.542, 19.802, 39.75, 20.324, 11.659, 3.395,
          5.888, 7.633, 28.039, 3.712, 22.894, 22.781, 8.857, 12.323, 10.107,
          10.193, 17.67, 9.478, 1.218, 10.118, 3.242, 7.424, 5.787, 0.927,
          2.855, 1.907, 0.566,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--bad'), // unfriendly color
      },
      {
        label: 'variable',
        data: [
          4.822, 26.68, 57.796, 0.359, 36.364, 8.736, 23.307, 41.165, 43.567,
          33.708, 30.916, 10.98, 33.433, 15.7, 15.762, 8.551, 17.549, 20.617,
          10.165, 6.485, 7.783, 17.363, 6.135, 10.68, 3.781, 6.772, 10.644,
          1.11, 0.514, 0.428,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--ok'), // variable color
      },
      {
        label: 'unknown',
        data: [
          1.259, 5.278, 6.585, 3.05, 3.133, 7.26, 10.177, 0.954, 3.538, 7.918,
          3.269, 2.608, 2.984, 1.527, 0.92, 17.374, 2.573, 1.611, 8.049, 0.473,
          1.959, 0.459, 1.376, 2.112, 3.66, 2.293, 1.542, 3.17, 0.307, 0.132,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--unknown'), // unknown color
      },
    ],
  };
};

export const getIntroSolidsSamples = () => {
  return {
    labels: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    data: [
      {
        label: 'bifidobacterium',
        data: [
          18.962, 12, 9.068, 10.332, 34.792, 14.418, 4.012, 22.114, 40.535,
          31.447, 28.706, 22.439, 25.7, 0.492, 37.042, 29.24, 44.186, 29.693,
          37.105, 57.807, 63.606, 70.049, 75.406, 82.345, 87.524, 86.432,
          64.739, 91.208, 96.984, 97.416,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--xgood'), // bifido color
      },
      {
        label: 'beneficial',
        data: [
          5.613, 13.234, 16.794, 23.131, 2.228, 25.843, 40.048, 27.103, 9.054,
          19.635, 28.844, 35.767, 33.723, 59.389, 26.505, 34.537, 22.345,
          36.954, 29.949, 10.102, 7.564, 1.612, 0.013, 2.89, 0.627, 2.069,
          25.584, 0.299, 0.007, 0.033,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--good'), // beneficial color
      },
      {
        label: 'unfriendly',
        data: [
          12.197, 36.791, 17.278, 16.914, 12.516, 5.881, 4.573, 5.981, 9.816,
          28.73, 2.248, 1.42, 13.627, 0.399, 4.528, 3.262, 2.163, 2.678, 1.569,
          5.445, 8.635, 17.087, 17.197, 3.674, 8.429, 3.439, 2.156, 1.356,
          2.212, 1.559,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--bad'), // unfriendly color
      },
      {
        label: 'variable',
        data: [
          57.887, 35.45, 55.753, 44.836, 48.207, 50.57, 27.473, 37.529, 20.347,
          5.872, 30.861, 28.285, 20.898, 30.956, 28.4, 26.864, 24.723, 20.435,
          26.834, 17.272, 14.785, 9.785, 5.618, 6.855, 2.062, 6.122, 3.375,
          6.561, 0.317, 0.81,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--ok'), // variable color
      },
      {
        label: 'unknown',
        data: [
          5.341, 2.525, 1.107, 4.787, 2.257, 3.288, 23.894, 7.273, 20.248,
          14.316, 9.341, 12.089, 6.052, 8.764, 3.525, 6.097, 6.583, 10.24,
          4.543, 9.374, 5.41, 1.467, 1.766, 4.236, 1.358, 1.938, 4.146, 0.576,
          0.48, 0.182,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--unknown'), // unknown color
      },
    ],
  };
};

export const getToddlerSamples = () => {
  return {
    labels: [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    data: [
      {
        label: 'bifidobacterium',
        data: [
          0, 0.703, 1.477, 2.103, 5.073, 4.231, 2.772, 14.257, 0, 1.194, 0.11,
          0.783, 0.047, 3.274, 0.681, 4.788, 0.208, 5.967, 0.977, 1.564, 3.465,
          0.004, 1.829, 1.944, 0.483, 2.287, 2.75, 0.381, 0.192, 4.362,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--xgood'), // bifido color
      },
      {
        label: 'beneficial',
        data: [
          19.563, 24.241, 27.397, 26.778, 27.971, 31.03, 32.962, 22.281, 37.563,
          36.995, 40.31, 41.495, 42.797, 39.904, 44.019, 40.102, 46.307, 48.069,
          53.568, 54.356, 52.482, 56.815, 55.659, 58.505, 61.776, 63.192,
          62.938, 73.883, 78.613, 78.212,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--good'), // beneficial color
      },
      {
        label: 'unfriendly',
        data: [
          4.059, 0.689, 0.806, 0.184, 18.641, 15.398, 12.726, 4.687, 0.105,
          0.094, 4.673, 0.648, 1.168, 1.21, 1.683, 2.342, 0.471, 0.55, 0.95,
          0.481, 2.227, 0.034, 0.456, 0.208, 0.134, 0.736, 0.638, 0.228, 0.313,
          1.207,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--bad'), // unfriendly color
      },
      {
        label: 'variable',
        data: [
          25.642, 58.283, 52.847, 57.951, 28.38, 29.058, 24.565, 26.891, 58.871,
          54.077, 48.831, 38.402, 40.597, 41.565, 31.076, 39.439, 39.171,
          20.585, 32.219, 30.408, 19.324, 34.582, 23.186, 27.004, 25.332,
          16.097, 19.336, 7.018, 9.896, 1.945,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--ok'), // variable color
      },
      {
        label: 'unknown',
        data: [
          50.736, 16.084, 17.473, 12.984, 19.935, 20.283, 26.975, 31.884, 3.461,
          7.64, 6.076, 18.672, 15.391, 14.047, 22.541, 13.329, 13.843, 24.829,
          12.286, 13.191, 22.502, 8.565, 18.87, 12.339, 12.275, 17.688, 14.338,
          18.49, 10.986, 14.274,
        ],
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--unknown'), // unknown color
      },
    ],
  };
};
