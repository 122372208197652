import React, { useCallback, useState } from 'react';

export const HeaderIllustrationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [headerIllustration, setIllustration] = useState<{
    url: string | null;
    bgColor: string | null;
  }>({ url: null, bgColor: null });
  const setHeaderIllustration = useCallback(
    ({ url, bgColor }: { url: string | null; bgColor: string | null }) => {
      setIllustration({ url, bgColor });
    },
    [],
  );
  return (
    <HeaderIllustrationContext.Provider
      value={{ ...headerIllustration, setHeaderIllustration }}
    >
      {children}
    </HeaderIllustrationContext.Provider>
  );
};
export type HeaderIllustrationContextType = {
  url: string | null;
  bgColor: string | null;
  setHeaderIllustration?: ({
    url,
    bgColor,
  }: {
    url: string | null;
    bgColor: string | null;
  }) => void;
};
export const HeaderIllustrationContext =
  React.createContext<HeaderIllustrationContextType>({
    url: null,
    bgColor: null,
  });

export const useHeaderIllustration = () =>
  React.useContext(HeaderIllustrationContext);

export default HeaderIllustrationProvider;
