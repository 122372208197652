import { useSelector } from 'react-redux';
import {
  useNavigate,
  useLocation,
  useParams,
  useMatch,
} from 'react-router-dom';
import c from 'classnames';

import { getKitById } from 'store/kits';

import { Typography } from '@repo/ui';
import { Icon } from '@repo/ui';
import { SelectedKit } from 'components/THSelect/KitSelect';
import SelectRow from 'components/THSelect/SelectRow';

import styles from './Header.module.scss';

export const Back = ({ goBack }: { goBack: () => void }) => {
  return (
    <div className={c(styles.back, 'flex')} onClick={goBack}>
      <Icon name='chevronBack' className='mx-auto' />
    </div>
  );
};

export const Header = ({ title }: { title: string }) => {
  const { kitId: currentKitId } = useParams<{ kitId: string }>();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const isConsultsInnerPage = pathname.startsWith('/consults/');
  const isTopLevelKitRoute =
    !!useMatch<'*', string>('/:page/:kitId') && !isConsultsInnerPage;
  const previousUrl = state?.from
    ? state.from
    : pathname.split('/').slice(0, -1).join('/');
  const goBack = () => {
    navigate(previousUrl, { state: { from: null } });
  };
  const currentKit = useSelector(getKitById(currentKitId));
  if (!currentKitId && !isConsultsInnerPage) {
    return null;
  }

  return (
    <div className={styles.header}>
      <div className={c(styles.titleRow)}>
        <div className='flex'>
          {!isTopLevelKitRoute && <Back goBack={goBack} />}
          <div className={styles.title}>
            <Typography
              variant={isTopLevelKitRoute ? 'heading-xl' : 'heading-m'}
              className='my-auto'
              responsive
            >
              {title}
            </Typography>
          </div>
        </div>
        {isTopLevelKitRoute && <SelectRow />}
      </div>

      {isTopLevelKitRoute && <>{!!currentKit && <SelectedKit />}</>}
    </div>
  );
};
export default Header;
