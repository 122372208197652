import { useState } from 'react';
import c from 'classnames';

import { capitalize } from 'helpers';
import type Taxon from 'types/Taxon';

import { Icon } from '@repo/ui';
import styles from './TaxonRow.module.scss';

export const TaxonRow = ({ taxon, row }: { taxon: Taxon; row: number }) => {
  const [toggleOpen, setToggleOpen] = useState(false);

  return (
    <>
      <div
        id={taxon.id}
        className={c(
          styles.taxonRow,
          styles[taxon.category],
          row !== 0 && styles.borderTop,
        )}
        onClick={() => setToggleOpen(!toggleOpen)}
      >
        <div className={styles.taxonData}>
          <div className={styles.taxonName}>{taxon.taxonomy_name}</div>
          <div
            className={c(
              'flex',
              styles.grow,
              styles.taxonEvaluation,
              styles[taxon.taxa_evaluation_meaning] ?? styles.unknown,
            )}
          >
            <Icon
              name={
                taxon.taxa_evaluation_meaning === 'flag'
                  ? 'alertCircleFilled'
                  : 'circleFilled'
              }
              size='xs'
              className={styles.evalCircle}
            />
            {capitalize(taxon.taxa_evaluation)}
          </div>
        </div>

        <div className={styles.relAbun}>
          {(taxon.user_abundance * 100).toFixed(3)}%
        </div>
      </div>
    </>
  );
};
