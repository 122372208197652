import { useNavigate } from 'react-router-dom';
import c from 'classnames';

import { TextLink } from 'components';
import { Icon, Typography, TextColor } from '@repo/ui';
import { getEvaluationIconFromColor } from 'views/Results/Insights/EvaluationGroupsSummary/EvaluationGroupsSummary';

import styles from './EvaluationSummaryCard.module.scss';

type EvaluationPillProps = {
  good: number;
  okay: number;
  improve: number;
  support: number;
};

export const EvaluationPill = ({
  good,
  okay,
  improve,
  support,
}: EvaluationPillProps) => {
  const total = good + okay + support + (improve ? improve : 0);
  if (total === 0) {
    return null;
  }
  return (
    <div className={styles.evaluationPill}>
      {good > 0 && (
        <div className='flex gap-1'>
          <Icon
            name={getEvaluationIconFromColor('green')}
            size='m'
            className={styles.green}
          />
          <Typography variant='body-s' className='my-auto'>
            {good}
          </Typography>
        </div>
      )}
      {okay > 0 && (
        <div className='flex gap-1'>
          <Icon
            name={getEvaluationIconFromColor('grey')}
            size='m'
            className={styles.grey}
          />
          <Typography variant='body-s' className='my-auto'>
            {okay}
          </Typography>
        </div>
      )}
      {improve > 0 && (
        <div className='flex gap-1'>
          <Icon
            name={getEvaluationIconFromColor('orange')}
            size='m'
            className={styles.orange}
          />
          <Typography variant='body-s' className='my-auto'>
            {improve}
          </Typography>
        </div>
      )}
      {support > 0 && (
        <div className='flex gap-1'>
          <Icon
            name={getEvaluationIconFromColor('red')}
            size='m'
            className={styles.red}
          />
          <Typography variant='body-s' className='my-auto'>
            {support}
          </Typography>
        </div>
      )}
    </div>
  );
};

export const EvaluationSummaryCard = ({
  title,
  description,
  evaluationCounts,
  link,
  pillText,
}: {
  title: string;
  description?: string;
  evaluationCounts?: {
    great: number;
    okay: number;
    support: number;
    improve: number;
  };
  link: string;
  pillText?: string;
}) => {
  const navigate = useNavigate();
  const navigateToLink = () => {
    navigate(link);
  };
  return (
    <div className={c(styles.card)} onClick={navigateToLink}>
      <div className={styles.content}>
        <Typography variant='heading-m'>{title}</Typography>
        {description && <Typography variant='label'>{description}</Typography>}
        <div className={styles.evaluationRow}>
          {!!evaluationCounts && (
            <div className='flex'>
              <EvaluationPill
                good={evaluationCounts.great}
                okay={evaluationCounts.okay}
                improve={evaluationCounts.improve}
                support={evaluationCounts.support}
              />
            </div>
          )}
          {!!pillText && (
            <div className={styles.evaluationPill}>
              <Typography variant='body-s'>{pillText}</Typography>
            </div>
          )}
        </div>
      </div>
      <div className={styles.moreLink}>
        <TextLink
          label='More'
          iconNameRight='chevronForward'
          color={TextColor.PURPLE}
          contentOnly
        />
      </div>
    </div>
  );
};

export default EvaluationSummaryCard;
