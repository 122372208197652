import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';

export const getConditions = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/conditions/v2/${kitId}`,
    {},
  );
  return response.result;
};
export const getConditionsQuery = (kitId?: string | null) => ({
  queryKey: [apiName, 'getConditions', kitId],
  queryFn: () => kitId && getConditions(kitId),
});
const useConditions = ({ kitId }: { kitId?: string | null }) => {
  const conditionsQuery = useSuspenseQuery(getConditionsQuery(kitId));
  return conditionsQuery;
};

export default useConditions;
