import styles from './SectionPlaceholder.module.scss';

interface SPProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

export const SectionPlaceholder = ({
  title,
  description,
  children,
}: SPProps) => {
  return (
    <div className={styles.placeholderSection}>
      {title && <h6>{title}</h6>}
      {description && (
        <p className={styles.placeholderDescription}>{description}</p>
      )}
      {children && <div>{children}</div>}
    </div>
  );
};
