import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion, useDragControls, useMotionValue } from 'framer-motion';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { useSidebarItems } from 'components/Sidebar/useSidebarItems';
import { getHomePageTinyAccountId } from 'store/home';
import TinyAccount from 'types/TinyAccount';

import SidebarItem from 'components/Sidebar/SidebarItem';
import { Brand, TinyAccountSelector } from 'components';
import { Typography } from '@repo/ui';

import styles from './MobileMenu.module.scss';

const MobileMenu = ({ toggleMobileMenu }: { toggleMobileMenu: () => void }) => {
  const navigate = useNavigate();
  const dragControls = useDragControls();
  const x = useMotionValue(0);
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const homePageTinyAccountId = useSelector(getHomePageTinyAccountId);
  const sideBarItems = useSidebarItems({ kitId: currentKitId, isMobile: true }); // not currently necessary for this component to have kitID here
  const tinyAccountSelected = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        navigate(`/`, { state: { tinyAccountId: tinyAccount.id } });
      }
    },
    [navigate],
  );
  return (
    <>
      <motion.div
        className={styles.menuOverlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        transition={{
          ease: 'linear',
          duration: 0.125,
        }}
        onClick={toggleMobileMenu}
      />
      <motion.div
        onClick={e => e.stopPropagation()}
        className={styles.mobileMenu}
        initial={{ right: '-100%' }}
        animate={{ right: '0%' }}
        exit={{ right: '-100%' }}
        transition={{
          ease: 'easeInOut',
          duration: 0.25,
        }}
        style={{ x }}
        onDragEnd={() => {
          if (x.get() > 50) {
            toggleMobileMenu();
          }
        }}
        drag='x'
        dragListener={false}
        dragControls={dragControls}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={{ left: 0, right: 0.5 }}
      >
        <div
          className={styles.dragToClose}
          onPointerDown={e => {
            dragControls.start(e);
          }}
        />
        <div className={styles.container}>
          <div className={styles.topRow}>
            <div className={styles.logo}>
              <Brand />
            </div>
          </div>

          <div className={styles.middleMenuItems}>
            <div className='mt-4'>
              <Typography variant={'heading-xl'}>Your family</Typography>
              <TinyAccountSelector
                onSelection={tinyAccountSelected}
                currentTinyAccountId={
                  currentTinyAccountId ?? homePageTinyAccountId
                }
                withProfile
              />
            </div>
          </div>
          <div className={styles.bottomMenuItems}>
            {sideBarItems.map((item, index) => (
              <SidebarItem
                key={index}
                to={item.to}
                icon={item.icon}
                label={item.label}
                external={item.external}
                onClick={toggleMobileMenu}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default MobileMenu;
