import {
  Bacterium,
  ButtonColors,
  ButtonVariants,
  DesignButton,
  TaxaFilterType,
} from 'components';
import { useState } from 'react';
import { TaxaData } from 'services/microbiome/useTaxaData';
import { KitType } from 'types/Kit';

import styles from './Insights.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import { highRelativeAbundanceLimit } from 'assets/constants';
export const InsightsBacteriaList = ({
  data,
  filters,
  type = KitType.STOOL,
}: {
  data: TaxaData[];
  filters: TaxaFilterType[];
  type?: KitType;
  expand?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = async () => setIsExpanded(!isExpanded);

  const bacteria = data;
  const activeCategories = filters.filter(filter => filter.active);

  const bacteriaList =
    bacteria
      .filter(bacterium => {
        const filterMatch = activeCategories.find(
          filter => filter[type].title === bacterium.category,
        );
        if (filterMatch) {
          return true;
        } else if (
          activeCategories.find(filter => filter[type].title === 'Unknown') &&
          !bacterium.category
        ) {
          return true;
        }
        return false;
      })
      .sort(
        (a: TaxaData, b: TaxaData) =>
          (b?.classified_relative_abundance ?? 0) -
          (a?.classified_relative_abundance ?? 0),
      ) ?? [];

  const bacteriaListExpanded = bacteriaList.filter(
    bacterium =>
      bacterium.classified_relative_abundance &&
      bacterium.classified_relative_abundance <= highRelativeAbundanceLimit,
  );

  const bacteriaListInitial = bacteriaList.filter(
    bacterium =>
      bacterium.classified_relative_abundance &&
      bacterium.classified_relative_abundance > highRelativeAbundanceLimit,
  );

  return !bacteriaList.length ? (
    <p>No species</p>
  ) : (
    <>
      <AnimatePresence>
        {bacteriaListInitial.map((bacterium, i) => {
          return (
            <Bacterium
              key={bacterium.rtc_id || i}
              bacterium={{
                ...bacterium,
                associations_list:
                  bacterium.report_taxa_categories?.associations_list ?? [],
              }}
              type={type}
            />
          );
        })}{' '}
        <DesignButton
          label={
            isExpanded ? 'Hide low abundance metrics' : 'View all microbes'
          }
          iconNameRight={isExpanded ? 'chevronUp' : 'chevronDown'}
          width={'full'}
          color={ButtonColors.LIGHT}
          variant={ButtonVariants.SOLID}
          onClick={toggleExpand}
          className='mt-2'
        />
        {isExpanded && (
          <motion.div
            key='expanded'
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 1 }}
            className={styles.collapse}
          >
            <div className='overflow-hidden'>
              {bacteriaListExpanded.map((bacterium, i) => {
                return (
                  <Bacterium
                    key={bacterium.rtc_id || i}
                    bacterium={{
                      ...bacterium,
                      associations_list:
                        bacterium.report_taxa_categories?.associations_list ??
                        [],
                    }}
                    type={type}
                  />
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
