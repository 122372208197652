import { brandColors, palette } from 'assets';

export function getColors() {
  return {
    ...brandColors,
    ...palette,
  };
}

export function getFamilyColors() {
  const colors = getColors();
  return [
    colors.green[500],
    colors.red[500],
    colors.orange[500],
    colors.yellow[500],
    colors.purple[500],
    colors.blue[500],
    colors.indigo[500],
    colors.brown[500],
  ];
}
