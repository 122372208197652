import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import c from 'classnames';

import { LINKS } from 'assets';
import { states } from 'constants/states';
import useImpersonate from 'services/auth/useImpersonate';
import useProValidation from 'services/practitioner/useProValidation';
import usePractitionerKitsForPatient, {
  KIT_IMAGES,
} from 'services/practitioner/usePractitionerKitsForPatient';
import { PractitionerKit } from 'types/PractitionerUser';

import {
  ButtonColors,
  ButtonSizes,
  Card,
  ContainerSizes,
  LinkButton,
  PageContainer,
  Select,
  TextLink,
  Typography,
} from 'components';
import { ProValidationFailModal } from '../OrderKits/OrderKits';

import styles from './OrderKitsStore.module.scss';

const PractitionerKitCard = ({
  kit,
  setShowValidationErrorModal,
}: {
  kit: PractitionerKit;
  setShowValidationErrorModal: Dispatch<SetStateAction<boolean | undefined>>;
}) => {
  const [allowProOrdering, setAllowProOrdering] = useState(false);
  const [patientState, setPatientState] = useState<string | null>(null);
  const { mutate, isLoading } = useProValidation(
    setAllowProOrdering,
    setShowValidationErrorModal,
  );

  const isProKit = kit.lookup_code === 'TINY_PRO_GUT_HEALTH_TEST';

  const shopLink = useMemo(() => {
    if (isProKit) {
      return allowProOrdering && kit.store_link
        ? kit.store_link
        : (kit.info_link ?? '');
    }
    return kit.store_link ?? '';
  }, [kit, isProKit, allowProOrdering]);

  const onStateChange = (state: string | number) => {
    setAllowProOrdering(false);
    setPatientState(state as string);
    mutate({ patient_state: state as string });
  };

  return (
    <Card variant='wrapper' className='h-100'>
      <div className={styles.cardContent}>
        <div>
          <div className={styles.imageWrapper}>
            <img
              src={KIT_IMAGES[kit.lookup_code]}
              alt={`Test package`}
              loading='lazy'
            />
          </div>
          <div className='flex align-items-center justify-content-between gap-1'>
            <Typography variant='heading-l'>
              {kit.display_name || kit.name}
            </Typography>
            <Typography variant='heading-s'>{`$${kit.price_with_discounts ? kit.price_with_discounts : kit.price}`}</Typography>
          </div>
          <Typography variant='body-s' className={c(styles.coldGrey, 'mt-2')}>
            Insights on the commensal and pathogenic species in the microbiome
            with accurate relative abundances.
          </Typography>
        </div>

        {!!kit?.store_link && (
          <div className='flex flex-column gap-1 mt-3'>
            {isProKit && (
              <div>
                <div className='dark-grey-text mb-1'>
                  <Typography variant='body-s'>
                    Patient state of residence
                  </Typography>
                </div>
                <Select
                  name='state'
                  options={states.map(state => ({
                    label: state,
                    value: state,
                  }))}
                  value={patientState}
                  onChange={onStateChange}
                  isDisabled={isLoading}
                  maxMenuHeight={140}
                />
              </div>
            )}
            <div className={styles.buttonsContainer}>
              <LinkButton
                label='Learn more'
                href={kit.info_link ? kit.info_link : kit.store_link}
                color={ButtonColors.LIGHT}
                size={ButtonSizes.M}
                width='full'
                external
              />
              <LinkButton
                label='Shop Now'
                href={shopLink}
                color={ButtonColors.DARK_PURPLE}
                size={ButtonSizes.M}
                width='full'
                external
                disabled={isProKit && !allowProOrdering}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

const OrderKitsStore = () => {
  const [showValidationErrorModal, setShowValidationErrorModal] = useState<
    boolean | undefined
  >(undefined);
  const { impersonateId, stopImpersonating } = useImpersonate();
  const { data: practitionerKits } = usePractitionerKitsForPatient();

  useEffect(() => {
    if (impersonateId) {
      stopImpersonating();
    }
  }, []);

  return (
    <div className={styles.mainContent}>
      <ProValidationFailModal show={showValidationErrorModal} />
      <TextLink label='Go Back' to='/order-kits' iconNameLeft='chevronBack' />
      <Typography variant='heading-xl'>Practitioner Pay</Typography>
      <Typography variant='body-s' className={styles.coldGrey}>
        If you would like to pay for kits upfront, you can purchase them
        directly here. You can have them shipped to you or the patient.{' '}
        <TextLink
          label='See FAQs'
          to={LINKS.orderTestPractitionerPayHelp}
          external
          size='body-s'
        />
      </Typography>
      <PageContainer size={ContainerSizes.XL}>
        <div className={c(styles.kitsGrid)}>
          {practitionerKits?.map(kit => (
            <PractitionerKitCard
              kit={kit}
              key={kit.product_id}
              setShowValidationErrorModal={setShowValidationErrorModal}
            />
          ))}
        </div>
      </PageContainer>
    </div>
  );
};

export default OrderKitsStore;
