import c from 'classnames';

import { PopHover } from 'components';

import styles from './Seeding.module.scss';

export const SeedingMicrobiomeBar = ({ data }) => {
  const n = Math.floor(Math.random() * 1000);
  return (
    <div className='ml-1 me-1'>
      <div className={styles.seedingCompositionBar}>
        {
          <>
            {/* TODO: extract these slices as a component that accepts a `category` */}
            <div
              id={`beneficial_slice_${n}`}
              className={c(
                'popover-host',
                styles.seedingBarSection,
                styles.green,
              )}
              style={{
                height: `${data.beneficial}%`,
              }}
            >
              <PopHover
                target={`beneficial_slice_${n}`}
                category='beneficial'
                className='pop-hover beneficial-bg'
                placement='top'
              >
                {Math.round(data.beneficial)}% beneficial
              </PopHover>
            </div>
            <div
              id={`unfriendly_slice_${n}`}
              className={c(
                'popover-host',
                styles.seedingBarSection,
                styles.red,
              )}
              style={{
                height: `${data.unfriendly}%`,
              }}
            >
              <PopHover
                target={`unfriendly_slice_${n}`}
                category='unfriendly'
                className='pop-hover unfriendly-bg'
                placement='top'
              >
                {Math.round(data.unfriendly)}% unfriendly
              </PopHover>
            </div>
            <div
              id={`variable_slice_${n}`}
              className={c(
                'popover-host',
                styles.seedingBarSection,
                styles.orange,
              )}
              style={{
                height: `${data.variable}%`,
              }}
            >
              <PopHover
                target={`variable_slice_${n}`}
                category='variable'
                className='pop-hover variable-bg'
                placement='top'
              >
                {Math.round(data.variable)}% variable
              </PopHover>
            </div>
          </>
        }
      </div>
    </div>
  );
};
