/**
 * Convert string to url
 * @param str
 * @returns
 * @example
 * convertToUrl('Hello World') => 'hello-world'
 * convertToUrl('Hello World!') => 'hello-world'
 * convertToUrl('Hello World!@#$%^&*()') => 'hello-world'
 */
export const stringToUrl = (str: string) =>
  str
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/[^\w-]+/g, '');
