import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Widget } from '@typeform/embed-react';

import useSubmitSurvey from 'services/surveys/useSubmitSurvey';
import { parseDate } from 'helpers';
import { EventPropertiesMap } from 'contexts/analytics/types';
import { getTinyAccountById } from 'store/account';
import { getKitById, getSurveysStatusForKitId } from 'store/kits';
import Kit from 'types/Kit';
import { BackButton } from 'components/BackButton/BackButton';

import styles from './Typeform.module.scss';

const getSampleDate = (kit: Kit) => {
  if (kit.id === 'tinyAccountSurvey') return null;
  const date = format(parseDate(kit.sample_date), 'MMMM do yyyy');
  return date;
};

const Typeform = () => {
  const { tinyAccountId, kitId, typeformId, surveyType } =
    useParams<{
      tinyAccountId: string;
      kitId: string;
      typeformId: string;
      surveyType: string;
    }>();
  const navigate = useNavigate();
  const [surveyFinished, setSurveyFinished] = useState(false);
  const submitSurvey = useSubmitSurvey();
  const tinyAccount = useSelector(getTinyAccountById(tinyAccountId));
  const kit = useSelector(getKitById(kitId));
  const status = useSelector(getSurveysStatusForKitId(kitId));

  const isSurveyCompleted = useMemo(() => {
    if (!typeformId || !status?.completed?.length) return false;
    const foundInCompleted = status.completed.find(
      survey => survey.typeform_id === typeformId,
    );
    return !!foundInCompleted;
  }, [status, typeformId]);

  if (!typeformId || !tinyAccountId || !tinyAccount) {
    return null;
  }
  const onSubmitTypeform = async () => {
    const eventProperties: EventPropertiesMap['Survey Completed'] = {
      kit_id: kitId,
      form_id: typeformId,
      tinyaccount_id: tinyAccountId,
    };
    // redirect to survey complete page
    kitId ? navigate(`/results/${kitId}`) : navigate('/results');
    await submitSurvey(eventProperties);
    setSurveyFinished(true);
  };

  const currentSurveyType = status?.needed.find(
    survey => survey.typeform_id === typeformId,
  );

  const formProperties: {
    first_name: string;
    tinyaccount_id: string;
    kit_id?: string;
    age?: string;
    sample_date?: string;
  } = {
    first_name: tinyAccount.first_name,
    tinyaccount_id: tinyAccountId,
    kit_id: kitId,
  };
  if (kit) {
    formProperties['age'] = kit.age_at_sampling?.toString();
    formProperties['sample_date'] = getSampleDate(kit)?.toString();
  }

  return (
    <>
      {(surveyFinished || isSurveyCompleted) && (
        <Navigate to={kitId ? `/results/${kitId}` : '/results'} replace />
      )}
      <Link to={`/results/${kitId}`}>
        <BackButton variant='link' />
      </Link>

      {currentSurveyType?.display_title && (
        <h3 className={styles.typeformTitle}>
          {currentSurveyType?.display_title}
        </h3>
      )}

      <Widget
        id={typeformId}
        style={{ width: '100%', height: 650 }}
        hidden={formProperties}
        onSubmit={onSubmitTypeform}
      />
    </>
  );
};

export default Typeform;
