import { Icon, IconName } from '@repo/ui';

import styles from './ActionDisplayTag.module.scss';

const getTagIcon = (name: string): IconName => {
  switch (name) {
    case 'Probiotic':
      return 'microbiome';
    case 'Supplement':
      return 'pill';
    case 'Food':
      return 'naturalFood';
    case 'Recipe':
      return 'spoon01';
    case 'Product':
      return 'shoppingCart';
    case 'Tip':
      return 'lightOn';
    default:
      return 'lightOn';
    //'Resample' is ignored
  }
};

export const ActionDisplayTag = ({ name }: { name: string }) => (
  <span className={styles.displayTag}>
    <Icon name={getTagIcon(name)} size='s' />
    <span>{name}</span>
  </span>
);
