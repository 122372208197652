import { useSelector } from 'react-redux';
import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { getMainAccountId } from 'store/account';
import { ConsultAgent } from 'types/Consult';

export async function getConsultAgents() {
  const response = await API.get(apiName, `${apiBasePath}/consult-agents`, {});
  return response;
}

const useConsultAgents = () => {
  const mainAccountId = useSelector(getMainAccountId);

  const consultAgentsListQuery = useSuspenseQuery<ConsultAgent[]>({
    queryKey: [apiName, `consultAgentsList`, mainAccountId],
    queryFn: () => getConsultAgents(),
  });

  return consultAgentsListQuery;
};

export default useConsultAgents;
