import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { ProfileSurveys } from 'types/Surveys';
import { useSelector } from 'react-redux';
import { getKitById } from 'store/kits';

/**
 * getProfileSurveys
 * @param tinyAccountId
 * @param kitId
 * @param isResample
 * @returns an array of the old typeform surveys if the user did an "old kit survey" already
 * or the new "profile surveys" otherwise
 */
export async function getProfileSurveys(
  tinyAccountId: string,
  kitId: string,
  isResample: 0 | 1,
) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/tinyaccounts/${tinyAccountId}/profile-surveys?is_resample=${isResample}&kit_id=${kitId}`,
    {},
  );
  return response?.result;
}

const useProfileSurveys = (tinyAccountId?: string | null, kitId?: string) => {
  const kit = useSelector(getKitById(kitId));
  const isResample = kit?.is_resample ? 1 : 0;

  const profileSurveysQuery = useSuspenseQuery<ProfileSurveys[] | null>({
    queryKey: [apiName, `profileSurveys`, tinyAccountId, kitId],
    queryFn: () =>
      tinyAccountId && kitId
        ? getProfileSurveys(tinyAccountId, kitId, isResample)
        : [],
    // staleTime: 0,
  });

  return {
    ...profileSurveysQuery,
    isNewProfileFlow: profileSurveysQuery.data?.length
      ? !!profileSurveysQuery.data[0].is_active
      : true, //new profile flow by default
    needsSurveys: useMemo(
      () =>
        !profileSurveysQuery.data?.every(
          profileSurvey => profileSurvey.is_complete,
        ),
      [profileSurveysQuery.data],
    ),
  };
};

export default useProfileSurveys;
