import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';

export async function getAllItemActions(itemId: number) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/actionPlan/recommendedItem/${itemId}`,
    {},
  );
  return response?.result;
}

const useItemActions = (itemId?: number) => {
  const actionPlanItemQuery = useSuspenseQuery({
    queryKey: [apiName, 'itemActions', itemId],
    queryFn: () => !!itemId && getAllItemActions(itemId),
  });
  return actionPlanItemQuery;
};

export default useItemActions;
