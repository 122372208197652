import { Fragment, useState, useEffect } from 'react';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { ActionPlanItem } from 'types/ActionPlan';

import { SectionPlaceholder, Typography } from 'components';
import { ButtonColors, ButtonSizes, LinkButton } from 'components';
import { ActionPlanLinkItem } from './ActionPlanLinkItem';
import checklistIllustration from 'assets/images/placeholders/checklist.svg';

import styles from './ActionPlanGroup.module.scss';

interface APGProps {
  title?: string;
  withNavigation?: boolean;
  actionPlanItems: ActionPlanItem[];
  placeholderTitle?: string;
  placeholderDescription?: string;
}

export const ActionPlanGroup = ({
  title,
  actionPlanItems,
  placeholderTitle = '',
  placeholderDescription = '',
  withNavigation = false,
}: APGProps) => {
  const [showEmptyState, setShowEmptyState] = useState(false);
  const currentKitId = useCurrentKitAndTinyAccount().currentKitId;
  useEffect(() => {
    setShowEmptyState(
      !actionPlanItems?.length &&
        (!!placeholderTitle || !!placeholderDescription),
    );
  }, [actionPlanItems?.length, placeholderDescription, placeholderTitle]);

  if (!currentKitId) return null;
  //hide if no action items or empty state
  return !actionPlanItems?.length && !showEmptyState ? null : (
    <div className={styles.actionPlanGroup}>
      <div className={styles.groupTitle}>
        <Typography variant={'heading-m'}>
          {title ? title : 'Related Actions'}
        </Typography>
        <img src={checklistIllustration} alt='list with check marks' />
      </div>
      <div className={styles.groupContainer}>
        {showEmptyState ? (
          <SectionPlaceholder
            title={placeholderTitle}
            description={placeholderDescription}
          />
        ) : (
          actionPlanItems.map((actionPlanItem, index) => (
            <Fragment key={`${actionPlanItem.id}`}>
              <ActionPlanLinkItem {...actionPlanItem} kitId={currentKitId} />
            </Fragment>
          ))
        )}
      </div>
      {withNavigation && (
        <div className={styles.navigation}>
          <LinkButton
            href={`/action-plan/${currentKitId}`}
            size={ButtonSizes.M}
            color={ButtonColors.PURPLE}
            label='Go to action plan'
            width={'full'}
          />
        </div>
      )}
    </div>
  );
};
