export type ImportantQuestionsForProfiles = string[];
export const importantQuestionsForProfiles: ImportantQuestionsForProfiles = [
  'birth_location',
  'delivery_mode',
  'adult_birth_method',
  'current_breastfeed',
  'adult_past_breastfed',
  'sample_reason_baby',
  'sample_reason_adult_female',
  'sample_reason_adult_male',
  'sample_baby_diet_breastmilk',
  'sample_baby_diet_formula',
  'sample_baby_diet_solids',
  'sample_baby_breastfed',
  'current_conditions_male',
  'current_conditions_female_other',
  'current_conditions',
  'baby_past_drugs',
  'sample_drugs',
  'pregnancy_drugs',
  'resample_diet',
];

export type ImportantQuestionsBySurveyId = {
  gcKqsqD8?: string[];
  Jz2sYg0x?: string[];
  skpTe0TS?: string[];
  IYH0dy52?: string[];
  X9ri1D2J?: string[];
  HyCkVdiY?: string[];
  Qqup3Txd?: string[];
};
const importantQuestionsBySurveyType: ImportantQuestionsBySurveyId = {
  //th_medication_bundle
  Qqup3Txd: [
    //Antibiotics
    'nMwulgwgCbYI',
    '1DboMNKFdj4k',
    'hvnMBJZ6RkTV',
    'SPntqbrS1qOR',
    //Antibiotics Resample
    'rlIoojuMxycZ',
    'lqyhU3cGIBaU',
    //Probiotics
    'Et9FDovuonSK',
    'ZNxr2jxefbpP',
    'Mh8GDVttovjB',
    'ue3wuklo3GzF',
    'EKCSROPdB4fG',
    '8jqNjYWFWeDW',
    '8Viq5E3YU8ye',
    'GPSZbk47h19P',
    'tg3bJRNf4bdc',
    'Xl8AWDcJIQ3v',
    'AgSBxZ3G3VcM',
    //Probiotics Resample
    'ZxKlGAJan0LS',
    'YpaXDsn3xZtZ',
    'X6vVrJkdGYNQ',
  ],
  //th_diet_bundle
  gcKqsqD8: [
    '908fKUlj0Adt',
    'PeCwIsRyad6v',
    'CvXE4XEMN7vW',
    //Resample
    'onK7QlGUoNF1',
    'itkURYNPuSqf',
  ],
  //th_conditions_bundle
  X9ri1D2J: [
    //NICU
    'FUThGkqGg3Wc',
    'Qj3sAitK2A3I',
    //Eczema
    'tn4CzEabUxYD',
    'kYM1ii5ljITa',
    'u8zSnvf761uh',
    'j9hPCDOwWAs0',
    'rsnuigGOIaKs',
    'zVHD3Nz1R6Cr',
    'C6jYXdnYlu9q',
    //Food reactions
    'LhdfM87uYidg',
    'jPDe4OrjVmUD',
    'ji3cbldwyu4d',
    '68geoyijLhlB',
    'jvAjmYIwy6kP',
    'EaJJyH2eeD0K',
    //Vaginal symptoms
    'PpWt74rdcOwD',
    'Stws5dC5V8Y6',
    'TcniRfEVKwUS',
    'jR6dqdkQKmrB',
    'J6XOBvPMNnmd',
    'eovL20lNu3bv',
    'w8h5ICq0x9ru',
    //Vaginal conditions
    'IMA4DddlX26z',
    '0F9NSxBWHAmk',
    'SrHLp1YBa1uS',
    's61niQRyFz3J',
    'I9cyCcDP7Be3',
    'J9gCPjmwVRkh',
    //GI symptoms
    'gFhW0Ym9lPqx',
    'Yc3XBoacvOqU',
    'cRuLXaTpViRg',
    'T1mqFOEmi15M',
    'bdvJWiKGzC93',
    'b4SFr8JLRIUL',
    'tjabagQL6Pjj',
    '2YNwp5hfCdYm',
    //Metabolic health
    'm9gpoTHSztYr',
    'XL2DUFj9NadK',
    'WX9L5syG9WDn',
    'Yrou5rVz57mb',
    'he98EOgG9iWZ',
  ],
  //th_daily_life_bundle
  HyCkVdiY: [
    'igSDGpBncSca',
    '7veysb6q77Mg',
    'shtGT8rN6EwV',
    'M15SqmXyUkLg',
    '4AvCR4KOZiS1',
    //Resample
    'FFTPU85vVJPU',
    '43yYlHL0cxfC',
    'TNtwufciIMJf',
    'MIZ8qypPCo2h',
    'kW62KWVUXxgh',
    '7hcMItZSrOrt',
    'nSL1zNG7AUdE',
  ],
  //th_ttc_bundle
  Jz2sYg0x: ['vKCshAZWz4U9', 'gexrMQobvZiC'],
  //th_pregnancy_adult_bundle
  skpTe0TS: ['mIImNU6oOmC9', 'KdJVSSbABGCy', 'PqJdhJGWz152'],
  //th_pregnancy_baby_bundle
  IYH0dy52: [
    'mIImNU6oOmC9',
    'KdJVSSbABGCy',
    'PqJdhJGWz152',
    'UJSWLGluUqjx',
    'ySIFsOPimvTQ',
    'OTGIRNCMYYhy',
    'sPRB8ZpogEFO',
    'wp7shnhTukZL',
    'kgsg5ZT64u1j',
    'Rdhbx6dXizJS',
    'PUAdjkSS38eN',
    //Resample
    '6Zd3zouYWsJ9',
    '1FpLE6AX5cCk',
    'Fdr5RHcyAz0t',
    '6p76EkZ5JVwz',
    'qCC4bA6RGSJm',
  ],
};

export const getImportantSurveyQuestionsWithProfile =
  (): ImportantQuestionsBySurveyId => {
    return importantQuestionsBySurveyType;
  };
