import { format } from 'date-fns';

import { kitResultsViewable, kitStatusParser, parseDate } from 'helpers';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import Kit from 'types/Kit';

import { TextLink } from 'components';
import { Icon, Typography, TextColor } from '@repo/ui';

import { HomeResultsStatus } from '../HomeNextSteps/HomeNextSteps';
import { FadedCard } from '../FadedCard/FadedCard';

import styles from './KitsSection.module.scss';

const KitSectionCard = ({ kit }: { kit: Kit }) => {
  const isVoid = kitStatusParser(kit.status) === 'Void';
  const resultsViable = kitResultsViewable(kit) && !!kit.date_survey_completed;
  const kitType = kit.sampling_type === 'stool' ? 'Gut' : 'Vag';
  const formattedDate = format(
    parseDate(kit.sample_date) ?? new Date(),
    'MM/dd/yy',
  );
  return (
    <div className={styles.card}>
      <div className='flex align-items-center gap-2 pb-1'>
        <Typography variant='heading-m'>{formattedDate}</Typography>
        <Typography variant='heading-m'>{`${kitType} health test`}</Typography>
      </div>
      <div className='flex align-items-center justify-content-between gap-1 flex-wrap'>
        <div className={styles.iconRow}>
          <Icon name='priceTagFilled' size='s' />
          <Typography variant='body-s'>{`Kit ID: ${kit.id}`}</Typography>
        </div>
        <div className={styles.iconRow}>
          <Icon name='atSign' size='s' />
          <Typography variant='body-s'>{kit.sample_label}</Typography>
        </div>
        {isVoid && (
          <div className='flex align-items-center gap-1 red'>
            <Typography variant='body-s'>Void</Typography>
            <Icon name='add' size='s' className={styles.rotate} />
          </div>
        )}
      </div>
      {!isVoid && (
        <div className='flex align-items-center justify-content-between gap-1'>
          {!kit.date_survey_completed ? (
            <TextLink
              label='Complete info'
              iconNameRight='chevronForward'
              to={`/information/${kit.id}`}
              state={{ from: `/` }}
              size='body-s'
            />
          ) : (
            <TextLink
              label='See survey responses'
              iconNameRight='chevronForward'
              to={`/results/${kit.id}/surveys`}
              state={{ from: `/` }}
              size='body-s'
            />
          )}
          {resultsViable ? (
            <TextLink
              label='See Results'
              iconNameRight='chevronForward'
              to={`/results/${kit.id}`}
              size='body-s'
            />
          ) : (
            !!kit.date_in_transit_to_lab && (
              <HomeResultsStatus text='Lab analysis' />
            )
          )}
        </div>
      )}
    </div>
  );
};

export const KitsSection = ({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) => {
  const { getDisplayName, kits } = useTinyAccountForHome(tinyAccountId);

  if (!kits?.length) return <></>;
  return (
    <FadedCard>
      <div className={styles.container}>
        <div className='flex align-items-center justify-content-between gap-2'>
          <Typography variant='heading-l'>{`${getDisplayName()} Tests`}</Typography>
          <TextLink
            label='Activate kit'
            iconNameLeft='add'
            iconSize='m'
            to={`/kits/activate`}
            state={{ from: `/` }}
            size={'label'}
            color={TextColor.GREY}
            className='gap-1'
          />
        </div>
        {kits?.map(kit => <KitSectionCard key={kit.id} kit={kit} />)}
      </div>
    </FadedCard>
  );
};
