interface MainAccount {
  id: string;
  email: string;
  practitioner_id: string | null;
  first_name: string;
  last_name: string;
  membership_plan: MembershipPlan | null; // null, 'tiny_plus' or 'tiny_plus_practitioners'
  membership_type: MembershipType | null; // 'free', 'premium', or 'vip' (vip are premiums we pay for)
  phone_number: string | null;
  programs: Program[];
  practitioner?: Practitioner | null;
  date_membership_downgraded: string | null;
  date_membership_upgraded: string | null;
  next_suggested_consult_date: string | null;
  referral_code: string | null;
  referral_credit: number;
  consult_credits_count: number;
  created_at: string;
  updated_at: string | null;
}

export enum MembershipPlan {
  TINY_PLUS = 'tiny_plus',
  TINY_PLUS_PROGRAMS = 'tiny_plus_programs',
  TINY_PLUS_PROACTIVE = 'tiny_plus_proactive',
  TINY_PLUS_PRACTITIONERS = 'tiny_plus_practitioners',
}

export enum MembershipType {
  FREE = 'free',
  PREMIUM = 'premium',
  VIP = 'vip',
}

export type Program = {
  id?: string;
  start_date?: string;
  end_date?: string;
  shopify_product_name?: string;
  status?: string;
  consults_link?: string;
};

export type Practitioner = {
  hide_consults?: boolean;
};

export default MainAccount;
