import { useMemo } from 'react';
import ReactSelect, {
  components,
  ControlProps,
  GroupBase,
  OptionProps,
} from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import c from 'classnames';
import { Icon, IconName, Typography } from '@repo/ui';
import styles from './Dropdown.module.scss';

export type SelectOption = {
  label?: string;
  value: string;
};

const OptionWrapper = (
  props: OptionProps<SelectOption, false, GroupBase<SelectOption>>,
) => {
  return (
    <div
      className={c(styles.optionWrapper, props.isSelected && styles.isSelected)}
      onClick={props.innerProps.onClick}
    >
      {props.children}
    </div>
  );
};

type ControlPropType = {
  label: string;
  subLabel?: string;
  iconString?: IconName;
  hideLabelMobile?: boolean;
  hideSubLabelMobile?: boolean;
};

const ControlWrapper = ({
  label,
  iconString,
  subLabel,
  hideLabelMobile,
  hideSubLabelMobile,
  ...props
}: ControlPropType &
  ControlProps<SelectOption, false, GroupBase<SelectOption>>) => (
  <components.Control {...props} className={styles.controlContainer}>
    {iconString && (
      <Icon className={styles.controlIcon} name={iconString} size='s' />
    )}
    <span className={styles.controlLabelContainer}>
      <Typography
        variant='label'
        className={c(styles.controlLabel, hideLabelMobile && styles.hideMobile)}
      >
        {label}
      </Typography>
      {subLabel && (
        <Typography
          variant='label'
          className={c(
            styles.controlSubLabel,
            hideSubLabelMobile && styles.hideMobile,
          )}
        >
          {subLabel}
        </Typography>
      )}
    </span>
    {props.children}
  </components.Control>
);

const Dropdown = ({
  value,
  options,
  CustomOption = components.Option,
  controlLabel,
  controlSubLabel,
  controlIconString,
  hideControlLabelMobile,
  hideControlSubLabelMobile,
  onChange,
  placeholder = 'Select...',
  menuIsOpen,
  isSearchable,
  defaultValue,
}: {
  value: string;
  options: SelectOption[];
  CustomOption?: React.ComponentType<
    OptionProps<SelectOption, false, GroupBase<SelectOption>>
  >;
  controlLabel?: string;
  controlSubLabel?: string;
  controlIconString?: IconName;
  hideControlLabelMobile?: boolean;
  hideControlSubLabelMobile?: boolean;
  onChange: (value: string) => void;
  placeholder?: string | null;
  menuIsOpen?: boolean;
  isSearchable?: boolean;
  defaultValue?: SelectOption | null;
}) => {
  const components = useMemo(() => {
    const custom: Partial<
      SelectComponents<SelectOption, false, GroupBase<SelectOption>>
    > = {};
    if (CustomOption) {
      custom.Option = function Option(props) {
        return (
          <OptionWrapper {...props}>
            <CustomOption {...props} />
          </OptionWrapper>
        );
      };
    }
    if (controlLabel) {
      custom.Control = function Control(props) {
        return (
          <ControlWrapper
            label={controlLabel}
            subLabel={controlSubLabel}
            iconString={controlIconString}
            hideLabelMobile={hideControlLabelMobile}
            hideSubLabelMobile={hideControlSubLabelMobile}
            {...props}
          />
        );
      };
    }
    return custom;
  }, [CustomOption, controlIconString, controlLabel, controlSubLabel]);
  return (
    <ReactSelect
      className={styles.select}
      classNamePrefix={styles.select}
      options={options}
      value={options.find(option => option?.value === value)}
      onChange={option => option?.value && onChange(option.value)}
      isSearchable={isSearchable}
      placeholder={placeholder}
      components={components}
      defaultValue={defaultValue}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default Dropdown;
