import Kit from 'types/Kit';
import { kitResultsViewable, kitStatusParser } from './kitStatuses';

const sampleDateComparator = (a: Kit, b: Kit) => {
  if (!a.sample_date && b.sample_date) {
    return 1;
  } else if (a.sample_date && !b.sample_date) {
    return -1;
  } else if (a.sample_date && b.sample_date) {
    return (
      new Date(b.sample_date).getTime() - new Date(a.sample_date).getTime()
    );
  } else {
    return 0;
  }
};
export function sortKitsByLatestSampleDate(kits: Kit[]) {
  if (!kits?.length) return [];
  if (kits.length === 1) return kits;
  return kits.sort(sampleDateComparator) || [];
}

export const sortKitsByResultsViewableOrLatest = (kits: Kit[]): Kit[] => {
  if (!kits?.length) return [];
  return kits
    .filter(kit => kitStatusParser(kit.status) !== 'Void')
    .sort((a, b) => {
      // first sort by status "results_ready"
      if (kitResultsViewable(a) && !kitResultsViewable(b)) {
        return -1;
      } else if (!kitResultsViewable(a) && kitResultsViewable(b)) {
        return 1;
      } else {
        // then sort by sample_date
        return sampleDateComparator(a, b);
      }
    });
};
