import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { toast } from 'react-toastify';
import { useSystemInfo } from 'services/system-info/useSystemInfo';

const postUpgradeKits = async () => {
  const response = await API.post(
    apiName,
    `${apiBasePath}/kits/upgrade-versions`,
    {},
  );
  return response?.result ?? [];
};

export const useUpgradeKits = () => {
  const { latestSciVersion } = useSystemInfo();
  const queryClient = useQueryClient();
  const kitMutation = useMutation({
    mutationFn: async () => {
      return await postUpgradeKits();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['kits'],
        refetchType: 'all',
      });
      toast.success(
        `All kit results will be updated to Version ${latestSciVersion}. Check back in a few days to see the changes to your report.`,
      );
    },
  });
  return kitMutation;
};
