import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useActionPlanData from 'services/actionPlan/actionPlanData';
import { getIsInProgram } from 'store/account';
import { ActionPlanItem } from 'types/ActionPlan';
import Kit from 'types/Kit';

import { ActionPlanGroup } from './ActionPlanGroup/ActionPlanGroup';
import { ResampleSummaryCard } from '../Results/Summary/SummaryCards';

import styles from 'components/Layout/ResponsiveLayout.module.scss';

type SectionGroupings = {
  tag: 'resultsBased' | 'informationBased' | 'general' | 'whenToResample';
  title: string;
  sections: (string | undefined)[];
  placeholderTitle: string;
  placeholderDescription: string;
  category: string | null;
};
const listOfSectionGroups: SectionGroupings[] = [
  {
    tag: 'resultsBased',
    title: 'High priority based on your results',
    sections: ['Gut Vaginal Health', 'Baby Gut Type', 'CST'],
    placeholderTitle: 'None for this sample  🎉',
    placeholderDescription:
      ' This is great, this means you have no items that need support at this time',
    category: null,
  },
  {
    tag: 'informationBased',
    title: 'Based on what you told us',
    sections: ['Condition'],
    placeholderTitle: '',
    placeholderDescription: '',
    category: null,
  },
  {
    tag: 'general',
    title: 'For general microbiome health',
    sections: ['General'],
    placeholderTitle: '',
    placeholderDescription: '',
    category: null,
  },
  {
    tag: 'whenToResample',
    title: 'When To Resample',
    sections: ['General'],
    placeholderTitle: '',
    placeholderDescription: '',
    category: 'WhenToResample',
  },
];

const getSectionGrouping = ({
  category,
  sections,
}: {
  category: string;
  sections: (string | undefined)[];
}) => {
  if (
    ['Gut Vaginal Health', 'Baby Gut Type', 'CST', 'Biomarkers'].some(section =>
      sections.includes(section),
    )
  ) {
    return 'resultsBased';
  }
  if (sections.includes('Condition')) {
    return 'informationBased';
  }
  if (category === 'WhenToResample') {
    return 'whenToResample';
  } else {
    return 'general';
  }
};

// the grouping is done by looking at all the outcomes of an AP Card item and then try to fit the group in one of the 'listOfSectionGroups' (check in order) if there is at least one outcome which belongs to that section
// the groups of outcomes should stay together and not split in different sections (don't repeat the same AP Card item in multiple sections)
export const groupActionPlanItems = (actionPlan: ActionPlanItem[]) =>
  actionPlan.reduce(
    (initialObj, data) => {
      const outcomesSections = data?.desired_outcomes?.map(
        desiredOutcome => desiredOutcome.section,
      );
      if (outcomesSections?.length && data.category) {
        initialObj[
          getSectionGrouping({
            sections: outcomesSections,
            category: data.category,
          })
        ].push(data);
      }
      return initialObj;
    },
    {
      resultsBased: [] as ActionPlanItem[],
      informationBased: [] as ActionPlanItem[],
      general: [] as ActionPlanItem[],
      whenToResample: [] as ActionPlanItem[],
    },
  );

export const ActionPlanAccordionList = ({
  kit,
  isActionPlanPage,
}: {
  kit: Kit;
  isActionPlanPage?: boolean;
}) => {
  const isInProgram = useSelector(getIsInProgram);
  const { data } = useActionPlanData({ kitId: kit.id });
  const sectionedResult = useMemo(
    () => data && groupActionPlanItems(data),
    [data],
  );

  return (
    <div className={isActionPlanPage ? styles.scrollable : ''}>
      {listOfSectionGroups.map(sectionCard => {
        if (!sectionedResult?.[sectionCard.tag]?.length) return null;
        return (
          <Fragment key={sectionCard.tag}>
            <ActionPlanGroup
              title={sectionCard.title}
              actionPlanItems={sectionedResult[sectionCard.tag]}
              placeholderTitle={sectionCard.placeholderTitle}
              placeholderDescription={sectionCard.placeholderDescription}
            />
          </Fragment>
        );
      })}
      {isActionPlanPage && (
        <div className='mt-4'>
          <ResampleSummaryCard />
        </div>
      )}
    </div>
  );
};
