import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSubdomain } from 'hooks/useSubdomain';
import { Spinfinity } from 'components';

interface SubdomainRouteProps {
  mode?: 'whitelist' | 'blacklist';
  subdomains: string | string[];
}

const SubdomainRoute = ({
  mode = 'whitelist',
  subdomains,
}: SubdomainRouteProps) => {
  const location = useLocation();
  const { subdomain, isLoaded } = useSubdomain();

  if (!isLoaded) return <Spinfinity />;
  else if (
    subdomain &&
    mode === 'whitelist' &&
    subdomains.includes(subdomain)
  ) {
    return <Outlet />;
  } else if (
    (mode === 'blacklist' && !subdomain) ||
    (subdomain && !subdomains.includes(subdomain))
  ) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to='/'
        replace
        state={{ from: location.pathname, ...location.state }}
      />
    );
  }
};
export default SubdomainRoute;
