import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useTaxaData from 'services/microbiome/useTaxaData';
import { getKitById } from 'store/kits';

import { taxaCategoryFilters, TaxaFilters } from 'components/TaxaFilters';
import { InsightsBacteriaList } from './InsightsBacteriaList';
import { ResultsParams } from 'views/Results';
import { useParams } from 'react-router-dom';
import { KitType } from 'types/Kit';
import { lowRelativeAbundanceLimit } from 'assets/constants';

const bifidobacteriumDataset = [];
const beneficialDataset = [];
const unfriendlyDataset = [];
const variableDataset = [];
const unknownDataset = [];

export const InsightsBacteria = () => {
  const { kitId: currentKitId } = useParams<ResultsParams>();
  const currentKit = useSelector(getKitById(currentKitId));
  const currentKitType = currentKit?.sampling_type;

  const [filters, setFilters] = useState(taxaCategoryFilters);
  const { data } = useTaxaData({
    kitType: currentKitType ?? KitType.STOOL,
    kitId: currentKitId,
    relativeAbundanceLimit: lowRelativeAbundanceLimit,
  });

  useEffect(() => {
    if (data) {
      const bacteriaRelAbun = data?.reduce(
        (filtered, bacterium) => {
          if (bacterium.classified_relative_abundance) {
            if (bacterium.category === 'Beneficial') {
              filtered.beneficial.classified_relative_abundance +=
                bacterium.classified_relative_abundance;
            }
            if (bacterium.category === 'Unfriendly') {
              filtered.unfriendly.classified_relative_abundance +=
                bacterium.classified_relative_abundance;
            }
            if (bacterium.category === 'Variable') {
              filtered.variable.classified_relative_abundance +=
                bacterium.classified_relative_abundance;
            }
            if (bacterium.category === 'Unknown' || !bacterium.category) {
              filtered.unknown.classified_relative_abundance +=
                bacterium.classified_relative_abundance;
            }
            if (
              bacterium?.taxonomy_name?.includes('Bifidobacterium') &&
              bacterium.taxonomy_category === 'species'
            ) {
              filtered.bifidobacterium.classified_relative_abundance +=
                bacterium.classified_relative_abundance;
            }
          }
          return filtered;
        },
        {
          beneficial: { classified_relative_abundance: 0 },
          unfriendly: { classified_relative_abundance: 0 },
          variable: { classified_relative_abundance: 0 },
          unknown: { classified_relative_abundance: 0 },
          bifidobacterium: { classified_relative_abundance: 0 },
        },
      );
      beneficialDataset.push(
        bacteriaRelAbun.beneficial.classified_relative_abundance * 100,
      );
      unfriendlyDataset.push(
        bacteriaRelAbun.unfriendly.classified_relative_abundance * 100,
      );
      variableDataset.push(
        bacteriaRelAbun.variable.classified_relative_abundance * 100,
      );
      unknownDataset.push(
        bacteriaRelAbun.unknown.classified_relative_abundance * 100,
      );
      bifidobacteriumDataset.push(
        bacteriaRelAbun.bifidobacterium.classified_relative_abundance * 100,
      );
    }
  }, [data]);

  return (
    <div className='mb-0'>
      <TaxaFilters
        kitType={currentKitType}
        taxaFilters={filters}
        setFilters={setFilters}
      />
      {data && (
        <div>
          <InsightsBacteriaList
            data={data}
            filters={filters}
            type={currentKitType}
          />
        </div>
      )}
    </div>
  );
};
