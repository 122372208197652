import { useSuspenseQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { API } from 'api';
import { apiBasePath, apiName } from 'assets';
import { getMainAccountId } from 'store/account';
import { Feature } from 'types/Feature';

const getAllFeatures = async () => {
  const response = await API.get(apiName, `${apiBasePath}/features`);
  return response;
};
export const getAllFeaturesQuery = (
  mainAccountId: string | null,
  enabled = true,
) => ({
  queryKey: [apiName, 'features', mainAccountId],
  queryFn: async () => (mainAccountId && (await getAllFeatures())) ?? [],
  enabled,
});
const useAllFeatures = (enabled = true) => {
  const mainAccountId = useSelector(getMainAccountId);

  const allFeaturesQuery = useSuspenseQuery<Feature[]>(
    getAllFeaturesQuery(mainAccountId, enabled),
  );

  // useEffect(() => {
  //   if (allFeaturesQuery.isSuccess) {
  //     dispatch(setFeatures(allFeaturesQuery.data));
  //   }
  // }, [allFeaturesQuery.isSuccess, allFeaturesQuery.data, dispatch]);

  return allFeaturesQuery;
};

export default useAllFeatures;
