import { DesignButton } from 'components';
import { THTextarea } from 'components/Form/THTextarea/THTextarea';
import SlideUpModal from 'components/Modal/SlideUpModal';
import { Typography } from '@repo/ui';
import { useForm } from 'react-hook-form';
import useUpdateFeedback from 'services/general/useUpdateFeedback';
import { Feedback } from 'types/Feedback';

type FeedbackModalFormProps = {
  feedback: string;
};

const FeedbackModal = ({
  toggle,
  showModal,
  feedback,
}: {
  toggle: () => void;
  showModal: boolean;
  feedback: Feedback;
}) => {
  const updateFeedbackMutation = useUpdateFeedback();
  const { register, handleSubmit, setError } =
    useForm<FeedbackModalFormProps>();
  const feedbackFormField = register('feedback', { required: false });
  const submitFeedback = (data: FeedbackModalFormProps) => {
    try {
      updateFeedbackMutation.mutateAsync({
        kit_id: feedback.kit_id,
        page_id: feedback.page_id,
        page_type: feedback.page_type,
        answer: feedback.answer,
        feedback: data.feedback,
      });

      toggle();
    } catch {
      setError('feedback', {
        message: 'Something went wrong, please try again',
      });
    }
  };
  return (
    <SlideUpModal title='Leave feedback' toggle={toggle} showModal={showModal}>
      <form onSubmit={handleSubmit(submitFeedback)}>
        <div className='flex flex-column gap-3'>
          <Typography variant={'heading-l'}>
            Why wasn&apos;t this helpful?
          </Typography>
          <Typography variant={'body-s'}>
            Your feedback helps us make your test results better
          </Typography>
          <THTextarea
            {...feedbackFormField}
            rows={7}
            placeholder='Please enter your feedback here'
            maxLength={500}
          />
          <DesignButton label='Submit' type='submit' />
        </div>
      </form>
    </SlideUpModal>
  );
};
export default FeedbackModal;
