import Kit, { KitStatus } from '../types/Kit';

export function kitResultsViewable(kit?: Kit | null) {
  if (!kit) return false;
  const status = kit.status;
  const date_results_ready = kit.date_results_ready;
  if (date_results_ready) return true;
  if (!status) return false;
  return [KitStatus.RESULTS_READY, KitStatus.EXPERT_REVIEW_COMPLETE].includes(
    status,
  );
}

export function kitStatusParser(status?: KitStatus) {
  if (!status) return 'Processing';
  switch (status) {
    case KitStatus.EXPERT_REVIEW_COMPLETE:
    case KitStatus.RESULTS_READY:
      return 'Results ready';
    case KitStatus.ACTIVATED:
    case KitStatus.CONFIRM_KIT_RETURNED_TO_LAB_BY_CUSTOMER:
    case KitStatus.DELIVERED_TO_CUSTOMER:
    case KitStatus.INITIALIZED:
    case KitStatus.IN_TRANSIT_TO_CUSTOMER:
    case KitStatus.IN_TRANSIT_TO_LAB:
    case KitStatus.LABEL_EXPIRY_EMAIL_SENT:
      return 'Not yet received';
    case KitStatus.VOID_CUSTOMER_REFUND:
    case KitStatus.VOID_INTERNAL:
    case KitStatus.VOID_LAB_ERROR:
    case KitStatus.VOID_LOST_IN_MAIL:
    case KitStatus.VOID_RETURN_LABEL_EXPIRED:
    case KitStatus.VOID_SAMPLE_EXPIRED:
    case KitStatus.VOID_SHIPPO_SHIPMENT_FAILED:
    case KitStatus.RETURN_MISSING:
    case KitStatus.VOID:
      return 'Void';
    case KitStatus.DELIVERED_TO_LAB:
    case KitStatus.SEQUENCES_DELIVERED:
    case KitStatus.PIPELINE_COMPLETE:
    default:
      return 'Processing';
  }
}

export function kitStatusColorParser(status?: KitStatus) {
  if (!status) return 'default';
  switch (status) {
    case KitStatus.EXPERT_REVIEW_COMPLETE:
    case KitStatus.RESULTS_READY:
      return 'good';
    case KitStatus.DELIVERED_TO_LAB:
    case KitStatus.PIPELINE_COMPLETE:
    case KitStatus.SEQUENCES_DELIVERED:
      return 'ok';
    case KitStatus.VOID_CUSTOMER_REFUND:
    case KitStatus.VOID_INTERNAL:
    case KitStatus.VOID_LAB_ERROR:
    case KitStatus.VOID_LOST_IN_MAIL:
    case KitStatus.VOID_RETURN_LABEL_EXPIRED:
    case KitStatus.VOID_SAMPLE_EXPIRED:
    case KitStatus.VOID_SHIPPO_SHIPMENT_FAILED:
    case KitStatus.RETURN_MISSING:
      return 'bad';
    case KitStatus.ACTIVATED:
    case KitStatus.CONFIRM_KIT_RETURNED_TO_LAB_BY_CUSTOMER:
    case KitStatus.DELIVERED_TO_CUSTOMER:
    case KitStatus.INITIALIZED:
    case KitStatus.IN_TRANSIT_TO_CUSTOMER:
    case KitStatus.IN_TRANSIT_TO_LAB:
    case KitStatus.LABEL_EXPIRY_EMAIL_SENT:
    default:
      return 'default';
  }
}
