import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { InsightMetric } from 'types/Insights';

export const getSubMetricsEvaluations = async (kitId: string) => {
  const res = await API.get(
    apiName,
    `${apiBasePath}/results/sub-metric-evaluations/${kitId}`,
    {},
  );
  return res.metrics as InsightMetric[];
};

export const getEvaluationsQuery = (kitId?: string) => ({
  queryKey: [apiName, `subMetricEvaluations`, kitId],
  queryFn: () => (kitId ? getSubMetricsEvaluations(kitId) : []),
});

const useSubMetricEvaluations = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const insightsDataQuery = useSuspenseQuery<InsightMetric[]>(
    getEvaluationsQuery(currentKitId),
  );

  return insightsDataQuery;
};

export default useSubMetricEvaluations;
