import { useState } from 'react';
import c from 'classnames';

import { KitType } from 'types/Kit';
import { BacteriumType } from 'types/Insights';

import { AssociationsList, Markdown } from 'components';
import { Icon } from '@repo/ui';
import styles from './Bacterium.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

function detailsPresent(bacterium: BacteriumType, type: KitType) {
  //has associations in any form
  if (
    (!!bacterium.associations?.length && !!bacterium.associations[0]?.length) ||
    !!bacterium.associations_list?.length
  )
    return true;
  //has about info
  if (bacterium.about) return true;
  return false;
}

type BacteriumProps = {
  bacterium: BacteriumType;
  transferredFromBirthMother?: boolean;
  type?: KitType;
};

export const Bacterium = ({
  bacterium,
  transferredFromBirthMother = false,
  type = KitType.STOOL,
}: BacteriumProps) => {
  const [isOpen, setIsOpen] = useState(false);
  // not all bacteria have an about
  const source = bacterium?.about?.replace(/\\n/gi, '\n \n');
  return (
    <>
      <div
        id={bacterium.rtc_id + '' || bacterium.id}
        className={c(
          styles.row,
          detailsPresent(bacterium, type) && styles.hoverable,
          detailsPresent(bacterium, type) && 'hoverable',
          styles[bacterium.category ?? 'Unknown'],
        )}
        onClick={() => setIsOpen(!isOpen)}
        role={detailsPresent(bacterium, type) ? 'button' : 'listitem'}
      >
        <div className={styles.taxonomyName}>{bacterium.taxonomy_name}</div>
        <div className={styles.bacteriaDetails}>
          <div className={c('font-S', styles.bacteriaAbundance)}>
            {(bacterium.classified_relative_abundance
              ? bacterium.classified_relative_abundance * 100
              : 0
            ).toFixed(3)}
            %
          </div>

          <Icon
            name='circleFilled'
            size='s'
            className={c(
              'my-auto',
              styles.bacteriaCircle,
              styles[bacterium.category ?? ''] ?? styles.Unknown,
            )}
          />
          {!!transferredFromBirthMother && (
            // content is coming from css
            <div
              className={`ps-1 sharedTaxaSymbol present-in-${bacterium.shared}`}
            />
          )}

          {bacterium.about ? (
            <Icon
              name='chevronDown'
              size='s'
              data-testid='chevron'
              className={c(
                'my-auto',
                styles.rotate,
                isOpen ? styles.iconRotateUp : styles.iconRotateDown,
              )}
            />
          ) : (
            <div className={styles.spacer} />
          )}
        </div>
      </div>

      <AnimatePresence>
        {detailsPresent(bacterium, type) && isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.6 }}
            className={styles.collapse}
          >
            <div className={c(styles.bacteriaDetail, 'text-start')}>
              {!!source && (
                <>
                  <h5
                    className={styles.detailHeading}
                    data-test='bacteria-collapse-about'
                  >
                    About
                  </h5>
                  <div>
                    <Markdown>{source}</Markdown>
                  </div>
                </>
              )}
              {!!bacterium.associations_list?.length && (
                <AssociationsList associations={bacterium.associations_list} />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
