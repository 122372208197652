import { forwardRef, Ref } from 'react';
import c from 'classnames';

import Kit from 'types/Kit';
import MicrobiomeScoreChart from 'components/MicrobiomeScoreChart/MicrobiomeScoreChart';
import { getPopScoreInfo } from 'views/Results/Summary/SummaryCards/MicrobiomeScoreSummaryCard/MicrobiomeScoreSummaryCard';
import LogoSvg from 'assets/images/logos/logo-full.svg?react';
import MyGutHealthResults from 'assets/images/my-gut-health-results.svg?react';

import styles from './MicrobiomeScoreSummaryCard.module.scss';

const MicrobiomeScoreShareImage = forwardRef(function MicrobiomeScoreShareImage(
  { kit, popScore }: { kit: Kit; popScore: number },
  ref,
) {
  const { percentile, color, ageText } = getPopScoreInfo(kit, popScore);
  return (
    <div
      className={styles.cardContainer}
      style={{ width: 362, height: 362 }}
      ref={ref as Ref<HTMLDivElement> | null}
    >
      {' '}
      <div className={styles.title}>
        <MyGutHealthResults />
      </div>
      <div className={c(styles.titleContainer, styles.isImage)}>
        <div className={c(styles.percentile, styles.margins)}>
          <span className={c(styles.percentileBig, styles[color ?? 'grey'])}>
            {Math.round(popScore)}
          </span>
          <span className='dark-grey'>/ 100</span>
        </div>
      </div>
      <div className={styles.chartContainer}>
        <MicrobiomeScoreChart
          kit={kit}
          popScore={popScore}
          // disableAnimations
          isImage
        />
      </div>
      <div className={styles.logoContainer}>
        <LogoSvg />
      </div>
    </div>
  );
});

export default MicrobiomeScoreShareImage;
