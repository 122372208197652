import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { parseDate, sortKitsByLatestSampleDate } from 'helpers';
import Kit from 'types/Kit';
import MainAccount, {
  MembershipPlan,
  MembershipType,
  Program,
} from 'types/MainAccount';
import TinyAccount from 'types/TinyAccount';
import { getKits } from './shared';
import { RootState } from './store';
import { getImpersonateId, getUsername, impersonate, signOut } from './user';
import { Consult } from 'types/Consult';
import { differenceInCalendarDays } from 'date-fns';

interface HomeState {
  tinyAccountId: string | null;
}

export const initialState: HomeState = {
  tinyAccountId: null,
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomePageTinyAccountId(state, action: PayloadAction<string | null>) {
      state.tinyAccountId = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(signOut, (state, action) => {
        return initialState;
      })
      .addCase(impersonate, (state, action) => {
        return initialState;
      }),
});

// Selectors
const getHomeState = (state: RootState) => state.home;
export const getHomePageTinyAccountId = createSelector(
  getHomeState,
  state => state.tinyAccountId,
);

export const { setHomePageTinyAccountId } = homeSlice.actions;
export default homeSlice.reducer;
