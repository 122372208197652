import { useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSuspenseQueries } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { groupAndSortSubmetrics } from '@repo/utils';
import { getEvaluationsQuery } from 'services/insights/useSubMetricEvaluations';
import { getActionPlanItemsQuery } from 'services/actionPlan/actionPlanData';
import {
  Accordion,
  ContainerSizes,
  PageContainer,
  Typography,
} from 'components';
import { EvaluationGroupsSummary } from 'views/Results/Insights/EvaluationGroupsSummary/EvaluationGroupsSummary';
import { CategoryMetricsFilter } from 'views/Results/Insights/CategoryMetrics/CategoryMetricsFilter';
import {
  MetricCard,
  SubMetricRow,
} from 'views/Results/Insights/CategoryMetrics/CategoryMetrics';

import styles from './CategoryMetrics.module.scss';
import { InsightMetric } from 'types/Insights';
import { Category, GroupedSubmetrics } from '@repo/types';

export const AllMetrics = () => {
  const { kitId: currentKitId } = useParams<{ kitId: string }>();
  const [{ data: submetrics }] = useSuspenseQueries({
    queries: [
      getEvaluationsQuery(currentKitId),
      getActionPlanItemsQuery(currentKitId),
    ],
  });

  const [evaluationFilters, setEvaluationFilters] = useState<string[]>([]);
  const isIncludedByFilter = useCallback(
    (submetric: InsightMetric) =>
      evaluationFilters.length === 0 ||
      evaluationFilters.includes(submetric.evaluation_color ?? ''),
    [evaluationFilters],
  );

  const groupedSubMetrics = useMemo(() => {
    const groupedSubmetrics = groupAndSortSubmetrics(submetrics).map(
      (category: Category) => {
        category.orderedMetrics = category.orderedMetrics.map(metric => {
          metric.orderedSubmetrics = metric.orderedSubmetrics.map(
            submetric => ({
              ...submetric,
              includedByFilters: isIncludedByFilter(submetric),
            }),
          );
          return metric;
        });
        return category;
      },
    );
    return groupedSubmetrics;
  }, [submetrics, isIncludedByFilter]);

  if (!submetrics?.length || !currentKitId) return <></>;

  return (
    <PageContainer size={ContainerSizes.MD} className={styles.allMetrics}>
      <div className={styles.header}>
        <div className='flex flex-column gap-2'>
          <Typography variant='heading-2xl' medium>
            All Insights
          </Typography>
          <div className={styles.dividerLine} />
          <div className={styles.summaryGrid}>
            <Typography variant='body-s' className={styles.pageSummary}>
              This page shows all metrics from the Beneficial Microbes,
              Disruptive Microbes, Balance and Robustness, and Digestion
              sections of your report.
            </Typography>
            <EvaluationGroupsSummary
              metrics={submetrics}
              variant='both'
              includedByFilter={isIncludedByFilter}
            />
          </div>
        </div>
        <CategoryMetricsFilter setFiltered={setEvaluationFilters} />
      </div>

      <div className={styles.cardsContainer}>
        {groupedSubMetrics.map(category => {
          const emptyCategory = !category.orderedMetrics.some(
            metric =>
              metric.orderedSubmetrics.filter(
                submetric => submetric.includedByFilters,
              ).length,
          );
          return (
            <Accordion
              key={`${uuid()}`}
              header={
                <Typography
                  variant='heading-l'
                  className={styles.categoryTitle}
                >
                  {category.name}
                </Typography>
              }
              initialState={!emptyCategory}
              className={styles.metricAccordion}
            >
              {' '}
              {emptyCategory && (
                <>
                  <Typography variant='body-s' className={styles.emptyCategory}>
                    No metrics matching your active filters.
                  </Typography>
                </>
              )}
              {category.orderedMetrics.map(metric => {
                const submetrics = metric.orderedSubmetrics.filter(
                  submetric => submetric.includedByFilters,
                );

                if (!submetrics?.length) return <></>;
                return (
                  <div className={styles.categoryMetric} key={`${uuid()}`}>
                    <Typography
                      variant='heading-s'
                      className={styles.metricTitle}
                    >
                      {metric.name}
                    </Typography>
                    <MetricCard key={`card_${metric.name}`}>
                      {submetrics.map(subMetric => (
                        <SubMetricRow
                          key={`${metric.name}_${subMetric.name}`}
                          kitId={currentKitId}
                          subMetric={subMetric}
                        />
                      ))}
                    </MetricCard>
                  </div>
                );
              })}
            </Accordion>
          );
        })}
      </div>
    </PageContainer>
  );
};
