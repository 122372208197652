import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import c from 'classnames';

import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { Typography } from '@repo/ui';

import styles from './Filters.module.scss';

export type FilterConfig = {
  value: string;
  display: string | React.ReactElement;
};

type FilterProps = {
  name: string;
  filters: string[];
  filterConfig: FilterConfig[];
  setFiltered:
    | ((filters: string[]) => Promise<void>)
    | Dispatch<SetStateAction<string[]>>; //setter from useState
  className?: string;
};
export const Filters = ({
  name,
  filters,
  filterConfig,
  setFiltered,
  className,
}: FilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { trackEvent } = useAnalytics();

  const toggleFilter = (filterValue: string | null) => {
    trackEvent('Clicked Button', {
      text: filterValue ?? `All ${name}`,
      href: '',
      isExternal: false,
      variety: 'Filter',
    });
    if (filterValue === null) {
      setFiltered([]);
      return;
    }
    if (filters?.includes(filterValue)) {
      //remove filter
      const updatedFilters =
        filters.length === 1
          ? []
          : filters.filter(currentFilter => currentFilter !== filterValue);
      setFiltered(updatedFilters);
    } else {
      //add filter
      const updatedFilters = !filters
        ? [filterValue]
        : [...filters, filterValue];
      setFiltered(
        updatedFilters.length === filterConfig.length ? [] : updatedFilters,
      );
    }
  };
  useEffect(() => {
    const setParams = async () => {
      if (filters.length) {
        await setSearchParams({ [name]: filters.join(',') });
      } else {
        await setSearchParams({});
      }
    };
    setParams();
  }, [filters, filters.length, name, setSearchParams]);

  useEffect(() => {
    if (searchParams.get(name)) {
      const filterValues = searchParams.get(name)?.split(',');
      if (!isEqual(filters, filterValues)) {
        setFiltered(filterValues ?? []);
      }
    }
  }, [searchParams]);
  return (
    <div className={c(styles.filtersContainer, className)}>
      <div
        className={c(
          styles.filter,
          !filters.length ? styles.active : styles.inactive,
        )}
        onClick={() => toggleFilter(null)}
      >
        <Typography variant='label' className={styles.greyText}>
          All {name}
        </Typography>
      </div>
      {filterConfig.map(({ value, display }) => (
        <div
          key={value}
          className={c(
            styles.filter,
            filters?.includes(value)
              ? styles.active
              : filters?.length
                ? styles.inactive
                : '',
          )}
          onClick={() => toggleFilter(value)}
        >
          {display}
        </div>
      ))}
    </div>
  );
};
