import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { BookmarkedItem } from 'types/ActionPlan';

const upsertBookmark = async (bookmark: BookmarkedItem) => {
  if (!bookmark.kit_ids) throw new Error('No Kit Id provided');
  if (!bookmark.recommendation_item_id)
    throw new Error('No recommendation item Id for kit');
  return API.put(apiName, `${apiBasePath}/actionPlan/bookmarks`, {
    body: {
      ...bookmark,
    },
  });
};

const useUpdateBookmark = () => {
  const queryClient = useQueryClient();
  const bookmarkMutation = useMutation({
    mutationFn: (bookmark: BookmarkedItem) => {
      return upsertBookmark(bookmark);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('bookmarks'),
      });
    },
  });
  return bookmarkMutation;
};

export default useUpdateBookmark;
