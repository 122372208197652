import { apiName, RESERVED_SUBDOMAINS } from 'assets';
import { API } from 'api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useSubdomain } from 'hooks/useSubdomain';

export type Partner = {
  name: string | null;
  logo: string | null;
  light_logo: string | null;
  slug: string | null;
  status: string | null;
  id: string | null;
  email: string | null;
};

const getPartner = async (slug: string) => {
  if (!slug) return false;
  const response = await API.get('publicapi', `/public/partners/${slug}`, {});
  return response?.result;
};

const usePartner = (): UseQueryResult<Partner> => {
  const { subdomain } = useSubdomain();
  const subdomainIsReserved =
    subdomain && RESERVED_SUBDOMAINS.includes(subdomain);
  const partnerQuery = useQuery({
    queryKey: [apiName, 'partnerInfo', subdomain],
    queryFn: () => subdomain && getPartner(subdomain),
    enabled: !!subdomain && !subdomainIsReserved,
  });
  return partnerQuery;
};

export default usePartner;
