import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import TinyAccount from 'types/TinyAccount';

const patchTinyAccount = async (
  tinyAccountId: string,
  body: Partial<TinyAccount>,
) => {
  if (!tinyAccountId) throw new Error('No tiny account Id provided');
  try {
    await API.patch(apiName, `${apiBasePath}/tinyaccounts/${tinyAccountId}`, {
      body,
    });
  } catch (err) {
    Sentry.captureException(err);
    toast.error('Error updating tiny account');
  }
};

const useUpdateTinyAccount = () => {
  const queryClient = useQueryClient();
  const tinyAccountMutation = useMutation({
    mutationFn: ({
      tinyAccountId,
      body,
    }: {
      tinyAccountId: string;
      body: Partial<TinyAccount>;
    }) => {
      return patchTinyAccount(tinyAccountId, body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tinyAccounts'] });
    },
  });
  return tinyAccountMutation;
};

export default useUpdateTinyAccount;
