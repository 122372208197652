import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import c from 'classnames';

import { Icon } from '@repo/ui';
import styles from './Conditions.module.scss';

type ConditionInfoProps = {
  toggle: () => void;
  showModal: boolean;
};
type ConditionsAgesRow = {
  display: string;
  baby1: boolean;
  baby2: boolean;
  toddler: boolean;
  child: boolean;
  ttc: boolean;
  adult: boolean;
};

type TableSection = { rows: ConditionsAgesRow[]; title: string };
const Yes = () => {
  return (
    <div className={c(styles.iconWrapper, styles.yes)}>
      <Icon name='check' size='s' />
    </div>
  );
};
const No = () => {
  return (
    <div className={styles.iconWrapper}>
      <Icon name='closeSm' size='xs' />
    </div>
  );
};
const ConditionTableSection = ({ rows, title }: TableSection) => {
  return (
    <>
      <div className={c(styles.grid, styles.bgGray)}>
        <span className={styles.sectionTitle}>{title}</span>
      </div>
      {rows.map(row => (
        <div className={styles.grid} key={row.display}>
          <div className={styles.rowItem}>{row.display}</div>
          <div className={styles.rowItem}>{row.baby1 ? <Yes /> : <No />}</div>
          <div className={styles.rowItem}>{row.baby2 ? <Yes /> : <No />}</div>
          <div className={styles.rowItem}>{row.toddler ? <Yes /> : <No />}</div>
          <div className={styles.rowItem}>{row.child ? <Yes /> : <No />}</div>
          <div className={styles.rowItem}>{row.ttc ? <Yes /> : <No />}</div>
          <div className={styles.rowItem}>{row.adult ? <Yes /> : <No />}</div>
        </div>
      ))}
    </>
  );
};

export const ConditionInfoModal = ({
  toggle,
  showModal,
}: ConditionInfoProps) => {
  const biomarkersAgesMap: ConditionsAgesRow[] = [
    {
      display: 'Eczema',
      baby1: true,
      baby2: false,
      toddler: false,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Asthma',
      baby1: true,
      baby2: true,
      toddler: false,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Atopic March (all)',
      baby1: true,
      baby2: true,
      toddler: false,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Food Allergies',
      baby1: true,
      baby2: true,
      toddler: true,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Gestational Diabetes (GDM)',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: false,
    },
    {
      display: 'Pre-term birth (vaginal microbiome)',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: false,
    },
  ];
  const associationsAgesMap: ConditionsAgesRow[] = [
    {
      display: 'Colic',
      baby1: true,
      baby2: false,
      toddler: false,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: `Cow's milk protein allergy`,
      baby1: true,
      baby2: true,
      toddler: false,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Obesity later in life',
      baby1: true,
      baby2: true,
      toddler: false,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Sleep',
      baby1: true,
      baby2: true,
      toddler: true,
      child: true,
      ttc: false,
      adult: false,
    },
    {
      display: 'Type 1 diabetes',
      baby1: true,
      baby2: true,
      toddler: true,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Viral respiratory infections',
      baby1: true,
      baby2: false,
      toddler: false,
      child: false,
      ttc: false,
      adult: false,
    },
    {
      display: 'Constipation',
      baby1: false,
      baby2: true,
      toddler: true,
      child: true,
      ttc: true,
      adult: true,
    },
    {
      display: 'Irritable Bowel Syndrome (IBS)',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: true,
    },
    {
      display: 'Long COVID-19',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: true,
    },
    {
      display: 'Polycystic ovary syndrome (PCOS)',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: true,
    },
    {
      display: 'Preeclampsia',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: false,
    },
    {
      display: 'SIBO',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: true,
    },
    {
      display: 'Urinary Tract Infection',
      baby1: false,
      baby2: false,
      toddler: false,
      child: false,
      ttc: true,
      adult: true,
    },
  ];

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      close={
        <div onClick={toggle}>
          <Icon name='collapse' size='m' />
        </div>
      }
      fullscreen='lg'
      size='lg'
      scrollable
    >
      <ModalHeader toggle={toggle}>Biomarkers Availability</ModalHeader>
      <ModalBody>
        <div className={styles.modalBodyWrapper}>
          <p className={styles.secondaryText}>
            We have the ability to screen for certain biomarkers at different
            ages. We can also screen for certain microbiome associations based
            on emerging research. Here’s a full list of what we can measure
            currently, depending on age:
          </p>

          <div className={styles.table}>
            <div
              className={c(styles.tableHeader, styles.grid, styles.titleRow)}
            >
              <div className={styles.headerEmpty}>&nbsp;</div>
              <div className={styles.item}>
                <span>Baby</span>
                <span>(0-6m)</span>
              </div>
              <div className={styles.item}>
                <span>Baby</span>
                <span>(6-12m)</span>
              </div>
              <div className={styles.item}>
                <span>Toddler</span>
                <span>(1-3y)</span>
              </div>
              <div className={styles.item}>
                <span>Child</span>
                <span>(3-18y)</span>
              </div>
              <div className={styles.item}>
                <span>TTC /</span>
                <span>Preg.</span>
              </div>
              <div className={styles.item}>
                <span>Adult</span>
              </div>
            </div>

            <ConditionTableSection
              rows={biomarkersAgesMap}
              title='Emerging Research: Condition Biomarkers'
            />
            <ConditionTableSection
              rows={associationsAgesMap}
              title='Emerging Research: Condition Associations'
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
