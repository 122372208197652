import { useEffect, useState } from 'react';
import { getSubdomain } from 'tldts';

export const useSubdomain = () => {
  const [subdomain, setSubdomain] = useState<string | null>(null);
  const [loaded, setLoaded] = useState(false);
  const sub = getSubdomain(window.location.href, {
    validHosts: ['localhost', 'vercel.app'],
  });
  useEffect(() => {
    setSubdomain(sub);
    setLoaded(true);
  }, [sub]);
  return {
    isLoaded: loaded,
    subdomain: subdomain?.includes('-tiny-health') ? '' : subdomain,
    isPractitionerPortal: subdomain === 'practitioner',
  };
};
