import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { ProfileQuestionAnswer } from 'types/ProfileQuestions';

const postQuestionAnswers = async (
  answers: ProfileQuestionAnswer[],
  tinyAccountId: string,
  kitId?: string,
) => {
  if (!tinyAccountId) throw new Error('No account provided');
  if (!answers?.length) throw new Error('No answer provided');
  return API.post(
    apiName,
    `${apiBasePath}/tinyaccounts/${tinyAccountId}/profile-answers${
      kitId ? `?kit_id=${kitId}` : ''
    }`,
    {
      body: answers,
    },
  );
};

const useUpdateProfileAnswer = () => {
  const queryClient = useQueryClient();
  const profileAnswerMutation = useMutation({
    mutationFn: ({
      answers,
      tinyAccountId,
      kitId,
    }: {
      answers: ProfileQuestionAnswer[];
      tinyAccountId: string;
      kitId?: string;
    }) => {
      return postQuestionAnswers(answers, tinyAccountId, kitId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('profileQuestions'),
      });
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('profileSurveys'),
      });
    },
  });
  return profileAnswerMutation;
};

export default useUpdateProfileAnswer;
