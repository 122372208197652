import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { getMainAccountId } from 'store/account';
import { Subscription } from 'types/Subscription';

type SubscriptionType = 'tiny_plus' | 'practitioner' | 'kits';

export async function getSubscriptions(type?: SubscriptionType) {
  const params = type ? `?type=${type}` : '';
  const response = await API.get(
    apiName,
    `${apiBasePath}/subscriptions${params}`,
    {},
  );
  return response?.result;
}

const useSubscriptions = (type?: SubscriptionType) => {
  const mainAccountId = useSelector(getMainAccountId);

  const subscriptionsListQuery = useQuery<Subscription[]>({
    queryKey: [apiName, `subscriptionsList`, mainAccountId, type],
    queryFn: () => getSubscriptions(type),
  });

  return subscriptionsListQuery;
};

export default useSubscriptions;
