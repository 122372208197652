import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Auth } from 'api';

import { useAppDispatch } from 'store/store';
import { storeEmail } from 'store/user';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const sendForgotPasswordEmail = createAsyncThunk(
  'user/sendForgotPassword',
  async (params: { email: string }, { dispatch }) => {
    dispatch(storeEmail(params.email));
    await Auth.forgotPassword(params.email);
  },
);

const useForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const forgotPassword = useCallback(
    async (email: string) => {
      try {
        await dispatch(sendForgotPasswordEmail({ email })).unwrap();
        navigate('/update-password');
        toast('Email sent. Please check your inbox');
      } catch (err) {
        const error = err as Error;
        if (error?.name === 'InvalidParameterException') {
          dispatch(storeEmail(email));
          await Auth.resendSignUp(email);
          toast(
            'You must verify your email address to reset your password. A new verification email has been sent to the provided email address.',
          );
          navigate('/check-email');
        } else throw error;
      }
    },
    [dispatch, navigate],
  );
  return forgotPassword;
};
export default useForgotPassword;
