import { TinyAccountSelect } from './TinyAccountSelect';
import { KitSelect } from './KitSelect';

import styles from './SelectRow.module.scss';

export const SelectRow = () => (
  <div className={styles.selectRow}>
    <TinyAccountSelect />
    <KitSelect />
  </div>
);

export default SelectRow;
