import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { Subscription } from 'types/Subscription';

const patchSubscription = async (
  subscriptionId: number,
  body: Partial<Subscription>,
) => {
  try {
    await API.patch(apiName, `${apiBasePath}/subscriptions/${subscriptionId}`, {
      body,
    });
  } catch (error) {
    throw new Error('Your request could not be completed. Please try again');
  }
};

const useUpdateSubscription = () => {
  const queryClient = useQueryClient();
  const subscriptionMutation = useMutation({
    mutationFn: ({
      subscriptionId,
      body,
    }: {
      subscriptionId: number;
      body: Partial<Subscription>;
    }) => {
      return patchSubscription(subscriptionId, body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes('subscription') ||
          query.queryKey.includes('subscriptionsList'),
      });
    },
  });
  return subscriptionMutation;
};

export default useUpdateSubscription;
