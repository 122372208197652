import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { addWeeks, format } from 'date-fns';
import c from 'classnames';

import { kitResultsViewable, parseDate } from 'helpers';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { getHomePageTinyAccountId } from 'store/home';

import { Card, Typography } from 'components';

import styles from './ProcessingYourSample.module.scss';

export const ProcessingYourSample = () => {
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const { latestKit, getDisplayName } = useTinyAccountForHome(tinyAccountId);
  const estimated = latestKit?.date_delivered_to_lab
    ? addWeeks(parseDate(latestKit?.date_delivered_to_lab), 4)
    : null;
  const message = useMemo(() => {
    const processingTime = '3-4 weeks';
    if (latestKit?.date_sequences_delivered)
      return 'Our lab has finished processing your sample and your results will be ready soon.';
    if (latestKit?.date_delivered_to_lab)
      return `Your sample was delivered to our lab on ${format(
        parseDate(latestKit.date_delivered_to_lab),
        'MMMM d',
      )}. We estimate you will have your results by ${format(
        addWeeks(parseDate(latestKit.date_delivered_to_lab), 4),
        'MMMM d',
      )}.`;
    if (latestKit?.date_in_transit_to_lab)
      return `Your sample is en route to our lab. Once received by the lab, your sample will take ${processingTime} to process.`;
    return `Please mail your sample using the envelope provided. Once received by the lab, your sample will take ${processingTime} to process.`;
  }, [latestKit]);

  //Only show this card after surveys and until results are ready
  if (!latestKit?.date_survey_completed || kitResultsViewable(latestKit))
    return <></>;
  return (
    <Card variant='wrapper' className={c(styles.card, 'orange-border-left')}>
      <div className='flex align-items-center flex-column gap-2 text-center'>
        {!!estimated && (
          <>
            <Typography variant='label'>Estimated results</Typography>
            <Typography variant='heading-l' className='pb-4'>
              {format(estimated, 'MMMM d')}
            </Typography>
          </>
        )}
        {!!latestKit?.date_delivered_to_lab && (
          <Typography variant='heading-m'>
            {`We’re processing ${getDisplayName(true)} sample`}
          </Typography>
        )}
        <Typography variant='body-s' responsive serif>
          {message}
        </Typography>
      </div>
    </Card>
  );
};
