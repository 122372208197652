import { API } from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiBasePath, apiName } from '../../assets';

export type Redemption = {
  discount_code: string;
  amount: number;
};
type RedeemCreditResponse = {
  result: Redemption;
};

const redeemCredit = async () => {
  const response: RedeemCreditResponse = await API.post(
    apiName,
    `${apiBasePath}/discounts/redeem`,
    {
      body: {},
    },
  );

  return response.result;
};

const useRedeemCredit = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: redeemCredit,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('mainaccount'),
      });
    },
  });
  return mutation;
};
export default useRedeemCredit;
