import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { useSubdomain } from 'hooks/useSubdomain';
import { Spinfinity, ErrorDisplay } from 'components';

import styles from './ResponsiveLayout.module.scss';

const AuthLayout = () => {
  const { isLoaded, isPractitionerPortal } = useSubdomain();

  if (!isLoaded) return null;
  return (
    <div>
      <div className={styles.authAppWrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.authWrapper}>
            <div className={styles.authWrapperInner}>
              <ErrorBoundary fallback={<ErrorDisplay />}>
                <Suspense fallback={<Spinfinity />}>
                  <Outlet />
                </Suspense>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;
