import c from 'classnames';

import styles from './PageContainer.module.scss';

export enum ContainerSizes {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  AUTO = 'auto',
}

export const PageContainer = ({
  size = ContainerSizes.LG,
  className = '',
  children,
}: {
  size?: ContainerSizes;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className={c(styles.container, styles[size], className)}>
      {children}
    </div>
  );
};
