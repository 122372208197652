import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { addDays, isBefore } from 'date-fns';
import c from 'classnames';

import useConsults from 'services/general/consultsList';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { sortRecentToOldestConsult } from 'views/Consults/Consults';
import { getHomePageTinyAccountId } from 'store/home';

import { Card } from 'components';
import { Icon, Typography } from '@repo/ui';
import styles from './ConsultNoteReminder.module.scss';

export const ConsultNoteReminder = ({
  className = '',
}: {
  className: string;
}) => {
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const { latestKit } = useTinyAccountForHome(tinyAccountId);
  const { data: consultsList } = useConsults();

  const latestKitConsultWithNotePublished = useMemo(() => {
    const kitConsultsWithNotes =
      consultsList
        ?.filter(
          consult =>
            consult.status.includes('completed') &&
            consult.kit_id === latestKit?.id &&
            consult.consult_note?.status === 'published',
        )
        .sort(sortRecentToOldestConsult) ?? [];
    return kitConsultsWithNotes?.length ? kitConsultsWithNotes[0] : null;
  }, [consultsList, latestKit]);

  const agentName = latestKitConsultWithNotePublished?.consult_agent?.first_name
    ? `${latestKitConsultWithNotePublished.consult_agent.first_name} ${latestKitConsultWithNotePublished.consult_agent.last_name}`
    : 'our team';

  const noteMarkdown =
    latestKitConsultWithNotePublished?.consult_note?.edited_value ||
    latestKitConsultWithNotePublished?.consult_note?.original_value;

  const isOlderThan7days = useMemo(() => {
    if (!latestKitConsultWithNotePublished) return false;
    const sevenDaysAfterConsult = addDays(
      latestKitConsultWithNotePublished.scheduled_dt * 1000,
      7,
    );
    return isBefore(sevenDaysAfterConsult, new Date());
  }, [latestKitConsultWithNotePublished]);

  if (
    !consultsList ||
    !latestKitConsultWithNotePublished ||
    !noteMarkdown ||
    isOlderThan7days
  )
    return <></>;
  return (
    <Card
      variant='wrapper'
      clickable={{
        text: 'Read specialist’s notes',
        href: `/consults/${latestKitConsultWithNotePublished.id}`,
        variety: 'Card',
        isExternal: false,
      }}
      className={c(styles.card, 'blue-border-left', className)}
    >
      <div className='flex align-item-center justify-between gap-1'>
        <div className='flex flex-column gap-2'>
          <div className='flex align-items-center gap-1 blue-icon'>
            <Icon name='messageHearthSquare' size='m' />
            <Typography variant='heading-m'>
              {`Read specialist’s notes`}
            </Typography>
          </div>
          <Typography variant='label'>{`from ${agentName}`}</Typography>
        </div>
        <Icon name='chevronForward' size='l' />
      </div>
    </Card>
  );
};
