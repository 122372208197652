import React, { useEffect } from 'react';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { TrackEvent } from 'contexts/analytics/types';
import { useIdentify } from 'contexts/analytics/useIdentify';
import { usePage } from 'contexts/analytics/usePage';
import { useTrack } from 'contexts/analytics/useTrack';
type AnalyticsProviderProps = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({
  children,
  writeKey,
}: AnalyticsProviderProps) => {
  const analyticsBrowser = React.useMemo(
    () => (writeKey ? AnalyticsBrowser.load({ writeKey }) : null),
    [writeKey],
  );

  useIdentify(analyticsBrowser);
  const pageEvent = usePage(analyticsBrowser);
  const trackEvent = useTrack(analyticsBrowser);
  useEffect(() => {
    const links = document.querySelectorAll('a');
    const handleLinkClick = (e: MouseEvent) => {
      const a = (e.target as Element)?.closest('a') as HTMLAnchorElement;
      //check to see if the event was tracked already, this attribute can be added to components to skip double tracking
      const isTrackedAlready = a.getAttribute('data-tracking-source');
      if (!isTrackedAlready) {
        trackEvent('Clicked Link', {
          text: a.innerText,
          href: a.href,
          isExternal: a.target === '_blank',
        });
      }
    };
    links.forEach(a => {
      a.addEventListener('click', handleLinkClick);
    });
    return () => {
      links.forEach(a => {
        a.removeEventListener('click', handleLinkClick);
      });
    };
  }, [trackEvent]);
  return (
    <AnalyticsContext.Provider value={{ trackEvent, pageEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
type AnalyticsContextType = {
  trackEvent: TrackEvent;
  pageEvent: ReturnType<typeof usePage>;
};
export const AnalyticsContext = React.createContext<AnalyticsContextType>({
  trackEvent: () => {
    // do nothing by default
  },
  pageEvent: () => {
    // do nothing by default
  },
});

export const useAnalytics = () => React.useContext(AnalyticsContext);
