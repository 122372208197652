import { kitResultsViewable } from 'helpers/kitStatuses';
import { useSelector } from 'react-redux';
import { useParams, useMatch } from 'react-router-dom';
import { getTinyAccountById } from 'store/account';
import { getKitById } from 'store/kits';

export const useCurrentKitAndTinyAccount = () => {
  const { kitId } = useParams<{ kitId: string }>();
  // this is painful but I can't figure out a better way to do this
  // basically, we want to get the kitId from the url, but usePage for page analytics
  // happens outside of the route tree where that param exists, so we need to
  // useMatch on specific routes if kitId isn't available from useParams
  const kitIdFromResultsMatch = useMatch<'kitId', string>(
    '/results/:kitId/*',
  )?.params;
  const kitIdFromActionPlanMatch = useMatch<'kitId', string>(
    '/action-plan/:kitId/*',
  )?.params;
  const kitIdFromReviewMatch = useMatch<'kitId', string>(
    '/review/:kitId',
  )?.params;

  const currentKitId =
    kitId ||
    kitIdFromResultsMatch?.kitId ||
    kitIdFromActionPlanMatch?.kitId ||
    kitIdFromReviewMatch?.kitId;

  const currentKit = useSelector(getKitById(currentKitId));
  const currentTinyAccountId = currentKit?.tinyaccount_id;
  const currentTinyAccount = useSelector(
    getTinyAccountById(currentTinyAccountId),
  );

  return {
    currentKit,
    currentKitId: currentKitId,
    currentTinyAccount,
    currentTinyAccountId: currentTinyAccount?.id ? currentTinyAccount.id : null,
    currentKitType: currentKit?.sampling_type,
    currentKitStatus: currentKit?.status,
    currentKitIsBabySample:
      currentKit?.age_at_sampling && currentKit?.age_at_sampling < 365,
    currentKitHasResults: currentKit && kitResultsViewable(currentKit),
  };
};
export default useCurrentKitAndTinyAccount;
