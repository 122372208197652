import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Column, ColumnDef, Row } from '@tanstack/react-table';
import c from 'classnames';

import useKitRequests from 'services/practitioner/useKitRequests';
import useSendReminderToPatient from 'services/practitioner/useSendReminderToPatient';
import { capitalizeFirstLetter, parseDate } from 'helpers';
import { PractitionerKitRequest } from 'types/PractitionerUser';
import { getIsPractitionerUser } from 'store/user';

import { Table, Icon, Spinfinity, Typography, ButtonSizes } from '@repo/ui';
import GroupCircle from 'assets/images/group-circle.svg?react';
import { ButtonColors, DesignButton } from 'components';

import styles from './PreviousRequestsTable.module.scss';

const PreviousRequestsTable = () => {
  const [noRequestsOnFirstLoad, setNoRequestsOnFirstLoad] = useState(false);
  const firstLoad = useRef(true);
  const { data: kitRequests, isSuccess, isLoading } = useKitRequests();

  useEffect(() => {
    // on the first data load, if the user has no patients, show the null state
    if (isSuccess) {
      if (firstLoad.current && kitRequests?.length === 0) {
        setNoRequestsOnFirstLoad(true);
      }
      firstLoad.current = false;
    }
  }, [kitRequests, isSuccess]);

  const kitRequestsTableColumns = useMemo<
    ColumnDef<PractitionerKitRequest, string | undefined>[]
  >(
    () => [
      {
        header: 'Patient Name',
        accessorKey: 'full_name',
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
      },
      {
        header: 'Email',
        accessorKey: 'customer_email',
        enableSorting: false,
        enableColumnFilter: false,
        size: 175,
      },
      {
        header: 'Test Ordered',
        accessorKey: 'kit_display_title',
        enableSorting: true,
        enableColumnFilter: false,
        size: 100,
      },
      {
        header: 'Date Ordered',
        accessorKey: 'created_at',
        accessorFn: row => new Date(row.created_at ?? '').toLocaleDateString(),
        sortingFn: (
          rowA: Row<PractitionerKitRequest>,
          rowB: Row<PractitionerKitRequest>,
          columnId: string,
        ) => {
          return (
            (new Date(rowA.original.created_at ?? '').getTime() ?? 0) -
            (new Date(rowB.original.created_at ?? '').getTime() ?? 0)
          );
        },
        enableColumnFilter: false,
        size: 100,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: props => {
          const row = props.row.original;
          return <RequestStatusPill row={row} />;
        },
        enableSorting: true,
        filterFn: (row, id, filterValue) => {
          for (const key in filterValue) {
            if (filterValue[key] && key === row.original.status) {
              return true;
            }
          }
          return false;
        },
        meta: {
          FilterComponent: ({
            column,
          }: {
            column: Column<PractitionerKitRequest>;
          }) => {
            const filterValues = column.getFilterValue() as {
              [key: string]: boolean;
            };
            const handleFilterClick = (status: string) => {
              const newFilters = {
                ...filterValues,
                [status]: !filterValues?.[status],
              };
              column.setFilterValue(newFilters);
            };
            const noFilters = Object.values(filterValues ?? {}).every(
              value => !value,
            );
            if (noFilters) {
              column.setFilterValue({
                complete: true,
                incomplete: true,
              });
            }
            return (
              <div className={styles.statusFilter}>
                <Typography variant='label' className='ml-4 mt-3'>
                  Filter by{' '}
                </Typography>
                {['complete', 'incomplete'].map(status => (
                  <div
                    className={styles.statusFilterItem}
                    key={status}
                    onClick={() => handleFilterClick(status)}
                  >
                    <div
                      className={c(
                        'my-auto',
                        filterValues[status] ? styles.active : '',
                      )}
                    >
                      <Icon name='done' size='xs' />
                    </div>

                    <Typography variant='label' medium={filterValues?.[status]}>
                      {capitalizeFirstLetter(status)}
                    </Typography>
                  </div>
                ))}
              </div>
            );
          },
        },
        size: 100,
      },
      {
        accessorKey: 'id',
        header: 'Reminder',
        cell: props => {
          return <SendReminder row={props.row.original} />;
        },
        enableSorting: false,
        enableColumnFilter: false,
        size: 125,
      },
    ],
    [],
  );

  if ((firstLoad.current && isLoading) || kitRequests === undefined) {
    return <Spinfinity />;
  }
  return (
    <div className={styles.tableContainer}>
      <Table
        data={kitRequests}
        columns={kitRequestsTableColumns}
        isLoading={isLoading}
        itemsPerPage={15}
        hideSearch={noRequestsOnFirstLoad}
        NullStateComponent={
          noRequestsOnFirstLoad ? (
            <NullStateComponent />
          ) : (
            <td colSpan={100}>
              <div className='flex justify-center'>
                <Typography variant='body-m' className='mx-auto my-2'>
                  No kit requests found, try changing your search terms
                </Typography>
              </div>
            </td>
          )
        }
      />
    </div>
  );
};
export default PreviousRequestsTable;

const NullStateComponent = () => {
  return (
    <td colSpan={100}>
      <div className={styles.nullStateContainer}>
        <GroupCircle className='mx-auto' />
        <Typography variant='heading-l' className='mx-auto'>
          No kit requests yet
        </Typography>
        <Typography variant='label' className='mx-auto'>
          Once you order kits, you can view your requests here.
        </Typography>
      </div>
    </td>
  );
};

const SendReminder = ({ row }: { row: PractitionerKitRequest }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const sendReminder = useSendReminderToPatient();
  const isPractitioner = useSelector(getIsPractitionerUser);
  const {
    id: invoiceId,
    status,
    date_last_invoice_reminder_sent,
    date_completed_at,
  } = row;

  const handleReleaseToPatientClick = useCallback(async () => {
    setIsBlocked(true);
    setTimeout(() => {
      // Unblock the button after 5 seconds
      setIsBlocked(false);
    }, 5000);
    await sendReminder.mutateAsync(invoiceId);
  }, [invoiceId]);

  return status === 'complete' ? (
    date_completed_at ? (
      <Typography variant='label'>
        {`Paid by patient ${format(
          parseDate(date_completed_at),
          'MM/dd/yyyy',
        )}`}
      </Typography>
    ) : (
      <></>
    )
  ) : (
    <div className='flex flex-column'>
      <DesignButton
        label='Send reminder'
        iconNameLeft='mail'
        onClick={handleReleaseToPatientClick}
        size={ButtonSizes.S}
        color={ButtonColors.PURPLE}
        loading={isBlocked}
        disabled={!isPractitioner}
      />
      {!!date_last_invoice_reminder_sent && (
        <Typography variant='label'>
          {`Reminder sent ${format(
            parseDate(date_last_invoice_reminder_sent),
            'MM/dd/yyyy',
          )}`}
        </Typography>
      )}
    </div>
  );
};

const RequestStatusPill = ({ row }: { row: PractitionerKitRequest }) => {
  const color = row.status === 'complete' ? 'green' : 'red';
  return (
    <div>
      <div className={c(styles.statusPill, styles[color])}>
        <div className={c(styles.dot, styles[color])} />
        <Typography variant='label'>
          {capitalizeFirstLetter(row.status)}
        </Typography>
      </div>
    </div>
  );
};
