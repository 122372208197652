import {
  add,
  format,
  differenceInCalendarDays,
  differenceInHours,
} from 'date-fns';
import c from 'classnames';

import { CONSULT_ACTIVE_STATUS } from 'assets';
import { Consult } from 'types/Consult';
import { getAgentNameFromEmail } from '../Consults';

import {
  ButtonColors,
  ButtonVariants,
  Card,
  LinkButton,
  Modal,
} from 'components';
import { Icon, Typography } from '@repo/ui';
import styles from './ConsultCard.module.scss';

export const ConsultCard = ({ consult }: { consult: Consult }) => {
  const isActive = CONSULT_ACTIVE_STATUS.includes(consult.status);
  const inDays = differenceInCalendarDays(
    new Date(consult.scheduled_dt * 1000),
    new Date(),
  );
  const inHours = differenceInHours(
    new Date(consult.scheduled_dt * 1000),
    new Date(),
  );

  const agentNameFromEmail = getAgentNameFromEmail(consult.agent);
  const agentName = consult?.consult_agent?.first_name
    ? `${consult.consult_agent.first_name} ${consult.consult_agent.last_name}`
    : agentNameFromEmail
      ? agentNameFromEmail
      : 'our team';

  return (
    <Card variant='wrapper'>
      <div className={styles.consultBody}>
        <div
          className={c(styles.decoration, isActive && styles.isActive)}
        ></div>
        <div className=''>
          <div className='flex flex-column'>
            <div className='flex align-item-center justify-between gap-1'>
              <div className='flex align-item-center gap-1'>
                <Typography variant='label' className={styles.greyText}>
                  {format(consult.scheduled_dt * 1000, 'MM/dd/yy')}
                </Typography>
                <Typography variant='label' className={styles.greyText}>
                  {format(consult.scheduled_dt * 1000, 'hh:mm')}
                </Typography>
                <Typography variant='label' className={styles.greyText}>
                  {`- ${format(
                    add(consult.scheduled_dt * 1000, { minutes: 30 }),
                    'hh:mm a',
                  )}`}
                </Typography>
              </div>
              {isActive && inDays >= 0 && (
                <Typography variant='label' className={styles.greyText}>
                  {inDays === 0
                    ? 'Today'
                    : `In ${inDays} day${inDays === 1 ? '' : 's'}`}
                </Typography>
              )}
            </div>
            <Typography
              variant='body-s'
              medium
            >{`Coaching session with ${agentName}`}</Typography>
            {!isActive && consult.status === 'cancelled' && (
              <div className='flex align-items-center gap-1 mt-1'>
                <div className={styles.greyIcon}>
                  <Icon name='closeSm' size='xs' />
                </div>
                <Typography
                  variant='label'
                  className={c(styles.greyText, 'capitalize')}
                >
                  {consult.status}
                </Typography>
              </div>
            )}
          </div>
          {isActive &&
            (inHours < 48 ? (
              <Modal
                title='Cancellation policy'
                label={<span className={styles.link}>Cancellation policy</span>}
              >
                <Typography variant='body-s' className={styles.greyText}>
                  {`Consultations must be canceled or rescheduled at least 48 hours prior to the scheduled time. If you are unable to attend your consultation and do not reschedule or cancel, you will receive a phone call from our team at the scheduled time. We will leave a voicemail walkthrough of your results, addressing any questions you have submitted at the time of booking. Please note, this phone number is unable to receive incoming calls. If you have follow-up questions please review the consult note in your dashboard and feel free to email us with any additional questions.`}
                </Typography>
              </Modal>
            ) : (
              <div className={styles.consultActions}>
                {!!consult.calendly_reschedule_url && (
                  <LinkButton
                    label='Reschedule'
                    href={`/consults/book?rescheduling=${consult.calendly_id_edit}`}
                    width={'full'}
                    color={ButtonColors.LIGHT}
                  />
                )}
                {!!consult.calendly_cancel_url && (
                  <LinkButton
                    label='Cancel'
                    href={`/consults/book?cancellation=${consult.calendly_id_edit}`}
                    width={'full'}
                    color={ButtonColors.LIGHT}
                  />
                )}
              </div>
            ))}
          {consult.status !== 'cancelled' &&
            consult.consult_note?.status === 'published' && (
              <LinkButton
                label="See specialist's notes"
                iconNameLeft='file03'
                href={{ pathname: `/consults/${consult.id}` }}
                variant={ButtonVariants.TEXT}
                color={ButtonColors.PURPLE}
                className='mt-1'
              />
            )}
          {!isActive &&
            consult.status !== 'cancelled' &&
            consult.consult_note?.status !== 'published' &&
            inHours < 0 &&
            inHours > -360 && ( // between 15 days of consult
              <div className='flex align-items-center gap-1 mt-1'>
                <div className={styles.greyIcon}>
                  <Icon name='clock' size='xs' />
                </div>
                <Typography variant='label' className={c(styles.greyText)}>
                  {`We'll send you an email when notes are ready.`}
                </Typography>
              </div>
            )}
        </div>
      </div>
    </Card>
  );
};
