import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import Kit from 'types/Kit';

const patchKit = async (kit: Partial<Kit>) => {
  if (!kit.id) throw new Error('No Kit Id provided');
  if (!kit.tinyaccount_id) throw new Error('No tiny account Id for kit');

  await API.patch(
    apiName,
    `${apiBasePath}/kits/${kit.id}/tinyaccount/${kit.tinyaccount_id}`,
    {
      body: {
        ...kit,
      },
    },
  );
};

const useUpdateKit = () => {
  const queryClient = useQueryClient();
  const kitMutation = useMutation({
    mutationFn: (newKit: Partial<Kit>) => {
      return patchKit(newKit);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['kits'],
        refetchType: 'all',
      });
    },
  });
  return kitMutation;
};

export default useUpdateKit;
