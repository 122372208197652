import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import c from 'classnames';

import useKitFeedback from 'services/general/useKitFeedback';
import useUpdateFeedback from 'services/general/useUpdateFeedback';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { Feedback, FeedbackPageType } from 'types/Feedback';

import { Icon } from '@repo/ui';

import { getHasModalOpened, setModalOpened } from 'store/modal';
import FeedbackModal from 'components/FeedbackWidget/FeedbackModal';
import styles from './FeedbackWidget.module.scss';
import { Spinfinity } from 'components';

type FeedbackProps = {
  kitId: string;
  pageType: FeedbackPageType;
  pageId: number;
};

export const FeedbackWidget = ({ kitId, pageType, pageId }: FeedbackProps) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const feedbackModal = {
    name: 'feedback',
    props: { kitId, pageType, pageId },
  };
  const modalOpened = useSelector(getHasModalOpened(feedbackModal));
  const [showFeedbackActive, setShowFeedbackActive] = useState<boolean | null>(
    null,
  );
  const [feedbackDirection, setFeedbackDirection] = useState<
    'up' | 'down' | null
  >(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { data: feedbackList, isPending } = useKitFeedback(kitId, pageType);

  const toggleFeedbackModal = () => {
    if (!modalOpened) {
      dispatch(setModalOpened(feedbackModal));
    }
    setShowFeedbackModal(wasOpen => !wasOpen);
  };

  useEffect(() => {
    if (feedbackList?.length) {
      const current = feedbackList.find(
        (feedback: Feedback) =>
          feedback.page_id === pageId && feedback.page_type === pageType,
      );
      if (!current) {
        setShowFeedbackActive(null);
        setFeedbackDirection(null);
        return;
      }
      const answer =
        current.answer === null ? null : current.answer ? 'up' : 'down';
      if (answer) {
        setShowFeedbackActive(true);
        setFeedbackDirection(answer);
      } else {
        setFeedbackDirection(null);
        setShowFeedbackActive(null);
      }
    }
  }, [feedbackList, pageId, pageType]);

  const updateFeedbackMutation = useUpdateFeedback();
  const clickFeedback = async (thumbs: string) => {
    const previousFeedback = feedbackDirection;
    const previousActive = showFeedbackActive;
    //we only update when there is no feedback given or when we change from one to the other
    const isChanging = thumbs !== feedbackDirection;
    if (showFeedbackActive === null || isChanging) {
      try {
        //toggle visually optimistically since the network request has a small delay
        setShowFeedbackActive(true);
        setFeedbackDirection(thumbs as 'up' | 'down');
        if (!modalOpened && thumbs === 'down') {
          toggleFeedbackModal();
        } else {
          toast.success('Thanks for your feedback');
        }
        await updateFeedbackMutation.mutateAsync({
          kit_id: kitId,
          page_type: pageType,
          page_id: pageId,
          answer: thumbs === 'up' ? true : false,
        });
        trackEvent('Clicked Feedback', {
          text: `Thumbs ${thumbs}`,
        });
      } catch (error) {
        //revert the optimistic update
        setShowFeedbackActive(previousActive);
        setFeedbackDirection(previousFeedback);
        toast.error('Your request could not be completed. Please try again');
      }
    }
  };
  if (isPending) return <Spinfinity />;
  return (
    <>
      <FeedbackModal
        toggle={toggleFeedbackModal}
        showModal={showFeedbackModal}
        feedback={{
          kit_id: kitId,
          page_type: pageType,
          page_id: pageId,
          answer: false,
        }}
      />
      <div className={c(styles.card, styles.border)}>
        <div className={styles.text}>Was this helpful?</div>
        <div className={styles.buttonGroup}>
          <div
            className={c(
              styles.button,
              styles.border,
              styles.up,
              showFeedbackActive && feedbackDirection === 'up' && styles.active,
            )}
            data-testid='feedback-up'
            onClick={() => clickFeedback('up')}
          >
            <Icon
              name={
                showFeedbackActive && feedbackDirection === 'up'
                  ? 'thumbsUpFilled'
                  : 'thumbsUp'
              }
              size='s'
            />
          </div>
          <div
            className={c(
              styles.button,
              styles.border,
              styles.down,
              showFeedbackActive &&
                feedbackDirection === 'down' &&
                styles.active,
            )}
            data-testid='feedback-down'
            onClick={() => clickFeedback('down')}
          >
            <Icon
              name={
                showFeedbackActive && feedbackDirection === 'down'
                  ? 'thumbsDownFilled'
                  : 'thumbsDown'
              }
              size='s'
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedbackWidget;
