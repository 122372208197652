import React, { Dispatch, SetStateAction, useState } from 'react';

import { Modal as ModalComponent } from 'components';
import { ModalProps } from 'components/Modal/Modal';

const useModal = (): {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  Modal: React.FC<ModalProps>;
} => {
  const [isOpen, setIsOpen] = useState(false);
  const Modal = React.memo<ModalProps>(({ children, ...props }) => (
    <ModalComponent {...props} controlledIsOpen={isOpen}>
      {children}
    </ModalComponent>
  ));
  Modal.displayName = 'MemoizedModal';

  return {
    Modal,
    isOpen,
    setIsOpen,
  };
};

export default useModal;
