import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useTinyAccounts from 'services/account/useTinyAccounts';
import TinyAccount from 'types/TinyAccount';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  ContainerSizes,
  LinkButton,
  PageContainer,
  Typography,
} from 'components';
import { MyFamily } from 'views/Kits/MyFamily';

import styles from './FamilyPage.module.scss';

const FamilyPage = () => {
  const navigate = useNavigate();
  const { tinyAccounts } = useTinyAccounts();

  const tinyAccountSelected = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        navigate(`/`, { state: { tinyAccountId: tinyAccount.id } });
      }
    },
    [navigate],
  );

  return (
    <PageContainer size={ContainerSizes.LG} className={styles.pageContainer}>
      <div className={styles.backContainer}>
        <LinkButton
          label='Back to Home'
          iconNameLeft='chevronBack'
          href={{
            pathname: `/`,
          }}
          variant={ButtonVariants.TEXT}
          size={ButtonSizes.M}
          color={ButtonColors.PURPLE}
        />
      </div>
      <div className={styles.informationContainer}>
        <Typography variant={'heading-xl'}>Add a family member</Typography>
        <MyFamily
          type='column'
          tinyAccounts={tinyAccounts}
          onSelection={tinyAccountSelected}
        />
      </div>
    </PageContainer>
  );
};

export default FamilyPage;
