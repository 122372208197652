import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

export const getGutTypeResponse = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/gutType/${kitId}`,
    {},
  );
  const flat_response =
    !response?.result?.length || !response?.result[0]?.gut_vaginal_type
      ? null
      : response.result[0];
  return flat_response;
};
export const getGutTypeQuery = (kitId?: string | null) => ({
  queryKey: [apiName, 'gutType', kitId],
  queryFn: () => kitId && getGutTypeResponse(kitId),
});
const useGutType = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const gutTypeQuery = useSuspenseQuery(getGutTypeQuery(currentKitId));
  const gutVaginalType =
    gutTypeQuery.data?.gut_vaginal_type?.display_name ?? null;
  return { ...gutTypeQuery, gutType: gutVaginalType, gutVaginalType };
};

export default useGutType;
