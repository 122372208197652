import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { format, isBefore, subMonths } from 'date-fns';

import { parseDate } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import usePersonalizedNote from 'services/general/personalizedNote';
import { FeedbackPageType } from 'types/Feedback';

import {
  PageHeader,
  PageContainer,
  Typography,
  FeedbackWidget,
  Markdown,
  Spinfinity,
} from 'components';

import styles from './Review.module.scss';

export const ReviewRoutes = () => {
  return (
    <Routes>
      <Route path=':kitId' element={<Review />} />
    </Routes>
  );
};

export const Review = () => {
  const { currentKit, currentKitId, currentTinyAccount } =
    useCurrentKitAndTinyAccount();
  const { data } = usePersonalizedNote();
  const withResultsOlderThanAMonthAgo = currentKit?.date_results_ready
    ? isBefore(
        parseDate(currentKit.date_results_ready),
        subMonths(new Date(), 1),
      )
    : false;
  // markdown from DB
  const personalizedNoteMarkdown = data?.original_value;
  if (!currentKitId) return <></>;
  return (
    <PageContainer className={styles.pageContainer}>
      <PageHeader
        title={`${currentTinyAccount?.first_name}'s results`}
        linkBack={`/results/${currentKitId}`}
      />
      <div className={styles.pageWrapper}>
        <div className={styles.titleWrapper}>
          <Typography variant={'label'} medium>
            Results overview
          </Typography>
          <Typography variant={'heading-2xl'}>
            An expert summary on where to start
          </Typography>
          {!!currentKit?.date_expert_review_complete && (
            <Typography variant={'label'} medium className={styles.greyText}>
              This note was published on{' '}
              {format(
                parseDate(currentKit.date_expert_review_complete),
                'MM/dd/yy',
              )}
            </Typography>
          )}
        </div>
        {withResultsOlderThanAMonthAgo && (
          <Typography variant={'label'}>
            This overview has been updated from a previous version. If you’d
            like to see your original summary or have any questions, please
            reach out to us at{' '}
            <span className='underline'>hello@tinyhealth.com</span>
          </Typography>
        )}
        <div className='my-3'>
          <FeedbackWidget
            kitId={currentKitId}
            pageType={FeedbackPageType.REVIEW}
            pageId={0}
          />
        </div>
        <Suspense fallback={<Spinfinity />}>
          {personalizedNoteMarkdown ? (
            <Markdown>{personalizedNoteMarkdown}</Markdown>
          ) : (
            <p className='mb-0'>
              {`Our microbiome experts are preparing a review of your results that will highlight our main recommendations based on your results and survey answers. We will send you another email when your personal note is ready.`}
              <br />
              <br />
              {`Please do not wait for this to start reviewing your results and action plan - this is just an extra bonus to help you interpret your report.`}
            </p>
          )}
        </Suspense>
      </div>
    </PageContainer>
  );
};
