const allowOneDecimalPlace = (value: number) => {
  return value.toFixed(1).replace(/\.0$/, '');
};

export const stringifyDaysOld = (
  daysOld: number,
  exact = false,
  useOld = true,
) => {
  const dayInYears = 365;
  const daysInMonth = 30.44; // Average days in a month
  const months = daysOld / daysInMonth;
  if (daysOld >= 2 * dayInYears) {
    // beyond two years, we use years only
    return `${exact ? allowOneDecimalPlace(daysOld / dayInYears) : Math.floor(daysOld / dayInYears)} yr${useOld ? ' old' : ''}`;
  } else if (months >= 1) {
    // for years two to five, we use years and months (1 decimal place)
    return `${exact ? allowOneDecimalPlace(months) : Math.floor(daysOld / daysInMonth)} mo${useOld ? ' old' : ''}`;
  } else {
    // then we just use weeks
    return `${exact ? allowOneDecimalPlace(daysOld / 7) : Math.floor(daysOld / 7)} wk${useOld ? ' old' : ''}`;
  }
};
