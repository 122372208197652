import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import useConditions from 'services/conditions/useConditions';
import { Condition, ConditionType } from 'types/Conditions';

import { ContainerSizes, PageContainer } from 'components';
import { useHeaderIllustration } from 'contexts/HeaderIllustrationContext';
import { ConditionDetailView } from './ConditionDetailView';
import { ConditionsSummaryView } from './ConditionsSummaryView';
import ConditionsSVG from 'assets/images/conditions.svg';

import './Conditions.module.scss';

export const ConditionsRoutes = () => {
  return (
    <Routes>
      <Route path={``} key='conditions' element={<Conditions />} />
      <Route
        path={`:condition`}
        key='conditions-detail'
        element={<ConditionDetailView />}
      />
    </Routes>
  );
};

export const Conditions = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  // get the conditions for this kit
  const { data: kitData } = useConditions({
    kitId: currentKitId,
  });
  const { setHeaderIllustration } = useHeaderIllustration();
  useEffect(() => {
    if (setHeaderIllustration) {
      setHeaderIllustration({
        url: ConditionsSVG,
        bgColor: '#E6F3FA',
      });
    }
    return () => {
      setHeaderIllustration?.({ url: null, bgColor: null });
    };
  }, [setHeaderIllustration]);

  if (!currentKitId) return <></>;
  return (
    <PageContainer size={ContainerSizes.MD}>
      <ConditionsSummaryView
        type='Biomarkers'
        conditions={kitData?.filter(
          (condition: Condition) => condition.type === ConditionType.BIOMARKER,
        )}
      />
      <ConditionsSummaryView
        type='Associations'
        conditions={kitData?.filter(
          (condition: Condition) =>
            condition.type === ConditionType.ASSOCIATION,
        )}
      />
    </PageContainer>
  );
};
