import { Suspense, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import useAllBookmarks from 'services/actionPlan/useAllBookmarks';
import useActionPlanData from 'services/actionPlan/actionPlanData';
import useItemActions from 'services/actionPlan/useItemActions';
import {
  colorForTinyAccount,
  getItemLinkLabelForType,
  parseDate,
} from 'helpers';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import { getLatestKits } from 'store/kits';
import {
  ActionPlanItem,
  ActionPlanRecommendationItem,
  BookmarkedItem,
} from 'types/ActionPlan';
import TinyAccount from 'types/TinyAccount';
import Kit from 'types/Kit';

import {
  PageContainer,
  ContainerSizes,
  ImageFallback,
  LinkButton,
} from 'components';
import { Icon, Spinfinity } from '@repo/ui';
import imgPlaceholder from 'assets/images/placeholders/lightbulb.svg';

import styles from './BookmarkItemDetail.module.scss';
import { BackButton } from 'components/BackButton/BackButton';

const getAllActionsForThisItem = (
  allActionsOfTheItem?: ActionPlanRecommendationItem,
) => {
  if (!allActionsOfTheItem?.recommendations) return [];
  const actionIds: number[] = [];
  allActionsOfTheItem?.recommendations.forEach(rec => {
    rec.action_plan_recommendations?.forEach(apr => {
      actionIds.push(apr.id as number);
    });
  });
  return actionIds;
};
const filterActions = (
  actions: ActionPlanItem[],
  allActionIdsOfThisKit: number[],
) => {
  return actions.filter(action =>
    allActionIdsOfThisKit.includes(action.id as number),
  );
};

export const BookmarkItemDetail = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const { data: bookmarks } = useAllBookmarks();

  const selectedItem = useMemo(() => {
    if (!itemId || !bookmarks?.length) return null;
    const bookmark = bookmarks.find(
      (bookmark: BookmarkedItem) =>
        bookmark.recommendation_item_id === parseInt(itemId),
    );
    return bookmark
      ? { ...bookmark.recommendation_item, id: bookmark.recommendation_item_id }
      : null;
  }, [bookmarks, itemId]);

  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  const latestKits = useSelector(getLatestKits());

  if (!selectedItem) return <></>;
  return (
    <PageContainer size={ContainerSizes.MD} className={styles.pageContainer}>
      <NavLink to={`/bookmarks`}>
        <BackButton variant='link' />
      </NavLink>
      <Suspense fallback={<Spinfinity fullScreen />}>
        <div className={styles.headerContainer}>
          <div className={styles.flexRow}>
            <div className={styles.body}>
              {!!selectedItem.title && (
                <div className={styles.itemTitle}>{selectedItem.title}</div>
              )}
              {!!selectedItem.description && (
                <div className={c(styles.itemDescription, styles.mobileMb12)}>
                  {selectedItem.description}
                </div>
              )}
            </div>
            <div className={c(styles.imageLarge, styles.noShrink)}>
              {selectedItem.photo_url && (
                <img src={selectedItem.photo_url} alt={selectedItem.title} />
              )}
            </div>
          </div>
          <div className={styles.itemExtraInfo}>
            {!!selectedItem.time && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='clock' size='s' />
                <span>{selectedItem.time}</span>
              </div>
            )}
            {!!selectedItem.info && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='infoCircle' size='s' />
                <span>{selectedItem.info}</span>
              </div>
            )}
            {!!selectedItem.discount_code && (
              <div
                className={c(styles.itemExtraDescription, styles.flexRowCenter)}
              >
                <Icon name='sale' size='s' />
                <span>{selectedItem.discount_code}</span>
              </div>
            )}
          </div>
          {!!selectedItem.link && (
            <LinkButton
              href={selectedItem.link}
              external
              width='full'
              color='purple'
              iconNameRight='trendUp02'
              className={styles.cta}
            >
              {getItemLinkLabelForType(selectedItem.type)}
            </LinkButton>
          )}
        </div>
        <div className={styles.sectionsContainer}>
          {orderedTinyAccounts.map(ta => {
            const accountKits = latestKits.filter(
              kit => kit.tinyaccount_id === ta.id,
            );
            return !accountKits?.length ? null : (
              <AccountSection
                key={ta.id}
                tinyAccount={ta}
                item={selectedItem}
                kits={accountKits}
              />
            );
          })}
        </div>
      </Suspense>
    </PageContainer>
  );
};

const AccountSection = ({
  tinyAccount,
  kits,
  item,
}: {
  tinyAccount: TinyAccount;
  kits: Kit[];
  item: BookmarkedItem;
}) => {
  const [hasActionsToDisplay, setHasActionsToDisplay] = useState<boolean>(true);
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  if (!tinyAccount || !hasActionsToDisplay) return <></>;
  return (
    <section className={styles.section}>
      <div className={styles.accountContainer}>
        <div
          className={c(styles.avatarBubble)}
          style={{
            backgroundColor: colorForTinyAccount(
              orderedTinyAccounts,
              tinyAccount.id,
            ),
          }}
        >
          {tinyAccount.first_name.substr(0, 1)}
        </div>
        <span className={styles.name}>{tinyAccount.first_name}</span>
      </div>
      <div className={styles.sectionCard}>
        {kits.map(kit => (
          <KitActions
            kit={kit}
            item={item}
            key={kit.id + '_' + item.id}
            setHasActionsToDisplay={setHasActionsToDisplay}
          />
        ))}
      </div>
    </section>
  );
};

const KitActions = ({
  kit,
  item,
  setHasActionsToDisplay,
}: {
  kit: Kit;
  item: BookmarkedItem;
  setHasActionsToDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { pathname } = useLocation();
  //this are the actions available for this kit
  const { data: actions } = useActionPlanData({ kitId: kit.id });
  //this are the actions related to this item
  const { data: itemActions } = useItemActions(item.id);
  const itemAllActionIds = useMemo(
    () => getAllActionsForThisItem(itemActions),
    [itemActions],
  );
  //the actions we need to display here
  const matchingActions = useMemo(
    () => actions && filterActions(actions, itemAllActionIds),
    [actions, itemAllActionIds],
  );

  useEffect(() => {
    setHasActionsToDisplay(
      (prev: boolean) => prev && !!matchingActions?.length,
    );
  }, [matchingActions, setHasActionsToDisplay]);

  if (!kit || !item || !matchingActions) return <></>;
  return (
    <div>
      {matchingActions.map(action => (
        <NavLink
          to={`/action-plan/${kit.id}/action/${action.id}`}
          state={{ from: pathname }}
          className={styles.actionContainer}
          key={action.id}
        >
          <div className={styles.content}>
            <div
              className={styles.illustrationWrapper}
              style={{
                backgroundColor: action.cover_url?.color ?? 'transparent',
              }}
            >
              <ImageFallback
                src={action?.cover_url?.url ?? ''}
                fallback={imgPlaceholder}
              />
            </div>

            <div className={styles.text}>
              <div className={styles.title}>{action.display_title}</div>
              <div className={styles.kit}>{`${
                kit.sampling_type === 'stool' ? 'Gut' : 'Vag'
              } test: ${format(parseDate(kit.sample_date), 'MM/dd/yy')}`}</div>
            </div>
          </div>
          <div className={styles.action}>
            <Icon name='chevronForward' size='m' />
          </div>
        </NavLink>
      ))}
    </div>
  );
};
