import styles from './GutHealthCompositionBar.module.scss';

type CompositionBarProps = {
  name: string;
  height: number;
};

export const GutHealthCompositionBar = ({
  name,
  height,
}: CompositionBarProps) => {
  return (
    <div className={styles.gutCompositionBarWrapper}>
      <div> {height}%</div>
      {/* 0 height should have 1 px barheight */}
      <div
        className={styles.gutComposition}
        style={{ height: `${height === 0 ? '1' : height}px` }}
      />
      <div> {name}</div>
    </div>
  );
};
