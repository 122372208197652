import { useSelector } from 'react-redux';

import { Spinfinity, OrderKitButton } from 'components';
import useSeeding from 'services/seeding/seedingResponses';
import { getKitsForTinyAccountId } from 'store/kits';
import { getBirthMotherForTinyAccountId } from 'store/account';

import { SeedingCard } from './SeedingCard';

import styles from './Seeding.module.scss';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

export const SeedingSample = () => {
  const { currentKit, currentKitId, currentTinyAccount, currentTinyAccountId } =
    useCurrentKitAndTinyAccount();

  const birthMother = useSelector(
    getBirthMotherForTinyAccountId(currentTinyAccountId),
  );
  const birthMotherKits = useSelector(
    getKitsForTinyAccountId(birthMother?.id ?? ''),
  );

  const { data, isLoading, isError } = useSeeding({ kitId: currentKitId });

  const firstName = currentTinyAccount?.first_name;
  const birthMotherFirstName = birthMother?.first_name;

  if (isError) throw new Error();

  if (!birthMother || !birthMotherKits.length) {
    return (
      <div className={styles.backgroundBlur}>
        <div
          className={`flex flex-column justify-content-center align-items-center ${styles.bgContainer}`}
        >
          <div className={styles.bgImage} />
          <p className={styles.overlayText}>
            Submit a gut sample for both mom and baby within the first 6 months
            to see what seeded.
          </p>

          {(currentKit?.ttc || currentKit?.pregnant) && (
            <OrderKitButton classNames={styles.overlayText}>
              Order Your Tiny&nbsp;Gut&nbsp;Kits
            </OrderKitButton>
          )}

          <div className={styles.blurImageContainer} />
        </div>
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinfinity />
      ) : (
        <SeedingCard
          seedingData={data?.result || []}
          birthMotherFirstName={birthMotherFirstName}
          babyFirstName={firstName}
        />
      )}
    </>
  );
};
