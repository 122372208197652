import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsActivePractitioner, getIsAdminUser } from 'store/user';

const PractitionerRoute = () => {
  const isActivePractitioner = useSelector(getIsActivePractitioner);
  const isAdmin = useSelector(getIsAdminUser);
  const location = useLocation();
  if (isActivePractitioner || isAdmin) return <Outlet />;
  if (location?.state?.from) {
    return <Navigate to={location.state.from} replace />;
  } else {
    return <Navigate to='/results' replace />;
  }
};
export default PractitionerRoute;
