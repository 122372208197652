import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { ProfileQuestionsGroup } from 'types/ProfileQuestions';
import { getKitById } from 'store/kits';

export async function getProfileQuestions(
  tinyAccountId: string,
  kitId?: string,
) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/tinyaccounts/${tinyAccountId}/profile-questions?is_sample=0&is_resample=0${
      kitId ? `&kit_id=${kitId}` : ''
    }`,
    {},
  );
  return response?.result ?? [];
}

const useProfileQuestions = (tinyAccountId?: string | null, kitId?: string) => {
  const kit = useSelector(getKitById(kitId));
  const profileQuestionsQuery = useSuspenseQuery<
    ProfileQuestionsGroup[] | null
  >({
    queryKey: [apiName, `profileQuestions`, tinyAccountId, kitId],
    queryFn: () => (tinyAccountId ? getProfileQuestions(tinyAccountId) : null),
  });

  return {
    ...profileQuestionsQuery,
    needsProfile: useMemo(() => {
      const isResample = !!kit?.is_resample;
      return !profileQuestionsQuery.data?.every(questionGroup => {
        return questionGroup.questions.every(question =>
          isResample ? question.kit_id === kitId : !!question.answer_text,
        );
      });
    }, [profileQuestionsQuery.data, kitId, kit]),
  };
};

export default useProfileQuestions;
