import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { useSubdomain } from 'hooks/useSubdomain';
import { Spinfinity, ErrorDisplay } from 'components';

import styles from './ResponsiveLayout.module.scss';

const AuthCleanLayout = () => {
  const { isLoaded, isPractitionerPortal } = useSubdomain();

  if (!isLoaded) return null;
  return (
    <div className={styles.cleanLayout}>
      <ErrorBoundary fallback={<ErrorDisplay />}>
        <Suspense fallback={<Spinfinity />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
export default AuthCleanLayout;
