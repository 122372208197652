import { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';

type ProValidationBody = {
  patient_state: string;
};

export const getProValidation = async (body: ProValidationBody) => {
  const response = await API.post(
    apiName,
    `${apiBasePath}/practitioners/pro-validation`,
    body,
  );
  return response;
};

const useProValidation = (
  setAllowProOrdering: Dispatch<SetStateAction<boolean>>,
  showValidationErrorModal: Dispatch<SetStateAction<boolean | undefined>>,
) => {
  const [fetching, setFetching] = useState(false);

  const mutation = useMutation({
    mutationFn: (body: ProValidationBody) => {
      showValidationErrorModal(undefined);
      setFetching(true);
      setAllowProOrdering(false);
      return getProValidation(body);
    },
    onSuccess: async (data, variables, context) => {
      toast.success('You are able to order the kit');
      showValidationErrorModal(false);
      setAllowProOrdering(true);
    },
    onError: (err, variables, context) => {
      const axios_error = err as AxiosError;
      if (axios_error.response?.status === 422) {
        showValidationErrorModal(true);
      } else {
        toast.error('Something went wrong, please try again');
      }
    },
    onSettled: () => {
      setFetching(false);
    },
  });

  return {
    mutate: mutation.mutate,
    isLoading: fetching,
  };
};

export default useProValidation;
