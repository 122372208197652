import { Icon, IconName } from '@repo/ui';

import styles from './EvidenceRating.module.scss';

export enum EvidenceType {
  STRONG = 'Strong',
  GOOD = 'Good',
  EMERGING = 'Emerging',
  LIMITED = 'Limited',
}

const getIconNameForEvidence = (evidence: string): IconName => {
  switch (evidence) {
    case EvidenceType.STRONG:
      return 'barsFull';
    case EvidenceType.GOOD:
      return 'barsMid';
    case EvidenceType.EMERGING:
      return 'barsLow';
    case EvidenceType.LIMITED:
      return 'barsEmpty';
    default:
      return 'barsEmpty';
  }
};

export const EvidenceRating = ({
  evidenceRating,
  title,
}: {
  evidenceRating: string;
  title?: string;
}) => {
  return (
    <>
      {title && <h5 className={styles.detailHeading}>{title}</h5>}
      <div className={styles.flexRow}>
        <Icon name={getIconNameForEvidence(evidenceRating)} size='s' />
        <span>{evidenceRating + ' evidence'}</span>
      </div>
    </>
  );
};
