import { ContainerSizes, PageContainer, Typography } from 'components';
import NothingSVG from 'assets/images/placeholders/emptyAP.svg?react';
import { useLocation } from 'react-router-dom';

const NoActionsAvailable = () => {
  const location = useLocation();
  const {
    state: { from },
  } = location;

  return (
    <PageContainer
      size={ContainerSizes.SM}
      className='flex flex-column gap-2 text-center'
    >
      <div className='mt-4'>
        <NothingSVG />
      </div>
      <Typography variant={'heading-l'}>Nothing to see yet</Typography>
      <Typography variant={'body-s'} className='grey-text'>
        You’ll get your personalized Action Plan when your lab results are ready
      </Typography>
    </PageContainer>
  );
};

export default NoActionsAvailable;
