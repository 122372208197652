import { useSelector } from 'react-redux';

import { getIsAnyTinyPlus, getIsTinyPlus } from 'store/account';
import { LINKS } from 'assets/constants';
import { Button, LinkButton } from 'components';
import { ReactNode } from 'react';

export const OrderKitButton = ({
  classNames,
  children,
}: {
  classNames?: string;
  children?: ReactNode;
}) => {
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const isTinyPlus = useSelector(getIsTinyPlus);
  return !isAnyTinyPlus ? (
    <div
      className={`flex flex-column justify-center align-items-center p-2 ${classNames} dont-print`}
    >
      <LinkButton
        href={`${LINKS.checkoutWithKitNonTinyPlus}&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=ap-cta`}
        className='m-0 button'
        external={true}
      >
        {children ? children : `Order Kit`}
      </LinkButton>
    </div>
  ) : (
    <div
      className={`flex flex-column justify-center align-items-center p-2 ${classNames} dont-print`}
    >
      <LinkButton
        href={`${
          isTinyPlus
            ? LINKS.checkoutWithKitTinyPlus
            : LINKS.checkoutWithKitTinyPlusProgramOrProactive
        }&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=ap-cta`}
        className='m-0 button'
        external={true}
      >
        {children ? children : `Order Kit`}
      </LinkButton>
    </div>
  );
};
