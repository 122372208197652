import { Suspense } from 'react';
import c from 'classnames';

import { userValueFormat } from 'helpers';
import { InsightMetric } from 'types/Insights';

import { TaxaOverTimeLineChart, Card, Markdown } from 'components';
import { Icon, IconName, Typography, Spinfinity } from '@repo/ui';
import { RangesGraph } from '../RangesGraph/RangesGraph';

import styles from './SubMetricGraphs.module.scss';

const evaluationPillProps: evaluationPillPropsType = {
  green: {
    icon: 'checkVerifiedFilled',
    label: 'Great results',
  },
  grey: {
    icon: 'checkFilled',
    label: 'Normal results',
  },
  orange: {
    icon: 'alertCircleFilled',
    label: 'Needs Improving',
  },
  red: {
    icon: 'alertTriangleFilled',
    label: 'Needs support',
  },
};
type evaluationPillPropsType = {
  [key: string]: {
    icon: IconName;
    label: string;
  };
};
export const EvaluationPillFromColor = ({
  evaluation_color,
  showIconOnly = false,
}: {
  evaluation_color: string;
  showIconOnly?: boolean;
}) => {
  const defaultEvaluationPillProps = {
    icon: 'infoCircleFilled' as IconName,
    label: 'Other results',
  };
  const { icon, label } =
    evaluationPillProps[evaluation_color] || defaultEvaluationPillProps;
  return (
    <div className='flex'>
      {showIconOnly ? (
        <div className={c(styles.icon, styles[evaluation_color])}>
          <Icon name={icon} />
        </div>
      ) : (
        <div className={c(styles.pill, styles[evaluation_color])}>
          <div className={c(styles.icon, styles[evaluation_color])}>
            <Icon name={icon} />
          </div>
          <div className='flex'>
            <Typography variant={'label'} className='m-auto'>
              {label}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

const SubMetricGraphs = ({ metric }: { metric: InsightMetric }) => {
  if (!metric) return <></>;
  return (
    <div className='flex flex-column gap-3'>
      <Card>
        <Typography variant={'heading-s'} className='mb-3'>
          Result
        </Typography>
        <div className={styles.resultWrapper}>
          <Typography variant={'heading-2xl'} className=''>
            {userValueFormat(
              metric.user_value,
              metric.data_type,
              metric.user_string,
            )}
          </Typography>
          <div className={styles.pillWrapper}>
            <EvaluationPillFromColor
              evaluation_color={metric.evaluation_color}
            />
          </div>
        </div>
      </Card>

      <Card>
        <Typography variant={'heading-s'} className='mb-3'>
          Ranges
        </Typography>
        {metric?.detected_text && (
          <Typography variant='heading-m'>
            <Markdown className='default-font'>{metric.detected_text}</Markdown>
          </Typography>
        )}
        {metric.data_type !== 'string' && !!metric.range_bar_display && (
          <div className={styles.rangesGraphWrapper}>
            <RangesGraph
              dataType={metric.data_type}
              relativeAbundance={metric.user_value}
              ranges={metric.gut_vaginal_health_kit_ranges}
              scaleBarMin={metric.scale_bar_min}
              scaleBarMax={metric.scale_bar_max}
            />
          </div>
        )}
      </Card>
      {metric.data_type !== 'string' && !!metric.graph_display && (
        <Card>
          <Typography variant={'heading-s'} className='mb-3'>
            Over time
          </Typography>
          <Suspense fallback={<Spinfinity />}>
            <div className={styles.lineGraphContainer}>
              <TaxaOverTimeLineChart
                taxa={[metric.sample_health_id]}
                range={metric.health_metrics_barscale_percentage}
                dataType={metric.data_type}
                animate
              />
            </div>
          </Suspense>
        </Card>
      )}
    </div>
  );
};
export default SubMetricGraphs;
