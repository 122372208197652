import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import c from 'classnames';

import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';

import { Button, ButtonColors, ButtonSizes, ButtonVariants } from '@repo/ui';

import type {
  ButtonProps,
  ButtonColor,
  ButtonVariant,
  ButtonSize,
} from '@repo/ui';

export {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  ButtonColor,
  ButtonVariant,
  ButtonSize,
};
interface ButtonWithTrackingProps extends ButtonProps {
  hrefTracking?: string;
  isExternal?: boolean;
}
export const ButtonWithTracking = ({
  hrefTracking,
  isExternalTracking,
  ...props
}: ButtonWithTrackingProps) => {
  const { trackEvent } = useAnalytics();
  const handleClick = useCallback(async () => {
    //click handler from props
    if (props.onClick) {
      await props.onClick();
    }
    //tracking of the clicked event
    if (!props.children || typeof props.children === 'string') {
      trackEvent('Clicked Button', {
        text: props.label ?? props.children?.toString() ?? '',
        href: hrefTracking ?? '',
        isExternal: isExternalTracking ?? false,
        variety: hrefTracking ? 'LinkButton' : 'Button',
      });
    }
  }, [props, trackEvent, hrefTracking, isExternalTracking]);
  return <Button {...props} onClick={async () => await handleClick()} />;
};
interface LinkButtonProps extends ButtonProps {
  href: string | { pathname: string; search?: string };
  state?: { from?: string; tinyAccountId?: string | null };
  external?: boolean;
  linkClassName?: string;
}
export const LinkButton = ({
  href,
  state,
  external,
  fullWidth = false, //deprecated
  linkClassName,
  ...props
}: LinkButtonProps) => {
  return external && typeof href === 'string' ? (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      //flag to know that it is already tracked and avoid double tracking in the general link tracking from AnalyticsProvider
      data-tracking-source='LinkButton'
      className={linkClassName}
    >
      <ButtonWithTracking
        {...props}
        hrefTracking={href}
        isExternalTracking={external}
      />
    </a>
  ) : (
    <NavLink
      to={href}
      state={state}
      className={c(fullWidth && 'w-100', linkClassName)}
    >
      <ButtonWithTracking {...props} />
    </NavLink>
  );
};
export default ButtonWithTracking;
