import { API } from 'api';

import { apiBasePath, apiName } from 'assets/constants';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

const getAllPDFsUrl = async () => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/pdf-report/download-all`,
    {},
  );
  return response?.result;
};
export const useDownloadAllPDFs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadAllPDFs = useCallback(async () => {
    setIsLoading(true);
    try {
      const signedUrl = await getAllPDFsUrl(); // Ensure this returns the axios response

      const anchor = document.createElement('a');
      anchor.href = signedUrl;
      anchor.download = 'tiny_health_reports.zip';
      anchor.click();
    } catch (error) {
      toast.error('Something went wrong, please try again or contact support');
      console.error('Error downloading PDFs:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  return { downloadAllPDFs, isLoading };
};
