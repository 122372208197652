import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { revertSurveyCompleted, setSurveyCompleted } from 'store/kits';

export const postSurveySubmitted = async ({
  tinyaccount_id,
  kit_id,
  form_id,
}: {
  tinyaccount_id: string;
  kit_id?: string;
  form_id: string;
}) => {
  const response = await API.post(
    apiName,
    `${apiBasePath}/tinyAccounts/${tinyaccount_id}/typeform_submit`,
    {
      form_id,
      kit_id: kit_id ?? null,
    },
  );
  return response;
};
const useSubmitSurvey = () => {
  const dispatch = useDispatch();
  const trackEvent = useAnalytics().trackEvent;

  const submitSurvey = async (properties: {
    form_id: string;
    tinyaccount_id: string;
    kit_id?: string;
  }) => {
    try {
      //optimistic update
      dispatch(setSurveyCompleted(properties));
      await postSurveySubmitted(properties);
      toast.success('Survey Completed');
      trackEvent('Survey Completed', properties);
      return true;
    } catch (error) {
      //revert optimistic update
      dispatch(revertSurveyCompleted(properties));
      toast.error(
        'There was an error submitting the survey. Please try again.',
      );
      Sentry.captureException(error);
    }
  };
  return submitSurvey;
};
export default useSubmitSurvey;
