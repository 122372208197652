import { getColors } from 'contexts';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const pieChartConfig = ({ animate = true }) => ({
  type: 'pie',
  aspectRatio: 1.3,
  options: {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    ...(animate ? {} : { animations: false }),
  },
});

export const pieChartConfigSeeding = () => ({
  type: 'pie',
  aspectRatio: 1,
  plugins: [ChartDataLabels],
  options: {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: getColors().black,
        align: 'end',
        offset: -5,
        formatter: function (value, context) {
          return context.chart.data.labels[context.dataIndex];
        },
        labels: {
          title: {
            font: {
              size: 16,
              weight: 600,
            },
          },
        },
      },
    },
  },
});
