import c from 'classnames';
import styles from './MicrobesBg.module.scss';
import TopLeftMicrobe from '../../assets/images/topLeft_microbe.svg?react';
import LeftCenterMicrobe from '../../assets/images/leftCenter_microbe.svg?react';
import BottomLeftMicrobe from '../../assets/images/bottomLeft_microbe.svg?react';
import TopRightMicrobe from '../../assets/images/topRight_microbe.svg?react';
import RightCenterMicrobe from '../../assets/images/rightCenter_microbe.svg?react';
import BottomRightMicrobe from '../../assets/images/bottomRight_microbe.svg?react';

export const MicrobesBg = ({ whiteBackground = false }) => {
  return (
    <div
      className={c(styles.container, whiteBackground && styles.bgTransparent)}
    >
      <TopLeftMicrobe className={c(styles.microbe, styles.topLeft)} />
      <LeftCenterMicrobe className={c(styles.microbe, styles.leftCenter)} />
      <BottomLeftMicrobe className={c(styles.microbe, styles.bottomLeft)} />
      <TopRightMicrobe className={c(styles.microbe, styles.topRight)} />
      <RightCenterMicrobe className={c(styles.microbe, styles.rightCenter)} />
      <BottomRightMicrobe className={c(styles.microbe, styles.bottomRight)} />
    </div>
  );
};
