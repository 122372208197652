import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { useSubdomain } from 'hooks/useSubdomain';
import { getIsAuthenticated, getRoleCanImpersonate } from 'store/user';

import AuthCleanLayout from 'components/Layout/AuthCleanLayout';

const NoAuthRouteCleanLayout = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const roleCanImpersonate = useSelector(getRoleCanImpersonate);
  const { isPractitionerPortal, isLoaded } = useSubdomain();
  const location = useLocation();
  const from = location.state?.from;
  if (!isLoaded) return null;
  if (isAuthenticated) {
    if (from) {
      return <Navigate to={from} replace />;
    } else if (roleCanImpersonate && isPractitionerPortal) {
      return <Navigate to='/portal' replace />;
    } else {
      return <Navigate to='/results' replace />;
    }
  } else {
    return <AuthCleanLayout />;
  }
};
export default NoAuthRouteCleanLayout;
