import { KitType } from './Kit';
import Guide from './Guide';

export enum ConditionAssociation {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}
export enum ConditionType {
  BIOMARKER = 'biomarker',
  ASSOCIATION = 'association',
}
export enum ConditionEvaluationMeaning {
  FLAG = 'flag',
  BORDERLINE = 'borderline',
  NEUTRAL = 'neutral',
  CLEAR = 'clear',
}

export type Condition = {
  sampling_type: KitType; //extra from relation table
  about: string;
  age_high: number;
  age_low: number;
  checkpoints: string;
  condition: string;
  condition_id: number;
  conditions_evaluation_id: number;
  conditions_kits_id: number;
  created_at: string;
  decreased_risk: string;
  display_title: string;
  empty_state: string;
  evaluation: string;
  evaluation_meaning: ConditionEvaluationMeaning;
  evaluation_text: string;
  evidence_rating: string | null;
  faq: string;
  id: number;
  increased_risk: string;
  kit_id: string;
  negative_score: number;
  other_factors: string;
  positive_score: number;
  published: boolean;
  references: string;
  sampling_state: string;
  score: number;
  score_high: number;
  score_low: number;
  type: ConditionType;
  updated_at: string;
  week_of_pregnancy_high: number;
  week_of_pregnancy_low: number;
  guides: Guide[];
};
