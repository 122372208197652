import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import useGutType from 'services/gutType/useGutType';

import { Spinfinity, Typography, TextColor } from '@repo/ui';
import { DiveDeeperSummaryCard } from 'views/Results/Summary/SummaryCards/DiveDeeperSummaryCard/DiveDeeperSummaryCard';

import styles from './GutTypeSummaryCard.module.scss';

export const GutTypeSummaryCard = () => {
  const { pathname } = useLocation();
  const { gutType } = useGutType();

  return (
    <DiveDeeperSummaryCard
      title='Your gut type'
      description='Think of it as your gut archetype - what makes up the majority of your gut.'
      link={`${pathname}/gut-type`}
      linkText='Learn more'
      className={styles.gutTypeSummaryCard}
    >
      <Suspense fallback={<Spinfinity />}>
        <Typography
          variant='heading-xl'
          color={TextColor.PURPLE}
          className='my-auto'
        >
          Type {gutType}
        </Typography>
      </Suspense>
    </DiveDeeperSummaryCard>
  );
};
