import { Route, Navigate, Routes } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { ErrorDisplay } from 'components';

import Summary from './Summary/Summary';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { InsightsRoutes } from 'views/Results/Insights';
import { MicrobiomeRoutes } from 'views/Results/Microbiome/Microbiome';
import { GutTypeRoutes } from 'views/Results/GutType';
import { CstRoutes } from 'views/Results/VaginalHealth/VaginalHealth';
import { ConditionsRoutes } from 'views/Results/Conditions/Conditions';
import { getShouldDisplaySeeding } from 'helpers';
import { SeedingRoutes } from 'views/Results/Seeding';

export const ResultsReadyRoutes = () => {
  const { currentKit, currentKitId, currentKitType } =
    useCurrentKitAndTinyAccount();
  if (!currentKitId) {
    return <Navigate to='/results' replace />;
  }
  return (
    <ErrorBoundary fallback={<ErrorDisplay />}>
      <Routes>
        <Route path={``} element={<Summary />} />
        <Route path='insights/*' element={<InsightsRoutes />} />
        <Route path='microbiome' element={<MicrobiomeRoutes />} />
        {currentKitType === 'vaginal' ? (
          <Route path='cst' element={<CstRoutes />} />
        ) : (
          <Route path='gut-type' element={<GutTypeRoutes />} />
        )}
        <Route path='conditions/*' element={<ConditionsRoutes />} />
        {currentKit && getShouldDisplaySeeding(currentKit) ? (
          <Route path='seeding' element={<SeedingRoutes />} />
        ) : null}
      </Routes>
    </ErrorBoundary>
  );
};
