import c from 'classnames';
import { Typography } from '@repo/ui';

import styles from './Badge.module.scss';

export const Badge = ({ title, state }) => {
  return (
    <div
      className={c(
        styles.wrapper,
        state === 'good'
          ? styles.good
          : state === 'ok'
            ? styles.ok
            : state === 'bad'
              ? styles.bad
              : state === 'default'
                ? styles.default
                : styles.default,
      )}
    >
      <Typography variant='label'>{title}</Typography>
    </div>
  );
};
