export function capitalize(str: string) {
  return str
    .split(' ')
    .map(st => st.substring(0, 1).toUpperCase() + st.substring(1, st.length))
    .join(' ');
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const precisionRound = (
  value: number,
  decimals = 0,
  shouldRoundZero = true,
) => {
  if (!value) return shouldRoundZero ? '0' : parseInt('0').toFixed(decimals);
  return value.toFixed(decimals);
};

export const formatNumberToDisplayPercentage = (value: number) => {
  //examples: '0%', '<1%', '1%', '23%', ...
  if (!value) return '0%';
  if (value > 0 && value < 1) return '<1%';
  return precisionRound(value) + '%';
};
