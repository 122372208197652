import { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import useActionPlanData from 'services/actionPlan/actionPlanData';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { useHeaderIllustration } from 'contexts/HeaderIllustrationContext';
import { ActionPlanItem } from 'types/ActionPlan';

import { ContainerSizes, PageContainer, Spinfinity } from 'components';
import { GutHealth } from './GutHealth';
import { BabyGutInfo } from './BabyGutInfo';
import { ActionPlanGroup } from '../../ActionPlan/ActionPlanGroup/ActionPlanGroup';
import GutTypeSVG from 'assets/images/gut-type.svg';

import styles from './GutHealth.module.scss';

export const GutTypeRoutes = () => {
  return (
    <Routes>
      <Route path={``} key={'gut-type'} element={<GutType />} />
    </Routes>
  );
};

const filterOutcomesBySection = (data: ActionPlanItem, section = '') => {
  const cardData = {
    ...data,
    display_title: data.display_title,
    detail: data.detail,
    desired_outcomes: section
      ? data.desired_outcomes?.filter(outcome => outcome.section === section)
      : data.desired_outcomes,
    id: data.id,
  };
  return cardData;
};

export const GutType = () => {
  const { currentKitId, currentKitIsBabySample } =
    useCurrentKitAndTinyAccount();
  const { data: actionPlanFromDBResults } = useActionPlanData({
    kitId: currentKitId,
  });

  const actionPlanItemsFiltered = !actionPlanFromDBResults?.length
    ? []
    : actionPlanFromDBResults
        .filter(
          (item: ActionPlanItem) =>
            !!item.desired_outcomes?.length &&
            item.desired_outcomes
              .map(outcome => outcome.section)
              .includes('Baby Gut Type'),
        )
        .map((item: ActionPlanItem) =>
          filterOutcomesBySection(item, 'Baby Gut Type'),
        );
  const { setHeaderIllustration } = useHeaderIllustration();

  useEffect(() => {
    if (setHeaderIllustration) {
      setHeaderIllustration({
        url: GutTypeSVG,
        bgColor: null,
      });
    }
    return () => {
      setHeaderIllustration?.({ url: null, bgColor: null });
    };
  }, [setHeaderIllustration]);

  return (
    <PageContainer size={ContainerSizes.MD}>
      <section id='gut_type' className={styles.gutTypeContainer}>
        <>
          <div className={styles.gutHealthSectionsContainer}>
            <Suspense fallback={<Spinfinity />}>
              <GutHealth />
              {currentKitIsBabySample && <BabyGutInfo />}
            </Suspense>
            <ActionPlanGroup actionPlanItems={actionPlanItemsFiltered} />
          </div>
        </>
      </section>
    </PageContainer>
  );
};
