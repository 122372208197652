import { LinkButton } from 'components';
import KitSvg from 'assets/images/kit_illustration.svg?react';

import styles from './NoKitRegistered.module.scss';

export const NoKitRegistered = ({ withCTA = true }) => {
  return (
    <div className={styles.noKitsContainer}>
      <KitSvg />
      <h2>You don&apos;t have any active kits yet</h2>
      {withCTA && (
        <>
          <p>
            Tap the button below to activate your first microbiome test kit and
            access your results.
          </p>
          <LinkButton href={'/kits/activate'}>Activate your kit</LinkButton>
        </>
      )}
    </div>
  );
};
