import { useMemo, useState } from 'react';

import { Condition } from 'types/Conditions';

import { Card, CardSlider, SectionPlaceholder, TextLink } from 'components';
import { ConditionInfoModal } from './ConditionInfoModal';
import { ConditionCard } from './ConditionCard';

import styles from './Conditions.module.scss';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

type ConditionsListProps = {
  type: string;
  conditions: Condition[];
};

const ConditionAssociationCard = ({ condition }: { condition: Condition }) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  return (
    <Card
      key={condition.id}
      title={<span className={styles.title}>{condition.display_title}</span>}
    >
      <p className={styles.associationText}>{condition.evaluation}</p>
      <div className={styles.details}>
        <TextLink
          label='View details'
          iconNameRight='chevronForward'
          to={`/results/${currentKitId}/conditions/${encodeURIComponent(
            condition.condition,
          )}`}
        />
      </div>
    </Card>
  );
};

const conditionsOrder = {
  flag: 0,
  borderline: 1,
  clear: 2,
  neutral: 3,
};

export const sortConditionsByEvaluation = (conditions: Condition[]) => {
  if (!conditions || !conditions.length) return null;
  return conditions.sort((a, b) => {
    return (
      conditionsOrder[a.evaluation_meaning ?? a] -
      conditionsOrder[b.evaluation_meaning ?? b]
    );
  });
};
export const ConditionsList = ({ type, conditions }: ConditionsListProps) => {
  const [showModal, setShowModal] = useState(false);
  const currentKit = useCurrentKitAndTinyAccount().currentKit;
  const toggleConditionInfoModal = () => {
    setShowModal(!showModal);
  };
  const conditionsInSample = conditions.filter(
    condition => condition.kit_id !== null,
  );
  const cardsToShow = useMemo(() => {
    return type === 'Biomarkers'
      ? sortConditionsByEvaluation(conditionsInSample)?.map(
          (condition: Condition) => (
            <ConditionCard
              kit={currentKit}
              condition={condition}
              title={condition.display_title}
              key={condition.id}
              withDetails
              withFooter
            />
          ),
        )
      : conditionsInSample.map(condition => (
          <ConditionAssociationCard key={condition.id} condition={condition} />
        ));
  }, [type, conditionsInSample, currentKit]);

  return (
    <>
      <ConditionInfoModal
        showModal={showModal}
        toggle={toggleConditionInfoModal}
      />
      {!conditionsInSample?.length ? (
        <SectionPlaceholder title={`No ${type?.toLowerCase()} available`}>
          <div className={styles.sectionPlaceholder}>
            <span>{`This sample doesn't have any Conditions ${type} available. `}</span>
            <span
              className={styles.greyLink}
              onClick={() => {
                toggleConditionInfoModal();
              }}
            >
              Learn more.
            </span>
          </div>
        </SectionPlaceholder>
      ) : (
        <>
          <div className={styles.conditionsGridListContainer}>
            {cardsToShow}
          </div>
          <div className={styles.conditionsSliderContainer}>
            <CardSlider cards={cardsToShow} />
          </div>
        </>
      )}
    </>
  );
};
