import { useSelector } from 'react-redux';

import useCheckoutKit from 'hooks/useCheckoutKit';
import { getShouldHideConsults, getIsAnyTinyPlus } from 'store/account';
import { LINKS } from 'assets/constants';
import { getKitById } from 'store/kits';
import { IconName } from '@repo/ui';

type SidebarItemConfig = {
  to: string;
  icon: IconName;
  label: string;
  onClick?: () => void;
  state?: {
    tinyAccountId?: string | null;
    kitId?: string | null;
    from?: string | null;
  };
  external?: boolean;
  bottom?: boolean;
  hasKitId?: boolean;
};
const MENU_ITEMS: {
  [key: string]: SidebarItemConfig;
} = {
  home: {
    to: '/',
    icon: 'home',
    label: 'Home',
  },
  results: {
    to: '/results/:kitId',
    icon: 'lightOn',
    label: 'Results',
  },
  actionPlan: {
    to: '/action-plan/:kitId',
    icon: 'today',
    label: 'Action plan',
  },
  kits: {
    to: '/kits',
    icon: 'add',
    label: 'Kits',
  },
  bookmarks: {
    to: '/bookmarks',
    icon: 'bookmark',
    label: 'Bookmarks',
  },
  resources: {
    to: '/guides',
    icon: 'book',
    label: 'Resources',
  },
  guides: {
    to: '/guides',
    icon: 'book',
    label: 'Guides',
  },
  store: {
    to: LINKS.store,
    icon: 'shoppingCart',
    label: 'Store',
    external: true,
  },
  consults: {
    to: '/consults',
    icon: 'messageHearthSquare',
    label: 'Coaching',
  },
  help: {
    to: '/help',
    icon: 'help',
    label: 'Need Help?',
  },
  referAFriend: {
    to: '/refer-a-friend',
    icon: 'gift',
    label: 'Invite a friend',
  },
  facebook: {
    to: LINKS.facebookGroup,
    icon: 'userMultiple',
    label: 'Join our Facebook community',
    external: true,
  },
  account: {
    to: '/account',
    icon: 'userSingle',
    label: 'Account',
  },
};

const getHomePageSidebarItems = (
  kitId: string | null,
  tinyAccountId: string | null,
  storeSidebarItem: SidebarItemConfig,
  hideConsults: boolean,
): SidebarItemConfig[] => {
  const items = [
    MENU_ITEMS.home,
    MENU_ITEMS.results,
    MENU_ITEMS.actionPlan,
    MENU_ITEMS.resources,
    ...(hideConsults ? [] : [MENU_ITEMS.consults]),
    { ...MENU_ITEMS.account, bottom: true },
    { ...MENU_ITEMS.bookmarks, bottom: true },
    { ...storeSidebarItem, bottom: true },
    { ...MENU_ITEMS.help, bottom: true },
    { ...MENU_ITEMS.referAFriend, bottom: true },
  ];
  return items.map(item => ({
    ...item,
    to: item.to.replace(':kitId', kitId ?? ''),
    state: { tinyAccountId, ...item.state },
  }));
};
const getMobileSidebarItems = (
  storeSidebarItem: SidebarItemConfig,
): SidebarItemConfig[] => {
  const items = [
    MENU_ITEMS.account,
    MENU_ITEMS.guides,
    storeSidebarItem,
    MENU_ITEMS.bookmarks,
    MENU_ITEMS.referAFriend,
    MENU_ITEMS.facebook,
    MENU_ITEMS.help,
  ];
  return items;
};
export const useSidebarItems = ({
  kitId,
  isMobile = false,
}: {
  kitId?: string;
  isMobile?: boolean;
}) => {
  const kit = useSelector(getKitById(kitId));
  const shouldHideConsults = useSelector(getShouldHideConsults);
  const tinyAccountId = kit?.tinyaccount_id;
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const { checkoutUrl } = useCheckoutKit(kit?.sampling_type);
  const storeSidebarItem: SidebarItemConfig = {
    to: checkoutUrl,
    icon: 'shoppingCart',
    label: isAnyTinyPlus ? 'Tiny+ store' : 'Store',
    external: true,
    bottom: true,
  };
  if (isMobile) {
    return getMobileSidebarItems(storeSidebarItem);
  }

  return getHomePageSidebarItems(
    kitId ?? null,
    tinyAccountId ?? null,
    storeSidebarItem,
    shouldHideConsults,
  );
};
