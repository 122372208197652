import { useLocation } from 'react-router-dom';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { ProfileQuestion, ProfileQuestionsGroup } from 'types/ProfileQuestions';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
  Typography,
} from 'components';

import styles from './ProfileConfirmationGroup.module.scss';

export const ProfileConfirmationGroup = ({
  questionGroup,
  viewOnly,
}: {
  questionGroup: ProfileQuestionsGroup;
  viewOnly?: boolean;
}) => {
  const { pathname } = useLocation();
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const sectionTitle = questionGroup.title
    ? questionGroup.title
    : questionGroup.questions.length === 1
      ? questionGroup.questions[0].question_text
      : 'Another question';
  return (
    <div className='flex flex-column gap-3'>
      <div className='flex align-items-center justify-content-between gap-2 w-100'>
        <div>
          <Typography variant='body-m'>{sectionTitle}</Typography>
        </div>
        <div>
          {!viewOnly && (
            <LinkButton
              label='Edit'
              iconNameRight='edit01'
              href={`/profile/${currentKitId}/confirm/${questionGroup.id}`}
              state={{ from: pathname }}
              variant={ButtonVariants.TEXT}
              size={ButtonSizes.S}
              color={ButtonColors.PURPLE}
            >
              Edit
            </LinkButton>
          )}
        </div>
      </div>
      {questionGroup.questions.map(question => {
        const hideQuestionText =
          !questionGroup.title && questionGroup.questions.length === 1;
        return (
          <div className='flex flex-column gap-2' key={question.id}>
            {question.answer_type === 'array' &&
            question.answer_options?.length > 0 ? (
              <ConfirmQuestionCheckbox
                question={question}
                hideQuestionText={hideQuestionText}
              />
            ) : (
              <ConfirmQuestionText
                question={question}
                hideQuestionText={hideQuestionText}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const ConfirmQuestionText = ({
  question,
  hideQuestionText,
}: {
  question: ProfileQuestion;
  hideQuestionText: boolean;
}) => {
  return (
    <div className='flex flex-column gap-1' key={question.id}>
      {!hideQuestionText && (
        <Typography variant='body-s' className='dark-grey-text'>
          {question.question_text}
        </Typography>
      )}
      <Typography variant='body-s' serif className={styles.answer}>
        {question.answer_text}
      </Typography>
    </div>
  );
};

const ConfirmQuestionCheckbox = ({
  question,
  hideQuestionText,
}: {
  question: ProfileQuestion;
  hideQuestionText: boolean;
}) => {
  return (
    <div>
      <div className='flex flex-column gap-1 dark-grey-text' key={question.id}>
        {!hideQuestionText && (
          <Typography variant='body-s'>{question.question_text}</Typography>
        )}
        {typeof question.answer_options === 'string'
          ? question.answer_options
          : question.answer_options.map(option => (
              <label key={option}>
                <input
                  type='checkbox'
                  className='mr-1'
                  value={option}
                  checked={question.answer_text?.includes(option)}
                  disabled
                />
                <Typography variant='body-s'>{option}</Typography>
              </label>
            ))}
      </div>
    </div>
  );
};
