import c from 'classnames';
import { Typography } from '@repo/ui';
import { useSystemInfo } from 'services/system-info/useSystemInfo';
import styles from './VersionPill.module.scss';

const removeVPrefix = (version: string) => {
  return version.replace(/^v/, '');
};
const removeLeadingZeros = (version: string) => {
  //removes leading zeroes but keeps the last zero if it is the only digit
  return version.replace(/^0+(?=\d)/, '');
};

const VersionPill = ({ version }: { version: string | null }) => {
  const { latestSciVersion } = useSystemInfo();
  const displayVersion = version
    ? removeVPrefix(version)
        .split('.')
        .map(segment => removeLeadingZeros(segment))
        .join('.')
    : latestSciVersion;
  const isUpdating = version === null;
  return (
    <div className={styles.versionPill}>
      <div className={c(styles.dot, isUpdating && styles.isUpdating)} />
      <Typography variant='label' medium className={styles.versionText}>
        {isUpdating
          ? `Updating to V ${latestSciVersion}`
          : `V ${displayVersion}`}
      </Typography>
    </div>
  );
};
export default VersionPill;
