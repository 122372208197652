import { SetStateAction, useCallback, useMemo } from 'react';
import { KitType } from 'types/Kit';
import { TaxaFilter } from './TaxaFilter';

import styles from './TaxaFilters.module.scss';
import { ButtonColor } from 'components';

export type TaxaFilterType = {
  [KitType.STOOL]: {
    title: string;
    help: string;
  };
  [KitType.VAGINAL]: {
    title: string;
    help: string;
  };
  active: boolean;
  color: ButtonColor;
};
export const taxaCategoryFilters: TaxaFilterType[] = [
  {
    [KitType.STOOL]: {
      title: 'Beneficial',
      help: 'These support good health and the growth of other friendly microbes.',
    },
    [KitType.VAGINAL]: {
      title: 'Protective',
      help: 'These bacteria support good health and play defense against disruptive microbes.',
    },
    active: true,
    color: 'green',
  },
  {
    [KitType.STOOL]: {
      title: 'Variable',
      help: 'These can be beneficial or unfriendly. This depends on how many there are and what else is around them.',
    },
    [KitType.VAGINAL]: {
      title: 'Variable',
      help: 'These can be protective or disruptive. This depends how many there are and what else is around them.',
    },
    active: true,
    color: 'orange',
  },
  {
    [KitType.STOOL]: {
      title: 'Unfriendly',
      help: 'We expect to see a few of these at low levels, but high levels for extended periods of time can cause issues.',
    },
    [KitType.VAGINAL]: {
      title: 'Disruptive',
      help: 'These microbes can cause issues. Some are linked with symptoms and disease states.',
    },
    active: true,
    color: 'red',
  },
  {
    [KitType.STOOL]: {
      title: 'Unknown',
      help: 'These bacteria are less common and have been classified down to the genome, but may not yet be well studied by the scientific community.',
    },
    [KitType.VAGINAL]: {
      title: 'Unknown',
      help: 'These bacteria are less common and have been classified down to the genome, but some may not yet be well studied by the scientific community.',
    },
    active: true,
    color: 'grey',
  },
];

export const TaxaFilters = ({
  kitType = KitType.STOOL,
  taxaFilters,
  setFilters,
}: {
  kitType?: KitType;
  taxaFilters: TaxaFilterType[];
  setFilters: React.Dispatch<SetStateAction<TaxaFilterType[]>>;
}) => {
  const activeFilters = useMemo(
    () => taxaFilters.filter(taxaFilter => taxaFilter.active),
    [taxaFilters],
  );
  const activeFilterHelpText =
    activeFilters?.length === 1 && activeFilters[0]
      ? activeFilters[0][kitType].help
      : '';
  const handleFilterClick = useCallback(
    (index: number) => {
      const thisFilter = taxaFilters[index];
      if (!thisFilter) return;
      const isOnlyActiveFilterClick =
        activeFilters.length === 1 && thisFilter.active;
      const allFiltersActive = activeFilters.length === taxaFilters.length;
      const newFilters = taxaFilters.map((taxaFilter, i) => {
        if (i === index) {
          return {
            ...taxaFilter,
            active:
              isOnlyActiveFilterClick || allFiltersActive
                ? true
                : !taxaFilter.active,
          };
        } else if (isOnlyActiveFilterClick) {
          return {
            ...taxaFilter,
            active: true,
          };
        } else if (allFiltersActive) {
          return {
            ...taxaFilter,
            active: false,
          };
        } else {
          return taxaFilter;
        }
      });
      setFilters(newFilters);
    },
    [activeFilters.length, setFilters, taxaFilters],
  );
  return (
    <fieldset className='mb-4'>
      <legend>
        <h6 className='mb-1'>Filter for:</h6>
      </legend>
      <div className={styles.filterButtonRow}>
        {taxaFilters.map((taxaFilter, index) => (
          <TaxaFilter
            key={index}
            active={taxaFilter.active}
            color={taxaFilter.color}
            title={taxaFilter[kitType].title}
            onClick={() => handleFilterClick(index)}
          />
        ))}
      </div>
      {activeFilterHelpText && (
        <div className={styles.helpText}>{activeFilterHelpText}</div>
      )}
    </fieldset>
  );
};
