import { useState } from 'react';
import c from 'classnames';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import styles from './Popover.module.scss';

export const PopHover = ({
  target = 'bollard',
  title = '',
  category = '',
  placement = 'top',
  className,
  children,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);
  const onHover = () => {
    setPopoverOpen(true);
  };
  const onHoverLeave = () => {
    setPopoverOpen(false);
  };
  return (
    <div className={className}>
      <div
        id={`popover-${target}`}
        className={styles.hoverTrigger}
        onMouseEnter={onHover}
        onClick={toggle}
        onMouseLeave={onHoverLeave}
      ></div>
      <Popover
        target={`popover-${target}`}
        isOpen={popoverOpen}
        toggle={toggle}
        placement={placement}
        className={c(styles.popHover, `${category}-bg`)}
      >
        {title && <PopoverHeader>{title}</PopoverHeader>}
        {children && <PopoverBody>{children}</PopoverBody>}
      </Popover>
    </div>
  );
};
