import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { throttle } from 'lodash-es';

import user from './user';
import kits from './kits';
import account from './account';
import modal from './modal';
// import features from './features';
import ui from './ui';
import home from './home';
import table from './table';

const saveState = (state: Partial<RootState>) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('root', serializedState);
};

const reHydrateStore = () => {
  const root = localStorage.getItem('root');
  if (root) {
    return JSON.parse(root);
  }
};

const rootReducer = combineReducers({
  user,
  kits,
  account,
  modal,
  // features,
  ui,
  home,
  table,
});

export const initStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState: preloadedState || reHydrateStore(),
  });
};

const store = initStore();

// on updates to the store, persist some parts to localStorage
// no more than once per second
store.subscribe(
  throttle(
    () =>
      saveState({
        user: store.getState().user,
        kits: store.getState().kits,
        modal: store.getState().modal,
      }),
    1000,
  ),
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof initStore>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
