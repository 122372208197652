import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import MainAccount from 'types/MainAccount';

const patchMainAccountPractitioner = async (body: Partial<MainAccount>) => {
  try {
    await API.patch(apiName, `${apiBasePath}/mainaccounts/practitioner`, {
      body,
    });
  } catch (error) {
    throw new Error('Your request could not be completed. Please try again');
  }
};

const useUpdateMainAccountPractitioner = () => {
  const queryClient = useQueryClient();
  const mainAccountMutation = useMutation({
    mutationFn: ({ body }: { body: Partial<MainAccount> }) => {
      return patchMainAccountPractitioner(body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mainaccount'] });
    },
  });
  return mainAccountMutation;
};

export default useUpdateMainAccountPractitioner;
