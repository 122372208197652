import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { API } from 'api';
import { apiBasePath, apiName } from 'assets';
import { getIsPractitionerUser } from 'store/user';

export const sendKitRequestReminder = async (invoiceId: number) => {
  const response = await API.post(
    apiName,
    `${apiBasePath}/practitioners/patient-invoices/send-reminder/${invoiceId}`,
    {},
  );
  return response;
};

const useSendReminderToPatient = () => {
  const isPractitioner = useSelector(getIsPractitionerUser);

  const mutation = useMutation({
    mutationFn: (invoiceId: number) => {
      if (!isPractitioner)
        throw new Error('Only practitioners can send reminders');
      return sendKitRequestReminder(invoiceId);
    },
    onSuccess: async (data, variables, context) => {
      toast.success('Email reminder sent to patient');
    },
    onError: () => {
      toast.error('Failed to send reminder, please try again');
    },
  });
  return mutation;
};

export default useSendReminderToPatient;
