import { useQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { ConditionAssociation } from 'types/Conditions';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

type ConditionTaxa = {
  id: number;
  association: ConditionAssociation;
  condition?: string;
  condition_id?: number;
  display_order?: number;
  indent?: number;
  kit_id?: string;
  taxa_evaluation?: string;
  taxa_evaluation_meaning?: string;
  taxonomy_name?: string;
  user_abundance?: string;
};
const getConditionTaxa = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/conditions/taxa/${kitId}`,
    {},
  );
  return response?.result;
};

const useConditionsTaxa = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const conditionTaxaQuery = useQuery<ConditionTaxa[]>({
    queryKey: [apiName, 'getConditionTaxa', currentKitId],
    queryFn: async () => currentKitId && (await getConditionTaxa(currentKitId)),
    enabled: !!currentKitId,
  });

  return conditionTaxaQuery;
};

export default useConditionsTaxa;
