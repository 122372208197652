import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';
import { ActionPlanItem } from 'types/ActionPlan';

export async function getActionPlanData(kitId: string) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/actionplan/v4/${kitId}`,
    {},
  );

  return response?.result;
}
export const getActionPlanItemsQuery = (kitId?: string | null) => ({
  queryKey: [apiName, 'actionPlanDatav4', kitId],
  queryFn: () => (kitId ? getActionPlanData(kitId) : []),
});
const useActionPlanData = ({ kitId }: { kitId?: string | null }) => {
  const actionPlanDataQuery = useSuspenseQuery<ActionPlanItem[] | null>(
    getActionPlanItemsQuery(kitId),
  );
  return actionPlanDataQuery;
};

export default useActionPlanData;
