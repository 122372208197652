import { useSelector } from 'react-redux';
import { API } from 'api';
import { useQuery } from '@tanstack/react-query';

import {
  getImportantSurveyQuestions,
  ImportantQuestionsBySurvey,
} from './getImportantSurveyQuestions';
import {
  getImportantSurveyQuestionsWithProfile,
  ImportantQuestionsBySurveyId,
} from './getImportantSurveyQuestionsWithProfile';
import { apiName, apiBasePath } from 'assets';
import { getKitBelongsToAccount } from 'store/kits';
import { QuestionAnswerPairType, SurveyResponsesType } from 'types/Surveys';
import {
  getImportantQuestionsGroupStructure,
  ImportantQuestionsGroup,
} from './getImportantSurveyQuestionsGrouped';

const getKitSurveyResponses = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/kits/${kitId}/typeform/responses`,
    {},
  );
  return response.result;
};

export const getSurveyResponsesQuery = (
  kitId: string | null | undefined,
  kitBelongsToAccount: boolean,
) => ({
  queryKey: [apiName, 'surveyResponses', kitId],
  queryFn: () =>
    (kitId && kitBelongsToAccount && getKitSurveyResponses(kitId)) || null,
});

const useSurveyResponses = (kitId?: string | null, withProfiles = false) => {
  const kitBelongsToAccount = useSelector(getKitBelongsToAccount(kitId));
  const { data, error, isLoading } = useQuery<SurveyResponsesType[] | null>(
    getSurveyResponsesQuery(kitId ?? null, kitBelongsToAccount),
  );

  const importantQuestions: SurveyResponsesType[] = [];
  //find the questions that are important
  data?.forEach((survey: SurveyResponsesType) => {
    if (withProfiles) {
      const importantQuestionsMapProfileFlow =
        getImportantSurveyQuestionsWithProfile();
      if (!survey.hide_responses) {
        const tempQuestions = survey.questions?.filter(question =>
          importantQuestionsMapProfileFlow[
            survey.form_id as keyof ImportantQuestionsBySurveyId
          ]?.includes(question.question_id),
        );
        importantQuestions.push({ ...survey, questions: tempQuestions });
      }
    } else {
      const importantQuestionsMap = getImportantSurveyQuestions();
      if (!survey.hide_responses) {
        const tempQuestions = survey.questions?.filter(question =>
          importantQuestionsMap[
            survey.title as keyof ImportantQuestionsBySurvey
          ]?.question_ids?.includes(question.question_id),
        );
        importantQuestions.push({ ...survey, questions: tempQuestions });
      }
    }
  });
  //group new typeform survey responses with the new sub grouping
  const importantQuestionsGrouped: ImportantQuestionsGroup[] = [];
  if (withProfiles) {
    //make a map of all questions
    const allQuestionsMap: { [key: string]: QuestionAnswerPairType } = {};
    data?.forEach((survey: SurveyResponsesType) => {
      if (!survey.hide_responses) {
        // Extract all questions
        if (survey.questions) {
          survey.questions.forEach(question => {
            allQuestionsMap[question.question_id] = question;
          });
        }
      }
    });
    //follow the grouping structure to create the new grouping
    const importantQuestionsGroupStructure =
      getImportantQuestionsGroupStructure();
    importantQuestionsGroupStructure.forEach(group => {
      const questions: QuestionAnswerPairType[] = [];
      group.questions.forEach(questionId => {
        const answeredQuestion = allQuestionsMap[questionId];
        if (answeredQuestion) {
          questions.push(answeredQuestion);
        }
      });
      if (questions.length > 0) {
        importantQuestionsGrouped.push({
          displayTitle: group.displayTitle,
          questions,
        });
      }
    });
  }

  return {
    data,
    error,
    isLoading,
    kitBelongsToAccount,
    importantQuestions,
    importantQuestionsGrouped,
  };
};

export default useSurveyResponses;
