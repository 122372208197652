import { useMemo } from 'react';

import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useProfileQuestions from 'services/general/profileQuestions';
import useSampleInfoQuestions from 'services/surveys/useSampleInfoQuestions';
import { ProfileQuestionsGroup } from 'types/ProfileQuestions';

import { Card, QuestionAnswerItem, QuestionAnswerList } from 'components';

const getQuestionAnswerItems = (groups: ProfileQuestionsGroup[]) => {
  const items = [] as QuestionAnswerItem[];
  groups.forEach(group =>
    group.questions.forEach(question => {
      let actualAnswerString: string;
      if (Array.isArray(question.answer_text)) {
        actualAnswerString = question.answer_text.join(' | ');
      } else {
        actualAnswerString = question.answer_text;
      }
      items.push({
        question_id: question.id + '',
        title: question.question_text,
        answer: actualAnswerString,
      });
    }),
  );
  return items;
};

export type ProfileVariant = 'profile' | 'sample' | 'all';
export const ProfileQuestionsAndAnswers = ({
  variant = 'all',
}: {
  variant: ProfileVariant;
}) => {
  const { currentKitId, currentTinyAccountId } = useCurrentKitAndTinyAccount();
  const { data: profileQuestions } = useProfileQuestions(
    currentTinyAccountId,
    currentKitId,
  );
  const { data: sampleInfoQuestions } = useSampleInfoQuestions(
    currentTinyAccountId,
    currentKitId,
  );

  const questionAnswerPairs = useMemo(() => {
    let accumulated = [] as QuestionAnswerItem[];
    if (['profile', 'all'].includes(variant) && profileQuestions?.length) {
      accumulated = getQuestionAnswerItems(profileQuestions);
    }
    if (['sample', 'all'].includes(variant) && sampleInfoQuestions?.length) {
      accumulated = [
        ...accumulated,
        ...getQuestionAnswerItems(sampleInfoQuestions),
      ];
    }
    return accumulated;
  }, [profileQuestions, sampleInfoQuestions, variant]);

  return (
    <Card>
      <QuestionAnswerList list={questionAnswerPairs} />
    </Card>
  );
};
