import { useCallback, useState } from 'react';
import { useUpgradeKits } from 'services/kits/useUpgradeKits';
import { useSystemInfo } from 'services/system-info/useSystemInfo';
import { UpdateStepProps } from '../VersionUpdate';
import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  Typography,
} from 'components';

export const OptInOrOut = ({
  setCurrentStep,
  navigateBack,
}: UpdateStepProps) => {
  const [upgradingKits, setUpgradingKits] = useState(false);
  const { latestSciVersion } = useSystemInfo();
  const upgradeKitsMutation = useUpgradeKits();

  const upgradeKits = useCallback(async () => {
    setUpgradingKits(true);
    await upgradeKitsMutation.mutateAsync();
    setUpgradingKits(false);
    setCurrentStep(prevStep => prevStep + 1);
  }, [upgradeKitsMutation]);

  return (
    <div className='flex flex-column mt-4'>
      <Typography variant='heading-m' className='mb-3'>
        {`I understand that ALL of my family’s kits will update to Version ${latestSciVersion} unless I choose to opt out.`}
      </Typography>
      <Typography variant='body-s' medium className='mb-4'>
        This cannot be undone.
      </Typography>
      <DesignButton
        label='Continue with update'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.GREEN}
        disabled={upgradingKits}
        className='mt-3'
        width={'full'}
        onClick={() => upgradeKits()}
      />
      <DesignButton
        label='Opt out of update'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.LIGHT}
        className='mt-3'
        width={'full'}
        onClick={() => navigateBack()}
      />
    </div>
  );
};
