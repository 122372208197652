import { useEffect, useRef } from 'react';

import { Chart as ChartJS, registerables } from 'chart.js';

import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(annotationPlugin);
if (registerables !== undefined) {
  ChartJS.register(...registerables);
}

function addData(chart, data, labels) {
  if (!data) return;
  const newlabels = [...chart.data.labels, ...labels];
  const labelsDedupe = [...new Set(newlabels)];
  chart.data.labels = labelsDedupe;

  const scatterData = [...chart.data.datasets, ...data];
  const scatterDataDedupe = [...new Set(scatterData)];
  chart.data.datasets = scatterDataDedupe;
  chart.update();
}

export const ScatterPlotChart = ({ id, config, data, labels }) => {
  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    const scatterPlotChart = new ChartJS(ctx, config);

    addData(scatterPlotChart, data, labels);
    return () => scatterPlotChart.destroy();
  }, [config, data, labels]);

  const canvasRef = useRef(null);

  return (
    <>
      <div className='mb-4'>
        <canvas height='300' id={id} ref={canvasRef} />
      </div>
    </>
  );
};
