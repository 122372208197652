import c from 'classnames';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';

import { DesignButton, ButtonColors, ButtonSizes } from 'components';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets/constants';
import KitIllustrationSvg from 'assets/images/KitIdIllustration.svg?react';

import styles from '../Kits.module.scss';
import { KitStepProps } from 'views/Kits/ActivateKit';

type RegisterKitFormValues = {
  kitId: string;
};

const KitActivationStep = ({
  activationState,
  setActivationState,
  setCurrentActivationStep,
  step,
}: KitStepProps) => {
  const {
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<RegisterKitFormValues>({ mode: 'onBlur' });

  const kitId = watch('kitId') ?? '';

  const findUnregisteredKit = async (formData: RegisterKitFormValues) => {
    try {
      const foundKit = await API.get(
        apiName,
        `${apiBasePath}/kits/find/${formData.kitId?.replace(/\s+/g, '')}`,
      );
      const foundKitId = foundKit.result[0]?.id;
      const foundKitSampleType = foundKit.result[0]?.sampling_type;
      const foundKitPractitioner = foundKit.result[0]?.practitioner;
      setActivationState({
        ...activationState,
        kit: {
          ...activationState.kit,
          id: foundKitId,
          sampling_type: foundKitSampleType,
          practitioner: foundKitPractitioner,
        },
      });
      setCurrentActivationStep(step + 1);
    } catch (error) {
      const message =
        //TODO: error handling with typescript
        //eslint-disable-next-line
        //@ts-ignore
        error?.response?.data?.error ??
        'Something went wrong, please check the kit ID and try again';
      setError('kitId', { type: 'noKit', message });
      Sentry.captureMessage(message);
    }
  };

  return (
    <div className={c(styles.stepContainer)}>
      <KitIllustrationSvg className={c(styles.kitIllustration)} />
      <h2 className={c(styles.activationInstructions, 'text-center')}>
        Let&apos;s activate {activationState.tinyAccount.first_name}&apos;s kit
      </h2>
      <p className={c(styles.activationInstructions, 'text-center')}>
        <b>Please enter the kit ID below.</b> You will find your ID on your test
        swab and also in the test box
      </p>

      <form
        className={c(styles.registerForm, styles.newKitRegistration)}
        onSubmit={handleSubmit(findUnregisteredKit)}
      >
        <div className={c('field', styles.fieldContainer)}>
          <input
            id='kitId'
            type='text'
            autoComplete='off'
            className={c(
              'text-center',
              styles.inputField,
              errors.kitId && styles.inputError,
            )}
            placeholder='HMC582'
            {...register('kitId', {
              required: true,
              minLength: 6,
            })}
          />
          {errors.kitId?.type === 'minLength' && (
            <div className={styles.formError}>
              Must input at least 6 characters
            </div>
          )}
          {errors.kitId?.type === 'required' && (
            <div className={styles.formError}>Kit ID is required</div>
          )}
          {errors.kitId?.type === 'noKit' && (
            <div className={styles.formError}>{errors.kitId.message}</div>
          )}
          <DesignButton
            label='Continue'
            type='submit'
            loading={isSubmitting}
            disabled={kitId.length < 6}
            size={ButtonSizes.M}
            color={ButtonColors.GREEN}
            width={'full'}
            className='mt-4'
          />
        </div>
      </form>
    </div>
  );
};

export default KitActivationStep;
