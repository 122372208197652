import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';

type TaxaOverTimeProps = {
  kitId?: string | null;
  metricId?: number;
};
export type TaxaOverTimeData = {
  evaluation_color: string;
  kit_id: string;
  name: string;
  sample_date: string;
  sampling_type: string;
  age_at_sampling: number;
  status: string;
  tinyaccount_id: string;
  user_string: string | null;
  user_value: number | null;
  version: 1.03;
};
export type TaxaOverTime = TaxaOverTimeData[];
async function getTaxaOverTime(kitId: string, metricId?: number) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/results/taxaOverTime/${kitId}/${metricId}`,
    {},
  );
  return response?.result ? response.result : [];
}
export const getTaxaOverTimeQuery = (
  kitId?: string | null,
  metricId?: number,
) => {
  return {
    queryKey: [apiName, 'getTaxaOverTime', kitId, metricId],
    queryFn: () =>
      !!kitId && !!metricId ? getTaxaOverTime(kitId, metricId) : [],
  };
};
export const usePrefetchTaxaOverTime = ({
  kitId,
  metricId,
}: TaxaOverTimeProps) => {
  const queryClient = useQueryClient();
  const prefetchTaxaOverTime = async () => {
    await queryClient.prefetchQuery(getTaxaOverTimeQuery(kitId, metricId));
  };
  return prefetchTaxaOverTime;
};
const useTaxaOverTime = ({ kitId, metricId }: TaxaOverTimeProps) => {
  const taxaOverTimeQuery = useSuspenseQuery<TaxaOverTime>(
    getTaxaOverTimeQuery(kitId, metricId),
  );

  return taxaOverTimeQuery;
};

export default useTaxaOverTime;
