import { Card } from 'components';
import { TaxonRow } from './TaxonRow';
import { AssociatedSubheading } from './AssociatedSubheading';

export const AssociatedTaxaList = ({ taxa, condition, association }) => {
  const getAssociatedTaxa = association =>
    taxa.filter(
      taxon =>
        taxon.condition === condition && taxon.association === association,
    );
  const associatedTaxa = getAssociatedTaxa(association);

  return (
    <div key={association + ' ' + condition}>
      {!!associatedTaxa.length && (
        <Card title={<AssociatedSubheading association={association} />}>
          {associatedTaxa.map((taxon, index) => (
            <TaxonRow
              key={
                taxon.id +
                ' ' +
                taxon.user_abundance +
                ' ' +
                condition +
                ' ' +
                association
              }
              taxon={taxon}
              row={index}
            />
          ))}
        </Card>
      )}
    </div>
  );
};
