import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

export async function getSubMetricDetail(kitId: string, subMetricId: string) {
  const res = await API.get(
    apiName,
    `${apiBasePath}/results/sub-metric-detail/${kitId}/${subMetricId}?relativeAbundanceLimit=0.00005`,
    {},
  );
  return res?.result;
}
export const getSubMetricDetailQuery = (
  kitId?: string,
  subMetricId?: string,
) => ({
  queryKey: [apiName, `subMetricDetail`, kitId, subMetricId],
  queryFn: () => kitId && subMetricId && getSubMetricDetail(kitId, subMetricId),
});

export const usePrefetchSubMetricDetail = (
  kitId: string,
  subMetricId: string,
) => {
  const queryClient = useQueryClient();
  const prefetchSubMetricDetail = async () => {
    await queryClient.prefetchQuery(
      getSubMetricDetailQuery(kitId, subMetricId),
    );
  };
  return prefetchSubMetricDetail;
};

const useSubMetricDetail = (subMetricId: string) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const insightsDataQuery = useSuspenseQuery(
    getSubMetricDetailQuery(currentKitId, subMetricId),
  );

  return insightsDataQuery;
};

export default useSubMetricDetail;
