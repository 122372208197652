import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { API } from 'api';
import c from 'classnames';

import { apiName, apiBasePath } from 'assets/constants';

import {
  DesignButton,
  ButtonColors,
  ButtonSizes,
  Spinfinity,
} from 'components';
import { KitStepProps } from 'views/Kits/ActivateKit';

import styles from '../Kits.module.scss';

type ResearchStudy = {
  id: string;
  name: string;
  status: string;
  participation_requirements?: object[];
  consent_form_url?: string;
  created_at?: string;
  updated_at?: string;
};
type ResearchConsentFormValues = {
  researchConsent: string[];
};
const ResearchStep = ({
  setActivationState,
  activationState,
  setCurrentActivationStep,
  step,
}: KitStepProps) => {
  const navigate = useNavigate();
  const {
    register: registerResearchConsent,
    handleSubmit: handleSubmitResearchConsent,
    setError,
  } = useForm<ResearchConsentFormValues>({
    defaultValues: { researchConsent: [] },
  });
  const queryClient = useQueryClient();
  const [researchStudies, setResearchStudies] = useState<ResearchStudy[]>([]);
  const [fetched, setFetched] = useState(false);
  const { tinyAccount, kit } = activationState;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const consentFormResponse: { research_studies: ResearchStudy[] } =
          await API.post(
            apiName,
            `${apiBasePath}/research-studies/eligibility`,
            {
              body: {
                tinyaccount_id: tinyAccount.id,
                kit_id: kit.id,
              },
            },
          );

        setFetched(true);
        if (!consentFormResponse?.research_studies?.length) {
          navigate(`/results/${kit.id}`);
        } else {
          setResearchStudies(consentFormResponse.research_studies);
        }
      } catch (err) {
        const error = err as Error;
        Sentry.captureException(error);
        if (error?.message) {
          setError('researchConsent', {
            type: 'error',
            message: error.message,
          });
        }
      }
    };
    if (!fetched) fetchData();
  }, [tinyAccount, kit, queryClient, navigate, setError, fetched]);

  const submitConsentForm = async (formData: ResearchConsentFormValues) => {
    try {
      const researchStudyIds = formData.researchConsent;

      researchStudyIds.forEach(async studyId => {
        await API.post(apiName, `${apiBasePath}/research-studies/activate`, {
          body: {
            tinyaccount_id: tinyAccount.id,
            research_study_id: studyId,
          },
        });
      });
    } finally {
      navigate(`/results/${kit.id}`);
    }
  };

  if (!researchStudies?.length) return <Spinfinity />;
  return (
    <div className={c(styles.stepContainer)}>
      <h2>Participate in research</h2>
      <p>
        You may be eligible to participate in Tiny Health&apos;s Fertility
        Research Study.
      </p>
      <form onSubmit={handleSubmitResearchConsent(submitConsentForm)}>
        {researchStudies.map((study, index) => {
          if (!study.consent_form_url) return null;
          return (
            <div
              key={`${study.id}-${index}`}
              className={styles.researchStudyPrompt}
            >
              <div className={c('flex', styles.consentContainer)}>
                <input
                  type='checkbox'
                  className={styles.consentCheckbox}
                  key={study.name}
                  value={study.id}
                  {...registerResearchConsent(`researchConsent`)}
                />
                <p className={styles.checkboxLabel}>
                  I want to participate and agree to the{' '}
                  <Link
                    to={study.consent_form_url}
                    target='_blank'
                    className={styles.consentLink}
                  >
                    consent form
                  </Link>
                </p>
              </div>
            </div>
          );
        })}
        <DesignButton
          label='Continue'
          type='submit'
          size={ButtonSizes.M}
          color={ButtonColors.GREEN}
          width={'full'}
          className='mt-4'
        />
      </form>
    </div>
  );
};

export default ResearchStep;
