import { useLocation } from 'react-router-dom';

import useConsultAgents from 'services/general/consultAgentsList';
import { ConsultAgent } from 'types/Consult';

import {
  ButtonColors,
  ButtonSizes,
  ContainerSizes,
  Header,
  LinkButton,
  FloatingCTA,
  PageContainer,
} from 'components';
import { Icon, Typography } from '@repo/ui';
import { ConsultPricing } from '../ConsultPricing/ConsultPricing';
import HeaderIllustration from 'components/Header/HeaderIllustration';
import ConsultsSVG from 'assets/images/consults.svg';

import styles from './ConsultsInfoPage.module.scss';

type ConsultSpecialistProps = {
  title?: string;
  subtitle?: string;
  description?: string;
  imgSrc: string;
};
const ConsultSpecialist = ({
  title,
  subtitle,
  description,
  imgSrc,
}: ConsultSpecialistProps) => {
  return (
    <div className={styles.specialistCard}>
      <div className={styles.avatarWrapper}>
        <img src={imgSrc} alt='Specialist' />
      </div>
      <div className='flex flex-column gap-3 p-3'>
        <div className='flex flex-column'>
          {title && (
            <Typography variant='body-s' medium>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant='label' className={styles.greyText}>
              {subtitle}
            </Typography>
          )}
        </div>
        {description && (
          <Typography variant='label' className={styles.greyText}>
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};
const ConsultFeedback = ({ text }: { text: string }) => {
  return (
    <div className='flex flex-column'>
      <div className={styles.stars}>
        <Icon name='starFilled' size='s' />
        <Icon name='starFilled' size='s' />
        <Icon name='starFilled' size='s' />
        <Icon name='starFilled' size='s' />
        <Icon name='starFilled' size='s' />
      </div>
      <Typography variant='body-s'>{`"${text}"`}</Typography>
    </div>
  );
};
export const ConsultInfo = ({
  consultAgentsList,
}: {
  consultAgentsList: ConsultAgent[];
}) => {
  return (
    <>
      <div className='flex flex-column gap-2'>
        <Typography variant='heading-2xl'>
          Navigate your results with our experts
        </Typography>
      </div>
      <Typography variant='body-s' className={styles.greyText}>
        Get additional guidance from our handpicked microbiome experts to
        navigate your results, ask questions and adjust your action plan based
        on your progress.
      </Typography>
      <Typography variant='body-s' className={styles.greyText}>
        During the call we’ll cover:
      </Typography>

      <div className='flex flex-column gap-3'>
        <div className={styles.listRow}>
          <div className={styles.iconWrapper}>
            <Icon name='barLineChart' size='m' />
          </div>
          <div className='flex flex-column gap-1'>
            <Typography variant='heading-s' medium>
              The ins and outs of your results
            </Typography>
            <Typography variant='body-s' className={styles.greyText}>
              And if you are not sure where to start, discuss which items to
              focus on first
            </Typography>
          </div>
        </div>
        <div className={styles.listRow}>
          <div className={styles.iconWrapper}>
            <Icon name='naturalFood' size='m' />
          </div>
          <div className='flex flex-column gap-1'>
            <Typography variant='heading-s' medium>
              How to make dietary changes
            </Typography>
            <Typography variant='body-s' className={styles.greyText}>
              {`Plus when to introduce new probiotics and supplements, considering your family's specific preferences and circumstances`}
            </Typography>
          </div>
        </div>
        <div className={styles.listRow}>
          <div className={styles.iconWrapper}>
            <Icon name='flash' size='m' />
          </div>
          <div className='flex flex-column gap-1'>
            <Typography variant='heading-s' medium>
              Any questions about your results or action plan and next steps
            </Typography>
            <Typography variant='body-s' className={styles.greyText}>
              The microbiome is a complex ecosystem and your results may not be
              straightforward
            </Typography>
          </div>
        </div>
        <div className={styles.listRow}>
          <div className={styles.iconWrapper}>
            <Icon name='stethoscope' size='m' />
          </div>
          <div className='flex flex-column gap-1'>
            <Typography variant='heading-s' medium>
              Is this medical advice?
            </Typography>
            <Typography variant='body-s' className={styles.greyText}>
              No. While our team does include medical doctors and microbiome
              specialists, our tests are intended for wellness and educational
              purposes only. They cannot diagnose or treat disease, and they
              aren’t a substitute for a physician’s consultation. However, you
              can print or download a PDF of your report to take to your
              provider for more guidance.
            </Typography>
          </div>
        </div>
      </div>

      <div className={styles.line}></div>
      <div className='flex flex-column gap-2'>
        <Typography variant='heading-l'>
          Meet our microbiome specialists
        </Typography>
        <Typography variant='body-s' className={styles.greyText}>
          Our specialists will help you interpret your results. Tiny Health does
          not provide medical advice.{' '}
        </Typography>
      </div>

      <div className='flex flex-column gap-2'>
        {consultAgentsList.map(consultAgent => (
          <ConsultSpecialist
            key={consultAgent.email}
            imgSrc={consultAgent.head_shot_url}
            title={`${consultAgent.first_name} ${consultAgent.last_name}`}
            subtitle={consultAgent.credentials}
            description={consultAgent.description}
          />
        ))}
      </div>

      <div className={styles.line}></div>
      <div className='flex flex-column gap-4'>
        <Typography variant='heading-l'>What are others saying?</Typography>
        <ConsultFeedback text='All my questions were answered' />
        <ConsultFeedback text='Reassurance, hope and peace of mind' />
        <ConsultFeedback text='Concrete game plan and clear next steps' />
      </div>
    </>
  );
};

const ConsultsInfoPage = () => {
  const { data: consultAgentsList } = useConsultAgents();
  const { pathname } = useLocation();

  return (
    <>
      <Header title={`Coaching`} />
      <HeaderIllustration imgSrc={ConsultsSVG} bgColor='#CDEAFA' />
      <PageContainer
        size={ContainerSizes.XL}
        className={styles.consultsContainer}
      >
        <div className={styles.sectionsContainer}>
          <main className={styles.mainContainer}>
            <ConsultInfo consultAgentsList={consultAgentsList} />
          </main>

          <section className={styles.right}>
            <FloatingCTA
              label='Book a session'
              href={pathname + '/book'}
              extra={<ConsultPricing />}
            >
              <div className={styles.blackCard}>
                <div className={styles.cardHeader}>
                  <div className={styles.pill}>20 minute call</div>
                  <div className='flex gap-2'></div>
                </div>
                <Typography variant='heading-m'>
                  Book your 1-on-1 coaching session now
                </Typography>
                <Typography variant='body-s'>
                  Work through your results with our qualified microbiome
                  specialists. If you are being asked to pay for a coaching
                  session but believe you should have credits, please reach out
                  to hello@tinyhealth.com
                </Typography>
                <div className='flex flex-column'>
                  <LinkButton
                    label='Book a session'
                    href={pathname + '/book'}
                    width={'full'}
                    size={ButtonSizes.M}
                    color={ButtonColors.PURPLE}
                  />
                  <ConsultPricing />
                </div>
              </div>
            </FloatingCTA>
          </section>
        </div>
      </PageContainer>
    </>
  );
};

export default ConsultsInfoPage;
