import ChartDataLabels from 'chartjs-plugin-datalabels';

import { getColors } from 'contexts';

export const stackedBarChartData = {
  // get pushed in during Report useEffect
  labels: [],

  // get pushed in during Report useEffect
  datasets: [],
};
const ChartBackground = {
  id: 'custom_canvas_background_color',
  beforeDraw: chart => {
    const ctx = chart.canvas.getContext('2d');
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = getColors().bg;
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};
export const getStackedBarChartConfig = () => ({
  type: 'bar',
  aspectRatio: 1.3,
  data: stackedBarChartData,
  plugins: [ChartDataLabels, ChartBackground],
  options: {
    plugins: {
      title: {
        display: true,
        text: 'Microbiome stacked bar chart',
      },
      datalabels: {
        color: getColors().blue[500],
      },
      // annotation: {
      //   annotations: {
      //     annotation1: {
      //       drawTime: 'afterDraw',
      //       type: 'line',
      //       scaleID: 'x',
      //       borderWidth: 3,
      //       borderColor: 'black',
      //       value: 0.9,
      //       label: {
      //         content: (ctx) => {
      //           return 'after probiotics'

      //         },
      //         enabled: true
      //       },
      //    }
      //   }
      // },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        max: 100,
        min: 0,
      },
    },
  },
});

export const compositionStackedHorizontalBarChartConfig = {
  type: 'bar',
  data: stackedBarChartData,
  options: {
    aspectRatio: 2.5,
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
        max: 100,
        min: 0,
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function () {
            return '';
          },
        },
      },

      y: {
        stacked: true,
        display: false,
        grid: {
          display: false,
        },
      },
    },
  },
};

export const compositionCompareStackedHorizontalBarChartConfig = {
  type: 'bar',
  data: stackedBarChartData,
  options: {
    aspectRatio: 1.3,
    indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
        max: 100,
        min: 0,
        ticks: {
          count: 5,
          display: true,
          callback: function (value) {
            return value + '%';
          },
        },
      },

      y: {
        display: false,
        weight: 0,
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  },
};

export const reportStackedHorizontalBarChartConfig = {
  type: 'bar',
  data: stackedBarChartData,
  options: {
    aspectRatio: 1.3,
    indexAxis: 'y',
    plugins: {
      title: {
        display: true,
        text: 'Microbiome Horizontal stacked bar chart',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        max: 100,
        min: 0,
        grid: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function () {
            return '';
          },
        },
      },

      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  },
};
