import { RecommendationItemType } from 'types/ActionPlan';

export const getItemLinkLabelForType = (
  type: RecommendationItemType,
): string => {
  let text = '';
  switch (type) {
    case RecommendationItemType.RECIPE:
      text = 'Full recipe';
      break;
    case RecommendationItemType.RESOURCE:
      text = 'Learn more';
      break;
    case RecommendationItemType.PRODUCT:
    case RecommendationItemType.PROBIOTIC:
    case RecommendationItemType.SUPPLEMENT:
      text = 'Shop now';
      break;
    case RecommendationItemType.FOOD:
    case RecommendationItemType.TIP:
      text = '';
      break;
  }
  return text;
};
