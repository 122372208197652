import { useQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { Customer, Subscription } from 'types/Subscription';

export async function getSubscription(subscriptionId: string | number) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/subscriptions/${subscriptionId}`,
    {},
  );
  return response?.result;
}

const useSubscription = (subscriptionId?: string | number) => {
  const subscriptionQuery = useQuery<{
    subscription: Subscription;
    customer: Customer;
  } | null>({
    queryKey: [apiName, `subscription`, subscriptionId],
    queryFn: () => (subscriptionId ? getSubscription(subscriptionId) : null),
  });

  return subscriptionQuery;
};

export default useSubscription;
