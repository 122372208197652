import { useSelector } from 'react-redux';
import { getPractitionerId } from 'store/account';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
} from 'components';
import { AddPractitionerForm } from './AddPractitionerForm';

import styles from './AccountModal.module.scss';

type AddPractitionerModalProps = {
  toggle: () => void;
  showModal: boolean;
};

const AddPractitionerModal = ({
  toggle,
  showModal,
}: AddPractitionerModalProps) => {
  const practitionerId = useSelector(getPractitionerId);

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        fullscreen='md'
        className={styles.modalContainer}
      >
        <ModalHeader toggle={toggle} className={styles.modalHeader}>
          Practitioner
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <AddPractitionerForm
            practitionerId={practitionerId}
            onFinishCallback={() => toggle()}
          />
          <span className={styles.modalFooter}>
            {`Don't see your practitioner on the list? Have them contact us at `}
          </span>
          <LinkButton
            label='practitioners@tinyhealth.com'
            href={'mailto:practitioners@tinyhealth.com'}
            external
            variant={ButtonVariants.TEXT}
            size={ButtonSizes.M}
            color={ButtonColors.PURPLE}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
export default AddPractitionerModal;
