import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';
import { ReportTaxaCategories } from 'types/Insights';

interface TaxaDataProps {
  kitId?: string;
  kitType: string;
  relativeAbundanceLimit?: number;
}
export type TaxaData = {
  /* REQUIRED */
  id: string;
  /* OPTIONAL */
  rtc_id?: number;
  kit_id?: string;
  taxonomy_category?: string;
  taxonomy_name?: string;
  classified_reads?: number;
  classified_relative_abundance?: number;
  all_relative_abundance?: number;
  version?: string;
  created_at?: string;
  updated_at?: string;

  report_taxa_categories?: ReportTaxaCategories;
  category?: string;
};

export const getTaxaData = async (
  kitId: string,
  relativeAbundanceLimit?: number,
) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/results/microbiome/${kitId}?relativeAbundanceLimit=${relativeAbundanceLimit}`,
    {},
  );
  return response?.result;
};

const useTaxaData = ({
  kitId,
  kitType,
  relativeAbundanceLimit,
}: TaxaDataProps) => {
  const taxaDataQuery = useSuspenseQuery<TaxaData[]>({
    queryKey: [apiName, `${kitType}InsightsBacteria`, kitId],
    queryFn: async () =>
      !!kitId && (await getTaxaData(kitId, relativeAbundanceLimit)),
  });
  return taxaDataQuery;
};

export default useTaxaData;
