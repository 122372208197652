import c from 'classnames';

import { ActionPlanDesiredOutcome } from 'types/ActionPlan';

import { Icon, IconName, Typography } from '@repo/ui';

import styles from './DesiredOutcomes.module.scss';
type OutcomeStyle = {
  iconStyle: IconName;
  colorStyle: string;
};
const getOutcomeStyle = (outcome: ActionPlanDesiredOutcome): OutcomeStyle => {
  const outcomeStyle: OutcomeStyle = {
    iconStyle: 'stars02Filled',
    colorStyle: 'purple',
  };
  switch (outcome.meaning_keyword) {
    case 'increase':
      outcomeStyle.iconStyle = 'upSmall';
      outcomeStyle.colorStyle = 'green';
      break;
    case 'decrease':
      outcomeStyle.iconStyle = 'downSmall';
      outcomeStyle.colorStyle = 'red';
      break;
    case 'monitor':
      outcomeStyle.iconStyle = 'stars02Filled';
      outcomeStyle.colorStyle = 'orange';
      break;
    default:
      outcomeStyle.iconStyle = 'stars02Filled';
      outcomeStyle.colorStyle = 'purple';
  }
  return outcomeStyle;
};

export const DesiredOutcomesList = ({
  outcomes,
}: {
  outcomes: ActionPlanDesiredOutcome[];
}) => {
  return (
    <>
      {outcomes?.map(outcome => (
        <DesiredOutcome outcome={outcome} key={outcome.title} />
      ))}
    </>
  );
};

export const DesiredOutcome = ({
  outcome,
}: {
  outcome: ActionPlanDesiredOutcome;
}) => {
  const { iconStyle, colorStyle } = getOutcomeStyle(outcome);
  return (
    <div
      className={c(styles.desiredOutcome, styles[colorStyle])}
      key={outcome.title}
    >
      <div className={c(styles.desiredOutcomeIconContainer)}>
        <Icon name={iconStyle} size='s' />
      </div>
      <Typography variant='body-s' responsive>
        {outcome.title}
      </Typography>
    </div>
  );
};
