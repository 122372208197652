import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

// Types
export type ModalProps = {
  [key: string]: string | number | boolean | null | undefined;
};

export type Modal = {
  name: string;
  props?: ModalProps;
};

interface ModalState {
  openedModals: Modal[];
}

// State
export const initialState: ModalState = {
  openedModals: [],
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalOpened(state, action: PayloadAction<Modal>) {
      state.openedModals.push(action.payload);
    },
  },
});

// Selectors
export const getModalState = (state: RootState) => state.modal;
export const getOpenedModals = createSelector(
  getModalState,
  state => state.openedModals,
);
export const getHasModalOpened = (modal: Modal) =>
  createSelector(
    getOpenedModals,
    openedModals =>
      !!openedModals.find(openedModal => {
        if (openedModal.name === modal.name) {
          if (modal.props) {
            return Object.keys(modal.props).every(key => {
              return (
                modal.props &&
                openedModal.props &&
                openedModal.props[key] === modal.props[key]
              );
            });
          } else {
            return true;
          }
        }
        return false;
      }),
  );

export const { setModalOpened } = modalSlice.actions;
export default modalSlice.reducer;
