import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { toBlob } from 'html-to-image';
import c from 'classnames';

import MicrobiomeScoreChart, {
  chartDataForKit,
} from 'components/MicrobiomeScoreChart/MicrobiomeScoreChart';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import useGutType from 'services/gutType/useGutType';

import Kit, { KitType } from 'types/Kit';
import {
  ButtonColors,
  ButtonVariants,
  DesignButton,
  TextLink,
  Typography,
} from 'components';

import MicrobiomeScoreModal from 'views/Results/Summary/SummaryCards/MicrobiomeScoreSummaryCard/MicrobiomeScoreModal';
import MicrobiomeScoreShareImage from 'views/Results/Summary/SummaryCards/MicrobiomeScoreSummaryCard/MicrobiomeScoreShareImage';

import styles from './MicrobiomeScoreSummaryCard.module.scss';

export const getPopScoreInfo = (kit?: Kit, popScore?: number) => {
  if (!kit || !popScore) return { description: '', percentile: 1 };
  const { sampling_type, age_at_sampling } = kit;
  if (!sampling_type || !age_at_sampling) {
    return { description: '', percentile: 0 };
  }
  const isBaby = age_at_sampling < 365;
  const chartData = chartDataForKit(kit);
  const popScoreData = chartData.find(data => data.score === popScore);
  const color = popScoreData?.color;
  let description = '';
  switch (color) {
    case 'red':
      description =
        'There are major imbalances, and we recommend taking action and resampling in the future.';
      break;
    case 'orange':
      description =
        'There are imbalances in your microbiome, and we recommend taking action and resampling in the future.';
      break;
    default:
      description = '';
  }
  let ageText = '';
  if (isBaby) {
    ageText = 'babies aged 0-1yr';
  } else if (age_at_sampling < 1095) {
    ageText = 'toddlers aged 1-3yrs';
  } else if (age_at_sampling < 6570) {
    ageText = 'children aged 3-18yrs';
  } else {
    ageText = 'adults 18+';
  }
  return {
    description,
    percentile: Math.max(1, popScoreData?.ab_perc ?? 1),
    color,
    ageText,
  };
};
export const OffScreenContainer = ({
  children,
  ...props
}: React.PropsWithChildren) => (
  <div className={styles.offScreenContainer} {...props}>
    {children}
  </div>
);
const MicrobiomeScoreSummaryCard = () => {
  const { currentKit } = useCurrentKitAndTinyAccount();
  const imageRef = useRef<HTMLDivElement>(null);
  const {
    data: { pop_gut_score: popScore },
  } = useGutType();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sampling_type = currentKit?.sampling_type;
  const { description, percentile, color, ageText } = getPopScoreInfo(
    currentKit,
    popScore,
  );
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const onShareClick = async () => {
    if (!imageRef.current) return;
    const newFile = await toBlob(imageRef.current as HTMLElement, {
      skipFonts: true,
    });

    if (!newFile) return;
    const data = {
      files: [
        new File([newFile], 'tinyhealth_microbiome_summary_score.png', {
          type: 'image/png',
          lastModified: Date.now(),
        }),
      ],
      // title: 'tinyhealth_microbiome_summary_score.png',
      // text: 'Check out my microbiome health score from Tiny Health! Find out yours at www.tinyhealth.com',
    };
    try {
      if (!navigator?.canShare?.(data)) {
        navigator.clipboard.write([
          new ClipboardItem({
            'image/png': newFile,
          }),
        ]);
        toast.success('Image copied to clipboard');
      } else {
        await navigator.share(data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  if (!currentKit) return <></>;
  return (
    <>
      <div className={styles.cardContainer}>
        <MicrobiomeScoreModal
          isOpen={isModalOpen}
          onClose={toggleModal}
          populationScore={popScore}
          color={color}
        />
        {sampling_type === KitType.STOOL && (
          <div className={styles.shareButton}>
            <DesignButton
              onClick={() => onShareClick()}
              label='Share'
              variant={ButtonVariants.TEXT}
              color={ButtonColors.PURPLE}
              iconNameRight='share'
            />
          </div>
        )}
        <div className={styles.titleChartContainer}>
          <div className={styles.titleContainer}>
            <Typography variant='heading-s'>Your results score</Typography>
            <div className={c(styles.percentile, styles.margins)}>
              <span
                className={c(styles.percentileBig, styles[color ?? 'grey'])}
              >
                {Math.round(popScore)}
              </span>
              <span className='dark-grey'>/ 100</span>
            </div>
          </div>
          <div
            className={c(
              styles.chartContainer,
              'mt-4',
              sampling_type === KitType.VAGINAL && styles.vaginalChart,
            )}
          >
            <MicrobiomeScoreChart kit={currentKit} popScore={popScore} />
          </div>
        </div>
        <div className='flex flex-column'>
          <Typography variant='heading-m'>{description}</Typography>
          <TextLink
            label='About score'
            iconNameRight='infoCircle'
            onClick={toggleModal}
            className={styles.info}
          />
        </div>
      </div>
      <OffScreenContainer>
        {imageRef ? (
          <MicrobiomeScoreShareImage
            kit={currentKit}
            popScore={popScore}
            ref={imageRef}
          />
        ) : null}
      </OffScreenContainer>
    </>
  );
};
export default MicrobiomeScoreSummaryCard;
