import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { setKits } from 'store/kits';
import { getMainAccountId } from 'store/account';
import Kit from 'types/Kit';

const fetchKits = async () => {
  const response: { result: Kit[] } = await API.get(
    apiName,
    `${apiBasePath}/kits`,
    {},
  );
  return response?.result ?? [];
};
export const getKitsQuery = (mainAccountId: string | null) => ({
  queryKey: ['kits', mainAccountId],
  queryFn: async () => mainAccountId && (await fetchKits()),
});
const useKits = () => {
  const dispatch = useDispatch();
  const mainAccountId = useSelector(getMainAccountId);
  const kitsQuery = useQuery(getKitsQuery(mainAccountId));

  useEffect(() => {
    if (kitsQuery.isSuccess && kitsQuery.data) {
      dispatch(setKits(kitsQuery.data));
    }
  }, [kitsQuery.isSuccess, kitsQuery.data, dispatch]);

  return kitsQuery;
};

export default useKits;
