import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import TinyAccount from 'types/TinyAccount';
import { getMainAccountId } from 'store/account';

const postTinyAccount = async (
  mainAccountId: string | null,
  tinyAccount: Partial<TinyAccount>,
) => {
  if (!mainAccountId) throw new Error('No main account Id provided');
  await API.post(apiName, `${apiBasePath}/tinyaccounts/${mainAccountId}`, {
    body: tinyAccount,
  });
};

const useCreateTinyAccount = () => {
  const mainAccountId = useSelector(getMainAccountId);
  const queryClient = useQueryClient();
  const tinyAccountMutation = useMutation({
    mutationFn: (tinyAccount: Partial<TinyAccount>) => {
      return postTinyAccount(mainAccountId, tinyAccount);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['tinyAccounts'] });
    },
    onError: err => {
      const axios_error = err as AxiosError;
      if (axios_error.response?.status === 409) {
        const error = new Error('Family member already exists');
        error.name = 'TinyAccountAlreadyExists';
        throw error;
      }

      throw err;
    },
  });
  return tinyAccountMutation;
};

export default useCreateTinyAccount;
