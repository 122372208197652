import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import { capitalizeFirstLetter } from 'helpers';
import { Subscription, SubscriptionStatus } from 'types/Subscription';

import { Icon, Typography } from '@repo/ui';

import styles from './SubscriptionField.module.scss';

export const StatusPill = ({ status }: { status: SubscriptionStatus }) => {
  return (
    <div className={c(styles.pill, styles[status])}>
      {capitalizeFirstLetter(status)}
    </div>
  );
};

const SubscriptionField = ({
  subscription,
}: {
  subscription: Subscription;
}) => {
  if (!subscription) return null;
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div>
          <div className={styles.titleRow}>
            <span className={styles.title}>
              {subscription.shopify_product_name || 'Kit subscription'}
            </span>
            <StatusPill status={subscription.status} />
          </div>
          <div className={c(styles.iconRow, styles[subscription.status])}>
            <Icon name='repeatFilled' size='xs' />
            <span>{`${subscription.quantity} kit / ${subscription.order_interval_frequency} ${subscription.order_interval_unit}`}</span>
          </div>
          {subscription.status === 'active' && (
            <div className={c(styles.iconRow, styles[subscription.status])}>
              <Icon name='deliveryFilled' size='xs' />
              <span>{`Next on ${format(
                new Date(subscription.date_next_charge_scheduled),
                'MM/dd/yy',
              )}`}</span>
            </div>
          )}
        </div>
        {subscription.status === 'active' && (
          <NavLink
            to={`/account/subscription/${subscription.id}`}
            className={styles.action}
            rel='noreferrer'
          >
            Edit
          </NavLink>
        )}
      </div>
    </div>
  );
};
export default SubscriptionField;
