import { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import ReactSelect from 'react-select';
import { matchSorter } from 'match-sorter';

import useUpdateMainAccountPractitioner from 'services/account/useUpdateMainAccountPractitioner';
import useActivePractitioners from 'services/general/practitionersList';
import PractitionerUser from 'types/PractitionerUser';

import { ButtonSizes, DesignButton, Spinfinity, Typography } from 'components';

import styles from './AccountModal.module.scss';

type PractitionerOption = {
  label: string;
  value: string;
  organization?: string;
};

const emptyPractitionerOption: PractitionerOption = {
  label: 'No practitioner',
  value: '',
  organization: '',
};

type PractitionerFormProps = {
  practitionerId?: string | null;
  onFinishCallback?: (practitioner?: Partial<PractitionerUser>) => void;
};

export const AddPractitionerForm = ({
  practitionerId,
  onFinishCallback,
}: PractitionerFormProps) => {
  const mainAccountMutation = useUpdateMainAccountPractitioner();
  const { data } = useActivePractitioners();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    practitionerId ? practitionerId : '',
  );
  const [inputValue, setInputValue] = useState('');
  const selectOptions = useMemo(() => {
    if (data?.length) {
      const practitionerOptions = data.map(practitioner => {
        return {
          label: `${practitioner?.user?.first_name} ${practitioner?.user?.last_name}`,
          value: practitioner.id,
          organization: practitioner.organization,
        };
      });
      return [emptyPractitionerOption, ...practitionerOptions];
    }
    return [emptyPractitionerOption];
  }, [data]);
  const filteredOptions = useMemo(() => {
    if (inputValue) {
      const sorted = matchSorter(selectOptions, inputValue, {
        keys: ['organization', 'label'],
      });
      return sorted;
    }
    return selectOptions;
  }, [inputValue, selectOptions]);

  const submitMainAccountUpdateForm = async () => {
    setIsSubmitting(true);
    const selectedPractitionerId = selectedValue?.trim();
    try {
      await mainAccountMutation.mutateAsync({
        body: {
          practitioner_id: selectedPractitionerId,
        },
      });
      toast.success('Practitioner updated');
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
    setIsSubmitting(false);
    if (onFinishCallback) {
      onFinishCallback(
        data?.find(practitioner => practitioner.id === selectedPractitionerId),
      );
    }
  };

  if (!selectOptions || selectOptions.length <= 1) return <Spinfinity />;
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        submitMainAccountUpdateForm();
      }}
    >
      <ReactSelect
        options={filteredOptions}
        onChange={option =>
          setSelectedValue(
            option?.value ? option.value : emptyPractitionerOption.value,
          )
        }
        defaultValue={selectOptions.find(
          option => option?.value === practitionerId,
        )}
        isSearchable={true}
        components={{
          Option: ({ children, ...props }) => (
            <div {...props} onClick={props.innerProps.onClick}>
              <div className={styles.selectOption}>
                {children}
                <p className='mb-0 f7'>{props.data.organization}</p>
              </div>
            </div>
          ),
        }}
        filterOption={() => true}
        onInputChange={inputValue => setInputValue(inputValue)}
      />
      <Typography variant='body-s' className={styles.modalAgreement}>
        By clicking save you agree to give your practitioner access to your
        results, survey answers and action plan for all family members.
      </Typography>
      <DesignButton
        label='Save'
        type='submit'
        loading={isSubmitting}
        size={ButtonSizes.M}
        width={'full'}
        className='mt-4'
      />
    </form>
  );
};
