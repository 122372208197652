import { Route, Routes } from 'react-router-dom';
import { SurveysDetail } from './SurveysDetail/SurveysDetail';
import { SurveysSummary } from './SurveysSummary/SurveysSummary';

export const SurveysRoutes = () => {
  return (
    <Routes>
      <Route path={`:formId`} key='surveysDetail' element={<SurveysDetail />} />
      <Route path={``} key='surveysSummary' element={<SurveysSummary />} />
    </Routes>
  );
};
