import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { ColumnFilter } from '@tanstack/react-table';
import { RootState } from './store';
import { signOut } from './user';
// Types

export type TableState = {
  columnFilters?: ColumnFilter[];
};

// State
export const initialState: TableState = {};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setColumnFilters(state, action: PayloadAction<ColumnFilter[]>) {
      state.columnFilters = action.payload;
    },
  },
  extraReducers: builder =>
    builder.addCase(signOut, (state, action) => {
      return initialState;
    }),
});

// Selectors
export const getTableState = (state: RootState) => state.table;
export const getColumnFilters = createSelector(
  getTableState,
  state => state.columnFilters,
);

export const { setColumnFilters } = tableSlice.actions;
export default tableSlice.reducer;
