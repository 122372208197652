import { useQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { stoolToGut } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { KitType } from 'types/Kit';

export function getInsightCategoryDetail(kitId: string, categoryId: number) {
  const res = API.get(
    apiName,
    `${apiBasePath}/results/kitInsightDetailWithTaxaById/${kitId}/${categoryId}`,
    {},
  );
  return res;
}

const useInsightCategoryDetailById = (categoryId: number) => {
  const { currentKitId, currentKitType } = useCurrentKitAndTinyAccount();
  const insightsDataQuery = useQuery({
    queryKey: [
      apiName,
      `${stoolToGut(currentKitType ?? KitType.STOOL)}InsightDetail`,
      currentKitId,
      categoryId,
    ],
    queryFn: () =>
      currentKitId && getInsightCategoryDetail(currentKitId, categoryId),
  });

  return insightsDataQuery;
};

export default useInsightCategoryDetailById;
