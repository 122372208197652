import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { ProfileQuestionsGroup } from 'types/ProfileQuestions';
import { getKitById } from 'store/kits';

export async function getSampleInfoQuestions(
  tinyAccountId: string,
  kitId: string,
  isResample: boolean,
) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/tinyaccounts/${tinyAccountId}/profile-questions?${
      isResample ? 'is_resample=1' : 'is_sample=1'
    }&kit_id=${kitId}`,
    {},
  );
  return response?.result;
}

const useSampleInfoQuestions = (
  tinyAccountId?: string | null,
  kitId?: string,
) => {
  const kit = useSelector(getKitById(kitId));
  const isResample = !!kit?.is_resample;
  const sampleInfoQuestionsQuery = useSuspenseQuery<
    ProfileQuestionsGroup[] | null
  >({
    queryKey: [apiName, `sampleInfoQuestions`, tinyAccountId, kitId],
    queryFn: () =>
      tinyAccountId && kitId
        ? getSampleInfoQuestions(tinyAccountId, kitId, isResample)
        : null,
  });

  return {
    ...sampleInfoQuestionsQuery,
    needsSampleInfo: useMemo(() => {
      return !sampleInfoQuestionsQuery.data?.every(questionGroup => {
        return questionGroup.questions.every(question => question.answer_text);
      });
    }, [sampleInfoQuestionsQuery.data, kit]),
  };
};

export default useSampleInfoQuestions;
