import { Condition, ConditionAssociation } from 'types/Conditions';

import { Card, Markdown } from 'components';
import { AssociatedSubheading } from './AssociatedSubheading';

type AssociatedRiskFactorsProps = {
  condition: Condition;
  association: ConditionAssociation;
};

export const AssociatedRiskFactors = ({
  condition,
  association,
}: AssociatedRiskFactorsProps) => {
  return condition ? (
    <div>
      <Card title={<AssociatedSubheading association={association} />}>
        {association === ConditionAssociation.NEGATIVE &&
          condition?.decreased_risk && (
            <Markdown>{condition.decreased_risk}</Markdown>
          )}
        {association === ConditionAssociation.POSITIVE &&
          condition?.increased_risk && (
            <Markdown>{condition.increased_risk}</Markdown>
          )}
      </Card>
    </div>
  ) : null;
};
