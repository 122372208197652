import { ReactNode } from 'react';

import styles from './AccountField.module.scss';
type AccountFieldProps = {
  children: ReactNode;
  title: string;
  action?: () => void;
  actionName?: string;
};
const AccountField = ({
  children,
  title,
  action,
  actionName,
}: AccountFieldProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {action && (
          <span className={styles.action} onClick={action} title={actionName}>
            {actionName}
          </span>
        )}
      </div>
      <div className={styles.group}>{children}</div>
    </div>
  );
};
export default AccountField;
