import { format } from 'date-fns';
import c from 'classnames';

import { parseDate } from 'helpers';
import useFeature from 'services/features/useFeature';
import useSubscriptions from 'services/general/subscriptionsList';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import useCheckoutKit from 'hooks/useCheckoutKit';
import { Subscription } from 'types/Subscription';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  Card,
  LinkButton,
  TextLink,
} from 'components';
import { Icon, Typography } from '@repo/ui';
import { FadedCard } from '../FadedCard/FadedCard';

import styles from './MembershipSection.module.scss';

export const MembershipSectionV2Card = ({
  subscription,
  tinyAccountId,
}: {
  subscription: Subscription;
  tinyAccountId: string | null;
}) => {
  const { latestKit } = useTinyAccountForHome(tinyAccountId);
  const { price, checkoutUrl } = useCheckoutKit(latestKit?.sampling_type);
  const checkoutUrlWithUtm = `${checkoutUrl}&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=benefits`;
  const hasUnusedKits =
    !!subscription.kits_count_total &&
    subscription.kits_count_used !== subscription.kits_count_total;

  if (!subscription) return <></>;
  return (
    <FadedCard>
      <div className='flex flex-column gap-3'>
        <div className={styles.cardHeader}>
          <Typography variant='heading-l'>
            {subscription.shopify_product_name ?? 'Your Tiny+ Membership'}
          </Typography>
          <div className='flex align-items-center gap-1 light-grey-text'>
            <Icon name='award' size='s' />
            <Typography variant='label' serif>
              {subscription.date_next_charge_scheduled
                ? `Renews ${format(
                    parseDate(subscription.date_next_charge_scheduled),
                    'MM/dd/yyyy',
                  )}`
                : 'Annual'}
            </Typography>
          </div>
        </div>

        <div className={styles.grid}>
          <Card
            variant='wrapper'
            clickable={{
              text: 'coaching sessions',
              href: `/results/${latestKit?.id}/consults/book`,
              isExternal: false,
              variety: 'Card',
            }}
          >
            <div className={styles.cardContent}>
              <Typography
                variant='heading-3xl'
                serif
                className={styles.quantity}
              >
                <span className={styles.quantityDark}>
                  {subscription.consult_credits_count_used ?? 0}
                </span>
                {` / ${subscription.consult_credits_count_total ?? 0}`}
              </Typography>
              <div className={c(styles.pill, styles.blue)}>
                <Typography variant='label' className='my-auto'>
                  coaching sessions
                  <Typography variant='label' className='hide-on-mobile'>
                    {` used`}
                  </Typography>
                </Typography>
              </div>

              <div className='flex align-items-center justify-content-between gap-2 mt-1'>
                <Typography variant='label' className='dark-grey'>
                  $39 / additional call
                </Typography>
                <div className={styles.desktopActions}>
                  <TextLink
                    label='Book session'
                    iconNameRight='chevronForward'
                    contentOnly
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card
            variant='wrapper'
            clickable={{
              text: hasUnusedKits ? 'Activate a kit' : 'Buy a kit',
              href: hasUnusedKits ? '/kits/activate' : checkoutUrlWithUtm,
              isExternal: !hasUnusedKits,
              variety: 'Card',
            }}
          >
            <div className={styles.cardContent}>
              <Typography
                variant='heading-3xl'
                serif
                className={styles.quantity}
              >
                <span className={styles.quantityDark}>
                  {subscription.kits_count_used ?? 0}
                </span>
                {` / ${subscription.kits_count_total ?? 0}`}
              </Typography>
              <div className={c(styles.pill, styles.purple)}>
                <Typography variant='label'>
                  test kits
                  <Typography variant='label' className='hide-on-mobile'>
                    {` used`}
                  </Typography>
                </Typography>
              </div>
              <div className='flex align-items-center justify-content-between gap-2 mt-1'>
                <Typography variant='label' className='dark-grey'>
                  {`$${price} / additional kit`}
                </Typography>
                <div className={styles.desktopActions}>
                  <TextLink
                    label={hasUnusedKits ? 'Activate a kit' : 'Buy a kit'}
                    iconNameRight='chevronForward'
                    contentOnly
                  />
                </div>
              </div>
            </div>
          </Card>
          <Card
            variant='wrapper'
            clickable={{
              text: 'Chat with us',
              href: '/help',
              isExternal: false,
              variety: 'Card',
            }}
            className={c(styles.cardContent, styles.big)}
          >
            <Typography variant='heading-xl' serif>
              Chat with us
            </Typography>
            <div className={c(styles.pill, styles.green, 'mt-1 mb-1')}>
              <Typography variant='label'>SMS and Email support</Typography>
            </div>
            <div
              className={c(
                styles.desktopActions,
                'mt-1 justify-content-center',
              )}
            >
              <TextLink
                label='Message us'
                iconNameRight='chevronForward'
                contentOnly
              />
            </div>
          </Card>
        </div>

        <LinkButton
          label='Buy additional kits'
          href={checkoutUrlWithUtm}
          external
          variant={ButtonVariants.SOLID}
          size={ButtonSizes.M}
          color={ButtonColors.DARK_PURPLE}
          width={'full'}
        />
      </div>
    </FadedCard>
  );
};

export const MembershipSectionV2 = ({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) => {
  const { data: subscriptionsList } = useSubscriptions();

  if (!subscriptionsList?.length) return <></>;
  return (
    <>
      {subscriptionsList.map(subscription => (
        <MembershipSectionV2Card
          tinyAccountId={tinyAccountId}
          subscription={subscription}
          key={subscription.id}
        />
      ))}
    </>
  );
};

export const MembershipSection = ({
  tinyAccountId,
}: {
  tinyAccountId: string | null;
}) => {
  const { hasMembership, latestKit } = useTinyAccountForHome(tinyAccountId);
  const { isFeatureEnabled: isMembershipV2Enabled } =
    useFeature('home_membership_v2');
  const { price, checkoutUrl } = useCheckoutKit(latestKit?.sampling_type);
  const checkoutUrlWithUtm = `${checkoutUrl}&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=benefits`;

  if (isMembershipV2Enabled)
    return <MembershipSectionV2 tinyAccountId={tinyAccountId} />;
  if (!hasMembership) return <></>;
  return (
    <FadedCard>
      <div className='flex flex-column gap-3'>
        <Typography variant='heading-l'>Your Tiny+ Benefits</Typography>

        <div className={styles.grid}>
          <Card
            variant='wrapper'
            clickable={{
              text: 'coaching sessions',
              href: `/results/${latestKit?.id}/consults/book`,
              isExternal: false,
              variety: 'Card',
            }}
          >
            <div className={styles.cardContent}>
              <Typography variant='label' className='grey-text'>
                Your Tiny+ Benefits
              </Typography>
              <Typography variant='body-s'>
                $39 / additional coaching call
              </Typography>
              <div className={c(styles.pill, styles.blue)}>
                <Typography variant='label'>coaching sessions</Typography>
              </div>
            </div>
          </Card>
          <Card
            variant='wrapper'
            clickable={{
              text: 'additional kit',
              href: checkoutUrlWithUtm,
              isExternal: true,
              variety: 'Card',
            }}
          >
            <div className={styles.cardContent}>
              <Typography variant='label' className='grey-text'>
                Your Tiny+ Benefits
              </Typography>
              <Typography variant='body-s'>
                {`$${price} / additional kit`}
              </Typography>
              <div className={c(styles.pill, styles.purple)}>
                <Typography variant='label'>test kits</Typography>
              </div>
            </div>
          </Card>
          <Card
            variant='wrapper'
            clickable={{
              text: 'Chat with us',
              href: '/help',
              isExternal: false,
              variety: 'Card',
            }}
            className={c(styles.cardContent, styles.big)}
          >
            <Typography variant='heading-xl' serif>
              Chat with us
            </Typography>
            <div className={c(styles.pill, styles.green)}>
              <Typography variant='label'>SMS and Email support</Typography>
            </div>
          </Card>
        </div>

        <LinkButton
          label='Buy additional kits'
          href={checkoutUrlWithUtm}
          external
          variant={ButtonVariants.SOLID}
          size={ButtonSizes.M}
          color={ButtonColors.DARK_PURPLE}
          width={'full'}
        />
      </div>
    </FadedCard>
  );
};
