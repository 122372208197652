import { useQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import Guide from 'types/Guide';

export async function getPublishedGuides() {
  const response = await API.get(
    apiName,
    `${apiBasePath}/mainaccounts/guides`,
    {},
  );
  return response?.result;
}

const usePublishedGuides = () => {
  const guidesQuery = useQuery<Guide[]>({
    queryKey: [apiName, `publishedGuides`],
    queryFn: () => getPublishedGuides(),
    // options...
  });

  return guidesQuery;
};

export default usePublishedGuides;
