import React from 'react';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import c from 'classnames';

import { Icon } from '@repo/ui';
import { PLACEHOLDER } from 'assets';
import { Typography } from 'components';
import styles from './THTextarea.module.scss';

type FieldProps = {
  name: string;
  rows: number;
  maxLength?: number;
  label?: string;
  errors?: FieldErrors;
  autoComplete?: string;
  placeholder?: string;
  center?: boolean;
  defaultValue?: string;
  classes?: string;
} & UseFormRegisterReturn;

export const THTextarea = React.forwardRef<HTMLTextAreaElement, FieldProps>(
  (
    {
      label,
      name,
      maxLength,
      errors,
      autoComplete,
      placeholder,
      center = false,
      defaultValue,
      classes,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={c(styles.field, classes)}>
        {label && (
          <Typography variant='body-s' className={styles.label}>
            {label}
          </Typography>
        )}
        <div className={styles.inputContainer}>
          <textarea
            id={name}
            name={name}
            maxLength={maxLength}
            placeholder={
              placeholder
                ? placeholder
                : PLACEHOLDER[name as keyof typeof PLACEHOLDER]
            }
            aria-label={label}
            className={c(styles.textarea, !!errors?.[name] && styles.hasError, {
              'text-center': center,
            })}
            {...rest} // { onChange, onBlur }
            ref={ref}
          />
        </div>
        <div className={styles.errors}>
          {errors && (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <div
                  className={styles.error}
                  id={`error-${name}`}
                  key={message}
                  role='alert'
                >
                  <Icon name='alertCircle' size='xs' /> {message}
                </div>
              )}
            />
          )}
        </div>
      </div>
    );
  },
);
THTextarea.displayName = 'THTextarea';
