import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

export const getPersonalizedNote = async (kitId?: string) => {
  if (!kitId) return;
  const response = await API.get(
    apiName,
    `${apiBasePath}/kits/${kitId}/note`,
    {},
  );
  return response?.note ?? null;
};
export const getPersonalizedNoteQuery = (kitId?: string | null) => ({
  queryKey: [apiName, 'getPersonalizedNoteDB', kitId],
  queryFn: () => kitId && getPersonalizedNote(kitId),
});
const usePersonalizedNote = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const personalizedNoteQuery = useSuspenseQuery(
    getPersonalizedNoteQuery(currentKitId),
  );

  return personalizedNoteQuery;
};

export default usePersonalizedNote;
