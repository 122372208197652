import { getFamilyColors } from 'contexts';
import TinyAccount from 'types/TinyAccount';

export function colorForTinyAccount(
  tinyAccounts: TinyAccount[],
  tinyAccountId: string,
) {
  const familyColors = getFamilyColors();
  const tinyAccountIndex = tinyAccounts.findIndex(
    ta => ta.id === tinyAccountId,
  );
  return familyColors[
    tinyAccountIndex % (familyColors ? familyColors.length : 1)
  ];
}
