import { ActionPlanRecommendation, BookmarkedItem } from 'types/ActionPlan';
import { Markdown, Typography } from 'components';
import { RecommendationItems } from '../RecommendationItems/RecommendationItems';

import styles from './Recommendations.module.scss';

export const Recommendations = ({
  recommendations,
  actionId,
  activeBookmarks,
}: {
  recommendations: ActionPlanRecommendation[];
  actionId?: number;
  activeBookmarks?: BookmarkedItem[];
}) => {
  return (
    <div className={styles.recommendationsContainer}>
      {recommendations.map(recommendation => (
        <div className={styles.recommendation} key={recommendation.id}>
          {!!recommendation.display_title && (
            <Typography variant='heading-l'>
              {recommendation.display_title}
            </Typography>
          )}
          {!!recommendation.description && (
            <div className={styles.description}>
              <Markdown>{recommendation.description}</Markdown>
            </div>
          )}
          {!!recommendation.items?.length && (
            <RecommendationItems
              items={recommendation.items}
              activeBookmarks={activeBookmarks}
              actionId={actionId}
            />
          )}
        </div>
      ))}
    </div>
  );
};
