import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets';
import { getEmail, getUsername } from 'store/user';
import { PractitionerKitRequest } from 'types/PractitionerUser';
import { useMemo } from 'react';
import { DISPLAY_NAMES } from './usePractitionerKitsForPatient';

export async function getKitRequests(email?: string) {
  if (!email) return null;
  const response = await API.get(
    apiName,
    `${apiBasePath}/practitioners/patient-invoices`,
    {},
  );
  return response?.result;
}

const useKitRequests = () => {
  const username = useSelector(getUsername);
  const email = useSelector(getEmail);
  const kitsRequestsQuery = useQuery<PractitionerKitRequest[]>({
    queryKey: [apiName, `useKitRequests`, username],
    queryFn: () => getKitRequests(email),
  });

  return {
    ...kitsRequestsQuery,
    data: useMemo(
      () =>
        kitsRequestsQuery.data?.map(request => {
          const full_name = `${request.customer_first_name} ${request.customer_last_name}`;
          const kit_display_title = `${
            DISPLAY_NAMES[request.shopify_product.lookup_code]
              ? DISPLAY_NAMES[request.shopify_product.lookup_code]
              : request.shopify_product.name
          }`;
          return { ...request, full_name, kit_display_title };
        }),
      [kitsRequestsQuery.data],
    ),
  };
};

export default useKitRequests;
