import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';

import { getKits } from 'store/shared';

import { useSystemInfo } from 'services/system-info/useSystemInfo';
import { useDownloadAllPDFs } from 'services/pdfReport/useDownloadAllPDFs';
import { useUpgradeKits } from 'services/kits/useUpgradeKits';
import { usePreviousKitMajorVersion } from 'services/system-info/usePreviousKitMajorVersion';

import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  Typography,
} from 'components';
import { KitStepProps } from 'views/Kits/ActivateKit';

import kitStyles from '../../Kits.module.scss';

type VersionUpdateSubStep = React.FC<{
  moveToNextScreen: () => Promise<void>;
  upgradeKits?: () => Promise<void>;
}>;
const StepZero: VersionUpdateSubStep = ({ moveToNextScreen }) => {
  const { latestSciVersion } = useSystemInfo();
  return (
    <div className='flex flex-column gap-1 mt-4 pt-4'>
      <Typography variant='heading-m' className='mb-2'>
        We’re updating all kits to the latest version.
      </Typography>
      <Typography variant='body-s' className='mb-4'>
        To ensure you receive the most accurate and up-to-date results based on
        the latest scientific research, we are upgrading all kits in your
        family’s account to Version {`${latestSciVersion}`}, our latest system.
        This will allow you to compare results easily.
      </Typography>
      <div className='mt-3'>
        <DesignButton
          label='Next'
          type='button'
          size={ButtonSizes.M}
          color={ButtonColors.GREEN}
          width={'full'}
          onClick={() => moveToNextScreen()}
        />
      </div>
    </div>
  );
};
const StepOne: VersionUpdateSubStep = ({ moveToNextScreen }) => {
  const { downloadAllPDFs } = useDownloadAllPDFs();
  return (
    <div className='flex flex-column'>
      <Typography variant='heading-m' className='mb-3'>
        What to expect:
      </Typography>
      <ul className='mb-4'>
        <li className='mb-2'>
          <Typography variant='body-s' className=''>
            <Typography variant='body-s' medium>
              Automatic Update:{' '}
            </Typography>
            All prior kits linked to your family’s account will be updated,
            including those for other family members.
          </Typography>
        </li>
        <li className='mb-2'>
          <Typography variant='body-s' className=''>
            <Typography variant='body-s' medium>
              Potential Changes:{' '}
            </Typography>
            Flagged metrics, actions, and the microbiome summary score may
            change after the update.
          </Typography>
        </li>
        <li className='mb-2'>
          <Typography variant='body-s' className=''>
            <Typography variant='body-s' medium>
              Processing Time:{' '}
            </Typography>
            Updates may take up to 1 week. You will receive an email
            notification once the update is complete.
          </Typography>
        </li>
      </ul>
      <Typography variant='heading-m' className='mb-3'>
        Want to keep your current results?
      </Typography>
      <Typography variant='body-s' className='mb-4'>
        If you prefer to keep your prior results as they are, you may opt out of
        the update on the next screen. If you proceed with the update, consider
        downloading a PDF of your current results for your records before
        activating the new kit.
      </Typography>
      <DesignButton
        label='Download all PDFs'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.LIGHT}
        className='mt-3'
        width={'full'}
        onClick={async () => downloadAllPDFs()}
      />
      <DesignButton
        label='Next'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.GREEN}
        className='mt-3'
        width={'full'}
        onClick={() => moveToNextScreen()}
      />
    </div>
  );
};
const StepTwo: VersionUpdateSubStep = ({ moveToNextScreen }) => {
  const { latestSciVersion } = useSystemInfo();
  const upgradeKitsMutation = useUpgradeKits();
  const upgradeKits = useCallback(async () => {
    await upgradeKitsMutation.mutateAsync();

    moveToNextScreen();
  }, [upgradeKitsMutation, moveToNextScreen]);
  return (
    <div className='flex flex-column mt-4'>
      <Typography variant='heading-m' className='mb-3'>
        {`I understand that ALL of my family’s kits will update to Version ${latestSciVersion} unless I choose to opt out.`}
      </Typography>
      <Typography variant='body-s' medium className='mb-4'>
        This cannot be undone.
      </Typography>
      <DesignButton
        label='Continue with update'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.GREEN}
        className='mt-3'
        width={'full'}
        onClick={() => upgradeKits()}
      />
      <DesignButton
        label='Opt out of update'
        type='button'
        size={ButtonSizes.M}
        color={ButtonColors.LIGHT}
        className='mt-3'
        width={'full'}
        onClick={() => moveToNextScreen()}
      />
    </div>
  );
};

const steps = [
  { id: 0, Component: StepZero },
  { id: 1, Component: StepOne },
  { id: 2, Component: StepTwo },
];

const VersionUpdateStep = ({
  setActivationState,
  activationState,
  setCurrentActivationStep,
  step,
}: KitStepProps) => {
  const { kit } = activationState;
  const { hasMultipleVersions } = usePreviousKitMajorVersion();
  const kits = useSelector(getKits);
  const [localStep, setLocalStep] = useState(0);

  const isResample = useMemo(() => {
    if (
      kits.find(
        oKit =>
          kit.tinyaccount_id === oKit.tinyaccount_id && oKit.date_results_ready,
      )
    ) {
      return true;
    }
    return false;
  }, [kit.tinyaccount_id, kits]);

  useEffect(() => {
    if (!isResample || !hasMultipleVersions) {
      setCurrentActivationStep(step + 1);
    }
  }, [isResample, hasMultipleVersions, setCurrentActivationStep, step]);

  const moveToNextScreen = async () => {
    if (localStep < 2) {
      setLocalStep(localStep + 1);
      return;
    }
    setCurrentActivationStep(step + 1);
  };
  const StepComponent = steps[localStep].Component;

  return (
    <div className={c(kitStyles.stepContainer)}>
      <StepComponent moveToNextScreen={moveToNextScreen} />
    </div>
  );
};

export default VersionUpdateStep;
