import { useCallback } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useLocation } from 'react-router-dom';
import type { TrackEvent } from 'contexts/analytics/types';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

export const useTrack = (analyticsBrowser: AnalyticsBrowser | null) => {
  const location = useLocation();
  const { currentKit, currentKitId } = useCurrentKitAndTinyAccount();

  const trackEvent: TrackEvent = useCallback(
    (eventName, properties) => {
      if (!analyticsBrowser) return;
      // if the event is happening on a kit page, add kit properties to the event
      if (currentKit && currentKitId) {
        const enrichedProperties = {
          ...properties,
          path: location.pathname,
          page: location.pathname.replace(`/${currentKitId}`, ''),
          // kit props
          kit_id: currentKit.id,
          sampling_type: currentKit.sampling_type,
          date_results_ready: currentKit.date_results_ready,
          age_at_sampling: currentKit.age_at_sampling,
          is_resample: currentKit.is_resample,
        };
        analyticsBrowser.track(eventName, enrichedProperties);
      } else {
        const enrichedProperties = {
          ...properties,
          path: location.pathname,
          page: location.pathname,
        };
        analyticsBrowser.track(eventName, enrichedProperties);
      }
    },
    [analyticsBrowser, currentKit, currentKitId, location.pathname],
  );
  return trackEvent;
};
