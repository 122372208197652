import { useMemo } from 'react';
import c from 'classnames';

import useTaxaBarChartData from 'services/microbiome/useTaxaBarChartData';
import {
  capitalizeFirstLetter,
  formatNumberToDisplayPercentage,
} from 'helpers';
import Kit from 'types/Kit';

import { PopHover, Typography } from 'components';

import styles from './MicrobiomeBar.module.scss';

const getPopHoverTarget = (type: string, value: number, kitId: string) => {
  return `${type}_slice_${value
    .toString()
    .replace('.', '')
    .substring(0, 7)}${kitId}`;
};

type BacteriaCategories = {
  [key: string]: { name: string; color: string; value: number; order: number };
};

const categoryColors = {
  beneficial: 'green',
  protective: 'green',
  unfriendly: 'red',
  disruptive: 'red',
  variable: 'orange',
  unknown: 'grey',
};
const categoryOrders = {
  unfriendly: 1,
  disruptive: 1,
  beneficial: 2,
  protective: 2,
  variable: 3,
  unknown: 4,
};
export const MicrobiomeBar = ({
  kit,
  index = 0,
  displayMode,
}: {
  kit: Kit;
  index?: number;
  displayMode?: string;
}) => {
  const { data } = useTaxaBarChartData(kit.id, kit.sampling_type);

  const bacteriaCategories = useMemo(() => {
    if (!data) return [];
    const categories: BacteriaCategories = {};
    let barFill = 0;
    Object.keys(data).forEach(category => {
      const value = data[category as keyof typeof data];
      if (value && category !== 'unknown') {
        categories[category] = {
          name: capitalizeFirstLetter(category),
          color: categoryColors[category as keyof typeof categoryColors],
          value: value * 100,
          order: categoryOrders[category as keyof typeof categoryOrders],
        };

        barFill += categories[category]?.value ?? 0;
      }
    });

    categories.unknown = {
      name: 'Unknown',
      color: 'grey',
      value: 100 - barFill,
      order: 4,
    };

    return Object.values(categories).sort((a, b) => a.order - b.order);
  }, [data]);

  return (
    <div className={styles.compositionWrapper}>
      {index === 0 && displayMode === 'full' && (
        <div className={c(styles.compositionContainer, styles.topLegend)}>
          {bacteriaCategories.map(category => (
            <div className={styles.abundance} key={category.name}>
              <span className={c(styles.circle, styles[category.color])} />
              <Typography variant='body-s'>{category.name}</Typography>
              <Typography variant='body-s'>
                {formatNumberToDisplayPercentage(category.value)}
              </Typography>
            </div>
          ))}
        </div>
      )}
      <div className={styles.graphContainer}>
        {bacteriaCategories?.map(category => (
          <div
            key={category.name}
            className={styles[category.color]}
            style={{ flexBasis: category.value + '%', minWidth: '1rem' }}
          >
            <PopHover
              target={getPopHoverTarget(category.name, category.value, kit.id)}
              category={category.name}
              className={`pop-hover ${category.color}-bg`}
              placement='top'
            >
              {formatNumberToDisplayPercentage(category.value)}
            </PopHover>
          </div>
        ))}
      </div>
      {displayMode === 'card' && (
        <div className={c(styles.compositionContainer, 'mt-2')}>
          {bacteriaCategories.map(category => (
            <div className={styles.abundance} key={category.name}>
              <span className={c(styles.circle, styles[category.color])} />
              <Typography variant='label'>{category.name}</Typography>
              <Typography
                variant='label'
                className={styles.abundancePercentage}
              >
                {formatNumberToDisplayPercentage(category.value)}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
