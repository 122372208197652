import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';

export async function getActionPlanData(
  kitId: string,
  actionId: string | number,
) {
  const response = await API.get(
    apiName,
    `${apiBasePath}/actionplan/v4/${kitId}/action/${actionId}`,
    {},
  );
  return response?.result;
}
const getActionPlanItemQueryKey = (
  kitId: string | null,
  actionId: string | number,
) => ({
  queryKey: [apiName, 'actionPlanItem', kitId, actionId],
  queryFn: () => kitId && actionId && getActionPlanData(kitId, actionId),
});
export const useActionPlanItemNonSuspense = ({
  kitId,
  actionId,
}: {
  kitId?: string | null;
  actionId?: string | number;
}) => {
  const actionPlanItemQuery = useQuery(
    getActionPlanItemQueryKey(kitId ?? null, actionId ?? ''),
  );
  return actionPlanItemQuery;
};
const useActionPlanItem = ({
  kitId,
  actionId,
}: {
  kitId?: string | null;
  actionId?: string | number;
}) => {
  const actionPlanItemQuery = useSuspenseQuery(
    getActionPlanItemQueryKey(kitId ?? null, actionId ?? ''),
  );
  return actionPlanItemQuery;
};

export default useActionPlanItem;
