import { useDispatch, useSelector } from 'react-redux';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getHasModalOpened, setModalOpened } from 'store/modal';

import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
  Modal,
  Typography,
} from 'components';
import review from 'assets/images/placeholders/review.svg';

import styles from './ResultsOverviewModal.module.scss';

export const ResultsOverviewModal = ({ published }: { published: boolean }) => {
  const dispatch = useDispatch();
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const resultsOverviewModal = {
    name: 'resultsOverview',
    props: { kitId: currentKitId },
  };
  const hasOpenedResultsOverviewModal = useSelector(
    getHasModalOpened(resultsOverviewModal),
  );

  return (
    <Modal
      preToggle={published && !hasOpenedResultsOverviewModal}
      showHeader={false}
      onModalOpen={() => dispatch(setModalOpened(resultsOverviewModal))}
    >
      <div className={styles.modalBody}>
        <div className={styles.textContainer}>
          <div className={styles.mainContent}>
            <Typography variant={'label'} medium>
              Welcome to your results overview!
            </Typography>
            <Typography variant={'heading-xl'}>
              An expert summary on where to start
            </Typography>
            <Typography
              variant={'body-s'}
              className={styles.greyText}
              responsive
            >
              Read an overview of your results, including what to focus on
              first.
            </Typography>
          </div>
          <LinkButton
            href={`/review/${currentKitId}`}
            label='Read now'
            variant={ButtonVariants.SOLID}
            size={ButtonSizes.M}
            color={ButtonColors.PURPLE}
          />
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.imageWrapper}>
            <img src={review} alt='checklist' />
          </div>
        </div>
      </div>
    </Modal>
  );
};
