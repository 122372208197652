import type { ToastOptions } from 'react-toastify';
export const toastConfig: Partial<ToastOptions> = {
  position: 'top-center',
  autoClose: 60000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  closeButton: false,
};
