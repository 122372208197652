import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API } from 'api';

import { apiBasePath, apiName } from 'assets/constants';
import { getPdfUrlForKitId, setRequestedPDFForKit } from 'store/kits';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

const generatePDFReport = async (kitId: string) => {
  const response = await API.post(
    apiName,
    `${apiBasePath}/pdf-report/${kitId}`,
    {},
  );
  return response;
};
const getSignedPDFReportURL = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/pdf-report/${kitId}`,
    {},
  );
  return response;
};
export const getPdfUrlQuery = (
  kitId?: string | null,
  kitPdfUrl?: string | null, // bucket path kits/:kitId
) => ({
  queryKey: ['pdf-report', kitId],
  queryFn: () => kitId && !!kitPdfUrl && getSignedPDFReportURL(kitId),
});
const useRequestPDFReport = (kitId?: string | null) => {
  const dispatch = useDispatch();
  const kitPdfUrl = useSelector(getPdfUrlForKitId(kitId ?? ''));
  const queryClient = useQueryClient();
  const requestPDFReport = async () => {
    if (!kitId) return;
    try {
      await generatePDFReport(kitId);
      await dispatch(setRequestedPDFForKit(kitId));

      await queryClient.invalidateQueries({ queryKey: ['kits'] });
      toast(
        'A PDF of your report is being generated. Check your email shortly.',
      );
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
  };
  const signedUrlQuery = useSuspenseQuery(getPdfUrlQuery(kitId, kitPdfUrl));

  return { requestPDFReport, signedUrl: signedUrlQuery.data };
};
//exposes a function to request a signed URL to download the report for Practitioners and Admin
export const useSignedPDFReportURL = () => {
  const getSignedPDFReportURL = async (
    kitId: string,
    mainaccountId: string,
  ) => {
    const response = await API.get(
      apiName,
      `${apiBasePath}/pdf-report/${kitId}`,
      { impersonateId: mainaccountId },
    );
    return response;
  };
  return getSignedPDFReportURL;
};
export default useRequestPDFReport;
