import { Suspense } from 'react';

import { stringifyDaysOld } from 'helpers';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { InsightMetric } from 'types/Insights';

import { Spinfinity, Typography } from 'components';
import { DiveDeeperSummaryCard } from '../DiveDeeperSummaryCard/DiveDeeperSummaryCard';
import MaturationIndexGraph from 'components/MaturationIndexGraph/MaturationIndexGraph';

export const MATURATION_INDEX_CATEGORY_KEY = 'Maturation';

export const MaturationIndexSummaryCard = ({
  subMetric,
}: {
  subMetric: InsightMetric;
}) => {
  const { currentKit, currentKitId, currentTinyAccount } =
    useCurrentKitAndTinyAccount();

  if (
    !currentKit ||
    !subMetric ||
    subMetric.category_key !== MATURATION_INDEX_CATEGORY_KEY
  )
    return <></>;
  return (
    <DiveDeeperSummaryCard
      title=''
      description=''
      link={`/results/${currentKitId}/insights/all-insights/${subMetric.gut_vaginal_health?.id}`}
      linkText='Learn more'
    >
      <Suspense fallback={<Spinfinity />}>
        <div className='flex flex-column'>
          <Typography variant={'heading-m'} className='dark-grey'>
            {`${currentTinyAccount?.first_name}’s gut looks like that of a`}
          </Typography>
          <Typography
            variant={'heading-xl'}
            className={subMetric.evaluation_color}
            serif
          >
            {stringifyDaysOld(subMetric.user_value, true)}
          </Typography>
          <MaturationIndexGraph
            kit={currentKit}
            subMetric={subMetric}
            minHeight={150}
            hideAllAxisLabels
            onlyCurrentKit
          />
        </div>
      </Suspense>
    </DiveDeeperSummaryCard>
  );
};
