import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import useUpdateMainAccount from 'services/account/useUpdateMainAccount';
import { getFirstName, getLastName } from 'store/account';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button, THField } from 'components';

import styles from './AccountModal.module.scss';

type AccountNameModalProps = {
  toggle: () => void;
  showModal: boolean;
};
type AccountNameModalFormValues = {
  firstName: string;
  lastName: string;
};

const AccountNameModal = ({ toggle, showModal }: AccountNameModalProps) => {
  const mainAccountMutation = useUpdateMainAccount();
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<AccountNameModalFormValues>({
    defaultValues: {
      firstName,
      lastName,
    },
    mode: 'onTouched',
  });
  const firstNameFormField = register('firstName', {
    required: {
      value: true,
      message: 'Required. Must be at least 1 character long',
    },
  });
  const lastNameFormField = register('lastName', {
    required: {
      value: true,
      message: 'Required. Must be at least 1 character long',
    },
  });

  const submitMainAccountUpdateForm = async (
    formData: AccountNameModalFormValues,
  ) => {
    try {
      await mainAccountMutation.mutateAsync({
        body: {
          first_name: formData.firstName?.trim(),
          last_name: formData.lastName?.trim(),
        },
      });
      toast.success('Name updated');
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        fullscreen='md'
        className={styles.modalContainer}
      >
        <ModalHeader toggle={toggle} className={styles.modalHeader}>
          Edit name
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <form onSubmit={handleSubmit(submitMainAccountUpdateForm)}>
            <div className='items-start flex'>
              <THField
                type='text'
                label='First Name'
                classes='half'
                errors={errors}
                {...firstNameFormField}
              />
              <THField
                type='text'
                label='Last name'
                classes='half'
                errors={errors}
                {...lastNameFormField}
              />
            </div>
            <Button
              width='full'
              type='submit'
              color='green'
              disabled={!isValid}
              loading={isSubmitting}
            >
              Save
            </Button>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default AccountNameModal;
