import c from 'classnames';
import { ProfileQuestionsGroup } from 'types/ProfileQuestions';
import { Back, ContainerSizes, PageContainer } from 'components';

import styles from './ProfileHeader.module.scss';

export const ProfileHeader = ({
  currentQuestionIdx,
  questions,
  goBack,
}: {
  currentQuestionIdx: number;
  questions: ProfileQuestionsGroup[];
  goBack: () => void;
}) => {
  return (
    <div className={styles.headerContainer}>
      {currentQuestionIdx > 0 ? (
        <Back goBack={goBack} />
      ) : (
        <div className={styles.blankSpace}></div>
      )}
      <PageContainer size={ContainerSizes.SM} className={styles.stepsProgress}>
        {questions?.map((s, idx) => (
          <div
            className={c(
              styles.progressMarker,
              idx < currentQuestionIdx && styles.filled,
              idx === currentQuestionIdx && styles.partial,
            )}
            key={idx}
            data-testid='profileProgressMarker'
          ></div>
        ))}
      </PageContainer>
      <div className={styles.blankSpace}></div>
    </div>
  );
};
