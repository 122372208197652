import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { ButtonColors, ButtonSizes, LinkButton, Typography } from 'components';

import styles from './ResultsOverviewSummaryCard.module.scss';

export const ResultsOverviewSummaryCard = () => {
  const { currentKitId, currentTinyAccount } = useCurrentKitAndTinyAccount();
  const name = currentTinyAccount?.first_name;
  if (!currentKitId) return <></>;
  return (
    <div className={c(styles.cardContainer)}>
      <div className={styles.cardContent}>
        <Typography variant={'heading-m'}>Results overview</Typography>

        <Typography variant={'heading-xl'}>
          {`${name}'s highest priority`}
        </Typography>
        <LinkButton
          label={'Read now'}
          href={'/review/' + currentKitId}
          color={ButtonColors.PURPLE}
          size={ButtonSizes.M}
          className={styles.button}
        />
      </div>
    </div>
  );
};
