import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import c from 'classnames';

import { parseDate } from 'helpers';

import {
  Card,
  LinkButton,
  ButtonVariants,
  ButtonSizes,
  ButtonColors,
} from 'components';
import { Icon, Typography } from '@repo/ui';

import styles from './TypeformSurveyCard.module.scss';

export const TypeformSurveyTime = ({ time }: { time: string | null }) => {
  if (!time) return <></>;
  return (
    <div
      className={c(
        'flex align-items-center gap-1',
        styles.allGrey,
        styles.noWrap,
      )}
    >
      <Icon name='clock' size='s' />
      <Typography variant='label' className='grey'>
        {time}
      </Typography>
    </div>
  );
};

type TypeformSurveyCardProps = {
  typeform_id: string;
  display_title: string;
  description?: string;
  needed?: boolean;
  linkDetail?: string;
  linkTypeform?: string;
  estimated_time?: string | null;
  dateSubmitted?: string;
};

export const TypeformSurveyCard = ({
  description,
  needed,
  linkDetail,
  linkTypeform,
  dateSubmitted,
  typeform_id,
  display_title,
  estimated_time,
}: TypeformSurveyCardProps) => {
  const { pathname } = useLocation();

  return (
    <Card id={typeform_id} variant='wrapper' className='p-3'>
      <div className='flex flex-column gap-3'>
        <div className='flex flex-column gap-2'>
          <div className='flex align-items-center justify-content-between gap-1 w-100'>
            <Typography variant='heading-m'>{display_title}</Typography>
            {needed ? (
              !!estimated_time && <TypeformSurveyTime time={estimated_time} />
            ) : (
              <div className='flex align-items-center gap-1'>
                <Icon name='check' size='m' active />
              </div>
            )}
          </div>
          {!!description && (
            <Typography variant='body-s' className='grey-text'>
              {description}
            </Typography>
          )}
        </div>

        <div className='flex align-items-center justify-content-between gap-1 w-100'>
          {needed && !!linkTypeform && (
            <LinkButton
              label='Complete survey'
              href={linkTypeform}
              state={{ from: pathname }}
              variant={ButtonVariants.SOLID}
              size={ButtonSizes.S}
              color={ButtonColors.PURPLE}
            />
          )}

          {!needed && !!linkDetail && (
            <LinkButton
              label='View response'
              href={{
                pathname: linkDetail,
              }}
              state={{ from: pathname }}
              variant={ButtonVariants.TEXT}
              size={ButtonSizes.S}
              color={ButtonColors.PURPLE}
            />
          )}

          {dateSubmitted ? (
            <Typography variant='label' className='grey-text'>
              {`Submitted on ${format(parseDate(dateSubmitted), 'MM/dd/yy')}`}
            </Typography>
          ) : null}
        </div>
      </div>
    </Card>
  );
};
