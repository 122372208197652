import { Card, CardBody } from 'reactstrap';

import { SeedingBacteriaList } from './SeedingBacteriaList';

import styles from './Seeding.module.scss';

export const SeedingBacteria = ({ data, transferredFromBirthMother }) => {
  return (
    <Card className={styles.seedingContainer}>
      <CardBody className={styles.wider}>
        <h5>Species Detail</h5>
        {data && (
          <SeedingBacteriaList
            data={data}
            transferredFromBirthMother={transferredFromBirthMother}
          />
        )}
      </CardBody>
    </Card>
  );
};
