import { useEffect, useRef, useState } from 'react';

import { ButtonColors, ButtonSizes, LinkButton } from 'components';

import styles from './FloatingCTA.module.scss';

type FloatingCTAProps = {
  label: string;
  href: string | { pathname: string; search?: string };
  state?: { from: string };
  children: React.ReactNode;
  extra?: React.ReactNode;
  external?: boolean;
};

export const FloatingCTA = ({
  label,
  href,
  state,
  children,
  extra,
  external,
}: FloatingCTAProps) => {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const targetElementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          // If the target element is in the viewport, set its visibility to true
          if (entry.isIntersecting) {
            setIsElementVisible(true);
          } else {
            setIsElementVisible(false);
          }
        });
      },
      {
        threshold: 0.5, // Adjust this threshold as needed (0.5 means at least 50% of the target element must be in the viewport)
      },
    );
    const current = targetElementRef.current;
    // Start observing the target element
    if (current) {
      observer.observe(current);
    }
    return () => {
      // Clean up the observer when the component unmounts
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <div ref={targetElementRef} className={styles.stickyDesktop}>
        {children}
      </div>
      {!isElementVisible && (
        <div className={styles.floating}>
          <div className='flex flex-column text-center'>
            <LinkButton
              label={label}
              href={href}
              state={state}
              external={external}
              width={'full'}
              size={ButtonSizes.M}
              color={ButtonColors.PURPLE}
            />
            {!!extra && <div className={styles.extraContent}>{extra}</div>}
          </div>
        </div>
      )}
    </div>
  );
};
