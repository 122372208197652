export enum FeedbackPageType {
  ACTION = 'action',
  INSIGHT = 'insight',
  CONDITION = 'condition',
  SUMMARY = 'summary',
  REVIEW = 'expert_review',
}

export type Feedback = {
  kit_id: string;
  page_type: FeedbackPageType;
  page_id: number;
  answer: boolean;
  mainaccount_id?: string;
  rating?: number;
  feedback?: string;
};
